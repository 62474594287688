import dynamic from 'next/dynamic'

import { AntecipationFeesToggle } from '@/components/AntecipationFeesToggle'
import Icon from '@/components/Icon'
import { PlanCards } from '@/components/PlansSelectCard/components/PlanCards'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import {
  ALL_MACHINES_COMMOM_INFO,
  ALL_USER_TAGS_PLANS_MAPPER
} from '@/constants/machines'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { useCatalog } from '@/contexts/catalog'
import { useMachines } from '@/contexts/machines'
import { usePlans } from '@/contexts/plans'
import { ReceivingOptions } from '@/types/fees'
import { AllMachinesType, MachinesInfoDetails } from '@/types/machines'
import { AllPlansType } from '@/types/plans'

import MachineImage from './components/MachineImageV2'
import styles from './MachineHero.module.css'

const MachinePrice = dynamic(() => import('@/components/MachinePrice'))

interface MachineHeroProps {
  machineName: {
    value: AllMachinesType
  }
}

export default function MachineHero({ machineName }: MachineHeroProps) {
  const { receivingOptions, setReceivingOptions } = usePlans()
  const { machineFromPlan } = useCatalog()
  const { machinesInfo } = useMachines()

  const machineSelected = machineFromPlan.machines.find(
    (machine) => machine.machineName === machineName?.value
  ) as MachinesInfoDetails

  const machineInfo = machinesInfo[machineName.value] || {}
  const machinePopularName =
    ALL_MACHINES_COMMOM_INFO[machineName.value].popularName
  const planeByCatalog = ALL_USER_TAGS_PLANS_MAPPER[
    machineSelected.catalog
  ] as AllPlansType

  const planPopularName = ALL_PLANS_COMMOM_INFO[planeByCatalog].popularName

  const machineImages = machineInfo.imageName

  return machineSelected ? (
    <div className={styles.machineContainer}>
      <div id="machine-title" className={styles.machineTitle}>
        <h1 className="font-extrabold ton-heading-1">
          {machinePopularName}{' '}
          <span className="font-light">{planPopularName}</span>
        </h1>
      </div>

      <div id="machine-images" className={styles.machineImages}>
        <MachineImage
          machineName={machineSelected.machineName}
          images={machineImages}
          badges={machineSelected?.badges}
          plan={planPopularName}
        />
      </div>

      <div id="machine-plans" className={styles.machinePlans}>
        <div className="flex flex-col gap-16 items-center">
          <div className="flex flex-col gap-8">
            <AntecipationFeesToggle.Root>
              <AntecipationFeesToggle.Label text="Recebimento da Venda" />

              <AntecipationFeesToggle.Content>
                <AntecipationFeesToggle.Button
                  onClick={() =>
                    setReceivingOptions(ReceivingOptions.OneBusinessDay)
                  }
                  condition={
                    receivingOptions === ReceivingOptions.OneBusinessDay
                      ? 'active'
                      : 'default'
                  }
                  text="1 dia útil"
                  id="btn-term-1-day"
                >
                  <Icon name="cent-clock" className="w-24 h-24 fill-current" />
                </AntecipationFeesToggle.Button>

                <AntecipationFeesToggle.Button
                  onClick={() => setReceivingOptions(ReceivingOptions.SameDay)}
                  condition={
                    receivingOptions === ReceivingOptions.SameDay
                      ? 'active'
                      : 'default'
                  }
                  text="No mesmo dia"
                  id="btn-term-0-day"
                >
                  <Icon name="cent-hand" className="w-24 h-24 fill-current" />
                </AntecipationFeesToggle.Button>
              </AntecipationFeesToggle.Content>
            </AntecipationFeesToggle.Root>
          </div>
          <div className="flex flex-col items-center w-[300px] md:w-auto">
            <PlanCards verticalOnly />
          </div>
          <div className="flex flex-col gap-8">
            <p className="text-center">
              Confira as{' '}
              <RetainQueryLink
                href="/planos-e-taxas"
                className="text-newTon-700 underline"
              >
                taxas de todos os planos
              </RetainQueryLink>
              .
            </p>

            <p className="text-center">
              Taxa 0% no Pix nos primeiros 30 dias após a primeira venda com Pix
              na maquininha.
            </p>
          </div>
        </div>
      </div>

      <div id="machine-features" className={styles.machineFeatures}>
        <ul className="flex flex-col gap-16">
          {machineSelected.features.map((feature) => (
            <li
              className="flex font-medium first:font-bold first:text-newTon-700"
              key={feature.text}
            >
              <Icon name={feature.icon} className="fill-current" />{' '}
              <p className="ml-8 paragraph-14">{feature.text}</p>
            </li>
          ))}
        </ul>
      </div>

      <div id="machine-price" className={styles.machinePrice}>
        {machineSelected.original_price && machineSelected.amount && (
          <MachinePrice
            from={machineSelected.original_price}
            to={machineSelected.amount}
            or={machineSelected.installmentValue}
            className="lg:gap-8 justify-between lg:justify-start"
            installment={machineSelected.installmentParcel}
          />
        )}
      </div>

      <div id="machine-action" className={styles.machineAction}>
        <RetainQueryLink
          href={machineSelected.linkToCheckout}
          className="w-full btn btn-large btn-primary"
        >
          {`Pedir ${machinePopularName} ${planPopularName}`}
        </RetainQueryLink>
      </div>
    </div>
  ) : (
    <div />
  )
}
