import { RetainQueryLink } from '@/components/RetainQueryLink'
import { useCountdownsContext } from '@/contexts/countdowns'
import useRegressiveCounter from '@/utils/useRegressiveCounter'

export function CountdownBanner() {
  const { countdownsByID, activeCountdowns } = useCountdownsContext()

  const countdown = countdownsByID.get('promo-landing')
  const showCountdown = activeCountdowns.has('promo-landing')

  const { day, hour, minute, second } = useRegressiveCounter({
    finalDate: countdown?.period.endAt || ''
  })

  if (!showCountdown) return null

  return (
    <RetainQueryLink
      href={{ hash: 'maquininhas' }}
      className="flex gap-8 md:gap-16 justify-center items-center p-8 md:px-24 w-full bg-newTon-600 cursor-pointer"
    >
      {countdown?.text && (
        <div className="p-4 md:p-8 w-min min-w-[140px] xs:min-w-max max-w-[162px] md:max-w-none xs:max-w-none text-center md:text-right bg-newTon-900 rounded-[8px]">
          <span className="text-[12px] md:text-[14px] font-bold leading-[18px] md:leading-[20px] text-display-0">
            {countdown.text}
          </span>
        </div>
      )}

      <div className="flex gap-4 md:gap-16 text-white">
        {day >= 0 && (
          <>
            <div className="flex flex-col items-center">
              <span className="font-heading text-[28px] md:text-[40px] font-bold leading-[28px] md:leading-[48px]">
                {String(day).padStart(1, '0')}
              </span>
              <span className="text-[10px] md:text-[12px] font-normal leading-[18px]">
                dia{day > 1 && 's'}
              </span>
            </div>
            <span className="my-auto text-[20px] font-bold leading-[28px]">
              :
            </span>
          </>
        )}

        {hour >= 0 && (
          <>
            <div className="flex flex-col items-center">
              <span className="font-heading text-[28px] md:text-[40px] font-bold leading-[28px] md:leading-[48px]">
                {String(hour).padStart(2, '0')}
              </span>
              <span className="text-[10px] md:text-[12px] font-normal leading-[18px]">
                hora{hour > 1 && 's'}
              </span>
            </div>
            <span className="my-auto text-[20px] font-bold leading-[28px]">
              :
            </span>
          </>
        )}

        {minute >= 0 && (
          <>
            <div className="flex flex-col items-center">
              <span className="font-heading text-[28px] md:text-[40px] font-bold leading-[28px] md:leading-[48px]">
                {String(minute).padStart(2, '0')}
              </span>
              <span className="text-[10px] md:text-[12px] font-normal leading-[18px]">
                minuto{minute > 1 && 's'}
              </span>
            </div>
            <span className="my-auto text-[20px] font-bold leading-[28px]">
              :
            </span>
          </>
        )}

        {second >= 0 && (
          <div className="flex flex-col items-center">
            <span className="font-heading text-[28px] md:text-[40px] font-bold leading-[28px] md:leading-[48px]">
              {String(second).padStart(2, '0')}
            </span>
            <span className="text-[10px] md:text-[12px] font-normal leading-[18px]">
              segundo{second > 1 && 's'}
            </span>
          </div>
        )}
      </div>
    </RetainQueryLink>
  )
}
