import { ReactNode } from 'react'

export interface AntecipationFeesToggleRootProps {
  children: ReactNode
  className?: string
}

export const AntecipationFeesToggleRoot = ({
  children,
  className
}: AntecipationFeesToggleRootProps) => {
  return (
    <div
      className={`flex flex-wrap gap-8 justify-center items-center w-full ${className}`}
    >
      {children}
    </div>
  )
}
