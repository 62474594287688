/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react'

import Button from '@/cmsComponents/ButtonV2'
import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import { PROMOTIONAL_DEFAULT_TIER } from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString } from '@/helpers/fees'
import { FeesProps } from '@/types/cms'
import { InformationFeesDetails } from '@/types/fees'
import {
  AllPlansForMachine,
  AllPlansForSmartphone,
  Liquidations
} from '@/types/plans'

import { ButtonV2Props } from '../ButtonV2'
import styles from './styles.module.css'
import { feesModelTypes } from '../DefaultHero'

type UltraRatesProps = Omit<FeesProps, 'referId' | 'buttons' | 'subtitle'> & {
  id: string
  buttons?: ButtonV2Props[]
  subtitle?: string
  feesModel:
    | feesModelTypes.Tapton
    | feesModelTypes.Machine
    | feesModelTypes.Link
  feesAntecipation: 'daily' | 'business-day'
}

const UltraRates: React.FC<UltraRatesProps> = ({
  id,
  title,
  subtitle,
  band,
  buttons,
  feesModel,
  feesAntecipation
}) => {
  const { allPlansAndFees } = usePlans()
  const feesBoleto = 'R$ 2,99'

  const machineFees = allPlansAndFees.find(
    (plan) =>
      plan.planName === AllPlansForMachine.Pro &&
      plan.tierName === PROMOTIONAL_DEFAULT_TIER
  )?.fees as InformationFeesDetails

  const smartphoneFees = allPlansAndFees.find(
    (plan) => plan.planName === AllPlansForSmartphone.Tapton
  )?.fees as InformationFeesDetails

  // Taxa de débito
  const fees = useMemo(() => {
    const feeByPlanType =
      feesModel === feesModelTypes.Tapton ? smartphoneFees : machineFees

    if (
      feesAntecipation === Liquidations.Daily &&
      feesModel !== feesModelTypes.Link
    ) {
      return feeByPlanType?.same_day.mainFlags
    }

    const allFees =
      feesModel === feesModelTypes.Link
        ? feeByPlanType?.one_month.mainFlags
        : feeByPlanType?.one_business_day.mainFlags

    return allFees
  }, [machineFees, smartphoneFees, feesModel, feesAntecipation])

  return (
    <>
      <div
        id={id}
        className="flex justify-center items-center py-24 px-16 md:px-12 lg:px-0 w-full bg-display-900"
      >
        <div className="flex flex-col md:flex-row flex-wrap gap-16 lg:gap-40 justify-center items-center md:px-0 md:m-0 w-full max-w-[455px] md:max-w-[1280px]">
          <div className="flex flex-col w-full md:w-[170px] lg:w-auto">
            <p className="font-heading text-[28px] lg:text-[32px] font-bold leading-[36px] lg:leading-[40px] text-center md:text-right text-display-0">
              {title}
            </p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
          <div className="flex flex-wrap gap-16 md:gap-24 justify-center items-center md:p-16 py-16 px-16 w-full md:w-auto max-w-[620px] bg-display-800 rounded-[16px]">
            <div className="flex flex-col">
              <p className="font-bold text-center md:text-left text-display-0 paragraph-18 lg:paragraph-20">
                {feesModel === feesModelTypes.Link ? 'Boleto' : 'Débito'}
              </p>
              <p
                id="btn-tapton-db"
                className="text-[20px] lg:text-[32px] font-bold leading-[28px] lg:leading-[40px] text-center md:text-left text-newTon-400"
              >
                {feesModel === feesModelTypes.Link
                  ? feesBoleto
                  : formatFeeToString(fees[0])}
              </p>
            </div>
            <div className="flex flex-col">
              <p
                id="btn-tapton-cr"
                className="font-bold text-center md:text-left text-display-0 paragraph-18 lg:paragraph-20"
              >
                Crédito
              </p>
              <p
                id="btn-tapton-cr"
                className="text-[20px] lg:text-[32px] font-bold leading-[28px] lg:leading-[40px] text-center md:text-left text-newTon-400"
              >
                {formatFeeToString(fees[1])}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="font-bold text-center md:text-left text-display-0 paragraph-18 lg:paragraph-20">
                Parc. 12x
              </p>
              <p
                id="btn-tapton-crparc"
                className="text-[20px] lg:text-[32px] font-bold leading-[28px] lg:leading-[40px] text-center md:text-left text-newTon-400"
              >
                {formatFeeToString(fees[12])}
              </p>
            </div>
          </div>
          <div className="lg:mt-0 w-full md:w-auto lg:max-w-[290px]">
            <div className="flex flex-col md:flex-col gap-16 lg:gap-24 xl:gap-16 md:justify-center items-center md:w-full">
              {buttons?.map((props) => (
                <Button key={props.label} {...props} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {band && (
        <div className="flex justify-center w-full text-display-900 bg-display-100">
          <MarkdownContent content={band} className={styles.BandCustom} />
        </div>
      )}
    </>
  )
}

export default UltraRates
