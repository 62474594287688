import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type ClientOnlyPortalProps = {
  children: React.ReactNode
  selector: string
}

export default function ClientOnlyPortal({
  children,
  selector
}: ClientOnlyPortalProps) {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const element = document.querySelector(selector)
    if (element) {
      ref.current = element

      setMounted(true)
    }
  }, [selector])

  return mounted && ref.current ? createPortal(children, ref.current) : <></>
}
