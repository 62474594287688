export interface AntecipationFeesToggleLabelProps {
  text: string
  className?: string
}

export const AntecipationFeesToggleLabel = ({
  text,
  className
}: AntecipationFeesToggleLabelProps) => {
  return (
    <div className="flex">
      <p className={`font-medium paragraph-16 ${className}`}>{text}</p>
    </div>
  )
}
