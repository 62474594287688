import React from 'react'

import DefaultTag from '@/cmsComponents/DefaultTag'
import HeroPromoUntil35OFF from '@/components/PromoUntil35OFF/components/HeroPromoUntil35OFF'
import { useAmplitudeTestAB } from '@/hooks/useAmplitudeTestAB'
import { CMSEventParams } from '@/services/amplitude/event.shared.interfaces'

import ActiveDiscountCoupon from '../ActiveDiscountCoupon'
import { Alert } from '../Alert'
import { AllMachinesDetailed } from '../AllMachinesDetailed'
import { AllMachinesInCard } from '../AllMachinesInCard'
import BandTitleDescription from '../BandTitleDescription'
import BannerAboutUs from '../BannerAboutUs'
import BannerBGDivided from '../BannerBGDivided'
import BannerInformations from '../BannerInformations'
import { BasicBand } from '../BasicBand'
import { BasicBanner } from '../BasicBanner'
import { BenefitsListCard } from '../BenefitsListCard'
import { Calculator } from '../Calculator'
import { CardBanner } from '../CardBanner'
import { CardsCarousel } from '../CardsCarousel'
import { CardsOverImage } from '../CardsOverImage'
import { CardVideoBenefits } from '../CardVideoBenefits'
import { CarouselBar } from '../CarouselBar'
import { CMSTrackViewProvider } from '../CMSTrackViewProvider'
import { CountdownBanner } from '../CountdownBanner'
import { DefaultHero } from '../DefaultHero'
import DiscountFormModal from '../DiscountFormModal'
import { DocumentsCarousel } from '../DocumentsCarousel'
import { Faq } from '../Faq'
import { FeesCardInformation } from '../FeesCardInformation'
import { FeeStatement } from '../FeeStatement'
import { FlagsCarousel } from '../FlagsCarousel'
import Flags from '../Flagsv2'
import { Footer } from '../Footer'
import { Header } from '../Header'
import HeroCardStyle from '../HeroCardStyle'
import { HighlightPanel } from '../HighlightPanel'
import { IndicateAndAdvantages } from '../IndicateAndAdvantages'
import { InfoCardsList } from '../InfoCardsList'
import { InfoEnsemble } from '../InfoEnsemble'
import { InfosWithFeaturedImage } from '../InfosWithFeaturedImage'
import InfosWithFeaturedVideo from '../InfosWithFeaturedVideo'
import LowerBand from '../LowerBand'
import MachineHero from '../MachineHero'
import MachinesAboutUs from '../MachinesAboutUs'
import { OverviewPanel } from '../OverviewPanel'
import { PlainBanner } from '../PlainBanner'
import { Plans } from '../Plans'
import PlansAndFees from '../PlansAndFees'
import { Reasons } from '../Reasons'
import { ReviewsInVideo } from '../ReviewInVideo'
import { ReviewsInText } from '../ReviewsInText/indext'
import { SimplePlanCardCarrousel } from '../SimplePlanCardCarrousel'
import { StepwiseGuide } from '../StepwiseGuide'
import { StrippedInfoBanner } from '../StrippedInfoBanner'
import { SwipperBenefits } from '../SwiperBenefits'
import { TextLegal } from '../TextLegal'
import { TextTestimonials } from '../TextTestimonials'
import VideoSteps from '../VideoSteps'
import { VideoStepsByStep } from '../VideoStepsByStep'
import { VideoTestimonials } from '../VideoTestimonials'

const componentsMapping = {
  'contents.faq': Faq,
  'contents.basic-banner': BasicBanner,
  'contents.default-hero': DefaultHero,
  'contents.info-ensemble': InfoEnsemble,
  'contents.info-cards-list': InfoCardsList,
  'contents.stepwise-guide': StepwiseGuide,
  'contents.striped-info-banner': StrippedInfoBanner,
  'contents.basic-band': BasicBand,
  'contents.header': Header,
  'contents.footer': Footer,
  'contents.carousel-bar': CarouselBar,
  'contents.another-machines': AllMachinesInCard,
  'contents.fee-card': FeesCardInformation,
  'contents.flags': Flags,
  'contents.flags-carousel': FlagsCarousel,
  'contents.machine-and-fees': MachineHero,
  'contents.active-discount-coupon': ActiveDiscountCoupon,
  'contents.calculator-v2': Calculator,
  'contents.plans-and-fees': PlansAndFees,
  'contents.all-machines': AllMachinesInCard,
  'contents.cards-carousel': CardsCarousel,
  'contents.card-banner': CardBanner,
  'contents.video-steps': VideoSteps,
  'contents.infos-with-featured-video': InfosWithFeaturedVideo,
  'contents.infos-with-featured-image': InfosWithFeaturedImage,
  'contents.video-steps-by-steps': VideoStepsByStep,
  'contents.benefits-list-card': BenefitsListCard,
  'contents.indicate-and-advantages': IndicateAndAdvantages,
  'contents.video-testimonials': VideoTestimonials,
  'contents.text-testimonials': TextTestimonials,
  'contents.cards-over-image': CardsOverImage,
  'contents.countdown-banner': CountdownBanner,
  'contents.promo-hero': HeroPromoUntil35OFF,
  'contents.reasons': Reasons,
  'contents.plans': Plans,
  'contents.all-machines-detailed': AllMachinesDetailed,
  'contents.documents-carousel': DocumentsCarousel,
  'contents.simple-plan-card-carrousel': SimplePlanCardCarrousel,
  'contents.reviews-in-text': ReviewsInText,
  'contents.reviews-in-video': ReviewsInVideo,
  'contents.overview-panel': OverviewPanel,
  'contents.highlight-panel': HighlightPanel,
  'contents.plain-banner': PlainBanner,
  'contents.hero-cards-style': HeroCardStyle,
  'contents.lower-band': LowerBand,
  'contents.banner-bg-divided': BannerBGDivided,
  'contents.machines-about-us': MachinesAboutUs,
  'contents.banner-about-us': BannerAboutUs,
  'contents.banner-informations': BannerInformations,
  'contents.band-title-description': BandTitleDescription,
  'contents.discount-form-modal': DiscountFormModal,
  'contents.default-tag': DefaultTag,
  'contents.alert': Alert,
  'contents.card-video-benefits': CardVideoBenefits,
  'contents.swiper-benefits': SwipperBenefits,
  'contents.text-legal': TextLegal,
  'contents.fee-statement': FeeStatement
}

export type AvailableComponentName = keyof typeof componentsMapping

type ComponentProps = { [key: string]: unknown }

export interface CMSAmplitudeExperiment {
  key: string
  isFlag: boolean
  isActive: boolean
}

export interface CMSExperiment {
  amplitude_experiment: CMSAmplitudeExperiment
  variantName: string
  isControl: boolean
}

export type DynamicComponentProps<T = unknown> = {
  name: AvailableComponentName
  experiment?: CMSExperiment | null
  trackView?: CMSEventParams
} & ComponentProps &
  T

type DynamicComponentType = (
  props: React.JSX.IntrinsicAttributes
) => JSX.Element

function Fallback() {
  return <></>
}

export function DynamicComponent({ name, ...cmsProps }: DynamicComponentProps) {
  const { activeVariant } = useAmplitudeTestAB(
    cmsProps.experiment?.amplitude_experiment?.key,
    cmsProps.experiment?.amplitude_experiment?.isFlag,
    cmsProps.experiment?.amplitude_experiment?.isActive
  )

  const Component = componentsMapping[name] as unknown as DynamicComponentType
  if (!Component) return <Fallback />

  const thereIsExperimentAtCMS = Boolean(
    cmsProps.experiment?.amplitude_experiment?.key
  )
  const isThisControlVariant = Boolean(cmsProps.experiment?.isControl)
  const variantHaveNotBeenLoadedYet = thereIsExperimentAtCMS && !activeVariant
  const isSameCMSAndAmplitudeVariant =
    cmsProps.experiment?.variantName === activeVariant

  const shouldRenderThisVariant =
    isSameCMSAndAmplitudeVariant ||
    (variantHaveNotBeenLoadedYet && isThisControlVariant)

  if (thereIsExperimentAtCMS && !shouldRenderThisVariant) {
    return <Fallback />
  }

  return (
    <CMSTrackViewProvider
      trackView={cmsProps.trackView}
      experiment={cmsProps.experiment}
    >
      <Component {...(cmsProps as React.JSX.IntrinsicAttributes)} />
    </CMSTrackViewProvider>
  )
}
