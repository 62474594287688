import Band from '@/cmsComponents/Band'
import Button, { ButtonV2Props } from '@/cmsComponents/ButtonV2'

export interface BasicBandProps {
  label: string
  buttons: ButtonV2Props[]
  referId?: string
  description?: string | null
  backgroundColor?: string | null
  variant?: 'default' | 'buttons-gap-md-24'
}

export function BasicBand(props: BasicBandProps) {
  return (
    <Band
      title={props.label}
      description={props.description}
      backgroundColor={props.backgroundColor}
      referId={props.referId}
      variant={props.variant}
    >
      {props?.buttons.map((buttonProps, index) => (
        <Button
          {...buttonProps}
          key={index}
          className="flex order-2 md:order-3 w-full md:w-max font-bold"
        />
      ))}
    </Band>
  )
}
