import { ScoreItemProps } from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import { StarRating } from './components/StarRating'

interface ScoreProps {
  scores: ScoreItemProps[]
}

export const Score = ({ scores }: ScoreProps) => {
  return (
    scores && (
      <div className="flex gap-24 md:gap-32 justify-center p-16 md:px-24 max-w-max bg-display-0 rounded-16 border">
        {scores.map(({ name, image, rating, ratingLimit, stars }) => (
          <div key={name} className="flex flex-col gap-4">
            {image && (
              <div className="flex justify-center items-center w-[76px] md:w-[96px] h-[28px]">
                <CloudinaryImage
                  width={image.width}
                  height={image.height}
                  src={image?.path}
                  alt={image.alternativeText}
                  quality={90}
                  className="object-contain max-w-full h-auto"
                />
              </div>
            )}
            <div className="flex flex-col gap-8">
              {rating && ratingLimit && (
                <p className="text-center">
                  <span className="font-bold ton-paragraph-32">{rating}</span>
                  <span className="text-display-500 ton-paragraph-16">
                    /{ratingLimit}
                  </span>
                </p>
              )}

              {stars && <StarRating stars={stars} />}
            </div>
          </div>
        ))}
      </div>
    )
  )
}
