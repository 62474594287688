import useSWRImmultable from 'swr/immutable'
import experimentFetcher from '../experimentFetcher'

export default function useExperiment(experimentId?: string) {
  const { data, error, isLoading } = useSWRImmultable(
    experimentId,
    experimentFetcher
  )

  return {
    variantKey: experimentId ? data?.data[experimentId]?.key : null,
    data,
    error,
    isLoading
  }
}
