import { BackgroundColorProps } from '@/types/cms'

interface TextLegalProps {
  referId: string
  background?: {
    colors: BackgroundColorProps
  }
  description: string
}

export function TextLegal({
  referId,
  background,
  description
}: TextLegalProps) {
  return (
    <section
      id={referId}
      className={`flex justify-center items-center px-16 md:px-24 lg:px-40 py-8 ${
        background ? background.colors : 'bg-display-100'
      }`}
    >
      <p className="text-center ton-paragraph-14">{description}</p>
    </section>
  )
}
