import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'

import CloudinaryImage from '@/components/CloudinaryImage'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import {
  ALL_PLANS_COMMOM_INFO,
  PROMOTIONAL_DEFAULT_TIER
} from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString } from '@/helpers/fees'
import { hasBlackFriday, typeStyles } from '@/helpers/hasBlackFriday'
import { PlanAndFeesInfo } from '@/types/fees'
import { AllPlansForMachine, AllPlansType } from '@/types/plans'
import cn from '@/utils/cn'

import { PlanCardModalInformation } from '../PlanCardModalInformation'

export interface PlanCardProps {
  planToCard: AllPlansType
  fullWidth?: boolean
  showTapTonCard?: boolean
  imgSrc: string
}

export const PlanCardItem = ({
  planToCard,
  fullWidth,
  imgSrc
}: PlanCardProps) => {
  const { plan, setPlan, allPlansAndFees, receivingOptions, flagsGroup } =
    usePlans()

  const { pathname } = useRouter()

  const [isOpen, setIsOpen] = useState(false)

  // Informações do plano a ser exibido no card
  const informationsPlanToCard = allPlansAndFees.find((plan) => {
    if (planToCard === AllPlansForMachine.Pro) {
      return (
        plan.planName === planToCard &&
        plan.tierName === PROMOTIONAL_DEFAULT_TIER
      )
    }

    return plan.planName === planToCard
  }) as PlanAndFeesInfo

  // Informações das taxas do plano a ser exibido no card
  const feesToCard = useMemo(() => {
    return informationsPlanToCard?.fees[receivingOptions][flagsGroup]
  }, [flagsGroup, informationsPlanToCard, receivingOptions])

  return (
    <>
      <label
        htmlFor={`planCardInput-${planToCard}`}
        className={cn(
          'flex overflow-hidden flex-col rounded-16 border cursor-pointer',
          fullWidth ? 'w-full' : 'w-[300px]',
          informationsPlanToCard?.planName === plan
            ? 'border-newTon-700'
            : 'border-display-300'
        )}
      >
        <div className="flex flex-col h-full">
          <RetainQueryLink
            href={{
              hash: pathname.includes('/maquininha/') ? '' : '#maquininhas' // TODO: Se não estiver na página de /maquininha, redireciona para a seção de maquininhas
            }}
            onClick={() => {
              setPlan(planToCard)
            }}
          >
            <div
              className={cn(
                hasBlackFriday(planToCard, typeStyles.backgroundColor),
                'flex flex-col gap-12 py-16 h-full'
              )}
            >
              <div className="flex gap-8 px-16">
                <div className="w-full">
                  <div className="flex gap-8 items-center">
                    <input
                      type="radio"
                      name={`planCardInput-${planToCard}`}
                      id={`planCardInput-${planToCard}`}
                      value={planToCard}
                      checked={informationsPlanToCard?.planName === plan}
                      onChange={() => {}}
                    />

                    <h5
                      className={cn(
                        hasBlackFriday(planToCard, typeStyles.titleColor),
                        'font-heading text-24 font-bold ton-heading-5'
                      )}
                    >
                      {ALL_PLANS_COMMOM_INFO[planToCard].popularName}
                    </h5>
                  </div>

                  {informationsPlanToCard?.description && (
                    <span
                      className={cn(
                        hasBlackFriday(planToCard, typeStyles.titleColor),
                        'ton-paragraph-16'
                      )}
                    >
                      {informationsPlanToCard.description}
                    </span>
                  )}
                </div>

                <div className="w-64">
                  <CloudinaryImage
                    src={`site-ton/${imgSrc}`}
                    width={128}
                    height={128}
                  />
                </div>
              </div>

              <div className="flex">
                <div className="flex flex-col gap-4 w-full">
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.titleColor),
                      'text-center ton-paragraph-12'
                    )}
                  >
                    DÉBITO
                  </b>
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.feesColor),
                      'font-heading text-center text-newTon-700 ton-paragraph-28'
                    )}
                  >
                    {feesToCard && formatFeeToString(feesToCard[0])}
                  </b>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.titleColor),
                      'text-center ton-paragraph-12'
                    )}
                  >
                    CRÉDITO
                  </b>
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.feesColor),
                      'font-heading text-center text-newTon-700 ton-paragraph-28'
                    )}
                  >
                    {feesToCard && formatFeeToString(feesToCard[1])}
                  </b>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.titleColor),
                      'text-center ton-paragraph-12'
                    )}
                  >
                    CRÉDITO 12X
                  </b>
                  <b
                    className={cn(
                      hasBlackFriday(planToCard, typeStyles.feesColor),
                      'font-heading text-center text-newTon-700 ton-paragraph-28'
                    )}
                  >
                    {feesToCard && formatFeeToString(feesToCard[12])}
                  </b>
                </div>
              </div>
            </div>
          </RetainQueryLink>
          <div
            className={cn(
              'flex justify-center items-center p-8 bg-display-100',
              hasBlackFriday(planToCard, typeStyles.backgroundFeesColor),
              !fullWidth && 'min-h-[52px]'
            )}
          >
            {AllPlansForMachine.Pro === planToCard ? (
              <span
                className={cn(
                  hasBlackFriday(planToCard, typeStyles.titleColor),
                  'text-center ton-paragraph-12'
                )}
              >
                Taxas promocionais válidas durante 30 dias ou até vender 5 mil
                reais.{' '}
                <button
                  type="button"
                  onClick={() => setIsOpen(true)}
                  className={cn(
                    hasBlackFriday(planToCard, typeStyles.titleInfoFees),
                    'text-newTon-700 underline outline-none'
                  )}
                >
                  Saiba mais
                </button>
              </span>
            ) : (
              <span className="text-center ton-paragraph-12">
                Sem mínimo de vendas
              </span>
            )}
          </div>
        </div>
      </label>

      <PlanCardModalInformation isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
