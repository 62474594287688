/* eslint-disable tailwindcss/no-custom-classname */

import { FlagNames, FlagSprite } from '@/components/IconSprite'
import { FlagsProps } from '@/types/cms'

import styles from './FlagsCarousel.module.css'

export interface FlagsCarouselProps extends FlagsProps {
  theme?: string
}

const FlagsCarousel = ({
  cards,
  title,
  description,
  referId,
  theme
}: FlagsCarouselProps) => {
  return (
    <div className={`${theme && theme}`}>
      <div
        id={referId}
        className={`relative py-24 px-16 md:px-24 xl:px-0 mx-auto w-full max-w-[412px] md:max-w-[1280px] ${
          theme && theme
        }`}
      >
        <div
          className={`flex overflow-hidden w-full flex-col gap-16 before:left-[7px] md:before:left-[23px] xl:before:left-0 after:right-[7px] md:after:right-[23px] xl:after:right-0 ${
            theme ? styles.containerGray : styles.containerWhite
          }`}
        >
          <div className="flex flex-col gap-8 justify-center items-center">
            <p className="flex z-10 justify-center max-w-[328px] md:max-w-[fit-content] font-bold text-center paragraph-16">
              {title}
            </p>
            {description && (
              <p className="flex z-10 justify-center font-normal text-center font-inter paragraph-14">
                {description}
              </p>
            )}
          </div>
          <div
            className={`flex flex-row overflow-hidden white-space-nowrap relative mx-auto ${styles.logos}`}
          >
            {[...Array(20)].map((_, index) => (
              <div
                key={index}
                className={`flex flex-row overflow-hidden pl-16 gap-16 h-full ${styles.logosSlide}`}
              >
                {cards.map(({ flag }, index) => (
                  <div
                    key={`Flag ${index} 1`}
                    className="flex items-center !w-[48px] !h-[32px]"
                  >
                    <FlagSprite
                      name={flag as FlagNames}
                      className="!w-[48px] !h-[32px]"
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlagsCarousel
