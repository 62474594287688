import { tv } from 'tailwind-variants'

import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import { ColorProps, IconAndDescriptionV2Props, ImagesProps } from '@/types/cms'

import Button, { ButtonV2Props } from '../ButtonV2'
import cn from '@/utils/cn'

interface CardBannerProps {
  referId?: string
  tag: string
  title: string
  description: string
  images: ImagesProps
  buttons: ButtonV2Props[]
  backgroundColor?: ColorProps
  cards?: IconAndDescriptionV2Props[]
  variant: 'default' | 'mobile-compressed'
}

const styles = tv({
  slots: {
    container: 'relative xl:px-0'
  },
  variants: {
    variant: {
      default: {
        container: 'lg:py-80 p-16 md:p-24'
      },
      'mobile-compressed': {
        container: 'lg:py-80 p-24'
      },
      'less-vertical-spacing': {
        container: 'lg:py-40 p-16 md:p-24'
      }
    }
  }
})

export function CardBanner({
  referId,
  tag,
  title,
  description,
  images,
  buttons,
  cards,
  backgroundColor,
  variant = 'default'
}: CardBannerProps) {
  const classes = styles({ variant: variant || 'default' })

  return (
    <div
      id={referId}
      className={cn(
        classes.container(),
        backgroundColor?.classColor
          ? `bg-${backgroundColor.classColor}`
          : 'bg-transparent'
      )}
    >
      <div
        className={cn(
          'flex overflow-hidden md:relative flex-row justify-between items-stretch m-auto w-full max-w-[455px] md:max-w-[1060px] h-full md:min-h-[357px] lg:min-h-[452px] bg-display-100 rounded-[24px] md:rounded-[40px] !rounded-bl-none',
          images.isReverseImage && 'md:flex-row-reverse'
        )}
      >
        <div className="flex z-[1] flex-col lg:p-[40px] md:py-32 md:px-24 w-full">
          <div className="flex flex-col justify-center w-full h-full">
            <div className="flex md:hidden object-cover w-full min-h-[192px]">
              <CloudinaryImage
                src={images.mobile.path}
                width={images.mobile.width}
                height={192}
                quality={100}
                alt={images.mobile.alternativeText}
                className="object-cover max-w-full h-auto"
              />
            </div>
            <div
              className={cn(
                'flex gap-24 lg:gap-40 md:p-0 px-16 md:px-24 pt-24 w-full',
                images.isReverseImage && 'md:flex-row-reverse'
              )}
            >
              <div className="flex flex-col flex-1 justify-center items-center md:items-start w-full md:max-w-[471px]">
                {tag && (
                  <span className="p-8 min-w-[38px] min-h-[33px] text-display-0 bg-danger-450 rounded-[8px] rounded-br-none lg:rounded-br-[8px] lg:rounded-bl-none paragraph-14">
                    {tag}
                  </span>
                )}
                <h2
                  className="mt-24 lg:mt-16 text-center md:text-left text-black"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <p
                  className="mt-8 text-center md:text-left lg:paragraph-18 paragraph-16"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className="hidden md:flex flex-1" />
            </div>

            {!!cards?.length && (
              <div className="flex flex-row flex-wrap gap-16 lg:gap-24 px-16 md:px-0 mx-auto mt-16 max-w-[430px] md:max-w-full">
                {cards.map(({ description, iconName }, index) => (
                  <div
                    key={`transfer card ${index}`}
                    className="flex flex-col md:flex-row flex-1 gap-[8px] lg:gap-[14px] md:justify-center lg:items-center p-16 min-w-[120px] lg:h-[85px] xl:h-[72px] bg-display-0 rounded-[8px]"
                  >
                    <Icon
                      name={iconName}
                      className="w-full min-w-[24px] max-w-[24px] text-newTon-600 fill-current"
                    />
                    <p className="xl:min-w-[151px] font-medium paragraph-14">
                      {description}
                    </p>
                  </div>
                ))}
              </div>
            )}

            <div
              className={cn(
                'flex flex-col md:flex-row gap-16 px-16 pt-24 pb-24 md:p-0 md:pt-24',
                images.isReverseImage && 'flex-col-reverse md:flex-row-reverse'
              )}
            >
              {buttons.map((props) => (
                <Button
                  key={props.label}
                  {...props}
                  className="w-full md:w-auto font-bold"
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={cn(
            'hidden md:flex lg:hidden absolute top-0 right-0 bottom-0 left-0 gap-24 lg:gap-40 items-stretch w-full',
            images.isReverseImage && 'md:flex-row-reverse'
          )}
        >
          <div className="flex-1" />
          <div className="flex flex-1 justify-end w-full min-h-full">
            <CloudinaryImage
              src={images.tablet.path}
              width={484}
              height={357}
              quality={100}
              alt={images.tablet.alternativeText}
              className="object-cover max-w-full h-auto"
            />
          </div>
        </div>
        <div
          className={cn(
            'hidden lg:flex absolute top-0 right-0 bottom-0 left-0 gap-24 lg:gap-40 items-stretch w-full',
            images.isReverseImage && 'md:flex-row-reverse'
          )}
        >
          <div className="flex-1" />
          <div className="flex flex-1 justify-end w-full min-h-full">
            <CloudinaryImage
              src={images.web.path}
              width={510}
              height={452}
              quality={100}
              alt={images.web.alternativeText}
              className="object-cover max-w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
