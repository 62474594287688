import React, { useEffect, useState } from 'react'

import { EmptyStar } from '../EmptyStar'
import { FullStart } from '../FullStar'
import { HalfStart } from '../HalfStar'

interface StarRatingProps {
  stars: number
}

export const StarRating = ({ stars }: StarRatingProps) => {
  const MAX_STARS = 5

  const [fullStars, setFullStars] = useState(0)
  const [halfStars, setHalfStars] = useState(0)
  const [emptyStar, setEmptyStars] = useState(0)

  useEffect(() => {
    if (stars > MAX_STARS) {
      setFullStars(MAX_STARS)
      setHalfStars(0)
      setEmptyStars(0)
      return
    }

    const rating = Math.round(stars * 2) / 2

    const full = Math.floor(rating)
    const half = rating % 1 !== 0 ? 1 : 0
    const empty = Math.floor(MAX_STARS - full - half)

    setFullStars(full)
    setHalfStars(half)
    setEmptyStars(empty)
  }, [stars])

  return (
    <div className="flex justify-center">
      {Array(fullStars)
        .fill('')
        .map((_, index) => (
          <FullStart width={16} height={16} key={`full-start-${index}`} />
        ))}

      {Array(halfStars)
        .fill('')
        .map((_, index) => (
          <HalfStart width={16} height={16} key={`half-start-${index}`} />
        ))}

      {Array(emptyStar)
        .fill('')
        .map((_, index) => (
          <EmptyStar width={16} height={16} key={`empty-start-${index}`} />
        ))}
    </div>
  )
}
