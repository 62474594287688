import { useCallback, useMemo, useState } from 'react'

import Icon from '@/components/Icon'
import NativeDropdown from '@/components/NativeDropdown'
import {
  nativeDropdownFlagGroupsMachine,
  nativeDropdownReceivingOption,
  nativeDropdownSaleType
} from '@/constants/fees'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString, formatValueToString } from '@/helpers/fees'
import {
  FlagsGroupType,
  InformationFeesDetails,
  ReceivingOptionsType
} from '@/types/fees'

import { feesCompetitorsPrices } from '../CalculatorTabs'
import Input from '../Input'

type CalculatorMachineProps = {
  className?: string
}

const CalculatorTapTon: React.FC<CalculatorMachineProps> = () => {
  const { planAndFeesSmartphone } = usePlans()

  // Inicializa com a bandeira MasterCard e Visa
  const [flagGroupSelected, setFlagGroupSelected] = useState(0)

  // Inicializa com o tipo de venda em 12x
  const [saleTypeSelected, setSaleTypeSelected] = useState(12)

  // Inicializa com recebimento em 1 dia útil
  const [receivingSelected, setReceivingSelected] = useState(1)

  // Inicializa o valor da venda com R$ 1000,00
  const [saleValueSelected, setSaleValueSelected] = useState(1000)

  // Obtem o valor inserido no campo de valor da venda
  const handleInputSaleValue = useCallback((value: number) => {
    setSaleValueSelected(value)
  }, [])

  // Obtem a taxa de acordo com os valores selecionados nos dropdowns
  const feeMachineSelectedByDropdowns = useMemo(() => {
    const feeSelected = planAndFeesSmartphone.fees as InformationFeesDetails

    const receivingSelectedByDropdown = nativeDropdownReceivingOption[
      receivingSelected
    ].valueType as ReceivingOptionsType

    const flagsGroupSelectedByDropdown = nativeDropdownFlagGroupsMachine[
      flagGroupSelected
    ].valueType as FlagsGroupType

    return feeSelected?.[receivingSelectedByDropdown][
      flagsGroupSelectedByDropdown
    ][saleTypeSelected]
  }, [
    planAndFeesSmartphone,
    receivingSelected,
    flagGroupSelected,
    saleTypeSelected
  ])

  // Calcula o valor liquido da venda de acordo com os valores selecionados nos dropdowns
  const valueWithTonFeeCalculated = useMemo(() => {
    const valueInPercent =
      (saleValueSelected * feeMachineSelectedByDropdowns) / 100
    return saleValueSelected - valueInPercent
  }, [feeMachineSelectedByDropdowns, saleValueSelected])

  // Calcula o valor liquido da venda de acordo com os valores selecionados nos dropdowns
  const valueWithCompetitorFeeCalculated = useMemo(() => {
    const valueInPercentFromCompetitor =
      (saleValueSelected * feesCompetitorsPrices[saleTypeSelected]) / 100

    const valueFinalFromCompetitor =
      saleValueSelected - valueInPercentFromCompetitor

    return valueWithTonFeeCalculated - valueFinalFromCompetitor
  }, [saleTypeSelected, saleValueSelected, valueWithTonFeeCalculated])

  return (
    <div className="flex flex-col md:flex-row gap-24 lg:gap-0 md:items-center lg:p-0 px-24 pt-24 pb-40 w-full bg-display-0 rounded-16 rounded-tl-none shadow-sm">
      <div className="flex flex-col gap-16 lg:p-40 w-full lg:border-r">
        {/* Bandeira */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="dropdown-select-flags-group"
            className="font-medium paragraph-16"
          >
            Bandeiras
          </label>

          <div>
            <NativeDropdown
              options={nativeDropdownFlagGroupsMachine}
              selectedOption={flagGroupSelected}
              dropdownId="dropdown-select-flags-group"
              setStateValue={setFlagGroupSelected}
              bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
              labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
              menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
            />
          </div>
        </div>

        {/* Tipo da Venda */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="dropdown-select-sale-type"
            className="font-medium paragraph-16"
          >
            Tipo da Venda
          </label>

          <div>
            <NativeDropdown
              options={nativeDropdownSaleType}
              selectedOption={saleTypeSelected}
              dropdownId="dropdown-select-sale-type"
              setStateValue={setSaleTypeSelected}
              bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
              labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
              menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
            />
          </div>
        </div>

        {/* Recebimento da Venda */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="dropdown-select-receiving"
            className="font-medium paragraph-16"
          >
            Recebimento da Venda
          </label>

          <div>
            <NativeDropdown
              options={nativeDropdownReceivingOption}
              selectedOption={receivingSelected}
              dropdownId="dropdown-select-receiving"
              setStateValue={setReceivingSelected}
              bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
              labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
              menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
            />
          </div>
        </div>

        {/* Valor da Venda */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="input-sale-value"
            className="font-medium paragraph-16"
          >
            Valor da Venda
          </label>

          <div>
            <Input
              id="input-sale-value"
              name="value"
              defaultValue={1000.0}
              handleinputsalevalue={handleInputSaleValue}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-16 md:gap-24 lg:p-40 w-full">
        <div className="flex gap-4 items-center pb-16 border-b border-display-400 ton-paragraph-16">
          <span className="flex-grow">Você recebe</span>
          <span className="font-bold text-newTon-700 whitespace-nowrap">
            {formatValueToString(valueWithTonFeeCalculated)}
          </span>
        </div>

        <div className="flex gap-4 items-center pb-16 border-b border-display-400 ton-paragraph-16">
          <span className="flex flex-col flex-grow">
            {nativeDropdownSaleType[saleTypeSelected].label}
          </span>

          <span className="font-bold whitespace-nowrap">
            {formatFeeToString(feeMachineSelectedByDropdowns)}
          </span>
        </div>

        <div className="flex gap-4 items-center ton-paragraph-16">
          <span className="flex-grow">Recebimento da venda</span>
          <span className="font-bold whitespace-nowrap">
            {nativeDropdownReceivingOption[receivingSelected].label}
          </span>
        </div>

        <div className="flex gap-16 items-center p-16 bg-display-100 rounded-16">
          <div>
            <Icon
              name="cent-piggy-bank"
              className="text-newTon-700 fill-current"
            />
          </div>
          <div className="ton-paragraph-14 md:ton-paragraph-16">
            Você economiza até{' '}
            <span className="font-bold text-newTon-700">
              {formatValueToString(
                valueWithCompetitorFeeCalculated > 0
                  ? valueWithCompetitorFeeCalculated
                  : 0
              )}
            </span>{' '}
            em relação aos concorrentes
          </div>
        </div>

        <span className="text-12 font-medium text-display-700">
          Taxas pra todos os dispositivos, Android e iOS. Para os dispositivos
          iOS, estão disponíveis apenas as bandeiras MasterCard, Visa e Amex
        </span>
      </div>
    </div>
  )
}

export default CalculatorTapTon
