import { CMSEventParams } from '@/services/amplitude/event.shared.interfaces'
import { InView } from 'react-intersection-observer'
import { CMSExperiment } from '../DynamicComponent'
import {
  AmplitudeTestKey,
  CommonEventProperties,
  trackEvent
} from '@/services/amplitude/trackEvent'
import { useCMSEvent } from '@/services/amplitude/events/useCMSEvent'

type TrackEventType = {
  description?: string
  section_reference?: string
  cta_reference?: string
  experiment_id?: string
  variant_id?: string
} & CommonEventProperties

export interface TrackViewProviderProps {
  trackView?: CMSEventParams
  experiment?: CMSExperiment | null
}

interface Props extends TrackViewProviderProps {
  children: React.ReactNode
}

export function CMSTrackViewProvider({
  trackView,
  experiment,
  children
}: Props) {
  const { rules, customEventProperties } = useCMSEvent(trackView)

  if (!trackView) {
    return <>{children}</>
  }

  const compliesWithCMSTrackRule =
    rules[trackView.ruleToTrack as keyof typeof rules]

  if (!compliesWithCMSTrackRule()) {
    return <>{children}</>
  }

  return (
    <>
      <InView
        triggerOnce={true}
        onChange={(inView) => {
          if (!inView) return

          const componentExperiment = experiment?.amplitude_experiment?.key
          const eventExperiment = trackView?.experiment?.key

          const experimentIdToSendInEvent = (eventExperiment ||
            componentExperiment) as AmplitudeTestKey

          const variantIdToSendInEvent = eventExperiment
            ? undefined // if provide experiment in event, trackEvent function will find the current variant when taken undefined as variant_id
            : componentExperiment

          trackEvent<TrackEventType>(
            experimentIdToSendInEvent,
            trackView!.name,
            {
              description: trackView?.description,
              section_reference: trackView?.sectionReference,
              cta_reference: trackView?.ctaReference,
              experiment_id: experimentIdToSendInEvent,
              variant_id: variantIdToSendInEvent,
              ...customEventProperties
            }
          )
        }}
      >
        <div className="pointer-events-none" />
      </InView>
      {children}
    </>
  )
}
