import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import styles from './MarkdownContent.module.css'
import cn from '@/utils/cn'

type MarkdownContentProps = {
  content: string
  className?: string
}

export const MarkdownContent = ({
  content,
  className = ''
}: MarkdownContentProps) => {
  return (
    // @ts-ignore
    <ReactMarkdown
      className={cn(styles.Main, className)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rehypePlugins={[rehypeRaw as any]}
    >
      {content}
    </ReactMarkdown>
  )
}
