import Icon from '@/components/Icon'
import { IconTitleAndDescriptionLinkV2Props } from '@/types/cms'

interface CardVideoBenefitsProps {
  referId: string
  title: string
  cardsBenefits: IconTitleAndDescriptionLinkV2Props[]
  videoUrl: string
  background: string
}

export function CardVideoBenefits({
  referId,
  title,
  cardsBenefits,
  videoUrl,
  background
}: CardVideoBenefitsProps) {
  return (
    <div id={referId} className="p-16 md:p-24 lg:py-[60px]">
      <div className="flex md:relative flex-col items-stretch m-auto w-full md:max-w-[720px] lg:max-w-[1238px] h-full md:min-h-[357px] lg:min-h-[470px] bg-display-100 rounded-[24px] md:rounded-[40px]">
        <div
          className="flex md:flex-[30%] justify-center md:w-full max-w-full min-h-[286px] max-h-[286px] rounded-t-[24px] md:rounded-t-[40px]"
          style={{ backgroundColor: background }}
        >
          <video
            preload="auto"
            muted
            playsInline
            loop
            autoPlay
            controls={false}
            src={videoUrl}
            className="min-w-[286px] md:h-[286px] rounded-t-[24px] md:rounded-t-[40px]"
          />
        </div>

        <div className="mx-auto mt-28 md:max-w-full md:text-center break-words">
          {title && (
            <h2
              className="mx-auto max-w-full font-heading font-bold text-center break-words ton-paragraph-32"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
        </div>

        <div className="mt-11">
          <div className="flex flex-col gap-24 lg:gap-40 justify-center items-center mx-auto w-full md:max-w-[672px] lg:max-w-[1200px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-9 lg:gap-16 justify-center items-center mb-24 w-full lg:w-2/3 max-w-[1190px]">
              {cardsBenefits.map(({ title, description, iconName }) => (
                <div
                  key={title}
                  className="flex flex-row lg:gap-12 px-24 w-full md:max-w-[360px] lg:max-w-[315px] xl:max-w-[390px]"
                >
                  <div className="pt-6 pr-12 text-newTon-600">
                    <Icon name={iconName} className="w-24 h-24 fill-current" />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-bold paragraph-16 lg:paragraph-18">
                      {title}
                    </p>
                    <p
                      className="paragraph-16"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
