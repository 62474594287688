type Props = {
  id?: string
  title: string
  children: React.ReactNode
}

import styles from '../../Header.module.css'

export function MenuDesktopWithSubMenu({ id = '', title, children }: Props) {
  return (
    <div className={`${styles.menuContainer} relative`} id={id}>
      <div
        aria-label={title}
        className="px-16 lg:px-8 xl:px-16 my-auto font-medium text-display-900 paragraph-16 lg:paragraph-14 xl:paragraph-16"
      >
        {title}
      </div>
      {children}
    </div>
  )
}
