import CloudinaryImage from '@/components/CloudinaryImage'
import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import { ALL_MACHINES_COMMOM_INFO } from '@/constants/machines'
import { useCatalog } from '@/contexts/catalog'
import { useMachines } from '@/contexts/machines'

export function MachinesColumn({
  onMachineClick
}: {
  onMachineClick?: () => void
}) {
  const { machinesInfo } = useMachines()
  const { machinesFromPlanSuper } = useCatalog()

  return (
    <>
      {machinesFromPlanSuper.machines.map((machine) => {
        const machineInfo = machinesInfo[machine.machineName] || {}
        const machinePopularName =
          ALL_MACHINES_COMMOM_INFO[machine.machineName].popularName

        return (
          <RetainQueryLinkDefault
            key={`machine-menu-${machine.machineName}`}
            href={machine.linkToProduct}
            prefetch={false}
            id={`btn-maquininha-${machine.machineName}`}
            className="flex items-center p-16"
            onClick={onMachineClick}
          >
            <CloudinaryImage
              src={`site-ton/maquininhas/${machineInfo.imageName.front}`}
              width={40}
              height={60}
              alt={`Maquininha ${machine.machineName}`}
              className="max-w-full h-auto"
            />
            <div className="ml-12">
              <p className="font-medium">
                {`Maquininha ${machinePopularName}`}
              </p>
              <p className="mt-2 text-display-600 paragraph-14">
                {`À partir de ${machine.installmentParcel}x de R$ ${machine.installmentValue}`}
              </p>
            </div>
          </RetainQueryLinkDefault>
        )
      })}
    </>
  )
}
