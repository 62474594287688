import packageJSON from '../../../../../package.json'
import { productsItems } from './content/products'
import styles from './Products.module.css'

const Products: React.FC = () => (
  <div className="flex border-t-[.1rem] border-display-800">
    {productsItems.map(({ name, Logo, active, url }) => (
      <a
        aria-label={name}
        className={`${styles.product} ${active && styles.productActive}`}
        key={name}
        href={url}
      >
        <Logo />
        <span className="text-display-0">{name}</span>
      </a>
    ))}
    <div className="flex justify-end items-end w-full">
      <p className="w-full text-right text-display-500 paragraph-14">
        v{packageJSON.version}
      </p>
    </div>
  </div>
)

export default Products
