import { Slider } from '@/components/Slider'
import { VideoReviewBlockProps } from '@/types/cms'

import { ReviewVideoCard } from '../ReviewVideoCard'

interface ReviewVideoCarrouselProps {
  reviews: VideoReviewBlockProps[]
}

export const ReviewVideoCarrousel = ({
  reviews
}: ReviewVideoCarrouselProps) => {
  return (
    reviews && (
      <Slider.Root
        configs={{
          options: {
            slides: {
              spacing: 16
            }
          },
          breakpoints: {
            md: {
              slides: {
                spacing: 40
              }
            }
          }
        }}
        navigations={{
          arrows: true
        }}
      >
        {reviews.map((review) => (
          <Slider.Item
            key={review.name}
            className="min-w-[320px] md:min-w-[360px] max-w-[320px] md:max-w-[360px]"
          >
            <ReviewVideoCard {...review} />
          </Slider.Item>
        ))}
      </Slider.Root>
    )
  )
}
