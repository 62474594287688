import Head from 'next/head'

import { RetainQueryLink } from '@/components/RetainQueryLink'
import { CLOUDINARY_BASE_URL } from '@/constants/links'

interface HeroImageProps {
  src: string
  alt: string
  height: number
  width: number
  hash?: string
}
export default function HeroImage({
  src,
  alt,
  height,
  width,
  hash
}: HeroImageProps) {
  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href={`${CLOUDINARY_BASE_URL}/w_500/${src}.webp`}
          media="(max-width: 767.9px)"
        />
        <link
          rel="preload"
          as="image"
          media="(min-width: 768px)"
          href={`${CLOUDINARY_BASE_URL}/w_700/${src}.webp`}
        />
      </Head>
      <div className="content-center w-full max-w-[464px] md:max-w-[336px] lg:max-w-[533px]">
        <RetainQueryLink href={{ hash: hash && hash }}>
          <picture>
            <source
              media="(min-width: 768px)"
              type="image/webp"
              srcSet={`${CLOUDINARY_BASE_URL}/w_700/${src}.webp`}
            />
            <source
              media="(min-width: 320px)"
              type="image/webp"
              srcSet={`${CLOUDINARY_BASE_URL}/w_500/${src}.webp`}
            />
            <img
              className="rounded-[21px]"
              alt={alt}
              height={height}
              width={width}
              src={`${CLOUDINARY_BASE_URL}/w_500/${src}.webp`}
            />
          </picture>
        </RetainQueryLink>
      </div>
    </>
  )
}
