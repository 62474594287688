import { tv, VariantProps } from 'tailwind-variants'

const description = tv({
  base: 'ton-paragraph-16 lg:ton-paragraph-18 text-center',
  variants: {
    textColor: {
      default: 'text-display-900',
      white: 'text-display-0'
    }
  },
  defaultVariants: { textColor: 'default' }
})
type DescriptionVariants = VariantProps<typeof description>

export interface HeroDescriptionProps extends DescriptionVariants {
  text: string
  className?: string
}

export const HeroDescription = ({
  text,
  className,
  textColor
}: HeroDescriptionProps) => {
  return <p className={description({ className, textColor })}>{text}</p>
}
