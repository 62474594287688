import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import useViewport from '@/utils/useViewport'

import styles from './tooltip.module.css'

interface Props {
  id: string
  tooltip: string
  children: React.ReactNode
}

const ToolTip: React.FC<Props> = ({
  id,
  tooltip,
  children,
  ...props
}): JSX.Element => {
  const { width } = useViewport()
  const isMobile = width <= 768

  return (
    <div key={id} data-tooltip-id={`tooltip-${id}`}>
      {children}
      <ReactTooltip
        id={`tooltip-${id}`}
        content={tooltip}
        className={styles.TooltipContent}
        place={isMobile ? 'top' : 'right'}
        {...props}
      />
    </div>
  )
}

export default ToolTip
