import Image from 'next/image'

import styles from './BannerMarquee.module.css'
import TonCircle from './TonCircle.svg'

const BannerMarquee = () => {
  return (
    <>
      <div className={styles.MarqueeContainer}>
        {[...Array(10)].map((_arr, index) => (
          <div
            key={`banner-marquee-item-${index}`}
            id={`banner-marquee-item-${index}`}
            className={`w-full h-full ${styles.MarqueeConfig}`}
          >
            <div className={`px-4 bg-display-900 w-full ${styles.MarqueeBox}`}>
              <span className="text-20 font-bold text-newTon-400">
                ATÉ 70% DE DESCONTO
              </span>
            </div>
            <div className={`px-4 bg-newTon-400 ${styles.MarqueeBox}`}>
              <span className="text-20 font-bold text-display-900">
                BLACK FRIDAY{' '}
                <Image
                  src={TonCircle}
                  alt="Logo do Ton em Círculo"
                  className="inline-block ml-2"
                  width={25}
                  height={25}
                />
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default BannerMarquee
