import Tag20PercentOFF from '@/assets/tags/Tag20PercentOFF'
import Tag25PercentOFF from '@/assets/tags/Tag25PercentOFF'
import Tag30PercentOFF from '@/assets/tags/Tag30PercentOFF'
import Tag35PercentOFF from '@/assets/tags/Tag35PercentOFF'
import CloudinaryImage from '@/components/CloudinaryImage'
import { Machine } from '@/types'

import { MachineCardPromotionsProps } from '../'

interface MachineCardImageProps {
  machine: Machine
  promotions: MachineCardPromotionsProps
}

export const MachineCardImage = ({
  machine,
  promotions
}: MachineCardImageProps) => {
  const { imgName, imgName: imageFolder, name } = machine
  const { isPromo20, isPromo25, isPromoUntil35 } = promotions

  const imgBadge = imgName === 't1' ? 'vendapelocelular' : 'compixnamaquininha'

  return (
    <>
      <div className="relative md:mb-16 w-full md:w-[112px] h-[152px]">
        <div className="relative mx-auto md:ml-0 w-[88px] h-full">
          <CloudinaryImage
            src={`site-ton/maquininhas/${imageFolder}/${imgName}-left`}
            alt={`Imagem da maquininha ${name}`}
            quality={100}
            fill
            sizes="100vw"
            className="object-contain"
          />

          <div className="absolute right-[-24px] bottom-0 md:bottom-[-18px] w-[56px] h-[56px]">
            <CloudinaryImage
              src={`site-ton/badges/${imgBadge}`}
              alt="Pix na maquininha"
              quality={100}
              fill
              sizes="100vw"
              className="object-contain"
            />
          </div>

          {isPromo20 && (
            <div className="absolute top-[5px] right-[-40px]">
              <Tag20PercentOFF className="w-[64px] h-[52px]" />
            </div>
          )}

          {isPromo25 && (
            <div className="absolute top-[5px] right-[-40px]">
              <Tag25PercentOFF className="w-[64px] h-[52px]" />
            </div>
          )}

          {isPromoUntil35 && (
            <>
              {imgName === 't3-smart' ? (
                <div className="absolute top-[5px] right-[-40px]">
                  <Tag35PercentOFF />
                </div>
              ) : (
                <div className="absolute top-[5px] right-[-40px]">
                  <Tag30PercentOFF />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
