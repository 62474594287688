import Button, { ButtonV2Props } from '../ButtonV2'

export interface BandTitleDescriptionProps {
  referId: string
  title: string
  description: string
  buttons: ButtonV2Props[]
}

export default function BandTitleDescription({
  title,
  description,
  buttons
}: BandTitleDescriptionProps) {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-col py-40 mx-16 text-center">
        <p className="pb-8 font-heading font-bold text-center text-newTon-700 paragraph-28 lg:paragraph-32">
          {title}
        </p>
        <p className="pb-16">{description}</p>
        {buttons.length > 0 && (
          <div className="flex flex-col md:flex-row flex-wrap gap-16 justify-center items-center w-full">
            {buttons.map((props) => (
              <Button key={props.label} {...props} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
