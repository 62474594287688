import React from 'react'

import {
  HeaderSprite,
  IconHeaderNames,
  IconSolutionsHeaderNames,
  SolutionHeaderSprite
} from '@/components/IconSprite'
import { MenuColumn } from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import styles from '../../Header.module.css'
import DownloadAppMenu from '../DownloadAppMenu'
import { MachinesColumn } from '../MachinesColumn'
import { MenuDesktopWithSubMenu } from '../MenuDesktopWithSubMenu'
import { getSearchParams } from '@/utils/searchParams'

type Props = {
  columns: MenuColumn[]
}

export function MenuDesktopColumns({ columns }: Props) {
  return (
    <>
      {columns.map((column) =>
        column?.menu_items_groups[0]?.menu_items?.length > 0 ||
        column?.download_app ? (
          <MenuDesktopWithSubMenu
            id={column?.title}
            title={column.title}
            key={`header-link-${column.title}`}
          >
            {column?.download_app ? (
              <DownloadAppMenu />
            ) : (
              <div
                className={`${styles.menu} ${
                  column.title === 'Atendimento' ? 'right-0 ' : ''
                }py-24`}
              >
                {column.menu_items_groups.map((group, index) => (
                  <React.Fragment key={index}>
                    {index !== 0 && <hr className="my-8 bg-display-200" />}
                    {group.title && (
                      <p className="px-16 pt-16 mb-8 font-bold">
                        {group.title}
                      </p>
                    )}
                    {group.menu_items.map(
                      (sub) =>
                        sub.link && (
                          <RetainQueryLinkDefault
                            key={sub.title}
                            href={{
                              pathname: sub.link.href,
                              query: getSearchParams(sub?.link?.query || '')
                            }}
                            id={sub?.title}
                            className={`flex items-center ${styles.solution}`}
                          >
                            {sub.icon?.name && (
                              <>
                                {column.title === 'Atendimento' ? (
                                  <HeaderSprite
                                    name={sub.icon.name as IconHeaderNames}
                                    className="max-w-[24px] max-h-[24px]"
                                  />
                                ) : (
                                  <SolutionHeaderSprite
                                    name={
                                      sub.icon.name as IconSolutionsHeaderNames
                                    }
                                    className="max-w-[22px] max-h-[22px]"
                                  />
                                )}
                              </>
                            )}
                            <div className="ml-12">
                              <div className="flex">
                                <p className="font-medium paragraph-16">
                                  {sub.title}
                                </p>
                              </div>
                              <p className="mt-2 text-display-600 paragraph-14">
                                {sub.description}
                              </p>
                            </div>
                          </RetainQueryLinkDefault>
                        )
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </MenuDesktopWithSubMenu>
        ) : column.directLink ? (
          <RetainQueryLinkDefault
            href={column.directLink.href}
            key={`header-link-${column.title}`}
            id={column.title}
            aria-label={column.title}
            className="px-16 lg:px-8 xl:px-16 my-auto font-medium text-display-900 hover:text-ton-500 paragraph-16 lg:paragraph-14 xl:paragraph-16 "
          >
            {column.title}
          </RetainQueryLinkDefault>
        ) : column?.isMachinesColumn ? (
          <MenuDesktopWithSubMenu
            title={column.title}
            key={`header-link-${column.title}`}
          >
            <div className={styles.menu}>
              <MachinesColumn />
              <hr className="my-8 bg-display-200" />
              <RetainQueryLinkDefault
                href={{ pathname: '/', hash: 'maquininhas' }}
                className="flex items-center"
              >
                <CloudinaryImage
                  src={`site-ton/todas-2`}
                  width={40}
                  height={60}
                  alt="Todas as Maquininhas"
                  className="max-w-full h-auto"
                />
                <div className="ml-12">
                  <p className="font-medium">Compare as maquininhas</p>
                  <p className="mt-2 text-display-600 paragraph-14">
                    Descubra a melhor maquininha pro seu negócio
                  </p>
                </div>
              </RetainQueryLinkDefault>
            </div>
          </MenuDesktopWithSubMenu>
        ) : (
          <></>
        )
      )}
    </>
  )
}
