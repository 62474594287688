import { useContext, useEffect, useState } from 'react'

import IS_SERVER from '@/constants/isServer'
import {
  ALL_MACHINES_COMMOM_INFO,
  ALL_USER_TAGS_PLANS_MAPPER
} from '@/constants/machines'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { AmplitudeContext } from '@/contexts/amplitude'
import { useMachines } from '@/contexts/machines'
import { usePlans } from '@/contexts/plans'
import { formatValueToString } from '@/helpers/fees'
import useCROTon032 from '@/services/amplitude/experiments/useCROTon032'
import { MachinesInfoDetails } from '@/types/machines'
import { AllPlansType } from '@/types/plans'
import cn from '@/utils/cn'
import { usePromotions } from '@/utils/getActivesPromotions'

import RetainQueryLinkDefault from '../RetainQueryLink/RetainQueryLinkDefault'
import DefaultProducts from './components/DefaultProducts'
import { FamilhaoTonWidget } from './components/FamilhaoTonWidget'
import Features from './components/Features'

interface TonMachineCardProps extends MachinesInfoDetails {
  className?: string
  behavior?: 'default' | 'familhao-do-ton'
}

const TonMachineCard = ({
  className,
  machineName,
  catalog,
  linkToCheckout,
  features,
  original_price: from,
  amount,
  installmentParcel,
  installmentValue,
  badges,
  behavior
}: TonMachineCardProps) => {
  const { machinesInfo } = useMachines()
  const { variantKey: variantCRO032Loaded, variants } = useCROTon032()

  const machineInfo = machinesInfo[machineName] || {}
  const getPlanName = ALL_USER_TAGS_PLANS_MAPPER[catalog] as AllPlansType
  const planPopularName = ALL_PLANS_COMMOM_INFO[getPlanName].popularName
  const machinePopularName = ALL_MACHINES_COMMOM_INFO[machineName].popularName
  const machineImageName = machineInfo.imageName

  const { analytics } = useContext(AmplitudeContext)
  const { plan } = usePlans()

  const { getFirstActivePromotion } = usePromotions()
  const activePromotion = getFirstActivePromotion()

  // TODO: Rever a uso dos estados quando o CRO032 for finalizado
  // const [hasFamilhaoTon, setHasFamilhaoTon] = useState(false)
  const [currentPrice, setCurrentPrice] = useState(amount)
  const [installmentPrice, setInstallmentPrice] = useState(installmentValue)
  const [linkToCart, setLinkToCart] = useState(linkToCheckout)

  // TODO: Rever a uso useEffects quando o CRO032 for finalizado
  useEffect(() => {
    setCurrentPrice(amount)
    setInstallmentPrice(installmentValue)
    setLinkToCart(linkToCheckout)
  }, [amount, installmentValue, linkToCheckout])

  return (
    <div className={cn('bg-display-0 rounded-2xl shadow-new', className)}>
      <div className="flex relative items-end mr-16 ml-24 h-[148px] rounded rounded-b-none">
        <DefaultProducts
          activePromotion={activePromotion}
          machineImageName={machineImageName}
          badges={badges as []}
          plan={plan}
          machinePopularName={machinePopularName}
          machineName={machineName}
        />
      </div>
      <div className="px-16 md:px-24 mt-[24px]">
        <div className="flex justify-between items-center min-w-max">
          <h3 className="text-[28px] lg:text-[40px] font-bold leading-[36px] lg:leading-[48px]">
            {machinePopularName}{' '}
            <span className="font-light">{planPopularName}</span>
          </h3>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex mt-16">
            <div className="mr-8 md:mr-16">
              <p className="paragraph-14">
                <s>{formatValueToString(parseFloat(from))}</s>
              </p>
              <p className="text-newTon-700">
                <b>{formatValueToString(parseFloat(currentPrice))}</b>
              </p>
              <p className="paragraph-14">à vista ou</p>
            </div>
            <div className="flex justify-end my-auto ml-auto font-heading font-bold text-newTon-700 paragraph-14 md:paragraph-16">
              <div className="self-end mr-4 text-right">
                <p className="leading-none text-display-900 paragraph-18">
                  {installmentParcel}x
                </p>
                <p className="mt-4 leading-none text-current paragraph-18">
                  R$
                </p>
              </div>
              <p className="self-end text-[78px] leading-[58px] text-current">
                {installmentPrice.split(',')[0]}
              </p>
              <p className="leading-[20px] text-current paragraph-18">
                ,{installmentPrice.split(',')[1]}
              </p>
            </div>
          </div>
        </div>

        {/* TODO: Rever a uso do componente FamilhaoTonWidget quando o CRO032 for finalizado */}
        {variantCRO032Loaded === variants.VARIANT_B &&
          behavior !== 'familhao-do-ton' && (
            <FamilhaoTonWidget machineName={machineName} />
          )}

        <RetainQueryLinkDefault
          href={linkToCart}
          id={`btn-pedir-maquininha-${machineName}`}
          className="mt-16 w-full font-bold capitalize bg-newTon-400 btn btn-primary btn-large"
          rel="noreferrer"
          onClick={async () => {
            if (IS_SERVER) return

            analytics?.track({
              event_type: `choose prod ton`,
              event_properties: {
                name: `choose prod ton`,
                description: `Evento disparado quando usuario clicar no cta de pedir maquininha`,
                section_reference: `Escolha sua maquininha`,
                cta_reference: `Pedir ${machinePopularName} ${planPopularName}`
              }
            })
          }}
        >
          Pedir {machinePopularName} {planPopularName}
        </RetainQueryLinkDefault>
      </div>
      <div className="flex flex-col md:flex-1">
        <div className="flex-1 p-24 mt-auto">
          <Features machine={machinePopularName} features={features} />
        </div>
      </div>
    </div>
  )
}
export default TonMachineCard
