import { useEffect, useState } from 'react'

import NativeDropdown from '@/components/NativeDropdown'
import {
  ALL_PLANS_COMMOM_INFO,
  ALL_PROMOTON_TIER,
  nativeDropdownPromotionsTiersMachinePlansAndFees
} from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString } from '@/helpers/fees'
import { hasBlackFriday, typeStyles } from '@/helpers/hasBlackFriday'
import { FlagsGroup, PlanAndFeesInfo, ReceivingOptions } from '@/types/fees'
import {
  AllPlansForMachine,
  AllPlansForMachineType,
  AllTiersForMachine
} from '@/types/plans'
import cn from '@/utils/cn'

interface ModalFunctionsProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalPlanActive: React.Dispatch<
    React.SetStateAction<AllPlansForMachineType>
  >
}

interface CardPlanProps {
  planCard: PlanAndFeesInfo[]
  flagGroup: FlagsGroup
  receivingOptions: ReceivingOptions
  modalFunctions?: ModalFunctionsProps
}

enum PROMOTON_TIERS {
  PromotonPromo = 0,
  PromotonBase = 1,
  PromotonRegular = 2,
  PromotonPlus = 3
}

export const PlanCard = ({
  planCard,
  flagGroup,
  receivingOptions,
  modalFunctions
}: CardPlanProps) => {
  const { plan, setPlan, setPromoTier } = usePlans()

  // Estado inicial do plano e tier
  const [{ description, planName, fees }, setCardInfoPlan] =
    useState<PlanAndFeesInfo>(planCard[0])

  // Estado para a listagem das taxas do plano por grupo de bandeiras
  const [planFees, setPlanFees] = useState(fees[receivingOptions][flagGroup])

  // Estado para o controle do card selecionado
  const [cardSelected, setCardSelected] = useState(
    planName === AllPlansForMachine.Pro
  )

  // Estado para o dropdown de variações do Promoton
  const [promotonDropdown, setPromotonDropdown] = useState(
    PROMOTON_TIERS.PromotonPromo
  )

  // Estado para o tier do Promoton
  const [promotonTier, setPromotonTier] = useState(
    AllTiersForMachine.TonProPromo
  )

  // Definição de um nome popular do plano
  const { popularName } = ALL_PLANS_COMMOM_INFO[planName]

  // Efeito para atualizar as taxas do plano de acordo com o grupo de bandeiras
  useEffect(() => {
    setPlanFees(fees[receivingOptions][flagGroup])
  }, [fees, flagGroup, plan, planCard, receivingOptions])

  // Efeito para atualizar o card selecionado de acordo com o plano
  useEffect(() => {
    setCardSelected(planName === plan)
  }, [plan, planName])

  // Efeito para atualizar o plano de acordo com o dropdown do Promoton
  useEffect(() => {
    const currentTier = ALL_PROMOTON_TIER[promotonDropdown]

    const promotonPlanIndex = planCard.findIndex(
      (plan) => plan.tierName === currentTier
    )

    setCardInfoPlan(planCard[promotonPlanIndex])
    setPromotonTier(currentTier)
  }, [promotonDropdown, planCard])

  // Efeito para atualizar o card de acordo com o plano
  useEffect(() => {
    switch (planName) {
      case AllPlansForMachine.Pro:
        const promotonPlanIndex = planCard.findIndex(
          (plan) => plan.tierName === promotonTier
        )

        setCardInfoPlan(planCard[promotonPlanIndex])
        break

      default:
        setCardInfoPlan(planCard[0])
        break
    }
  }, [planCard, planName, promotonTier])

  useEffect(() => {
    const promotionalTier = nativeDropdownPromotionsTiersMachinePlansAndFees[
      promotonDropdown
    ].valueType as AllTiersForMachine
    setPromoTier(promotionalTier)
  }, [promotonDropdown, setPromoTier])

  return (
    <label className="cursor-pointer" htmlFor={`planCard-${planName}`}>
      <div
        className={cn(
          hasBlackFriday(planName, typeStyles.backgroundColor),
          'flex flex-col gap-24 p-16 md:p-24 w-[270px] md:w-[300px] h-full bg-display-0 rounded-16 border',
          cardSelected ? 'border-newTon-700' : 'border-display-400'
        )}
      >
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex gap-8 items-center">
            <input
              type="radio"
              id={`planCard-${planName}`}
              name="planName"
              value={planName}
              checked={cardSelected}
              onChange={(e) => setPlan(e.target.value as AllPlansForMachine)}
            />

            <span
              className={cn(
                hasBlackFriday(planName, typeStyles.titleColor),
                'font-heading font-bold ton-paragraph-24'
              )}
            >
              {popularName}
            </span>
          </div>

          <span
            className={cn(
              hasBlackFriday(planName, typeStyles.titleColor),
              'text-center ton-paragraph-16'
            )}
          >
            {description}
          </span>
        </div>

        {planName === AllPlansForMachine.Pro && (
          <>
            {/* TODO: Rever componente NativeDropdown, estão dependentes de enum com tipagem 'number' */}
            <NativeDropdown
              options={nativeDropdownPromotionsTiersMachinePlansAndFees}
              selectedOption={promotonDropdown}
              dropdownId="dropdown-tiers-promoton"
              setStateValue={setPromotonDropdown}
              bodyClassName={cn(
                hasBlackFriday(planName, typeStyles.borderNativeDropdown),
                hasBlackFriday(planName, typeStyles.backgroundColor),

                'border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0'
              )}
              labelClassName={cn(
                hasBlackFriday(planName, typeStyles.titleNativeDropdown),
                'font-medium whitespace-nowrap !w-full !justify-between text-14'
              )}
              menuClassName={cn(
                hasBlackFriday(planName, typeStyles.backgroundColor),
                hasBlackFriday(planName, typeStyles.titleNativeDropdown),
                'top-[44px] !w-[calc(100%+1px)] left-0 font-semibold'
              )}
              lineClassName={cn(
                hasBlackFriday(
                  planName,
                  typeStyles.hoverBackgroundColorNativeDropdown
                ),
                hasBlackFriday(
                  planName,
                  typeStyles.focusBackgroundColorNativeDropdown
                )
              )}
              iconClassName={hasBlackFriday(
                planName,
                typeStyles.titleNativeDropdown
              )}
            />

            {promotonTier === AllTiersForMachine.TonProPromo && (
              <span
                className={cn(
                  hasBlackFriday(planName, typeStyles.titleColor),
                  'text-center ton-paragraph-14'
                )}
              >
                Taxas promocionais válidas por <b>30 dias</b> ou{' '}
                <b>até atingir R$ 5 mil</b> em vendas.{' '}
                <button
                  type="button"
                  className={cn(
                    hasBlackFriday(planName, typeStyles.titleInfoFees),
                    'inline font-medium text-center text-newTon-700 underline'
                  )}
                  onClick={() => {
                    modalFunctions?.setIsOpen(true)
                    modalFunctions?.setModalPlanActive(planName)
                  }}
                >
                  Saiba mais
                </button>
              </span>
            )}

            {promotonTier !== AllTiersForMachine.TonProPromo && (
              <span
                className={cn(
                  hasBlackFriday(planName, typeStyles.titleColor),
                  'text-center ton-paragraph-14'
                )}
              >
                Taxas após período promocional de acordo com seu faturamento.{' '}
                <button
                  type="button"
                  className={cn(
                    hasBlackFriday(planName, typeStyles.titleInfoFees),
                    'inline font-medium text-center text-newTon-700 underline ton-paragraph-14'
                  )}
                  onClick={() => {
                    modalFunctions?.setIsOpen(true)
                    modalFunctions?.setModalPlanActive(planName)
                  }}
                >
                  Saiba mais
                </button>
              </span>
            )}
          </>
        )}

        {planName === AllPlansForMachine.Ultra && (
          <button
            type="button"
            className="inline font-medium text-center text-newTon-700 underline ton-paragraph-14"
            onClick={() => {
              modalFunctions?.setIsOpen(true)
              modalFunctions?.setModalPlanActive(planName)
            }}
          >
            Veja mais detalhes do plano
          </button>
        )}

        {planName === AllPlansForMachine.Mega && (
          <button
            type="button"
            className="inline font-medium text-center text-newTon-700 underline ton-paragraph-14"
            onClick={() => {
              modalFunctions?.setIsOpen(true)
              modalFunctions?.setModalPlanActive(planName)
            }}
          >
            Veja mais detalhes do plano
          </button>
        )}

        {planName === AllPlansForMachine.Super && (
          <button
            type="button"
            className="inline font-medium text-center text-newTon-700 underline ton-paragraph-14"
            onClick={() => {
              modalFunctions?.setIsOpen(true)
              modalFunctions?.setModalPlanActive(planName)
            }}
          >
            Veja mais detalhes do plano
          </button>
        )}

        <div className="flex flex-col gap-16">
          {planFees.map((fee, index) => (
            <div
              key={`plan-card-fee-${index}`}
              className="flex gap-8 justify-between items-center"
            >
              <span
                className={cn(
                  hasBlackFriday(planName, typeStyles.titleColor),
                  'flex-grow font-medium paragraph-16'
                )}
              >
                {index === 0 && 'DÉBITO'}
                {index === 1 && 'CRÉDITO À VISTA'}
                {index > 1 && `CRÉDITO ${index}x`}
              </span>
              <span
                className={cn(
                  hasBlackFriday(planName, typeStyles.titleInfoFees),
                  'font-bold text-right text-newTon-700 paragraph-16'
                )}
              >
                {formatFeeToString(fee)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </label>
  )
}
