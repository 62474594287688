import Button from '@/cmsComponents/ButtonV2'
import Icon from '@/components/Icon'

import CloudinaryImage from '@/components/CloudinaryImage'
import { HighlightPanelItemProps } from '../..'
import cn from '@/utils/cn'

export const HighlightPanelItem = ({
  referId,
  tag,
  title,
  description,
  iconsAndDescriptions,
  buttons,
  image,
  imagePosition
}: HighlightPanelItemProps) => {
  const imgPosition =
    imagePosition === 'left' ? 'md:flex-row-reverse' : 'md:flex-row'

  return (
    <div id={referId} className="pt-40 lg:pt-[56px]">
      <div
        className={cn(
          imgPosition,
          'flex flex-col gap-24 px-16 md:px-24 mx-auto max-w-[455px] md:max-w-[1280px] lg:gap-40 xl:px-0'
        )}
      >
        <div className="flex flex-col gap-16 w-full">
          <div className="flex flex-col gap-16 p-16 md:p-24 lg:p-40 bg-display-0 rounded-24 lg:rounded-40">
            {tag && (
              <div className="p-8 mx-auto md:ml-0 w-max bg-danger-450 rounded-8 rounded-bl-none">
                <span className="text-14 text-display-0">{tag}</span>
              </div>
            )}

            {(title || description) && (
              <div className="flex flex-col gap-8">
                {title && (
                  <h2
                    dangerouslySetInnerHTML={{ __html: title }}
                    className="text-center md:text-left"
                  />
                )}

                {description && (
                  <p
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="text-center md:text-left ton-paragraph-16 md:ton-paragraph-18"
                  />
                )}
              </div>
            )}

            {iconsAndDescriptions && (
              <div className="grid flex-wrap grid-cols-2 gap-8 justify-center">
                {iconsAndDescriptions.map(({ iconName, description }) => (
                  <div key={iconName} className="flex gap-8 items-center">
                    <div className="w-24 h-24">
                      <Icon
                        name={iconName}
                        className="text-newTon-600 fill-current"
                      />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: description }}
                      className="font-medium ton-paragraph-14 md:ton-paragraph-16"
                    />
                  </div>
                ))}
              </div>
            )}

            {buttons && (
              <div className="">
                {buttons.map((props) => (
                  <Button key={props.label} {...props} />
                ))}
              </div>
            )}
          </div>
        </div>

        {image && (
          <div className="flex w-full md:max-w-[286px] lg:max-w-[510px]">
            {image.mobile && (
              <div className="md:hidden">
                <CloudinaryImage
                  width={image.mobile.width}
                  height={image.mobile.height}
                  src={image.mobile.path}
                  alt={image.mobile.alternativeText}
                  className="max-w-full h-auto rounded-16"
                />
              </div>
            )}

            {image.tablet && (
              <div className="hidden md:flex lg:hidden relative w-full h-full min-h-full">
                <CloudinaryImage
                  width={image.tablet.width}
                  height={image.tablet.height}
                  src={image.tablet.path}
                  alt={image.tablet.alternativeText}
                  className="object-cover rounded-24"
                  fill
                  sizes="100vw"
                />
              </div>
            )}

            {image.web && (
              <div className="hidden lg:flex relative w-full h-full min-h-full">
                <CloudinaryImage
                  width={image.web.width}
                  height={image.web.height}
                  src={image.web.path}
                  alt={image.web.alternativeText}
                  className="object-cover rounded-40"
                  fill
                  sizes="100vw"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
