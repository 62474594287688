import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { tv } from 'tailwind-variants'

import Icon from '@/components/Icon'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import { CLOUDINARY_BASE_URL } from '@/constants/links'
import { useQRCodeDrawer } from '@/contexts/qrcodeDrawer'
import {
  IconTitleDescriptionAndLinkWithIconProps,
  ImagesProps
} from '@/types/cms'
import { handleLinkWithParamsFromCMS } from '@/utils/handleLinkWithParamsFromCMS'

import Button, { ButtonV2Props } from '../ButtonV2'

export interface StrippedInfoBannerProps {
  referId: string
  title?: string
  images: ImagesProps
  infos: IconTitleDescriptionAndLinkWithIconProps[]
  buttons?: ButtonV2Props[]
  variant: 'default' | 'small' | 'mobile-compressed'
}

const styles = tv({
  slots: {
    container: 'relative lg:py-80 lg:px-0',
    cardIcon: 'mt-4 text-newTon-600 fill-current',
    cardTitle: ''
  },
  variants: {
    variant: {
      default: {
        container: 'p-16 md:p-24',
        cardIcon: 'min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]',
        cardTitle: 'lg:text-xl font-bold lg:leading-5 paragraph-16'
      },
      'mobile-compressed': {
        container: 'p-24',
        cardIcon: 'min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]',
        cardTitle: 'lg:text-xl font-bold lg:leading-5 paragraph-16'
      },
      small: {
        container: 'p-16 md:p-24',
        cardIcon: 'min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]',
        cardTitle: 'font-bold lg:paragraph-18 paragraph-16'
      }
    }
  }
})

export function StrippedInfoBanner({
  referId,
  title,
  images,
  infos,
  buttons,
  variant = 'default'
}: StrippedInfoBannerProps) {
  const classes = styles({ variant: variant || 'default' })

  const router = useRouter()
  const { toggleDrawer, linkToDownloadApp } = useQRCodeDrawer()

  return (
    <>
      <Head>
        <link
          rel="preload"
          media="(min-width: 1026px)"
          href={`${CLOUDINARY_BASE_URL}/w_${images.web?.width},q_auto/${images.web?.path}`}
          as="image"
        />
        <link
          rel="preload"
          media="(min-width: 768px)"
          href={`${CLOUDINARY_BASE_URL}/w_${images.tablet?.width},q_auto/${images.tablet?.path}`}
          as="image"
        />
        <link
          rel="preload"
          media="(max-width: 767px)"
          href={`${CLOUDINARY_BASE_URL}/w_${images.mobile?.width},q_auto/${images.mobile?.path}`}
          as="image"
        />
      </Head>
      <section
        className="flex flex-col justify-center items-center py-40 lg:py-80 px-24"
        id={referId}
      >
        <div className="flex overflow-hidden flex-col justify-center items-center m-auto w-full max-w-[455px] md:max-w-screen-xl h-full">
          <div className="flex mx-auto mb-[-40px] md:mb-[-72px] lg:mb-[-344px] w-full max-w-[455px] md:max-w-[720px] lg:max-w-[1280px] md:h-[352px] lg:h-[600px]">
            <picture>
              <source
                media="(min-width: 1026px)"
                srcSet={`${CLOUDINARY_BASE_URL}/w_${images.web?.width},q_auto/${images.web?.path}`}
                type="image/webp"
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${CLOUDINARY_BASE_URL}/w_${images.tablet?.width},q_auto/${images.tablet?.path}`}
                type="image/webp"
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${CLOUDINARY_BASE_URL}/w_${images.mobile?.width},q_auto/${images.mobile?.path}`}
                type="image/webp"
              />
              <source
                media="(min-width: 0px)"
                srcSet={`${CLOUDINARY_BASE_URL}/w_${images.mobile?.width},q_1/${images.mobile?.path}`}
                type="image/webp"
              />
              <img
                src={`${CLOUDINARY_BASE_URL}/w_${images.mobile?.width},q_auto/${images.mobile?.path}`}
                alt={images.mobile?.alternativeText}
                className="object-contain"
              />
            </picture>
          </div>
          <div className="flex flex-col lg:p-40 px-16 md:px-24 max-w-[1280px]">
            <div className="flex z-[1] flex-col justify-center items-center lg:p-40 py-24 px-24 w-full bg-display-100 rounded-[16px]">
              <h2
                className="text-center heading-4"
                dangerouslySetInnerHTML={{ __html: title || '' }}
              />
              <div className="flex z-[1] flex-col md:flex-row gap-16 lg:gap-24 mt-16 md:mt-24">
                {infos.map(
                  ({
                    title,
                    description,
                    iconName,
                    linkIconName,
                    linkText,
                    linkHandleAppDownload,
                    linkHref
                  }) => (
                    <div
                      key={`transfer card ${title}`}
                      className="flex flex-row md:flex-col lg:flex-row md:flex-1 gap-16 lg:gap-16 lg:justify-center lg:items-start min-w-[120px]"
                    >
                      <Icon name={iconName} className={classes.cardIcon()} />
                      <div className="flex flex-col">
                        <p className={classes.cardTitle()}>{title}</p>
                        <p className="mt-4 md:mb-16 paragraph-14 lg:paragraph-16">
                          {description}
                        </p>
                        {linkHref ? (
                          <Link
                            href={handleLinkWithParamsFromCMS({
                              linkHref,
                              routeQuery: router.query
                            })}
                            className="flex flex-row w-[fit-content] text-newTon-700 hover:text-newTon-800 hover:border-b border-newTon-800 cursor-pointer"
                          >
                            <p className="font-default font-medium paragraph-16">
                              {linkText}
                            </p>
                            {linkIconName && (
                              <Icon
                                name={linkIconName}
                                className="ml-4 w-[24px] h-[24px] fill-current"
                              />
                            )}
                          </Link>
                        ) : linkHandleAppDownload ? (
                          <>
                            <div className="lg:hidden">
                              <RetainQueryLink href={linkToDownloadApp}>
                                <button className="flex flex-row w-[fit-content] text-newTon-700 hover:text-newTon-800 border-b border-transparent hover:border-newTon-800 cursor-pointer">
                                  <p className="font-default font-medium paragraph-16">
                                    {linkText}
                                  </p>
                                  {linkIconName && (
                                    <Icon
                                      name={linkIconName}
                                      className="ml-4 w-[24px] h-[24px] fill-current"
                                    />
                                  )}
                                </button>
                              </RetainQueryLink>
                            </div>

                            <button
                              onClick={toggleDrawer}
                              className="hidden lg:flex flex-row w-[fit-content] text-newTon-700 hover:text-newTon-800 border-b border-transparent hover:border-newTon-800 cursor-pointer"
                            >
                              <p className="font-default font-medium paragraph-16">
                                {linkText}
                              </p>
                              {linkIconName && (
                                <Icon
                                  name={linkIconName}
                                  className="ml-4 w-[24px] h-[24px] fill-current"
                                />
                              )}
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-24 xl:mt-40 w-full max-w-[455px] md:max-w-none">
          {buttons?.map((button) => (
            <Button {...button} className="!font-roboto" />
          ))}
        </div>
      </section>
    </>
  )
}
