import { ParsedUrlQuery } from 'querystring'
import { UrlObject } from 'url'

interface HandleLinkWithParamsFromCMSProps {
  linkHref?: string
  routeQuery?: ParsedUrlQuery
}

interface LinkHrefCTAProps extends URL {
  query?: Record<string, string>
}

const ORIGIN = 'https://ton.com.br'

function createURL(urlString: string): URL {
  let url: URL

  try {
    url = new URL(urlString)
  } catch (e) {
    url = new URL(urlString, ORIGIN)
  }
  return url
}

export const handleLinkWithParamsFromCMS = ({
  linkHref,
  routeQuery
}: HandleLinkWithParamsFromCMSProps) => {
  const pathnameFromCMS = linkHref || ''
  const url = createURL(pathnameFromCMS)

  const linkCTA = url as LinkHrefCTAProps

  Array.from(url.searchParams.entries()).forEach(([key, value]) => {
    if (!linkCTA.query) {
      linkCTA.query = {}
    }

    Object.assign(linkCTA.query, { [key]: value })
  })

  if (linkCTA.origin === ORIGIN) {
    const href: UrlObject = {
      pathname: linkCTA.pathname,
      query: {
        ...routeQuery,
        ...linkCTA.query
      },
      hash: linkCTA.hash
    }

    return href
  } else {
    return linkCTA.href
  }
}
