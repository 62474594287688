import TonLogo from '@/assets/icons/LogoSmall'
import PagarmeLogo from '@/assets/icons/PagarmePotenciaGray'
import StoneLogo from '@/assets/icons/StonePotenciaGray'

export const productsItems = [
  {
    name: 'TON',
    Logo: TonLogo,
    active: true,
    url: '#ton'
  },
  {
    name: 'STONE',
    Logo: StoneLogo,
    url: 'https://www.stone.com.br/'
  },
  {
    name: 'PAGAR.ME',
    Logo: PagarmeLogo,
    url: 'https://pagar.me/'
  }
]
