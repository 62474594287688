import CloudinaryImage, {
  CloudinaryImageProps
} from '@/components/CloudinaryImage'
import cn from '@/utils/cn'

export const HeroBannerImage = ({
  width,
  height,
  src,
  alt,
  className,
  ...rest
}: CloudinaryImageProps) => {
  return (
    <CloudinaryImage
      width={width}
      height={height}
      src={src}
      alt={alt}
      quality={100}
      className={cn(
        'rounded-16 md:rounded-24 max-w-full h-auto object-cover',
        className
      )}
      {...rest}
    />
  )
}
