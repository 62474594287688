export type IconNames =
  | 'arrow-right'
  | 'auto-truck'
  | 'battery-charging'
  | 'calculator'
  | 'cent-clock'
  | 'cent-hand'
  | 'cent-piggy-bank'
  | 'shopping-cart'
  | 'check'
  | 'chevron-down'
  | 'chevron-up'
  | 'close'
  | 'credit-card'
  | 'customer-support'
  | 'date-calendar'
  | 'date-clock'
  | 'dollar-phone'
  | 'dollar-shield'
  | 'e-tag-coupon'
  | 'form-denied'
  | 'life-buoy'
  | 'loan'
  | 'logo-on'
  | 'logo-pix'
  | 'magnifying-search'
  | 'menu'
  | 'message-r-chat'
  | 'mobile-card'
  | 'money'
  | 'mouse-touch-point'
  | 'phone'
  | 'phone-card'
  | 'phone-check'
  | 'phone-heart'
  | 'phone-x'
  | 'plus'
  | 'pos-receipt-list'
  | 'pos-small'
  | 'qr-code-pix'
  | 'question'
  | 'dollar-circle'
  | 'security-lock'
  | 'shopping-cart'
  | 'signal-contactless'
  | 'signal-loader'
  | 'signal-wifi-2'
  | 'smart-phone'
  | 'stone-solution'
  | 'truck'
  | 'whatsapp'
  | 'arrow-left-2x'
  | 'arrow-right-2x'
  | 'shopping-cart-2x'
  | 'familhao-ton'
  | 'plus-circle'

export type FlagNames =
  | 'aExpress'
  | 'applePay'
  | 'elo'
  | 'googlePay'
  | 'hipercard'
  | 'mastercard'
  | 'pix'
  | 'qrCode'
  | 'samsungPay'
  | 'visa'
export type IconFooterNames =
  | 'logo_stoneco'
  | 'logo-facebook'
  | 'logo-instagram'
  | 'logoTon'
  | 'logoTon_green'
  | 'logo-twitter'
  | 'logo-youtube'
  | 'pagar-me'
  | 'stone'

export type IconHeaderNames =
  | 'life-buoy'
  | 'logo-on'
  | 'logo-whatsapp'
  | 'phone'
  | 'truck'

export type IconSolutionsHeaderNames =
  | 'phone-heart'
  | 'phone-card'
  | 'shopping-cart'
  | 'qr-code'
  | 'cent-hand'
  | 'signal-contactless'
  | 'dollar-phone'
  | 'familhao-ton'

interface RootSprite<T> {
  name: T
  className?: string
}

interface BaseSpriteFn<T> extends RootSprite<T> {
  spriteName: string
}

const baseSpriteFn = <T,>({
  spriteName,
  name,
  className = ''
}: BaseSpriteFn<T>) => {
  return (
    <svg className={`${className}`}>
      <use xlinkHref={`/${spriteName}.svg#${name}`} />
    </svg>
  )
}

export const IconSprite = (props: RootSprite<IconNames>) =>
  baseSpriteFn({
    spriteName: 'sprite',
    className: `${props.className} fill-current`,
    ...props
  })

export const FlagSprite = (props: RootSprite<FlagNames>) =>
  baseSpriteFn({
    spriteName: 'bandeiras',
    className: `${props.className} fill-current`,
    ...props
  })

export const FooterSprite = (props: RootSprite<IconFooterNames>) =>
  baseSpriteFn({ spriteName: 'sprite-footer', ...props })

export const HeaderSprite = (props: RootSprite<IconHeaderNames>) =>
  baseSpriteFn({
    spriteName: 'sprite-svg-atendimento',
    className: `${props.className} fill-current`,
    ...props
  })

export const SolutionHeaderSprite = (
  props: RootSprite<IconSolutionsHeaderNames>
) =>
  baseSpriteFn({
    spriteName: 'sprite-solutions-header',
    className: `${props.className}`,
    ...props
  })
