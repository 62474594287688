import cn from '@/utils/cn'
import { tv } from 'tailwind-variants'

interface AccountBandProps {
  title: string
  children: React.ReactNode
  referId?: string
  description?: string | null
  backgroundColor?: string | null
  variant?: 'default' | 'buttons-gap-md-24'
}

const styles = tv({
  slots: {
    buttonsContainer:
      'flex flex-col md:flex-row gap-16 justify-center pb-8 mt-24 w-full'
  },
  variants: {
    variant: {
      default: {
        buttonsContainer: ''
      },
      'buttons-gap-md-24': {
        buttonsContainer: 'md:gap-24'
      }
    }
  }
})

const AccountBand: React.FC<AccountBandProps> = ({
  referId,
  title,
  children,
  description,
  backgroundColor,
  variant
}) => {
  const classes = styles({ variant: variant || 'default' })
  const bgContainer = backgroundColor ? backgroundColor : 'bg-display-200'

  return (
    <div
      id={referId || 'Context_tonParceiroIdeal'}
      className={cn(
        'flex justify-center items-center py-40 lg:py-80 px-24 w-full',
        bgContainer
      )}
    >
      <div className="flex flex-col justify-center items-center w-full max-w-[455px] md:max-w-[730px]">
        <p
          className="font-heading font-bold text-center ton-heading-3"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {description && (
          <p
            className="mt-8 font-default text-[16px] lg:text-[18px] leading-[24px] lg:leading-[26px] text-center"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <div className={classes.buttonsContainer()}>{children}</div>
      </div>
    </div>
  )
}

export default AccountBand
