import Button, { ButtonV2Props } from '@/cmsComponents/ButtonV2'
import Icon, { IconsNames } from '@/components/Icon'
import { BackgroundColorProps } from '@/types/cms'
import cn from '@/utils/cn'

export interface LowerBandProps {
  referId: string
  text: string
  iconName: IconsNames
  backgroundColor: BackgroundColorProps
  buttons: ButtonV2Props[]
}

export default function LowerBand({
  text,
  iconName,
  buttons,
  backgroundColor = 'bg-display-0'
}: LowerBandProps) {
  return (
    <div className={cn('w-full', backgroundColor)}>
      <div className="flex flex-col md:flex-row justify-center items-center py-40 md:py-80 px-16 md:px-24 mx-auto w-full max-w-[444px] md:max-w-[768px] lg:max-w-[778px] text-center md:text-left">
        <div className="flex justify-center items-center md:mr-8 w-[72px] md:min-w-[80px] h-[72px] md:h-[80px]">
          <Icon
            name={iconName}
            className="w-[55px] md:w-[61px] text-newTon-600 fill-current"
          />
        </div>
        <h2 dangerouslySetInnerHTML={{ __html: text }} />
        <div className="flex flex-col gap-16 items-stretch pt-24 md:pt-0 md:ml-24 w-full md:w-max">
          {buttons?.map((props) => (
            <Button key={props.label} {...props} />
          ))}
        </div>
      </div>
    </div>
  )
}
