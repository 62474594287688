import Link from 'next/link'
import { useRouter } from 'next/router'

import RetainQueryLink from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import { ALL_MACHINES_COMMOM_INFO } from '@/constants/machines'
import { useCatalog } from '@/contexts/catalog'

import styles from '../Links.module.css'

type Props = {
  title: string
}
export function MachinesColumn({ title }: Props) {
  const { query } = useRouter()
  const { machinesFromPlanSuper } = useCatalog()

  return (
    <>
      <div className={styles.category} key={title}>
        <p className="mb-10 uppercase paragraph-12">{title}</p>
        {machinesFromPlanSuper.machines.map((machine) => {
          return (
            <RetainQueryLink
              key={`machine-menu-${machine.machineName}`}
              href={machine.linkToProduct}
              aria-label={`Maquininha ${machine.machineName}`}
              className="my-6 text-display-0 paragraph-14"
            >
              {`Maquininha ${
                ALL_MACHINES_COMMOM_INFO[machine.machineName].popularName
              }`}
            </RetainQueryLink>
          )
        })}

        <Link
          href={{
            pathname: '/',
            query,
            hash: 'maquininhas'
          }}
          passHref
          aria-label="Compare as maquininhas"
          className="my-6 text-display-0 paragraph-14"
        >
          Compare as maquininhas
        </Link>
      </div>
    </>
  )
}
