import React, { useState } from 'react'

import CloudinaryImage from '@/components/CloudinaryImage'
import { AllMachines, AllMachinesType } from '@/types/machines'
import { usePromotions } from '@/utils/getActivesPromotions'

type MachineImageProps = {
  machineName: AllMachinesType
  badges?: string[]
  images: {
    front: string
    left: string
    right: string
  }
  plan?: string
}

// Função para renderizar a tag de promoções
const renderPromoTag = (promo: boolean, src: string, alt: string) =>
  promo && (
    <div className="absolute top-8 md:top-16 right-[-12px] md:right-0 z-[1]">
      <CloudinaryImage
        src={src}
        alt={alt}
        width={
          100
        } /* TODO: Adicionar como 93 se caso for finalizado a Black Friday */
        height={
          70
        } /* TODO: Adicionar como 62 se caso for finalizado a Black Friday */
        quality={90}
        className="max-w-full h-auto"
      />
    </div>
  )

const MachineImage: React.FC<MachineImageProps> = ({
  machineName,
  badges,
  images,
  plan
}) => {
  const [currentImage, setCurrentImage] = useState(images.front)
  const isT3Smart = machineName === AllMachines.T3Smart
  const isT1 = machineName === AllMachines.T1
  const isT2 = machineName === AllMachines.T2
  const isT3 = machineName === AllMachines.T3
  const { getActivePromotions } = usePromotions()

  const { isPromoBlackFriday } = getActivePromotions()

  // Tag de promoções renderizadas
  /* TODO: Voltar o promoTag comentado e apagar o de baixo quando a black friday for finalizada */
  /* const promoTag = (
    <>
      {isPromoUntil35 &&
        plan === 'Ton Pro' &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/30OFF',
          'Balão na cor roxa com 30% de desconto'
        )}
      {isPromoUntil35 &&
        plan === 'Ton Pro' &&
        isT3Smart &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/35OFF',
          'Balão na cor roxa com 35% de desconto'
        )}
      {isPromoUntil35 &&
        plan !== 'Ton Pro' &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/25OFF',
          'Balão na cor roxa com 25% de desconto'
        )}
    </>
  ) */
  const promoTag = (
    <>
      {isPromoBlackFriday &&
        (isT1 || isT2) &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/black/BLACK30OFF',
          'Imagem de selo de desconto na cor preta com 30% de desconto. O 30% está na cor verde neon e a frase “de desconto” na cor branca.'
        )}
      {isPromoBlackFriday &&
        plan !== 'Ton Pro' &&
        isT3 &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/black/BLACK40OFF',
          'Imagem de selo de desconto na cor preta com 40% de desconto. O 40% está na cor verde neon e a frase “de desconto” na cor branca.'
        )}
      {isPromoBlackFriday &&
        plan !== 'Ton Pro' &&
        isT3Smart &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/black/BLACK70OFF',
          'Imagem de selo de desconto na cor preta com 70% de desconto. O 70% está na cor verde neon e a frase “de desconto” na cor branca.'
        )}
      {isPromoBlackFriday &&
        plan === 'Ton Pro' &&
        isT3 &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/black/BLACK50OFF',
          'Imagem de selo de desconto na cor preta com 50% de desconto. O 50% está na cor verde neon e a frase “de desconto” na cor branca.'
        )}
      {isPromoBlackFriday &&
        plan === 'Ton Pro' &&
        isT3Smart &&
        renderPromoTag(
          true,
          'site-ton/promotions_tag/black/BLACK65OFF',
          'Imagem de selo de desconto na cor preta com 65% de desconto. O 65% está na cor verde neon e a frase “de desconto” na cor branca.'
        )}
    </>
  )

  return (
    <div className="flex flex-row-reverse md:flex-col gap-16 md:gap-32 justify-center items-center mx-auto md:mb-0 w-full lg:w-[290px] max-w-[455px] md:max-w-[290px]">
      <div className="flex relative justify-center md:pr-0 w-[224px] md:w-[250px]">
        <CloudinaryImage
          src={`site-ton/maquininhas/${currentImage}`}
          alt={`Imagem da máquininha ${machineName}`}
          width={290}
          height={435}
          quality={90}
          className="max-w-full h-auto"
        />
        {promoTag}

        <div
          className={`flex absolute ${
            isT3Smart
              ? 'flex-col right-[26px] bottom-24'
              : 'flex-row right-0 md:-bottom-32 bottom-8'
          }`}
        >
          {badges?.map((badge) => (
            <div
              key={badge}
              className={`first:z-40 w-80 h-80 ${
                isT3Smart
                  ? 'last:mt-[-22px]'
                  : 'md:w-[96px] md:h-[96px] last:-ml-12 md:last:-ml-16'
              }`}
            >
              <CloudinaryImage
                src={`site-ton/badge-${badge}`}
                alt="Icone representando a feature"
                width={96}
                height={96}
                quality={90}
                className="max-w-full h-auto rounded-full"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-16 md:mt-24">
        {Object.values(images).map((image) => (
          <div
            key={image}
            onClick={() => setCurrentImage(image)}
            className={`shadow rounded border h-[72px] w-[56px] py-8 cursor-pointer text-center ${
              currentImage === image ? 'border-ton-500' : ''
            }`}
          >
            <CloudinaryImage
              src={`site-ton/maquininhas/${image}`}
              alt="Miniatura da imagem da maquininha"
              width={37}
              height={56}
              quality={90}
              className="mx-auto max-w-full h-auto"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MachineImage
