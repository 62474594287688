import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions, SwiperOptions } from 'swiper/types'

import Icon from '@/components/Icon'
import { PlanCardItem } from '@/components/PlansSelectCard/components/PlanCardItem'
import { ALL_PLANS_FOR_SMARTPHONE } from '@/constants/plans'
import { AllPlansForMachineType } from '@/types/plans'
import cn from '@/utils/cn'

interface PlanCardDesktopWithCarouselProps {
  allPlansForMachineSplitted: AllPlansForMachineType[][]
  showTapTonCard?: boolean
  verticalOnly?: boolean
}

const navigationOptions: NavigationOptions = {
  enabled: true,
  nextEl: '#swiper-next',
  prevEl: '#swiper-prev',
  disabledClass: 'text-display-400'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation, Thumbs],
  slidesPerView: 'auto',
  navigation: navigationOptions
}

export const PlanCardDesktopWithCarousel = ({
  allPlansForMachineSplitted,
  showTapTonCard,
  verticalOnly
}: PlanCardDesktopWithCarouselProps) => {
  return (
    <div className="gap-16 w-screen md:w-auto">
      <Swiper {...swiperOptions} className="!overflow-visible">
        {allPlansForMachineSplitted.map((coupleOfPlans, index) => (
          <SwiperSlide
            className="!flex !flex-col !w-[fit-content] !h-auto"
            key={index}
          >
            <div className="flex flex-wrap gap-16 justify-center" key={index}>
              <div className="flex flex-col gap-16">
                {showTapTonCard && (
                  <div className="p-8 font-bold text-center text-newTon-700 bg-newTon-50 rounded-8 ton-paragraph-14">
                    Pra vender com maquininha
                  </div>
                )}

                <div
                  className={cn(
                    'flex gap-16 md:gap-y-24 justify-center',
                    verticalOnly ? 'flex-col' : 'flex row'
                  )}
                  key={`plan-cards-subsection-${index}`}
                >
                  {coupleOfPlans.map((planToRender) => (
                    <PlanCardItem
                      key={`plan-card-${planToRender}`}
                      planToCard={planToRender}
                      fullWidth={verticalOnly}
                      showTapTonCard={showTapTonCard}
                      imgSrc="taxapixqrcode"
                    />
                  ))}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {showTapTonCard && (
          <SwiperSlide
            className={cn(
              '!flex !flex-col !h-auto !w-[fit-content]',
              !verticalOnly && 'ml-16'
            )}
            key={allPlansForMachineSplitted.length + 1}
          >
            <div
              className={cn('flex flex-col gap-16', verticalOnly && 'w-full')}
            >
              <div className="p-8 font-bold text-center text-display-900 bg-display-300 rounded-8 ton-paragraph-14">
                Pra vender com o celular
              </div>
              <div>
                {ALL_PLANS_FOR_SMARTPHONE.map((planToRender) => (
                  <PlanCardItem
                    key={`plan-card-${planToRender}`}
                    planToCard={planToRender}
                    fullWidth={verticalOnly}
                    showTapTonCard={showTapTonCard}
                    imgSrc="tapTonImg"
                  />
                ))}
              </div>
            </div>
          </SwiperSlide>
        )}
        <span className="flex gap-16 justify-center mt-16 w-full">
          <span
            id="swiper-prev"
            className="w-40 h-40 text-newTon-700 cursor-pointer"
          >
            <Icon name="arrow-right-2x" className="rotate-180 fill-current" />
          </span>
          <span
            id="swiper-next"
            className="w-40 h-40 text-newTon-700 cursor-pointer"
          >
            <Icon name="arrow-right-2x" className="fill-current" />
          </span>
        </span>
      </Swiper>
    </div>
  )
}
