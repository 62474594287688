import { AntecipationFeesToggleButton } from './AntecipationFeesToggleButton'
import { AntecipationFeesToggleContent } from './AntecipationFeesToggleContent'
import { AntecipationFeesToggleLabel } from './AntecipationFeesToggleLabel'
import { AntecipationFeesToggleRoot } from './AntecipationFeesToggleRoot'

export const AntecipationFeesToggle = {
  Root: AntecipationFeesToggleRoot,
  Content: AntecipationFeesToggleContent,
  Label: AntecipationFeesToggleLabel,
  Button: AntecipationFeesToggleButton
}
