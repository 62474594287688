import { ColorProps, ReviewsInVideoContentProps } from '@/types/cms'

import { ReviewVideoCarrousel } from './components/ReviewVideoCarrousel'

interface ReviewsInVideoProps {
  review_video?: ReviewsInVideoContentProps
  backgroundColor?: ColorProps
}

export const ReviewsInVideo = ({
  review_video,
  backgroundColor
}: ReviewsInVideoProps) => {
  return (
    <div
      className={`${
        backgroundColor?.classColor ? `bg-${backgroundColor.classColor}` : ''
      } py-24 md:pb-80 md:pt-40 flex flex-col gap-16`}
    >
      {review_video?.title && (
        <div className="px-16">
          <p className="font-bold text-center ton-paragraph-18">
            {review_video.title}
          </p>
        </div>
      )}

      {review_video?.reviews && (
        <ReviewVideoCarrousel reviews={review_video.reviews} />
      )}
    </div>
  )
}
