import CloudinaryImage from '@/components/CloudinaryImage'

type TMachineCard = {
  index: string
  image: string
  name: string
  plan: string
  value: string
}

const MachineCard: React.FC<TMachineCard> = ({
  index,
  image,
  name,
  plan,
  value
}) => {
  return (
    <div
      key={`machine-${index}`}
      className="flex flex-col gap-16 min-w-[224px] xl:min-w-[290px] lg:max-w-[290px] min-h-[352px] lg:min-h-[360px] rounded-3xl border border-display-200"
    >
      <div className="flex justify-center items-center w-full h-[216px] border-b-[1px] border-display-200">
        <CloudinaryImage
          src={`site-ton/${image}`}
          alt={`Foto ${name}`}
          width={112}
          height={168}
          className="object-contain max-w-full h-auto"
        />
      </div>
      <div>
        <h3 className="text-center text-display-900 heading-3">
          {name}
          <span className="font-normal">{` ${plan}`}</span>
        </h3>
        <p className="mt-4 font-bold text-center text-newTon-600 paragraph-24">
          {value}
        </p>
        <p className="mt-4 text-center text-display-900 paragraph-14">
          A cada maquininha adquirida
        </p>
      </div>
    </div>
  )
}

export default MachineCard
