import { ComponentProps } from 'react'

type MachineCardRootProps = ComponentProps<'div'>

export const MachineCardRoot = ({ children }: MachineCardRootProps) => {
  return (
    <div className="flex flex-col gap-16 p-16 h-full bg-display-0 rounded-16 md:rounded-24">
      {children}
    </div>
  )
}
