import React, { useMemo } from 'react'

import { formatValueToString } from '@/helpers/fees'

type MachinePriceProps = {
  from: string
  to: string
  or: string
  installment: string
  className?: string
}

const MachinePrice: React.FC<MachinePriceProps> = ({
  from,
  to,
  or,
  installment,
  className
}) => {
  const [firstValue, secondValue] = useMemo(() => {
    return or?.split(',')
  }, [or])

  return (
    <div className={`flex flex-1 items-center ${className}`}>
      <div className="flex flex-col whitespace-nowrap">
        <span className="line-through ton-paragraph-14">
          {formatValueToString(parseFloat(from))}
        </span>
        <p className="font-bold text-newTon-700 ton-paragraph-16">
          {formatValueToString(parseFloat(to))}
        </p>
        <p className="paragraph-14">à vista ou</p>
      </div>
      <div className="flex h-full font-heading">
        <div className="self-end mr-4 mb-8 text-right">
          <p className="font-bold leading-none text-display-900">
            {installment}x
          </p>
          <p className="mt-4 font-bold leading-none text-newTon-700">R$</p>
        </div>
        <p className="self-center text-[72px] font-bold leading-[64px] text-newTon-700">
          {firstValue}
        </p>
        <p className="font-bold text-newTon-700">,{secondValue}</p>
      </div>
    </div>
  )
}

export default MachinePrice
