import Link from 'next/link'
import { useRouter } from 'next/router'

import ShoppingCart from '@/assets/icons/ShoppingCart'
import Icon from '@/components/Icon'
import { IconProps } from '@/types/cms'
import { sendEvent } from '@/utils/analytics'
import { handleLinkWithParamsFromCMS } from '@/utils/handleLinkWithParamsFromCMS'

const onClickOrderNow = () => {
  sendEvent({
    action: 'ADQUIRA',
    category: 'CTA_HEADER'
  })
}

interface CTAButtonProps {
  iconCTAButton?: IconProps
  textCTAButton?: string
  redirectCTAButton?: string
  className?: string
}

const CTAButton = ({
  className,
  redirectCTAButton,
  iconCTAButton,
  textCTAButton
}: CTAButtonProps) => {
  const route = useRouter()

  return (
    <Link
      onClick={onClickOrderNow}
      href={handleLinkWithParamsFromCMS({
        linkHref: redirectCTAButton,
        routeQuery: route.query
      })}
      aria-label={textCTAButton}
      className={`btn btn-primary btn-regular ${className}`}
    >
      {iconCTAButton ? (
        <Icon
          name={iconCTAButton.name}
          className="mr-8 text-current fill-current"
        />
      ) : (
        <ShoppingCart className="mr-8 text-current stroke-current" />
      )}
      <b className="font-bold">{textCTAButton}</b>
    </Link>
  )
}

export default CTAButton
