import {
  hasTargetOnHtmlOrChilds,
  IHasTargetOnHtmlOrChilds
} from './hasTargetOnHtmlOrChilds'

export const listenClickOnElementBasedOnRef =
  (
    ref: React.RefObject<HTMLDivElement>,
    callback: (props: IHasTargetOnHtmlOrChilds) => void
  ) =>
  (event: MouseEvent): void => {
    if (event.target && ref.current) {
      callback(hasTargetOnHtmlOrChilds(event.target, ref.current))
    }
  }
