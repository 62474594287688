import Link from 'next/link'
import { useRouter } from 'next/router'

import CloudinaryImage from '@/components/CloudinaryImage'
import { AwardsColumn, InfoColumn, MenuColumn } from '@/types/cms'
import { handleLinkWithParamsFromCMS } from '@/utils/handleLinkWithParamsFromCMS'

import styles from './Links.module.css'
import { MachinesColumn } from './MachinesColumn'

interface Props {
  columns: MenuColumn[]
  infoColumn: InfoColumn
  awardsColumn: AwardsColumn
}
export function Columns({ columns, infoColumn, awardsColumn }: Props) {
  const route = useRouter()

  return (
    <div className="flex flex-wrap mb-16">
      {columns.map(({ menu_items_groups, title, isMachinesColumn }) =>
        isMachinesColumn ? (
          <MachinesColumn title={title} key={title} />
        ) : (
          <div className={styles.category} key={title}>
            <p className="mb-10 uppercase paragraph-12">{title}</p>
            {menu_items_groups.map(({ menu_items }) =>
              menu_items.map(({ link, shouldOpenCookiesConsentModal, title }) =>
                shouldOpenCookiesConsentModal ? (
                  <button
                    className="p-0 my-6 font-normal text-left text-display-0 bg-none hover:bg-none border-none hover:border-none active:border-none hover:shadow-none outline-none focus:outline-none active:outline-none paragraph-14 cmp-revoke-consent"
                    key={title}
                  >
                    {title}
                  </button>
                ) : (
                  <Link
                    key={title}
                    href={handleLinkWithParamsFromCMS({
                      linkHref: link?.href,
                      routeQuery: route.query
                    })}
                    aria-label={title}
                    className={`paragraph-14 text-display-0 my-6`}
                  >
                    {title}
                  </Link>
                )
              )
            )}
          </div>
        )
      )}

      <div className="lg:pr-20 mt-8 md:mr-40 mb-40 lg:mb-40 md:ml-24 lg:ml-0 md:max-w-[24rem] lg:max-w-[22rem] text-display-500">
        <p className="lg:mt-8 mb-12 uppercase paragraph-12">
          {infoColumn.title}
        </p>
        <div className="flex flex-col gap-6">
          {infoColumn.lines.map(({ text }) => (
            <p key={text} className="text-display-0 paragraph-14">
              {text}
            </p>
          ))}
        </div>
      </div>

      <div className="lg:items-center lg:mt-16 md:w-full lg:w-auto text-display-500">
        <p className="lg:pl-12 mb-10 uppercase paragraph-12">
          {awardsColumn.title}
        </p>

        <div className="flex flex-col sm:flex-row lg:flex-col sm:items-center">
          <div className="flex flex-row lg:flex-col flex-wrap gap-4 gap-y-6 lg:gap-y-8 max-w-[250px] md:max-w-max">
            {awardsColumn.awards.map(
              ({ image: { path, alternativeText }, width, height }, index) => (
                <div key={index} className="self-center">
                  <CloudinaryImage
                    src={path}
                    width={width}
                    height={height}
                    alt={alternativeText}
                    className="w-max max-w-full h-auto"
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
