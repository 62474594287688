import { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { ImageCMSProps } from '@/types/cms'
import cn from '@/utils/cn'
import { datadogRum } from '@datadog/browser-rum'

import CloudinaryImage from '../../components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'
import styles from './VideoStepsByStep.module.css'

export interface VideoStepsByStepProps {
  referId: string
  title: string
  subtitle: string
  frames: {
    title: string
    description: string
    frameStart: number
    frameStop: number
  }[]
  mobileEdgeImage: ImageCMSProps
  videoUrl: string
  buttons: ButtonV2Props[]
}

export function VideoStepsByStep({
  referId,
  frames,
  mobileEdgeImage,
  subtitle,
  title,
  videoUrl,
  buttons
}: VideoStepsByStepProps) {
  const [stepNumber, setStepNumber] = useState(0)
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true
  })

  const videoRef = useRef<HTMLVideoElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const step = useMemo(() => frames[stepNumber], [frames, stepNumber])

  useEffect(() => {
    if (videoRef.current && inView) {
      const { currentTime } = videoRef.current

      const time = currentTime - step.frameStart
      const isNextFrame = time > 0 && time < 1

      if (!isNextFrame) {
        videoRef.current.currentTime = step.frameStart
      }

      videoRef.current.play().catch((err) => {
        datadogRum.addError(err, { component: 'VideoStepsByStep' })
      })
    }
  }, [step, videoRef, inView])

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeStep = document.getElementsByClassName(
        styles.active
      )[0] as HTMLDivElement

      scrollContainerRef.current.scrollTo({
        left: activeStep.offsetLeft - 24,
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [step, scrollContainerRef])

  return (
    <div
      id={referId}
      className="flex overflow-hidden flex-col gap-24 md:gap-24 py-40 md:py-40 lg:py-80 px-24 md:px-24 text-display-900 bg-display-0"
    >
      <div className="lg:hidden">
        <h2
          className="max-w-full text-center break-words"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="mt-8 text-center text-display-700 paragraph-16"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-24 lg:justify-center items-center lg:mx-auto w-full lg:w-[950px]">
        <div className="flex flex-col gap-24 lg:gap-36 lg:justify-center w-full md:max-w-[720px] lg:max-w-[620px]">
          <div className="hidden lg:block">
            <h2
              className="max-w-full break-words"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className="mt-8 w-[486px] text-display-700 paragraph-20"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </div>
          <div ref={scrollContainerRef}>
            <ul className="flex relative lg:flex-col lg:gap-24 p-0">
              {frames.map(({ title, description }, index) => (
                <li key={`step-${index}`} className="flex flex-col w-full">
                  <div
                    onClick={() => setStepNumber(index)}
                    className={cn(
                      'cursor-pointer flex flex-1 justify-center items-center border-b-4 lg:border-l-4 border-display-400 min-h-[52px] lg:min-h-[70px] lg:border-b-0',
                      stepNumber === index
                        ? styles.active
                        : 'lg:border-transparent'
                    )}
                  >
                    <div className="flex justify-center items-center mx-16 w-32 h-32">
                      <p
                        className={cn(
                          'p-16 font-bold paragraph-24 lg:paragraph-28',
                          stepNumber === index
                            ? 'text-newTon-700'
                            : 'text-display-400'
                        )}
                      >
                        {index + 1}
                      </p>
                    </div>

                    <div className="hidden lg:block flex-1">
                      <p
                        className="md:w-[200px] lg:w-full font-bold text-display-900 paragraph-18 lg:paragraph-20"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />

                      <p
                        className="mt-4 paragraph-16 lg:paragraph-18"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="flex lg:hidden">
              {frames.map(({ title, description }, index) => (
                <div
                  key={`card-${index}`}
                  onClick={() => setStepNumber(index)}
                  className={cn(
                    stepNumber === index
                      ? styles.cardActive
                      : styles.cardInactive,
                    'flex-col p-16 md:w-[240px]'
                  )}
                >
                  <p
                    className="md:w-[200px] lg:w-full font-bold text-display-900 paragraph-18 lg:paragraph-20"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  <p
                    className="mt-4 paragraph-16 lg:paragraph-18"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row flex-wrap lg:flex-nowrap md:order-4 gap-16 justify-center lg:justify-start">
            {buttons?.map((props) => (
              <Button
                key={props.label}
                {...props}
                className="md:order-4 md:mx-auto lg:mx-0 md:mt-24 md:w-[fit-content]"
              />
            ))}
          </div>
        </div>
        <div className="relative" ref={ref}>
          <div className="w-[200px] md:w-[224px] lg:w-[290px] h-[424px] md:h-[475px] lg:h-[615px]">
            <CloudinaryImage
              src={mobileEdgeImage.path}
              width={580}
              height={1231}
              alt={mobileEdgeImage.alternativeText}
              className="max-w-full h-auto"
            />
          </div>
          <div className="overflow-hidden absolute top-[37px] md:top-[48px] right-[10px] md:right-[8px] lg:right-[12px] left-[8px] md:left-[7px] lg:left-[9px] h-[360px] md:h-[388px] lg:h-[530px] bg-white rounded-[8px] border border-display-400">
            <video
              id={`${referId}-video`}
              preload="auto"
              muted
              playsInline
              onEnded={(e) => {
                e.currentTarget.currentTime = 0
                setStepNumber(0)
                e.currentTarget.play()
              }}
              onTimeUpdate={(e) => {
                const { currentTime } = e.currentTarget

                if (currentTime >= step.frameStop) {
                  setStepNumber((step) =>
                    stepNumber < frames.length - 1 ? step + 1 : step
                  )
                }
              }}
              ref={videoRef}
              src={videoUrl}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
