/* eslint-disable tailwindcss/no-custom-classname */
import { useCallback, useState } from 'react'
import IntlCurrencyInput, {
  CurrencyInputProps
} from 'react-currency-input-field'
import { IntlConfig } from 'react-currency-input-field/dist/components/CurrencyInputProps'

import cn from '@/utils/cn'

interface Props {
  handleinputsalevalue: (value: number) => void
}

type InputProps = CurrencyInputProps & Props

const currencyConfig: IntlConfig = {
  locale: 'pt-BR',
  currency: 'BRL'
}

const Input: React.FC<InputProps> = ({ ...rest }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <div
      className={cn(
        'flex relative bg-display-0 w-full text-display-900',
        isFocused ? `border-ton-500 hover:border-ton-500` : `border-display-400`
      )}
    >
      <IntlCurrencyInput
        decimalsLimit={2}
        decimalScale={2}
        maxLength={15}
        allowNegativeValue={false}
        placeholder="R$ 0,00"
        disableAbbreviations
        intlConfig={currencyConfig}
        defaultValue={rest.defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onValueChange={(_value, _name, values) => {
          let checkedValue = 0.0
          if (!values?.float) {
            rest.handleinputsalevalue(checkedValue)
            return
          }
          checkedValue = values.float
          rest.handleinputsalevalue(checkedValue)
        }}
        className="p-12 w-full text-base leading-4 placeholder-display-400 text-display-900 bg-transparent rounded-[8px] flex-0"
      />
    </div>
  )
}

export default Input
