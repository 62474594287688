import { useEffect, useState } from 'react'

import { usePlans } from '@/contexts/plans'
import { AllPlansForSmartphone } from '@/types/plans'

import ButtonTab from '../ButtonTab'
import CalculatorMachine from '../CalculatorMachines'
import CalculatorTapTon from '../CalculatorTapTon'

export type pageModeProps = 'machines' | 'tapton' | 'paymentLink'
export interface CalculatorTabsProps {
  defaultSelected: pageModeProps
}

// Taxas de concorrentes para cálculo de economia
export const feesCompetitorsPrices = [
  3.99, 6.99, 11.91, 13.29, 14.64, 15.97, 17.27, 18.55, 19.81, 21.04, 22.24,
  23.43, 24.59
]

const CalculatorTabs = ({ defaultSelected }: CalculatorTabsProps) => {
  const { plan } = usePlans()
  const [selectMode, setSelectMode] = useState<pageModeProps>(defaultSelected)

  useEffect(() => {
    if (plan === AllPlansForSmartphone.Tapton) {
      setSelectMode(AllPlansForSmartphone.Tapton)
      return
    }

    setSelectMode('machines')
  }, [plan])

  useEffect(() => {
    setSelectMode(defaultSelected)
  }, [defaultSelected])

  return (
    <div className="flex flex-col w-full md:w-auto">
      <div className="overflow-x-auto w-full">
        <div className="flex flex-nowrap gap-8">
          <ButtonTab
            id="btn-newcalc-maquininha"
            option="machines"
            selectMode={selectMode}
            setSelectMode={setSelectMode}
          >
            Maquininha
          </ButtonTab>
          <ButtonTab
            id="btn-newcalc-tapton"
            option={AllPlansForSmartphone.Tapton}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
          >
            TapTon
          </ButtonTab>
        </div>
      </div>
      {selectMode === 'machines' && <CalculatorMachine />}
      {selectMode === AllPlansForSmartphone.Tapton && <CalculatorTapTon />}
    </div>
  )
}

export default CalculatorTabs
