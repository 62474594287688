import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import Logo from '@/assets/icons/Logo'
import ClientOnlyPortal from '@/components/ClientOnlyPortal'
import Icon, { IconsNames } from '@/components/Icon'
import { IconSolutionsHeaderNames } from '@/components/IconSprite'
import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import { LINKS } from '@/constants/links'
import useCROTon032 from '@/services/amplitude/experiments/useCROTon032'
import {
  HeaderProps as GenericHeaderProps,
  IconProps,
  MenuColumn
} from '@/types/cms'
import cn from '@/utils/cn'

import CTAButton from './components/CTAButton'
import { MenuDesktopColumns } from './components/MenuDesktopColumns'
import { MenuMobileColumns } from './components/MenuMobileColumns'
import styles from './Header.module.css'

export type HeaderProps = {
  iconCTAButton?: IconProps
  textCTAButton?: string
  redirectCTAButton?: string
  showCTAButton?: boolean
  headerLinks?: HeaderLinkProps[]
  showAttendanceAndDownloadLinks?: boolean
  hideMobileMenu?: boolean
  header: GenericHeaderProps
}

type HeaderLinkProps = {
  id?: string
  text: string
  href?: string
  iconName: IconsNames
  subs?: {
    id?: string
    iconName: IconSolutionsHeaderNames
    text: string
    description: string
    href: string
  }[]
}

export function Header({
  iconCTAButton,
  redirectCTAButton,
  textCTAButton,
  showCTAButton = true,
  showAttendanceAndDownloadLinks = true,
  hideMobileMenu = false,
  header: headerFromCMS
}: HeaderProps) {
  const router = useRouter()

  const menuRef = useRef<HTMLDivElement>(null)
  const { variantKey: variantCRO032Loaded, variants } = useCROTon032()

  // TODO: remover esse estado após CRO-032
  const [header, setHeader] = useState<GenericHeaderProps>(headerFromCMS)
  const [topValue, setTopValue] = useState(0)
  const [topMenuHeightValue, setTopMenuHeightValue] = useState(0)
  const [openMenuMobile, setOpenMenuMobile] = useState(false)
  const [openContactMenu, setOpenContactMenu] = useState(false)

  const adjustMenuPosition = useCallback(() => {
    if (menuRef.current) {
      const headerRect = menuRef.current.getBoundingClientRect()
      const newTop = headerRect.top + headerRect.height
      const menuHeightValue = window.innerHeight

      setTopValue(newTop)
      setTopMenuHeightValue(menuHeightValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef, setTopValue, setTopMenuHeightValue])

  const handleOpenMenuMobile = useCallback(() => {
    adjustMenuPosition()
    setOpenMenuMobile((value) => !value)
    setOpenContactMenu(false)
  }, [adjustMenuPosition])

  const handleOpenContactMenu = useCallback(() => {
    setOpenContactMenu((value) => !value)
    setOpenMenuMobile(false)
  }, [])

  const onMachineClick = useCallback(() => {
    setOpenMenuMobile(false)
  }, [])

  useEffect(() => {
    const el = document.getElementById('__next')

    if (el) {
      if (openMenuMobile) {
        el.style.overflow = 'hidden'
      } else {
        el.removeAttribute('style')
      }
    }
  }, [openMenuMobile])

  useEffect(() => {
    if (openContactMenu) {
      setOpenMenuMobile(false)
    }

    if (openMenuMobile) {
      setOpenContactMenu(false)
    }
  }, [openMenuMobile, openContactMenu])

  // TODO: remover este useEffect após CRO-032
  useEffect(() => {
    if (
      [undefined, variants.CONTROL].includes(variantCRO032Loaded) ||
      router.pathname === '/maquina-de-cartao'
    ) {
      return
    }

    const menuColumnItems = headerFromCMS.main_menu_columns
    const lastColumn = menuColumnItems[menuColumnItems.length - 1]
    const newColumn: MenuColumn = {
      ...lastColumn,
      title: 'Familhão do Ton',
      directLink: {
        href: '/familhao-do-ton'
      }
    }

    const newMenuColumnItems = [
      ...menuColumnItems.slice(0, menuColumnItems.length - 1), // todos os itens até o penúltimo
      newColumn, // novo item
      menuColumnItems[menuColumnItems.length - 1] // último item
    ]

    setHeader({
      ...headerFromCMS,
      main_menu_columns: newMenuColumnItems
    })
  }, [headerFromCMS, router.pathname, variantCRO032Loaded, variants])

  return (
    <div
      ref={menuRef}
      id="Context_menu"
      className="sticky lg:relative top-[-1px] z-[52] px-16 md:px-24 h-[64px] md:h-[72px] bg-display-0 lg:shadow-none"
    >
      <div
        className={cn(
          'flex justify-between items-center h-full',
          variantCRO032Loaded === variants.CONTROL ? 'lg:hidden' : 'xl:hidden' // TODO: remove conditional after CRO-032
        )}
      >
        <RetainQueryLinkDefault
          href="/"
          id="btn-stone-ton-mobile"
          aria-label="ton"
          className="text-ton-500"
        >
          <Logo
            className="w-[54px] h-[40px] text-newTon-600 fill-current"
            data-testid="logo"
          />
        </RetainQueryLinkDefault>
        <div className="flex my-auto ml-auto">
          {showCTAButton && (
            <div className={cn(!hideMobileMenu ? 'hidden' : '', 'sm:block')}>
              <CTAButton
                redirectCTAButton={redirectCTAButton}
                iconCTAButton={iconCTAButton}
                textCTAButton={textCTAButton}
              />
            </div>
          )}
          {!hideMobileMenu && (
            <div className={styles.menuMobileRightIcons}>
              <div
                onClick={handleOpenContactMenu}
                className={openContactMenu ? styles.active : ''}
              >
                <Icon name="customer-support" className="fill-current" />
              </div>
              <div
                onClick={handleOpenMenuMobile}
                className={cn(styles.menuIcon, openMenuMobile && styles.active)}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
          )}
        </div>
        <ClientOnlyPortal selector="#menuMobile">
          <div
            className={cn(
              styles.menuDrawer,
              openMenuMobile
                ? '!flex !opacity-100'
                : 'transition-all delay-[350ms] opacity-0 hidden'
            )}
            style={{ top: topValue, height: topMenuHeightValue }}
          >
            <div
              className={cn(
                styles.menuMobile,
                'ease-linear',
                openMenuMobile ? 'translate-x-0' : 'translate-x-full'
              )}
            >
              <div className="flex overflow-x-auto flex-col gap-16 h-full">
                <MenuMobileColumns
                  columns={header.main_menu_columns}
                  onMachineClick={onMachineClick}
                />
                <div>
                  {showAttendanceAndDownloadLinks && (
                    <MenuMobileColumns
                      columns={header.side_menu_columns.filter(
                        (column) => column.title !== 'Baixe o App'
                      )}
                      onMachineClick={onMachineClick}
                    />
                  )}
                </div>
                {showCTAButton && (
                  <div className="px-16 w-full">
                    <CTAButton
                      redirectCTAButton={redirectCTAButton}
                      iconCTAButton={iconCTAButton}
                      textCTAButton={textCTAButton}
                      className="w-full"
                    />
                  </div>
                )}
                {showAttendanceAndDownloadLinks && (
                  <div className="px-16 mb-60 w-full">
                    <a
                      aria-label="Baixar o App"
                      className="w-full btn btn-secondary btn-regular"
                      href={LINKS.APP_LINK}
                    >
                      <b className="ml-8 font-bold">Baixar o App</b>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </ClientOnlyPortal>
        <div
          className={cn(
            styles.customerMenuDrawer,
            openContactMenu
              ? '!flex !opacity-100'
              : 'delay-[350ms] opacity-0 hidden'
          )}
        >
          <div
            className={cn(
              styles.menuMobile,
              'ease-linear transform-none',
              openMenuMobile ? 'translate-x-0' : 'translate-x-full'
            )}
          >
            <div className="flex flex-col gap-12 items-center p-16 bg-display-0">
              <p className="font-bold">Quero ser cliente Ton</p>
              <div className="flex gap-12 w-full">
                <a
                  href={LINKS.NOT_CLIENT_PHONE}
                  className="px-0 w-1/2 btn btn-primary btn-large"
                >
                  <p className="flex">
                    <Icon name="phone" className="mr-8 fill-current" />
                    Telefone
                  </p>
                </a>

                <RetainQueryLinkDefault
                  href={LINKS.NOT_CLIENT_WHATSAPP}
                  className="px-0 w-1/2 btn btn-primary btn-large"
                >
                  <p className="flex">
                    <Icon name="whatsapp" className="mr-8 fill-current" />
                    Whatsapp
                  </p>
                </RetainQueryLinkDefault>
              </div>
            </div>
            <hr className="bg-display-200" />
            <div className="flex flex-col gap-12 items-center p-16 bg-display-0">
              <p className="font-bold">Já sou cliente Ton</p>
              <div className="flex gap-12 w-full">
                <a
                  href={LINKS.APP_LINK}
                  className="px-0 w-1/2 btn btn-secondary btn-large"
                >
                  <p className="flex">
                    <Icon name="smart-phone" className="mr-8 fill-current" />
                    Chat do App
                  </p>
                </a>
                <RetainQueryLinkDefault
                  href={LINKS.CLIENT_WHATSAPP}
                  className="px-0 w-1/2 btn btn-secondary btn-large"
                >
                  <p className="flex">
                    <Icon name="whatsapp" className="mr-8 fill-current" />
                    Whatsapp
                  </p>
                </RetainQueryLinkDefault>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(
          'hidden  justify-between lg:justify-center items-center mx-auto max-w-screen-xl h-full',
          variantCRO032Loaded === variants.CONTROL ? 'lg:flex' : 'xl:flex' // TODO: remove conditional after CRO-032
        )}
      >
        <RetainQueryLinkDefault
          href="/"
          id="btn-stone-ton"
          aria-label="ton"
          className="text-ton-500"
        >
          <Logo className="w-[54px] h-[40px] fill-current" data-testid="logo" />
        </RetainQueryLinkDefault>
        <div
          className="hidden md:flex flex-1 justify-start items-center self-stretch ml-24"
          data-testid="links-container"
        >
          <MenuDesktopColumns columns={header.main_menu_columns} />
        </div>
        <div className="flex items-center ml-auto h-full">
          {showAttendanceAndDownloadLinks && (
            <MenuDesktopColumns columns={header.side_menu_columns} />
          )}
          {showCTAButton && (
            <CTAButton
              redirectCTAButton={redirectCTAButton}
              iconCTAButton={iconCTAButton}
              textCTAButton={textCTAButton}
              className="ml-16 bg-newTon-400"
            />
          )}
        </div>
      </div>
    </div>
  )
}
