import { tv, VariantProps } from 'tailwind-variants'

import CloudinaryImage from '@/components/CloudinaryImage'

const plans = tv({
  slots: {
    badge:
      'rounded-8 border border-display-300 bg-display-100 p-8  flex justify-center items-center min-h-[58px] md:min-h-full',
    badgeText:
      'ton-paragraph-14 font-medium text-display-900 text-center whitespace-pre-line'
  },

  variants: {
    highlight: {
      true: {
        badge: 'border-newTon-100 bg-newTon-50',
        badgeText: 'text-newTon-700'
      }
    }
  }
})

const { badge, badgeText } = plans()

interface SimplePlanCardProps extends VariantProps<typeof plans> {
  planName: string
  description: string
  image: {
    src: string
    alt: string
  }
  conditionalText: string
}

export const SimplePlanCard = ({
  planName,
  description,
  image,
  conditionalText,
  highlight
}: SimplePlanCardProps) => {
  return (
    <div className="flex flex-col gap-16 p-16 w-full bg-display-0 rounded-16 border border-display-300">
      <div className="flex flex-col gap-2">
        <div>
          <p className="font-heading font-bold ton-paragraph-24">{planName}</p>
        </div>
        <div className="flex items-center">
          <div className="w-full">
            <p className="ton-paragraph-16">{description}</p>
          </div>
          <div className="relative w-full max-w-[64px] h-[76px]">
            <CloudinaryImage
              src={image.src}
              alt={image.alt}
              fill={true}
              sizes="100vw"
              className="object-contain"
            />
          </div>
        </div>
      </div>
      <div className={badge({ highlight })}>
        <p
          className={badgeText({ highlight })}
          dangerouslySetInnerHTML={{ __html: conditionalText }}
        />
      </div>
    </div>
  )
}
