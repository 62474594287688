import { tv, VariantProps } from 'tailwind-variants'

import Icon from '@/components/Icon'
import { IconsDescriptionsV2Props } from '@/types/cms'
import cn from '@/utils/cn'

const description = tv({
  base: 'font-medium paragraph-16',
  variants: {
    textColor: {
      default: 'text-display-900',
      white: 'text-display-0'
    }
  },
  defaultVariants: { textColor: 'default' }
})
type DescriptionVariants = VariantProps<typeof description>

export interface HeroIconDescriptionProps extends DescriptionVariants {
  descriptions: IconsDescriptionsV2Props[]
  className?: string
}

export const HeroIconDescriptions = ({
  descriptions,
  className,
  textColor
}: HeroIconDescriptionProps) => {
  return (
    <div className={cn('flex flex-wrap gap-16', className)}>
      {descriptions.map(({ description: text, iconName }) => (
        <div
          key={text}
          className="flex gap-12 items-center w-full lg:max-w-[226px] xl:max-w-[262px]"
        >
          {iconName && (
            <Icon
              name={iconName}
              className="w-[24px] min-w-[24px] h-[24px] min-h-[24px] text-display-0 fill-current"
            />
          )}
          <p className={description({ textColor })}>{text}</p>
        </div>
      ))}
    </div>
  )
}
