import { getCookiesAsObject } from './getCookiesAsObject'

export function getGaClientId(): string {
  const cookies = getCookiesAsObject()

  if (cookies.hasOwnProperty('_ga_6E38STZDQX')) {
    return cookies['_ga_6E38STZDQX']
  }

  return ''
}
