import { ColorProps, IconAndDescriptionV2Props, ImagesProps } from '@/types/cms'

import { ButtonV2Props } from '../ButtonV2'
import { HighlightPanelItem } from './components/HighlightPanelItem'
import cn from '@/utils/cn'

export interface HighlightPanelItemProps {
  id: number
  title?: string
  referId?: string
  tag?: string
  description?: string
  iconsAndDescriptions: IconAndDescriptionV2Props[]
  imagePosition: 'left' | 'right'
  buttons: ButtonV2Props[]
  image?: ImagesProps
}

export interface HighlightPanelProps {
  id: number
  referId?: string
  mergeAbove: boolean
  items: HighlightPanelItemProps[]
  backgroundColor: ColorProps
}

export const HighlightPanel = ({
  referId,
  backgroundColor,
  mergeAbove,
  items
}: HighlightPanelProps) => {
  const bgColor = backgroundColor?.classColor
    ? `bg-${backgroundColor.classColor}`
    : ''

  const merge = mergeAbove
    ? 'mt-[-160px] pt-[120px] pb-24 md:pb-[56px]'
    : 'py-40'

  return (
    <div id={referId} className={cn(bgColor, merge)}>
      {items.map((item) => (
        <HighlightPanelItem key={item.id} {...item} />
      ))}
    </div>
  )
}
