import { useEffect, useState } from 'react'

import Icon from '../Icon'

interface IDropdown {
  dropdownId: string
  options: NativeDropdownOptionsProps[]
  selectedOption: number
  setStateValue: React.Dispatch<React.SetStateAction<number>>
  dayValue?: boolean
  labelClassName?: string
  bodyClassName?: string
  menuClassName?: string
  lineClassName?: string
  iconClassName?: string
}

export type NativeDropdownOptionsProps = {
  id: string
  value: string
  label: string
  className?: string
  valueType?: unknown
  description?: string
}

const NativeDropdown = ({
  dropdownId,
  options,
  selectedOption,
  setStateValue,
  dayValue = false,
  labelClassName,
  bodyClassName,
  menuClassName,
  lineClassName,
  iconClassName
}: IDropdown) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(selectedOption)

  type DayValue = 1 | 14 | 30

  const handleSelection = (value: number) => {
    setSelected(value)
    setStateValue(value)
  }

  const handleOpenDropdown = (forceSet?: boolean) => {
    forceSet != undefined
      ? setOpenDropdown(forceSet)
      : setOpenDropdown(!openDropdown)

    setTimeout(() => {
      document.getElementById(options[selected]?.id)?.focus()
    }, 0)
  }

  const mouseClickCallback = (event: MouseEvent) => {
    const target = event.target as HTMLButtonElement
    const dropdownContent = document.getElementsByClassName('dropdown-content')
    const dropdownButton = document.getElementById(dropdownId)

    if (
      target != dropdownButton &&
      !Array.from(dropdownContent).includes(target)
    )
      handleOpenDropdown(false)
  }

  const keydownCallback = (e: KeyboardEvent) => {
    const target = e.target as HTMLButtonElement
    const dropdownContent = document.getElementsByClassName('dropdown-content')
    const dropdownButton = document.getElementById(dropdownId)

    if (target === dropdownButton)
      switch (e.code) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Space':
        case 'Enter': {
          e.preventDefault()
          handleOpenDropdown(true)
        }
      }

    if (Array.from(dropdownContent).includes(target))
      switch (e.code) {
        case 'Tab':
        case 'Escape':
          e.preventDefault()
          handleOpenDropdown(false)
      }
  }

  const handleDropdownSelection = (
    e: React.KeyboardEvent<HTMLLIElement>,
    id: string,
    value: number
  ) => {
    if (e.code === 'ArrowDown') {
      e.preventDefault()
      ;(document.getElementById(id)?.nextElementSibling as HTMLElement)?.focus()
    }
    if (e.code === 'ArrowUp') {
      e.preventDefault()
      ;(
        document.getElementById(id)?.previousElementSibling as HTMLElement
      )?.focus()
    }
    if (['Enter', 'Space'].includes(e.code)) {
      e.preventDefault()
      dayValue
        ? handleSelection(Number(value) as DayValue)
        : handleSelection(Number(value))
      handleOpenDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', mouseClickCallback)
    document.addEventListener('keydown', keydownCallback)
    return () => {
      document.removeEventListener('click', mouseClickCallback)
      document.removeEventListener('keydown', keydownCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelected(selectedOption)
  }, [selectedOption])

  return (
    <div className={`inline-block relative cursor-pointer !${bodyClassName}`}>
      <button
        id={dropdownId}
        onClick={() => handleOpenDropdown()}
        tabIndex={0}
        className={`flex flex-row gap-6 justify-center w-[max-content] font-bold paragraph-16 dropdown-button ${labelClassName}`}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls={`${dropdownId}_dropdown`}
        aria-labelledby={`${dropdownId}_label`}
        aria-expanded={openDropdown}
        aria-activedescendant={`${dropdownId}_element_${selectedOption}`}
      >
        {options[selected]?.label}
        <span
          className={`text-display-900 ${iconClassName}`}
          onClick={(event) => {
            event.stopPropagation()
            handleOpenDropdown(!openDropdown)
          }}
        >
          <Icon
            name="chevron-down"
            className={`!w-24 !h-24 fill-current ${
              openDropdown && 'rotate-180'
            }`}
          />
        </span>
      </button>
      <ul
        role="listbox"
        id={`${dropdownId}_dropdown`}
        tabIndex={-1}
        aria-multiselectable={false}
        className={`${
          openDropdown ? 'flex flex-col' : 'hidden'
        } absolute z-10 w-[max-content] bg-white border border-newTon-200 max-h-[200px] overflow-y-auto ${menuClassName}`}
      >
        {options.map(({ value, label, id }) => (
          <li
            id={id}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={`py-8 px-16 text-center hover:bg-newTon-100 focus:bg-newTon-50 cursor-pointer dropdown-content ${lineClassName}`}
            key={label}
            tabIndex={-1}
            onKeyDown={(e) => {
              handleDropdownSelection(e, id, Number(value))
            }}
            onClick={() => {
              dayValue
                ? handleSelection(Number(value) as DayValue)
                : handleSelection(Number(value))
              handleOpenDropdown(false)
            }}
            aria-selected={label === options[selected]?.label}
            role="option"
          >
            <label>
              <input
                type="radio"
                checked={label === options[selected]?.label}
                value={label}
                className="hidden"
                name={`${dropdownId}_radio`}
                onChange={() => {
                  dayValue
                    ? handleSelection(Number(value) as DayValue)
                    : handleSelection(Number(value))
                  handleOpenDropdown(false)
                }}
              />
              <span className="font-normal paragraph-16">{label}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NativeDropdown
