import CloudinaryImage from '@/components/CloudinaryImage'
import Icon, { IconsNames } from '@/components/Icon'
import { ImagesProps } from '@/types/cms'

export interface BannerInformationsProps {
  referId: string
  iconName: IconsNames
  images: ImagesProps
  infos: {
    title: string
    description: string
  }[]
}

export default function BannerInformations({
  referId,
  images,
  iconName,
  infos
}: BannerInformationsProps) {
  return (
    <div id={referId} className="flex justify-center w-full">
      <div className="flex relative flex-col lg:flex-row gap-16 lg:p-[40px] mx-16 lg:mx-40 mt-[174px] md:mt-[306px] lg:mt-[328px] xl:mt-[408px] w-full max-w-[412px] md:max-w-[1280px]">
        <div className="flex justify-center items-center w-[72px] lg:w-[80px] min-w-[72px] lg:min-w-[80px] h-[72px] lg:h-[80px] min-h-[72px] lg:min-h-[72px] bg-newTon-500 rounded-md lg:rounded-tr-none rounded-bl-none lg:rounded-bl-md">
          <Icon
            name={iconName}
            className="w-[51px] lg:w-[57px] min-w-[51px] lg:min-w-[57px] text-display-0 fill-current"
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-16 lg:gap-40 p-16 lg:p-24 bg-newTon-900 rounded-md rounded-tl-none">
          {infos.map(({ description, title }) => (
            <div className="flex flex-col gap-8 lg:w-[calc(33%)]">
              <h4
                dangerouslySetInnerHTML={{ __html: title }}
                className="font-heading font-bold text-newTon-300"
              />
              <p
                dangerouslySetInnerHTML={{ __html: description }}
                className="text-display-0"
              />
            </div>
          ))}
        </div>
        <div className="absolute top-[-134px] md:top-[-256px] lg:top-auto lg:bottom-0 left-0 z-[-10] mx-auto w-full max-w-[412px] md:max-w-[1280px] !h-[208px] md:!h-[328px] lg:!h-[600px] rounded-[24px]">
          <div className="md:hidden">
            <CloudinaryImage
              src={images.mobile.path}
              alt={images.mobile.alternativeText}
              className="object-cover rounded-[24px]"
              fill
              sizes="100vw"
            />
          </div>
          <div className="hidden md:block lg:hidden">
            <CloudinaryImage
              src={images.tablet.path}
              alt={images.tablet.alternativeText}
              className="object-cover rounded-[24px]"
              fill
              sizes="100vw"
            />
          </div>
          <div className="hidden lg:block">
            <CloudinaryImage
              src={images.web.path}
              alt={images.web.alternativeText}
              className="object-cover rounded-[24px]"
              fill
              sizes="100vw"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
