import { KeenSliderInstanceProps } from '../../../Root'
import { DotAction, DotOptionsProps } from './DotAction'

export interface SliderNavigationDotsProps {
  instance?: KeenSliderInstanceProps
  options?: Omit<DotOptionsProps, 'active'>
}

export const SliderNavigationDots = ({
  instance,
  options
}: SliderNavigationDotsProps) => {
  if (!instance?.current) return <></>

  const {
    current: {
      track: {
        details: { maxIdx, abs }
      }
    }
  } = instance

  const numDots = maxIdx === 1 ? 0 : maxIdx
  const sliderIndex = abs === 1 || abs === 0 ? 0 : abs - 1

  return (
    <div className="flex gap-8 justify-center">
      {Array(numDots)
        .fill('')
        .map((_, index) => (
          <DotAction
            active={sliderIndex === index}
            instance={instance}
            slideNumber={index}
            key={`dot-action-${index}`}
            {...options}
          />
        ))}
    </div>
  )
}
