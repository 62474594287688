import { Slider } from '@/components/Slider'
import { TextReviewBlockProps } from '@/types/cms'

import { ReviewTextCard } from '../ReviewTextCard'

interface ReviewTextCarrouselProps {
  reviews: TextReviewBlockProps[]
}

export const ReviewTextCarrousel = ({ reviews }: ReviewTextCarrouselProps) => {
  return (
    reviews && (
      <Slider.Root
        configs={{
          options: {
            slides: {
              spacing: 16
            }
          },
          breakpoints: {
            md: {
              slides: {
                spacing: 40
              }
            }
          }
        }}
        navigations={{
          arrows: true
        }}
      >
        {reviews.map((review) => (
          <Slider.Item
            key={review.name}
            className="min-w-[328px] md:min-w-[400px] max-w-[328px] md:max-w-[400px]"
          >
            <ReviewTextCard {...review} />
          </Slider.Item>
        ))}
      </Slider.Root>
    )
  )
}
