import React from 'react'

import { IconsNames } from '@/components/Icon'
import {
  IconSolutionsHeaderNames,
  SolutionHeaderSprite
} from '@/components/IconSprite'
import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'

type MenuItemProps = {
  id?: string
  text: string
  iconName: IconsNames
  href: string
}

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  iconName,
  href,
  id = ''
}) => {
  return (
    <div className="w-full bg-display-0">
      <RetainQueryLinkDefault
        href={href}
        id={id}
        aria-label={text}
        className="flex p-16 my-auto font-medium text-display-900 paragraph-16"
      >
        <SolutionHeaderSprite
          name={iconName as IconSolutionsHeaderNames}
          className="mr-12 max-w-[22px] max-h-[22px] fill-current"
        />
        {text}
      </RetainQueryLinkDefault>
    </div>
  )
}

export default MenuItem
