import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import { TestimonialsProps as TestimonialsCMSProps } from '@/types/cms'
import cn from '@/utils/cn'
import { FreeMode, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  NavigationOptions,
  PaginationOptions,
  SwiperOptions
} from 'swiper/types'

export interface Testimonials extends TestimonialsCMSProps {
  roundedBottom?: boolean
}

interface TextTestimonialsProps {
  testimonials: Testimonials
  referId: string
}

const navigationOptions: NavigationOptions = {
  enabled: true,
  nextEl: '#swiper-next-tt',
  prevEl: '#swiper-prev-tt',
  disabledClass: 'text-display-400'
}

const paginationOptions: PaginationOptions = {
  enabled: true,
  clickable: true,
  el: '#client-testimonials',
  bulletActiveClass: 'swiper-pagination-bullet-active !bg-newTon-700'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation, FreeMode, Pagination],
  freeMode: true,
  navigation: navigationOptions,
  pagination: paginationOptions,
  spaceBetween: 40,
  slidesPerView: 1,
  breakpoints: {
    768: {
      slidesPerView: 'auto'
    }
  }
}

export function TextTestimonials({
  referId,
  testimonials: {
    id,
    title,
    icon,
    testimonialsDescriptions,
    roundedBottom = false
  }
}: TextTestimonialsProps) {
  return (
    <div
      id={referId || id}
      className={cn(
        'flex flex-col items-center py-40 lg:py-80 pb-[38px] bg-display-0',
        roundedBottom ? 'rounded-b-[24px] lg:rounded-b-[40px]' : 'rounded-none'
      )}
    >
      <div className="flex z-[10] justify-center px-16 md:px-24 w-full">
        <div className="flex flex-col-reverse md:flex-row md:justify-between items-center lg:items-start mb-24 md:mb-[-8px] lg:mb-[-16px] w-full max-w-[1280px]">
          <MarkdownContent
            content={title}
            className="font-bold text-center md:text-left heading-3"
          />
          <div className="flex md:mr-16 mb-16 md:mb-0 h-full">
            <Icon
              name={icon.name}
              className="md:!w-[108px] lg:!w-[120px] md:!h-[130px] lg:!h-[148px]"
            />
          </div>
        </div>
      </div>
      <div className="px-16 md:px-0 max-w-[444px] md:max-w-full 2xl:max-w-[1280px]">
        <Swiper {...swiperOptions}>
          {testimonialsDescriptions.map(
            ({ namePersona, typeTestimonial, description, image }, index) => (
              <SwiperSlide key={index} className="md:max-w-[400px] !h-auto">
                <div className="flex flex-col items-start p-16 h-full bg-display-100 rounded-t-[16px] rounded-bl-[16px]">
                  <div className="flex gap-12 items-center">
                    <div className="overflow-hidden w-[48px] h-[48px] rounded-[8px]">
                      <CloudinaryImage
                        src={image.path}
                        alt={image.alternativeText}
                        width={48}
                        height={48}
                        quality={100}
                        className="object-cover max-w-full h-auto"
                      />
                    </div>
                    <div>
                      <p className="font-bold text-newTon-700 paragraph-18">
                        {namePersona}
                      </p>
                      <p className="text-display-600 paragraph-14">
                        {typeTestimonial}
                      </p>
                    </div>
                  </div>
                  <p className="mt-16 paragraph-16">{description}</p>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
      <div id="arrow-container" className="flex md:hidden gap-16 pt-24">
        <span
          id="swiper-prev-tt"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="rotate-180 fill-current" />
        </span>
        <span
          id="swiper-next-tt"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="fill-current" />
        </span>
      </div>
      <div
        id="client-testimonials"
        className="hidden md:flex justify-center items-center mt-24"
      />
    </div>
  )
}
