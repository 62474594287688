import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import {
  ColorProps,
  IconsDescriptionsV2Props,
  ImageCMSProps,
  ImagesProps
} from '@/types/cms'
import cn from '@/utils/cn'

import Button, { ButtonV2Props } from '../ButtonV2'

export interface BenefitsListCardProps {
  referId: string
  title: string
  description: string
  image: ImageCMSProps
  images: ImagesProps
  iconsDescriptions: IconsDescriptionsV2Props[]

  backgroundColor?: ColorProps
  buttons: ButtonV2Props[]
}

export function BenefitsListCard({
  referId,
  title,
  description,
  images,
  iconsDescriptions,
  backgroundColor,
  buttons
}: BenefitsListCardProps) {
  const bgContainer = backgroundColor?.classColor
    ? `bg-${backgroundColor.classColor}`
    : 'bg-display-50'

  return (
    <section id={referId} className={`py-40 lg:py-80 px-24 ${bgContainer}`}>
      <div className="md:flex md:flex-col md:items-center md:mx-auto lg:max-w-[1060px]">
        <h2
          className="md:text-[28px] md:leading-[36px] text-center heading-2 lg:heading-2"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="mt-4 text-center md:text-left text-display-800 paragraph-16 lg:paragraph-20"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="flex flex-col lg:flex-row lg:gap-40 lg:items-center mt-24 lg:mt-40">
          <div className="md:hidden relative self-center mb-24 lg:mb-0 max-w-[413px]">
            <CloudinaryImage
              width={images.mobile.width}
              height={images.mobile.height}
              src={images.mobile.path}
              alt={images.mobile.alternativeText}
              className={cn(
                'max-w-full h-auto',
                images.hasBackground ? 'object-contain' : 'object-cover'
              )}
              quality={100}
            />
          </div>
          <div className="hidden md:block lg:hidden relative self-center px-[33px] mb-24 lg:mb-0 max-w-[413px]">
            <CloudinaryImage
              width={images.tablet.width}
              height={images.tablet.height}
              src={images.tablet.path}
              alt={images.tablet.alternativeText}
              quality={100}
              className={cn(
                'max-w-full h-auto',
                images.hasBackground ? 'object-contain' : 'object-cover'
              )}
            />
          </div>
          <div className="hidden lg:block relative self-center mb-24 lg:mb-0 w-full max-w-[451px]">
            <CloudinaryImage
              width={images.web.width}
              height={images.web.height}
              src={images.web.path}
              alt={images.web.alternativeText}
              className={cn(
                'max-w-full h-auto',
                images.hasBackground ? 'object-contain' : 'object-cover'
              )}
              quality={100}
            />
          </div>
          <div className="flex flex-col gap-16 lg:gap-24 lg:w-full max-w-full lg:max-w-[510px]">
            <ul className="flex flex-col gap-16">
              {iconsDescriptions.map(({ description, iconName }, index) => (
                <li key={index} className="flex gap-8 paragraph-16">
                  <div className="my-auto min-w-[24px] min-h-[24px]">
                    <Icon
                      name={iconName}
                      className="text-newTon-600 fill-current"
                    />
                  </div>
                  <p
                    className="pr-[29px] lg:pr-0 w-full lg:max-w-[449px] paragraph-16"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </li>
              ))}
            </ul>
            <div className="flex flex-row flex-wrap lg:flex-nowrap gap-16 justify-center md:self-center lg:self-start">
              {buttons?.map((props) => (
                <Button
                  key={props.label}
                  {...props}
                  className="md:w-[fit-content]"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
