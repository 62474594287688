import { FlagNames, FlagSprite } from '@/components/IconSprite'
import { FlagsProps } from '@/types/cms'
import cn from '@/utils/cn'

interface TFlags extends FlagsProps {
  bgColor?: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
}

export default function Flags({
  flagPayments: {
    title = 'Aceite as principais formas de pagamento123',
    cards,
    bgColor = 50
  },
  variant = 'default'
}: {
  flagPayments: TFlags
  variant?: 'default' | 'vertical-spaced'
}) {
  return (
    <div
      className={cn(
        `justify-center items-center px-24 py-40 bg-display-${bgColor}`,
        {
          'lg:py-80': variant === 'vertical-spaced'
        }
      )}
    >
      <div
        className={`flex flex-col items-center justify-center bg-display-${bgColor}`}
      >
        <div className="flex gap-8 w-full max-w-[310px] sm:max-w-[630px] md:max-w-[734px] lg:max-w-full">
          <div className="mx-auto !w-auto">
            <div className="flex flex-col items-center mt-[8px] xxs:whitespace-nowrap rounded-[1px] xxs:border xxs:border-b-0 border-display-300">
              <p
                className={`px-4 mt-[-10px] font-bold text-center paragraph-14 bg-display-${bgColor}`}
              >
                {title}
              </p>
            </div>
            <div className="flex flex-wrap gap-8 justify-center pt-[8px]">
              {cards.map(({ flag }, index) => (
                <div key={`Flag ${index}`} className="!w-[48px] !h-[32px]">
                  <FlagSprite
                    name={flag as FlagNames}
                    className="w-full h-full"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
