import Button, { ButtonV2Props } from '@/cmsComponents/ButtonV2'
import Icon from '@/components/Icon'
import { IconProps } from '@/types/cms'

export interface DocumentProps {
  icon: IconProps
  title: string
  button: ButtonV2Props
}

export function Document({ button, icon, title }: DocumentProps) {
  return (
    <div className="p-16 w-[300px] min-w-[300px] h-full bg-white rounded-lg border border-display-300">
      <div className="flex flex-col gap-16 justify-center items-center w-full h-full">
        <div className="p-16 w-max bg-display-100 rounded-md">
          <Icon {...icon} className="w-40 h-40 text-newTon-500" />
        </div>
        <p className="font-bold text-center ton-paragraph-20">{title}</p>
        <Button {...button} className="!w-full" />
      </div>
    </div>
  )
}
