import React from 'react'

import CloudinaryImage from '@/components/CloudinaryImage'
import { IconsNames } from '@/components/Icon'
import {
  HeaderSprite,
  IconHeaderNames,
  IconSolutionsHeaderNames,
  SolutionHeaderSprite
} from '@/components/IconSprite'
import { MenuColumn } from '@/types/cms'

import styles from '../../Header.module.css'
import { MachinesColumn } from '../MachinesColumn'
import MenuItem from '../MenuItem'
import MenuWithSubMenu from '../MenuItemWithSubMenu'
import RetainQueryLink from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import { getSearchParams } from '@/utils/searchParams'

type Props = {
  columns: MenuColumn[]
  onMachineClick(): void
}

export function MenuMobileColumns({ columns, onMachineClick }: Props) {
  return (
    <div>
      {columns.map((column) =>
        column?.menu_items_groups[0]?.menu_items?.length > 0 ? (
          <MenuWithSubMenu
            id={column.title}
            key={`card-${column.title}`}
            text={column.title}
            iconName={column.mobileIcon?.name}
          >
            {column.menu_items_groups.map((group, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <hr className="my-8 bg-display-200" />}
                {group.title && (
                  <p className="px-16 pt-16 mb-8 font-bold">{group.title}</p>
                )}
                {group.menu_items.map(
                  (sub) =>
                    sub.link && (
                      <RetainQueryLink
                        key={sub.title}
                        href={{
                          pathname: sub.link.href,
                          query: getSearchParams(sub?.link?.query || '')
                        }}
                        id={sub?.title}
                        className={`flex items-center p-16 ${styles.solutionMobile}`}
                      >
                        {sub.icon && (
                          <>
                            {column.title === 'Atendimento' ? (
                              <HeaderSprite
                                name={sub.icon.name as IconHeaderNames}
                                className="max-w-[24px] max-h-[24px]"
                              />
                            ) : (
                              <SolutionHeaderSprite
                                name={sub.icon.name as IconSolutionsHeaderNames}
                                className="max-w-[22px] max-h-[22px]"
                              />
                            )}
                          </>
                        )}

                        <div className="ml-12">
                          <p className="font-medium paragraph-16">
                            {sub.title}
                          </p>
                          <p className="mt-2 text-display-600 paragraph-14">
                            {sub.description}
                          </p>
                        </div>
                      </RetainQueryLink>
                    )
                )}
              </React.Fragment>
            ))}
          </MenuWithSubMenu>
        ) : column.directLink ? (
          <MenuItem
            id={column.title}
            key={column.title}
            text={column.title}
            href={column.directLink.href}
            iconName={column.mobileIcon?.name}
          />
        ) : column?.isMachinesColumn ? (
          <MenuWithSubMenu
            id={column.title}
            key={column.title}
            text={column.title}
            iconName={column.mobileIcon?.name as IconsNames}
          >
            <>
              <MachinesColumn onMachineClick={onMachineClick} />

              <a
                href={`/#maquininhas`}
                className="flex items-center p-16"
                onClick={onMachineClick}
              >
                <CloudinaryImage
                  src={`site-ton/todas-2`}
                  width={40}
                  height={60}
                  alt={`Todos as Maquininha`}
                  className="max-w-full h-auto"
                />
                <div className="ml-12">
                  <p className="font-medium">Compare as maquininhas</p>
                  <p className="mt-2 text-display-600 paragraph-14">
                    Descubra a melhor maquininha pro seu negócio
                  </p>
                </div>
              </a>
            </>
          </MenuWithSubMenu>
        ) : null
      )}
    </div>
  )
}
