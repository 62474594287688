import { HeroBannerContainer } from '@cmsComponents/Hero/components/HeroBannerContainer'
import { HeroBannerImage } from '@cmsComponents/Hero/components/HeroBannerImage'
import { HeroDescription } from '@cmsComponents/Hero/components/HeroDescription'
import { HeroIconDescriptions } from '@cmsComponents/Hero/components/HeroIconDescriptions'
import { HeroRoot } from '@cmsComponents/Hero/components/HeroRoot'
import { HeroTag } from '@cmsComponents/Hero/components/HeroTag'
import { HeroTitle } from '@cmsComponents/Hero/components/HeroTitle'
import { HeroWrapper } from '@cmsComponents/Hero/components/HeroWrapper'

export const Hero = {
  Root: HeroRoot,
  Wrapper: HeroWrapper,
  Tag: HeroTag,
  Title: HeroTitle,
  Description: HeroDescription,
  IconDescriptions: HeroIconDescriptions,
  Banner: {
    Container: HeroBannerContainer,
    Image: HeroBannerImage
  }
}
