import Image from 'next/image'

import cloudinaryLoader from '@/utils/cloudinary-loader'

export type CloudinaryImageProps = {
  src: string
  width?: number
  alt?: string
  height?: number
  className?: string
  quality?: number
  priority?: boolean
  sizes?: string
  fill?: boolean
  loading?: 'eager' | 'lazy'
}

export default function CloudinaryImage({
  src,
  width,
  quality,
  alt = '',
  height,
  className,
  priority = false,
  loading = 'lazy',
  fill,
  ...props
}: CloudinaryImageProps) {
  return (
    <Image
      loader={cloudinaryLoader}
      width={width}
      height={height}
      src={src}
      alt={alt}
      quality={quality}
      className={className}
      priority={priority}
      loading={priority ? 'eager' : loading}
      fill={fill && !width ? true : undefined}
      {...props}
    />
  )
}
