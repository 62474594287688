import React from 'react'

import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import cn from '@/utils/cn'
import { ClassValue } from 'tailwind-variants'

type TCasesVideosModal = {
  showModal: boolean
  modalClassName?: ClassValue[]
  modalInnerContainerClassName?: ClassValue[]
  childrenContainerClassName?: ClassValue[]
  onCloseModal: () => void
  children: React.ReactNode
}

const CasesVideosModal: React.FC<TCasesVideosModal> = ({
  showModal,
  modalClassName,
  modalInnerContainerClassName,
  childrenContainerClassName,
  onCloseModal,
  children
}) => {
  return (
    <Modal
      className={cn(
        'w-full max-w-[1200px] h-full bg-transparent xs:rounded rounded-none',
        modalClassName
      )}
      open={showModal}
      closeOnClickOut={true}
      onCloseClick={onCloseModal}
    >
      <div
        className={cn(
          'mx-auto w-full max-w-[1200px] h-full',
          modalInnerContainerClassName
        )}
      >
        <div className="flex">
          <button type="button" onClick={onCloseModal} className="ml-auto">
            <Icon name="close" className="text-display-0 fill-current" />
          </button>
        </div>
        <div
          className={cn(
            'flex relative justify-center items-center mt-24 w-full h-[90%]',
            childrenContainerClassName
          )}
        >
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default CasesVideosModal
