import { KeenSliderInstanceProps } from '../../../Root'
import { ArrowAction } from './ArrowAction'

export interface NavigationArrowStateProps {
  prev: boolean
  next: boolean
}

export interface SliderNavigationArrowProps {
  instance?: KeenSliderInstanceProps
  states: NavigationArrowStateProps
}

export const SliderNavigationArrow = ({
  instance,
  states
}: SliderNavigationArrowProps) => {
  if (!instance) return <></>

  return (
    <div className="flex gap-16 justify-center">
      <ArrowAction
        disabled={states.prev}
        icon="arrow-left-2x"
        instance={instance}
        action="prev"
      />

      <ArrowAction
        disabled={states.next}
        icon="arrow-right-2x"
        instance={instance}
        action="next"
      />
    </div>
  )
}
