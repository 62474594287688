import { tv } from 'tailwind-variants'

const titleStyle = tv({
  base: 'ton-heading-1 text-display-900 font-extrabold text-center'
})

export interface HeroTitleProps {
  title: string
  className?: string
}

export const HeroTitle = ({ title, className }: HeroTitleProps) => {
  return (
    <h1
      dangerouslySetInnerHTML={{ __html: title }}
      className={titleStyle({ className })}
    />
  )
}
