import Machines from '@/components/MachinesV2'
import TapTonMachine from '@/components/PlansWithTapTon/TapTonMachine'
import { usePlans } from '@/contexts/plans'
import { AllPlansForSmartphone } from '@/types/plans'

interface AllMachinesDetailedProps {
  referId: string
  title: string
  description?: string
  variants?: 'default' | 'familhao-do-ton'
}

export function AllMachinesDetailed({
  referId,
  title,
  description,
  variants: behavior
}: AllMachinesDetailedProps) {
  const { plan } = usePlans()

  return (
    plan && (
      <div id={referId}>
        {plan === AllPlansForSmartphone.Tapton ? (
          <TapTonMachine />
        ) : (
          <Machines
            title={title}
            description={description}
            behavior={behavior}
          />
        )}
      </div>
    )
  )
}
