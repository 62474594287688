import { FreeMode, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  NavigationOptions,
  PaginationOptions,
  SwiperOptions
} from 'swiper/types'

import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import { ImageTitleAndDescriptionProps } from '@/types/cms'

interface CardsCarouselProps {
  referId: string
  BenefitsTitle: string
  descriptionTop?: string
  descriptionBottom?: string
  cards: ImageTitleAndDescriptionProps[]
}

const navigationOptions: NavigationOptions = {
  enabled: true,
  nextEl: '#swiper-next-rtb',
  prevEl: '#swiper-prev-rtb',
  disabledClass: 'text-display-400'
}
const paginationOptions: PaginationOptions = {
  enabled: true,
  clickable: true,
  el: '#unique-rtbs',
  bulletActiveClass: 'swiper-pagination-bullet-active !bg-newTon-700'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation, FreeMode, Pagination],
  freeMode: true,
  navigation: navigationOptions,
  pagination: paginationOptions,
  spaceBetween: 16,
  slidesPerView: 'auto',
  slidesOffsetBefore: 16,
  slidesOffsetAfter: 16,
  breakpoints: {
    768: {
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24
    }
  }
}

export function SwipperBenefits({
  referId,
  BenefitsTitle,
  cards,
  descriptionBottom,
  descriptionTop
}: CardsCarouselProps) {
  return (
    <div
      id={referId}
      className="flex flex-col items-center py-40 2xl:px-0 mx-auto max-w-[fit-content] bg-display-0"
    >
      <div className="flex flex-wrap justify-center px-16 mt-8 md:mt-40 w-full">
        <h2
          className="max-w-full font-heading text-center text-newTon-600"
          dangerouslySetInnerHTML={{ __html: BenefitsTitle }}
        />
      </div>
      <div className="flex justify-center px-16 md:px-24 pt-8 pb-24 lg:pb-40">
        <p className="text-center paragraph-16">{descriptionTop}</p>
      </div>
      <div className="w-full">
        <Swiper {...swiperOptions}>
          {cards.map(
            ({ title, description, image, backgroundColor }, index) => (
              <SwiperSlide
                key={index}
                className="flex justify-center mb-4 !w-[265px] !h-auto rounded-md border border-display-300"
                style={{
                  backgroundColor: backgroundColor
                    ? `${backgroundColor}`
                    : 'transparent'
                }}
              >
                <div
                  className={`flex overflow-hidden justify-center mt-16 mb-8 h-[72px]`}
                >
                  <p className="content-center max-w-[199px] font-heading font-bold text-center text-display-0 ton-paragraph-28">
                    {title}
                  </p>
                </div>
                <div className="mb-8 !w-[263px]">
                  <CloudinaryImage
                    width={image.width / 2}
                    height={image.height / 2}
                    src={image.path}
                    alt={image.alternativeText}
                    quality={100}
                    priority
                    className="object-cover w-full h-[250px]"
                  />
                </div>
                <div className="flex justify-center">
                  <p className="mb-16 max-w-[199px] text-center text-white paragraph-14">
                    {description}
                  </p>
                </div>
              </SwiperSlide>
            )
          )}
        </Swiper>
        {descriptionBottom && (
          <div className="flex justify-center pt-24 lg:pt-40">
            <p className="text-center paragraph-16">{descriptionBottom}</p>
          </div>
        )}
      </div>
      <div id="arrow-container" className="flex gap-16 pt-24 lg:pt-40">
        <span
          id="swiper-prev-rtb"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="rotate-180 fill-current" />
        </span>
        <span
          id="swiper-next-rtb"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="fill-current" />
        </span>
      </div>
    </div>
  )
}
