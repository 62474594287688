import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions, SwiperOptions } from 'swiper/types'

import Icon from '@/components/Icon'
import { ALL_PLANS_COMMOM_INFO, ALL_PLANS_FOR_MACHINE } from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { ALL_MACHINES_REX_COMMISIONS } from '@/resources/renda-ton/commissions'

import Button, { ButtonV2Props } from '../ButtonV2'
import MachineCard from './MachineCard'

export interface MachineCardsProps {
  referId: string
  title: string
  description: string
  buttons: ButtonV2Props[]
}

const navigationOptions: NavigationOptions = {
  nextEl: '#swiper-next-iaa',
  prevEl: '#swiper-prev-iaa',
  disabledClass: 'text-display-300'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation],
  spaceBetween: 16,
  slidesPerView: 'auto',
  slidesOffsetBefore: 16,
  slidesOffsetAfter: 16,
  navigation: navigationOptions,
  breakpoints: {
    768: {
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24
    },
    1025: {
      spaceBetween: 40,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0
    }
  }
}

export function IndicateAndAdvantages({
  referId,
  title,
  description,
  buttons
}: MachineCardsProps) {
  const { plan, setPlan } = usePlans()

  return (
    <div id={referId} className="flex flex-col gap-24 lg:gap-40 py-40 lg:py-80">
      <div className="px-24">
        <h2
          className="text-[28px] leading-[36px] text-center md:heading-2"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="mt-4 text-center paragraph-16 lg:paragraph-20"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <div className="flex flex-col gap-24 lg:gap-40">
        <div className="flex justify-center self-center">
          <ul
            id="tabList"
            className="flex overflow-x-auto px-24 xs:px-0 pb-2 w-full max-w-[455px]"
          >
            {ALL_PLANS_FOR_MACHINE.map((planName) => (
              <li
                key={`tab-${planName}`}
                onClick={() => {
                  setPlan(planName)
                }}
                className={`tabItem flex justify-center items-center py-[12px] px-[8px] max-h-[48px] w-[82px] border-t-[1px] border-b-[1px] cursor-pointer border-l-[1px] last:border-r-[1px] first:rounded-tl-[8px] first:rounded-bl-[8px] last:rounded-tr-[8px] last:rounded-br-[8px] ${
                  planName === plan
                    ? `text-newTon-700 border-newTon-700 border-r-[1px] [&+li]:border-l-0`
                    : 'relative border-display-400 text-display-900 hover:text-newTon-600 hover:border-newTon-600'
                }`}
              >
                <p className="font-medium whitespace-nowrap paragraph-16">
                  {ALL_PLANS_COMMOM_INFO[planName].shortPopularName}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className="xl:mx-auto w-full max-w-[1280px]">
          <Swiper
            {...swiperOptions}
            key={`cards-${plan}`}
            className="overflow-visible w-full"
          >
            {ALL_MACHINES_REX_COMMISIONS[
              plan as keyof typeof ALL_MACHINES_REX_COMMISIONS
            ].machines.map((machine) => (
              <SwiperSlide
                key={`${machine.name}-${machine.plan}`}
                className="max-w-[224px] lg:max-w-[290px]"
              >
                <MachineCard
                  index={`${machine.name}-${machine.plan}-mobile`}
                  {...machine}
                />
              </SwiperSlide>
            ))}

            <span className="flex gap-16 justify-center mt-24 w-full">
              <span
                id="swiper-prev-iaa"
                className="w-40 h-40 text-newTon-600 cursor-pointer"
              >
                <Icon
                  name="arrow-right-2x"
                  className="rotate-180 fill-current"
                />
              </span>
              <span
                id="swiper-next-iaa"
                className="w-40 h-40 text-newTon-600 cursor-pointer"
              >
                <Icon name="arrow-right-2x" className="fill-current" />
              </span>
            </span>
          </Swiper>
        </div>
      </div>

      <div className="flex gap-16 justify-center px-16 md:px-0 w-full">
        {buttons?.map((props) => (
          <Button key={props.label} {...props} className="md:w-[fit-content]" />
        ))}
      </div>
    </div>
  )
}
