import Icon from '@/components/Icon'
import {
  FlagsProps,
  IconTitleAndDescriptionV2Props,
  ImageCMSProps
} from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'
import { FlagsCarousel } from '../FlagsCarousel'

interface InfosWithFeaturedImageProps {
  referId: string
  title: string
  description: string
  buttons: ButtonV2Props[]
  cards: IconTitleAndDescriptionV2Props[]
  media: ImageCMSProps
  flags: FlagsProps
}

export const InfosWithFeaturedImage = ({
  referId,
  media,
  title,
  description,
  buttons,
  cards,
  flags
}: Partial<InfosWithFeaturedImageProps>) => {
  return (
    <div id={referId} className="py-40 md:py-80">
      <div className="flex flex-col xl:flex-row gap-24 md:gap-40 xl:items-center px-16 md:px-40 lg:px-0 mx-auto xl:mb-16 md:max-w-[950px]">
        {media && (
          <div className="relative mx-auto w-full lg:max-w-[455px] h-full min-h-[170px] sm:min-h-[255px] md:min-h-[350px]">
            <CloudinaryImage
              src={media.path}
              alt={media.alternativeText}
              fill
              sizes="100vw"
              className="object-contain"
            />
          </div>
        )}

        <div className="flex flex-col gap-16 mx-auto max-w-[688px]">
          {(title || description) && (
            <>
              {title && (
                <div className="text-center xl:text-left">
                  <h2
                    className="whitespace-pre-line"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
              )}

              {description && (
                <div className="text-center xl:text-left whitespace-pre-line">
                  <p className="ton-paragraph-14">{description}</p>
                </div>
              )}
            </>
          )}

          {cards && (
            <div className="flex flex-col gap-16 md:gap-24">
              {cards.map(({ title, description, iconName }) => (
                <div key={title} className="flex gap-16">
                  <div className="p-12 h-[fit-content] bg-newTon-100 rounded-16 border-4 border-newTon-50">
                    <div className="">
                      <Icon
                        name={iconName}
                        className="text-newTon-600 fill-current"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-bold ton-paragraph-18 md:ton-paragraph-20">
                      {title}
                    </p>
                    <p className="ton-paragraph-16">{description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {buttons && (
            <div className="mx-auto lg:ml-0 w-full max-w-[455px] md:max-w-max">
              {buttons.map((props) => (
                <Button key={props.label} {...props} />
              ))}
            </div>
          )}
        </div>
      </div>

      {flags && <FlagsCarousel flag_payment={flags} />}
    </div>
  )
}
