import useSWRImmultable from 'swr/immutable'

import AMPLITUDE from '@/constants/amplitude'

import experimentFetcher from '../experimentFetcher'

enum Variants {
  CONTROL = 'control-cro-ton-032',
  VARIANT_B = 'var-b-cro-ton-032',
  VARIANT_C = 'var-c-cro-ton-032'
}

export default function useCROTon032() {
  const { data, error, isLoading } = useSWRImmultable(
    AMPLITUDE.TESTS.CRON_TON_032,
    experimentFetcher
  )

  return {
    variantKey: data?.data[AMPLITUDE.TESTS.CRON_TON_032]?.key,
    data,
    error,
    isLoading,
    variants: Variants
  }
}
