import { MachineCardDetails } from './MachineCardDetails'
import { MachineCardImage } from './MachineCardImage'
import { MachineCardInfo } from './MachineCardInfo'
import { MachineCardRoot } from './MachineCardRoot'

export interface MachineCardPromotionsProps {
  isPromo20?: boolean
  isPromoZero?: boolean
  isPromoCombo?: boolean
  isPromo25?: boolean
  isPromoUntil35?: boolean
}

export const MachineCard = {
  Root: MachineCardRoot,
  Image: MachineCardImage,
  Info: MachineCardInfo,
  Details: MachineCardDetails
}
