import { getGaClientId } from './getGaClientId'

export function getCrossLeadId() {
  const gaClientId = getGaClientId()

  if (!gaClientId) {
    return ''
  }

  const crossLeadId = gaClientId.substring(6).split('.')[0]

  return crossLeadId
}
