import { PlansProps } from '@/cmsComponents/Plans'
import { usePlans } from '@/contexts/plans'
import { ReceivingOptions } from '@/types/fees'

import { AntecipationFeesToggle } from '../AntecipationFeesToggle'
import Icon from '../Icon'
import RetainQueryLink from '../RetainQueryLink/RetainQueryLinkDefault'
import { PlanCards } from './components/PlanCards'

export const PlansSelectCard = ({ referId, hasTapton }: PlansProps) => {
  const { receivingOptions, setReceivingOptions } = usePlans()

  return (
    <section id={referId} className="pt-24 pb-40 mx-auto max-w-screen-xl">
      <div className="flex flex-col gap-16 md:gap-24 items-center">
        <h2 className="ton-heading-2">Conheça os Planos Ton</h2>

        <div className="flex flex-col gap-8">
          <AntecipationFeesToggle.Root>
            <AntecipationFeesToggle.Label text="Recebimento da venda" />

            <AntecipationFeesToggle.Content>
              <AntecipationFeesToggle.Button
                onClick={() =>
                  setReceivingOptions(ReceivingOptions.OneBusinessDay)
                }
                condition={
                  receivingOptions === ReceivingOptions.OneBusinessDay
                    ? 'active'
                    : 'default'
                }
                text="1 dia útil"
                id="btn-term-1-day"
              >
                <Icon name="cent-clock" className="w-24 h-24 fill-current" />
              </AntecipationFeesToggle.Button>

              <AntecipationFeesToggle.Button
                onClick={() => setReceivingOptions(ReceivingOptions.SameDay)}
                condition={
                  receivingOptions === ReceivingOptions.SameDay
                    ? 'active'
                    : 'default'
                }
                text="No mesmo dia"
                id="btn-term-0-day"
              >
                <Icon name="cent-hand" className="w-24 h-24 fill-current" />
              </AntecipationFeesToggle.Button>
            </AntecipationFeesToggle.Content>
          </AntecipationFeesToggle.Root>

          {receivingOptions === ReceivingOptions.SameDay && (
            <div className="flex gap-10 justify-center items-center py-4 px-8 mx-auto max-w-[293px] md:max-w-full bg-newTon-50 rounded-8 border border-newTon-700">
              <div className="w-24">
                <Icon
                  name="emotiton-money"
                  className=" text-newTon-700 fill-current"
                />
              </div>

              <p className="font-bold text-center text-newTon-700 ton-paragraph-14">
                Receba suas vendas no mesmo dia com a menor taxa do Brasil
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-24 justify-center items-center md:items-stretch xl:mx-auto w-[300px] md:w-full max-w-[1280px]">
          <PlanCards showTapTonCard={hasTapton} />
        </div>

        <div className="flex flex-col gap-8">
          <p className="text-center">
            Confira as{' '}
            <RetainQueryLink
              href="/planos-e-taxas"
              className="text-newTon-700 underline"
            >
              taxas de todos os planos
            </RetainQueryLink>
            .
          </p>

          <p className="text-center">
            Taxa 0% no Pix nos primeiros 30 dias após a primeira venda com Pix
            na maquininha.
          </p>
        </div>
      </div>
    </section>
  )
}
