import CloudinaryImage from '@/components/CloudinaryImage'
import { TextReviewBlockProps } from '@/types/cms'

type ReviewTextCardProps = TextReviewBlockProps

export const ReviewTextCard = ({
  image,
  name,
  role,
  quote
}: ReviewTextCardProps) => {
  return (
    <div className="flex flex-col gap-16 p-16 h-full bg-display-0 rounded-16 rounded-br-none">
      <div className="flex gap-12 items-center">
        {image && (
          <CloudinaryImage
            width={48}
            height={48}
            src={image.path}
            alt={image.alternativeText}
            quality={90}
            className="max-w-full h-auto rounded-16"
          />
        )}

        <div>
          {name && (
            <p className="font-bold text-newTon-700 ton-paragraph-18">{name}</p>
          )}
          {role && <p className="text-display-600 ton-paragraph-16">{role}</p>}
        </div>
      </div>
      {quote && (
        <div>
          <p className="ton-paragraph-16">{quote}</p>
        </div>
      )}
    </div>
  )
}
