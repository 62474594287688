import { useCatalog } from '@/contexts/catalog'
import { AllMachinesType } from '@/types/machines'

import AnotherMachineCard from './components/AnotherMachineCard'

type AllMachinesProps = {
  referId: string
  title?: string
  machineName?: {
    value: AllMachinesType
  }
}
export const AllMachinesInCard = ({
  referId = 'maquininhas',
  title = 'Outras maquininhas pra você',
  machineName
}: AllMachinesProps) => {
  const { machineFromPlan } = useCatalog()

  return (
    <>
      <div id={referId} className="anchor" />
      <div className="flex flex-col gap-40 md:gap-80 py-40 md:py-80 bg-display-0">
        <div className="flex flex-col items-center px-24 md:px-40 w-full">
          <h2 className="mb-24 text-center md:text-left">{title}</h2>
          <ul className="flex flex-wrap gap-24 md:gap-40 justify-center mx-auto">
            {machineFromPlan.machines
              .filter((product) => product.machineName !== machineName?.value)
              .map((machine) => (
                <li key={machine.id}>
                  <AnotherMachineCard {...machine} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  )
}
