/* eslint-disable tailwindcss/no-custom-classname */
import { useKeenSlider } from 'keen-slider/react'
import { useState } from 'react'

import Icon from '@/components/Icon'
import cn from '@/utils/cn'
import useViewport from '@/utils/useViewport'
import { Document, DocumentProps } from './Document'
export interface DocumentsCarouselProps {
  referId: string
  title: string
  description: string
  documents: DocumentProps[]
}

export function DocumentsCarousel({
  referId,
  title,
  description,
  documents
}: DocumentsCarouselProps) {
  const { width } = useViewport()
  const [currentPosition, setCurrentPosition] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const cardWidth = 296
  const spacingBetweenCards = 16
  const maxWidth = 1280

  const maxDocumentsPerView =
    Math.min(maxWidth, width) / (cardWidth + spacingBetweenCards)

  const [keenSliderRef, keenSlider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: maxDocumentsPerView,
      spacing: spacingBetweenCards
    },
    mode: 'snap',
    renderMode: 'performance',
    slideChanged(s) {
      setCurrentPosition(s.track.details.rel)
    },
    created() {
      const timeRequiredToNotShowSliderRenderingCards = 200

      if (!isLoading) return

      setTimeout(() => {
        setIsLoading(false)
      }, timeRequiredToNotShowSliderRenderingCards)
    }
  })

  const cantMoveLeft = isLoading || currentPosition < 1
  const cantMoveRight =
    isLoading || currentPosition === keenSlider.current?.track.details.maxIdx

  return (
    <div
      id={referId}
      className="flex overflow-x-hidden flex-col gap-16 lg:gap-24 py-24 lg:py-40"
    >
      <div className="px-24">
        <h2
          className="text-[32px] md:text-[40px] leading-[38px] md:leading-[48px] text-center md:heading-2"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="mt-8 text-center paragraph-14 lg:paragraph-16"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <div className="relative m-auto w-full max-w-[1280px]">
        {isLoading && (
          <div
            role="status"
            className={cn(
              'flex overflow-visible gap-16 w-max animate-pulse absolute'
            )}
          >
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className={cn(
                  'mb-4 w-[300px] h-[224px] bg-gray-100 rounded-lg',
                  `delay-${index < 4 ? index + 2 : 1}00`
                )}
              />
            ))}
          </div>
        )}

        <div
          className={cn('w-full active:cursor-move keen-slider cursor-grab', {
            'opacity-0': isLoading
          })}
          style={{
            overflow: 'initial !important'
          }}
          ref={keenSliderRef}
        >
          {documents.map((documentProps) => (
            <div key={documentProps.title} className="keen-slider__slide">
              <Document {...documentProps} />
            </div>
          ))}
        </div>

        {keenSlider && (
          <div className="flex gap-16 justify-center pt-24 w-full">
            <button
              type="button"
              onClick={() => {
                setCurrentPosition(currentPosition - 1)
                keenSlider.current?.prev()
              }}
              disabled={cantMoveLeft}
            >
              <Icon
                name="arrow-left-2x"
                className={cn(
                  'fill-current',
                  cantMoveLeft ? 'text-display-300' : 'text-newTon-600'
                )}
              />
            </button>

            <button
              type="button"
              onClick={() => {
                setCurrentPosition(currentPosition + 1)
                keenSlider.current?.next()
              }}
              disabled={cantMoveRight}
            >
              <Icon
                name="arrow-right-2x"
                className={cn(
                  'fill-current',
                  cantMoveRight ? 'text-display-300' : 'text-newTon-600'
                )}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
