import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import {
  IconTitleDescriptionAndLinkWithIconProps,
  ImageCMSProps
} from '@/types/cms'
import cn from '@/utils/cn'
import { handleLinkWithParamsFromCMS } from '@/utils/handleLinkWithParamsFromCMS'

import CloudinaryImage from '../../components/CloudinaryImage'
import Icon from '../../components/Icon'
import Button, { ButtonV2Props } from '../ButtonV2'

export interface InfoCardsListProps {
  referId: string
  midia?: ImageCMSProps
  title: string
  description?: string
  infos: IconTitleDescriptionAndLinkWithIconProps[]
  buttons?: ButtonV2Props[]
  variant?: 'compact' | 'expanded'
  alt?: string
}

export function InfoCardsList({
  referId,
  midia,
  title,
  description,
  buttons,
  variant,
  infos,
  alt
}: InfoCardsListProps) {
  const isExpanded = variant === 'expanded'
  const isCompact = variant !== 'expanded'

  return (
    <div id={referId} className="relative py-40 lg:py-80 px-16 lg:px-0 w-full">
      <div
        className={cn(
          'flex flex-col gap-24 justify-center items-center mx-auto w-full max-w-[455px] md:max-w-[1280px]',
          {
            'lg:gap-[80px]': isExpanded,
            'lg:gap-[56px]': isCompact
          }
        )}
      >
        <div
          className={cn('flex gap-8 w-full md:max-w-[500px]', {
            'lg:max-w-[400px]': isCompact,
            'max-w-[368px] lg:max-w-[383px]': isExpanded
          })}
        >
          {midia && (
            <div
              className={cn('lg:min-w-[80px] lg:h-[80px]', {
                'mt-auto min-w-[72px] h-[72px]': isCompact,
                'mt-auto min-w-[64px] h-[64px]': isExpanded
              })}
            >
              <CloudinaryImage
                src={midia?.path}
                width={80}
                height={80}
                alt={alt ? alt : midia.alternativeText}
                loading="lazy"
                className="max-w-full h-auto"
              />
            </div>
          )}
          <div className="flex flex-col justify-center items-center p-16 lg:py-[18px] lg:px-24 w-full bg-newTon-800 rounded-[16px] rounded-bl-none">
            <p className="font-heading text-display-0 paragraph-24 lg:paragraph-28">
              {title}
            </p>
          </div>
        </div>
        <div
          className={cn('flex flex-col gap-24', {
            'lg:gap-[56px] w-full': isCompact,
            'lg:gap-40': isExpanded
          })}
        >
          <div className="flex flex-wrap gap-16 md:gap-24 3xl:gap-40 justify-center w-full max-w-[455px] md:max-w-screen-xl">
            {infos.map(
              (
                { title, description, iconName, linkHref, linkIconName },
                index
              ) => (
                <div
                  key={index}
                  className={cn('flex gap-16 w-full md:max-w-[348px]', {
                    'lg:grid auto-rows-max lg:max-w-[230px] xl:max-w-[290px]':
                      isCompact,
                    'lg:max-w-[400px]': isExpanded
                  })}
                >
                  <div className="p-12 w-[fit-content] h-[fit-content] text-newTon-700 bg-newTon-100 rounded-md border-4 border-newTon-50">
                    <Icon name={iconName} className="w-24 h-24 fill-current" />
                  </div>
                  <div className="flex flex-col w-full">
                    {linkHref ? (
                      <RetainQueryLink
                        href={handleLinkWithParamsFromCMS({
                          linkHref
                        })}
                      >
                        <p className="flex items-center md:max-w-[240px] xl:max-w-full font-bold text-display-900 hover:text-newTon-700 hover:underline cursor-pointer paragraph-18 md:paragraph-20">
                          {title}
                          {linkIconName && (
                            <Icon
                              name={linkIconName}
                              className="w-24 h-24 fill-current"
                            />
                          )}
                        </p>
                      </RetainQueryLink>
                    ) : (
                      <p
                        className={cn(
                          'md:max-w-[240px] xl:max-w-full font-bold',
                          {
                            'paragraph-20': isCompact,
                            'paragraph-18 lg:paragraph-20': isExpanded
                          }
                        )}
                      >
                        {title}
                      </p>
                    )}
                    <p
                      className={cn('mt-4 mb-8', {
                        'paragraph-16': isCompact,
                        'whitespace-pre-line paragraph-14 lg:paragraph-16':
                          isExpanded
                      })}
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  </div>
                </div>
              )
            )}
          </div>
          {description && (
            <MarkdownContent
              className="grid text-center"
              content={description}
            />
          )}
        </div>

        {buttons?.map((button) => (
          <Button
            key={button.label}
            className={cn('order-2 md:order-3 w-full md:w-max font-bold', {
              grid: button.handleAppDownload,
              flex: !button.handleAppDownload
            })}
            {...button}
          />
        ))}
      </div>
    </div>
  )
}
