import { AllPlansForMachine } from '@/types/plans'

export enum typeStyles {
  backgroundColor = 'background-color',
  titleColor = 'title-color',
  feesColor = 'fees-color',
  backgroundFeesColor = 'background-fees-color',
  titleInfoFees = 'title-info-fees',
  titleNativeDropdown = 'title-native-dropdown',
  borderNativeDropdown = 'border-native-dropdown',
  hoverBackgroundColorNativeDropdown = 'hover-background-color-native-dropdown',
  focusBackgroundColorNativeDropdown = 'focus-background-color-native-dropdown'
}

const templateColor = {
  backgroundColor: '!bg-display-900',
  titleColor: '!text-display-0',
  feesColor: '!text-newTon-400',
  backgroundFeesColor: 'bg-display-700',
  titleInfoFees: '!text-newTon-600',
  titleNativeDropdown: '!text-display-200',
  borderNativeDropdown: '!border-display-700',
  hoverBackgroundColorNativeDropdown: 'hover:!bg-display-800',
  focusBackgroundColorNativeDropdown: 'focus:!bg-newTon-800'
}

export const hasBlackFriday = (plaName: string, typeStyle: string): string => {
  if (plaName === AllPlansForMachine.Pro) {
    switch (typeStyle) {
      case typeStyles.backgroundColor:
        return templateColor.backgroundColor
      case typeStyles.titleColor:
        return templateColor.titleColor
      case typeStyles.feesColor:
        return templateColor.feesColor
      case typeStyles.backgroundFeesColor:
        return templateColor.backgroundFeesColor
      case typeStyles.titleInfoFees:
        return templateColor.titleInfoFees
      case typeStyles.titleNativeDropdown:
        return templateColor.titleNativeDropdown
      case typeStyles.borderNativeDropdown:
        return templateColor.borderNativeDropdown
      case typeStyles.hoverBackgroundColorNativeDropdown:
        return templateColor.hoverBackgroundColorNativeDropdown
      case typeStyles.focusBackgroundColorNativeDropdown:
        return templateColor.focusBackgroundColorNativeDropdown
      default:
        return ''
    }
  } else {
    return ''
  }
}
