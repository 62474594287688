import { tv } from 'tailwind-variants'

import CloudinaryImage from '@/components/CloudinaryImage'
import { ImagesProps } from '@/types/cms'

import Button, { ButtonV2Props } from '../ButtonV2'

export interface CardOverImageProps {
  referId: string
  tag: string
  title: string
  description: string
  image: ImagesProps
  buttons: ButtonV2Props[]
  variant: 'card-on-left' | 'card-on-right'
}

const styles = tv({
  slots: {
    card: 'flex h-full  z-[1] lg:p-40 w-full lg:h-auto'
  },
  variants: {
    variant: {
      'card-on-left': {
        card: 'lg:justify-start'
      },
      'card-on-right': {
        card: 'lg:justify-end'
      }
    }
  }
})

export function CardOverImage({
  referId,
  tag,
  title,
  description,
  image,
  buttons,
  variant
}: CardOverImageProps) {
  const classes = styles({ variant: variant || 'card-on-left' })

  return (
    <div
      id={referId}
      className="flex relative items-stretch lg:items-center m-auto md:m-0 lg:m-auto lg:my-80 w-full md:w-[348px] lg:w-full max-w-[455px] lg:max-w-[1170px]"
    >
      <div className="anchor" />

      <div className="flex overflow-hidden relative flex-col lg:flex-row items-center w-full h-full lg:min-h-[487px] bg-display-0 rounded-[24px] lg:rounded-[40px]">
        <div className="flex lg:hidden overflow-hidden w-full min-h-[216px] max-h-[216px] rounded-t-[24px]">
          <CloudinaryImage
            width={455}
            height={216}
            src={image.mobile.path}
            // quality={image.mobileQuality}
            alt={image.mobile.alternativeText}
            className="object-cover max-w-full h-auto"
          />
        </div>

        <div className={classes.card()}>
          <div className="flex flex-col md:justify-center w-full lg:max-w-[510px] h-full bg-display-100 lg:rounded-[24px]">
            <div className="flex flex-col lg:justify-center p-24 w-full h-full">
              {tag && (
                <div className="w-max bg-red-300 rounded-t-[8px] rounded-bl-[8px]">
                  <p className="p-8 text-display-0 paragraph-14">{tag}</p>
                </div>
              )}

              <h2
                className="mt-16 w-full font-bold text-left heading-2"
                dangerouslySetInnerHTML={{ __html: title }}
              ></h2>

              <p className="mt-8 w-full text-left paragraph-16 lg:paragraph-18">
                {description}
              </p>

              <div className="mt-auto">
                <div className="flex flex-row flex-wrap lg:flex-nowrap gap-16 justify-center lg:justify-start mt-24">
                  {buttons.map((buttonProps) => (
                    <Button key={buttonProps.label} {...buttonProps} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex overflow-hidden absolute inset-0 w-full h-full">
          <CloudinaryImage
            width={1170}
            height={423}
            src={image.web.path}
            // quality={image.webQuality}
            alt={image.web.alternativeText}
            className="object-cover max-w-full h-auto"
          />
        </div>
      </div>
    </div>
  )
}
