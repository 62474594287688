import { ReactNode } from 'react'
import { tv } from 'tailwind-variants'

const wrapper = tv({
  base: 'max-w-[1280px] mx-auto flex flex-col md:flex-row gap-24 lg:gap-40 md:items-center'
})

export interface HeroWrapperProps {
  children: ReactNode
  className?: string
}

export const HeroWrapper = ({ children, className }: HeroWrapperProps) => {
  return <div className={wrapper({ className })}>{children}</div>
}
