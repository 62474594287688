import { ReactNode } from 'react'

export interface AntecipationFeesToggleContentProps {
  children: ReactNode
}

export const AntecipationFeesToggleContent = ({
  children
}: AntecipationFeesToggleContentProps) => {
  return (
    <div className="flex gap-8 p-8 bg-display-100 rounded-24">{children}</div>
  )
}
