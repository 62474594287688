import { useContext } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

import { AmplitudeContext } from '@/contexts/amplitude'
import { Machine } from '@/types'
import cookieConsentAccepted from '@/utils/cookie-consent-checker'
import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'

const cardInfo = tv({
  slots: {
    symbol: 'mt-[-5px] text-[12px] font-bold',
    price: 'text-36 font-bold leading-32 font-heading'
  },

  variants: {
    promotion: {
      true: {
        symbol: 'text-[#8062FF]',
        price: 'text-[#8062FF]'
      },
      false: {
        symbol: 'text-newTon-700',
        price: 'text-newTon-700'
      }
    }
  },

  defaultVariants: {
    promotion: false
  }
})

const { symbol, price } = cardInfo()

interface MachineCardInfoProps extends VariantProps<typeof cardInfo> {
  machine: Machine
}

export const MachineCardInfo = ({
  machine,
  promotion
}: MachineCardInfoProps) => {
  const { analytics } = useContext(AmplitudeContext)
  const { name, to, or, href, imgName } = machine

  return (
    <div className="flex flex-col gap-8 md:gap-16 md:w-[185px]">
      <div className="flex flex-col font-bold text-center ton-paragraph-20">
        <span>{name}</span>
        <span>PromoTon</span>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex gap-4 justify-center items-center">
          <div className="flex flex-col justify-center items-end">
            <span className="text-[12px] font-bold">12x</span>
            <span className={symbol({ promotion })}>R$</span>
          </div>
          <span className={price({ promotion })}>{or}</span>
        </div>

        <p className="text-center ton-paragraph-12">ou R$ {to} à vista</p>
      </div>

      <RetainQueryLinkDefault
        href={href}
        id={`btn-pedir-${imgName}-sobre-o-ton`}
        className=" w-full md:w-[185px] font-bold capitalize whitespace-nowrap bg-newTon-400 btn btn-primary btn-large"
        rel="noreferrer"
        onClick={() => {
          if (!window) return
          if (!cookieConsentAccepted) return

          analytics?.track({
            event_type: 'cta ton',
            event_properties: {
              name: 'cta ton',
              description: `Evento disparado quando usuario clicar no cta da section "Já conhece as máquinas? No Plano PromoTon a taxa é baixinha:`,
              section_reference: `Já conhece as máquinas? No Plano PromoTon a taxa é baixinha:`,
              cta_reference: `${name} PromoTon | Peça a sua ${name}`
            }
          })
        }}
      >
        Peça sua {name}
      </RetainQueryLinkDefault>
    </div>
  )
}
