import { useEffect, useState } from 'react'

import Button, { ButtonV2Props } from '@/cmsComponents/ButtonV2'
import Calculators from '@/components/Calculators'
import { usePlans } from '@/contexts/plans'
import { AllPlansForSmartphone } from '@/types/plans'

export interface CalculatorProps {
  referId: string
  label: string
  buttons?: ButtonV2Props[]
  tabInitiallySelected: 'tapton' | 'machines'
}

export function Calculator(calculator: CalculatorProps) {
  const { plan } = usePlans()
  const [initialTab, setInitialTab] = useState(
    plan === AllPlansForSmartphone.Tapton
      ? 'tapton'
      : calculator.tabInitiallySelected
  )

  useEffect(() => {
    if (plan === AllPlansForSmartphone.Tapton) {
      setInitialTab('tapton')
      return
    }

    setInitialTab(calculator.tabInitiallySelected)
  }, [plan, calculator.tabInitiallySelected])

  return (
    <Calculators
      tabInitiallySelected={initialTab}
      id={calculator?.referId}
      title={calculator?.label}
    >
      {calculator.buttons && (
        <div className="flex gap-16 mt-24 w-full md:w-max">
          {calculator.buttons?.map(
            ({
              color,
              size,
              label,
              linkPathname,
              linkHash,
              linkQuery,
              linkTarget,
              handleAppDownload
            }) => (
              <Button
                key={`button-${label}`}
                size={size}
                color={color}
                linkPathname={linkPathname}
                linkHash={linkHash}
                linkQuery={linkQuery}
                linkTarget={linkTarget}
                handleAppDownload={handleAppDownload}
                className="flex order-2 md:order-3 w-full md:w-max font-bold"
              >
                {label}
              </Button>
            )
          )}
        </div>
      )}
    </Calculators>
  )
}
