import getUserId from './getUserId'

function getUnixTimestampsFromStartOfMinute() {
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  return now.getTime()
}

export function getInsertId(eventName: string) {
  const unixTimestamp = getUnixTimestampsFromStartOfMinute()
  const userId = getUserId()
  return `${userId}-${unixTimestamp}-${eventName}`
}
