import useRegressiveCounter from '@/utils/useRegressiveCounter'

type Props = {
  finalDate: Date | string
}

type Times = [number, string][]

export function Countdown({ finalDate }: Props) {
  const { day, hour, minute, second } = useRegressiveCounter({
    finalDate
  })

  function formatTime(digits: number, sufix: string) {
    return digits >= 0 ? `${String(digits).padStart(2, '0')}${sufix}` : ''
  }

  function formatTimes(times: Times) {
    return times.map((time) => formatTime(...time)).join(' ')
  }

  return (
    <span className="flex gap-4 items-center text-[12px] font-normal leading-[18px]">
      acaba em
      <span className="flex items-center p-4 text-[10px] font-bold leading-[12px] text-display-900 bg-ton-500 rounded-[8px]">
        {formatTimes([
          [day, 'd'],
          [hour, 'h'],
          [minute, 'min'],
          [second, 's']
        ])}
      </span>
    </span>
  )
}
