import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import Icon from '@/components/Icon'
import { IconTitleAndDescriptionV2Props } from '@/types/cms'

import Button, { ButtonV2Props } from '../ButtonV2'

interface InfosWithFeaturedVideoProps {
  referId: string
  videoUrl: string
  title: string
  description?: string
  cards: IconTitleAndDescriptionV2Props[]
  buttons?: ButtonV2Props[]
}

export function InfosWithFeaturedVideo({
  referId,
  title,
  description,
  cards,
  buttons,
  videoUrl
}: InfosWithFeaturedVideoProps) {
  return (
    <div
      className="flex md:flex-row justify-center items-center lg:mx-auto w-full lg:max-w-[1076px]"
      id={referId}
    >
      <div className="flex flex-col justify-center items-center py-40 px-16 md:px-0 lg:pt-80 lg:pb-0 md:mx-0 w-full max-w-[620px] md:max-w-none">
        <h2
          className="mb-8 w-full text-center heading-2"
          dangerouslySetInnerHTML={{ __html: title || '' }}
        />
        <MarkdownContent
          content={description || ''}
          className="mb-24 text-[14px] leading-[20px] text-center"
        />
        <div className="flex flex-col md:flex-row gap-24 lg:gap-40 justify-center items-center xl:p-0 md:px-16 w-full md:max-w-none">
          <div className="flex md:flex-[30%] md:w-full md:min-w-[400px] max-w-[400px] md:max-w-[400px] md:min-h-[375px] bg-transparent">
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              controls={false}
              src={videoUrl}
              className="w-full md:w-[500px] h-full md:h-[288px]"
            />
          </div>
          <div className="flex flex-col lg:flex-row flex-wrap gap-16 lg:gap-x-40 lg:gap-y-24 w-full">
            {cards.map(({ title, description, iconName }) => (
              <div
                key={title}
                className="flex flex-row lg:flex-col gap-16 items-start md:items-start w-full md:max-w-none lg:max-w-[264px] xl:max-w-[290px]"
              >
                <div className="flex p-16 text-ton-600 bg-newTon-50 rounded-lg border-newTon-50">
                  <Icon name={iconName} className="w-24 h-24 fill-current" />
                </div>
                <div className="w-full">
                  <p className="font-default text-[16px] lg:text-[20px] font-bold leading-[24px] lg:leading-[28px] lg:paragraph-20">
                    {title}
                  </p>
                  <MarkdownContent
                    content={description}
                    className="mt-4 font-default text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px]"
                  />
                </div>
              </div>
            ))}
            {buttons?.map((props) => (
              <Button key={props.label} {...props} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfosWithFeaturedVideo
