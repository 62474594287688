import { useEffect, useState } from 'react'

interface EmergencyCounterProps {
  finalDate: string | Date
}

export default function useRegressiveCounter({
  finalDate
}: EmergencyCounterProps): {
  day: number
  hour: number
  minute: number
  second: number
} {
  const [regressiveCounter, setRegressiveCounter] = useState({
    day: 0,
    hour: 0,
    minute: 0,
    second: 0
  })

  useEffect(() => {
    const updateAccountant = (): void => {
      const currentDateTime = new Date()
      const finalDateTime = new Date(finalDate)

      const newYearTime = new Date(
        currentDateTime.getTime() +
          (finalDateTime.getTime() - currentDateTime.getTime())
      )
      const currentTime = new Date()
      const difference = Number(newYearTime) - Number(currentTime)
      const day = Math.floor(difference / 1000 / 60 / 60 / 24)
      const hour = Math.floor(difference / 1000 / 60 / 60) % 24
      const minute = Math.floor(difference / 1000 / 60) % 60
      const second = Math.floor(difference / 1000) % 60

      setRegressiveCounter({ day, hour, minute, second })
    }
    const count = setInterval(updateAccountant, 1000)
    return () => clearInterval(count)
  }, [finalDate])

  return regressiveCounter
}
