import Icon, { IconsNames } from '@/components/Icon'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import { ImagesProps, LinkProps } from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'

export interface HeroCardStyleProps {
  referId: string
  tag: string
  title: string
  description: string
  iconName: IconsNames

  heroButtons: ButtonV2Props[]
  cardsButtons: ButtonV2Props[]
  cards: {
    description: string
    title: string
    link: LinkProps
    image: ImagesProps
  }[]
  cardsButtonOutText: string
}

export default function HeroCardStyle({
  referId,
  iconName,
  tag,
  title,
  description,
  heroButtons,
  cardsButtons,
  cardsButtonOutText,
  cards
}: HeroCardStyleProps) {
  return (
    <div id={referId} className="flex flex-col w-full">
      <div className="flex justify-center py-24 md:py-[56px] px-16 bg-newTon-400">
        <div className="flex flex-col gap-16 max-w-[412px] md:max-w-[730px]">
          <div className="flex flex-row gap-8 justify-center">
            <div className="flex justify-center items-center w-48 h-48 bg-display-0 rounded-2xl rounded-br-none">
              <Icon
                name={iconName}
                className="w-[34px] h-full text-newTon-500 fill-current"
              />
            </div>
            <div className="flex justify-center items-center p-8 mt-auto w-max h-[min-content] bg-newTon-800 rounded-[8px] rounded-bl-none">
              <p className="text-display-0 paragraph-14">{tag}</p>
            </div>
          </div>
          <div className="flex flex-col gap-8 text-center text-display-900">
            <h1
              className="mx-auto max-w-[440px] lg:max-w-[580px]"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="flex gap-16 justify-center w-full">
            {heroButtons?.map((props) => (
              <Button
                key={props.label}
                {...props}
                className="md:w-[fit-content]"
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex relative z-[-50]">
        <div className="absolute top-0 left-0 w-full">
          <CloudinaryImage
            src="v1/site-ton/sobre-o-ton/bottom-hero-about-ton"
            alt="Fundo da imagem do banner principal"
            width={1290}
            height={196}
            sizes="100vw"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="flex flex-col gap-24 md:gap-40 self-center pb-40 mx-16 md:mx-24 w-full max-w-[444px] md:max-w-full lg:max-w-[950px]">
        <div className="flex flex-col md:flex-row gap-24 lg:gap-40 self-center px-16 md:px-24 lg:px-0 w-full">
          {cards?.map(({ description, title, link, image }, index) => (
            <>
              <div
                key={`${index}-mobile`}
                className="flex md:hidden flex-row rounded-md border border-display-300"
              >
                <RetainQueryLink
                  href={{ pathname: link.href, hash: link.hash }}
                >
                  <div className="flex flex-col gap-8 p-16 w-[calc(100%-138px)] min-w-[190px] text-newTon-600 hover:text-newTon-800 bg-display-0 hover:bg-display-100 rounded-l-md cursor-pointer">
                    <p
                      className="font-bold paragraph-18"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <p
                      className="text-display-900 paragraph-14"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </div>
                </RetainQueryLink>
                {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                <div className="flex overflow-hidden relative w-[138px] rounded-r-md h-max">
                  <CloudinaryImage
                    src={image.mobile.path}
                    alt="Card de vantagens mobile"
                    fill
                    sizes="100vw"
                    className="object-cover"
                  />
                </div>
              </div>

              <div
                key={index}
                className="hidden md:flex relative flex-row flex-1 lg:flex-none lg:justify-center lg:w-[290px] min-w-[224px] lg:max-w-[290px] h-[440px] rounded-[24px]"
              >
                <CloudinaryImage
                  src={image.tablet.path}
                  alt="Card de vantagens"
                  // quality={image.tabletQuality || 'auto'}
                  className="object-cover rounded-[24px]"
                  fill
                  sizes="100vw"
                />
                <RetainQueryLink
                  href={{ pathname: link.href, hash: link.hash }}
                >
                  <div className="flex absolute top-16 left-16 flex-col gap-8 p-16 max-w-[calc(100%-32px)] text-newTon-600 hover:text-newTon-800 bg-display-0 hover:bg-display-100 rounded-md border border-display-300 cursor-pointer">
                    <p
                      className="font-bold paragraph-18 lg:paragraph-20"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <p
                      className="text-display-900 paragraph-14"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </div>
                </RetainQueryLink>
              </div>
            </>
          ))}
        </div>
        <div className="flex flex-col md:flex-row gap-16 md:gap-24 justify-center items-center mx-16 text-center">
          <p
            className="font-bold paragraph-20"
            dangerouslySetInnerHTML={{ __html: cardsButtonOutText }}
          />
          {cardsButtons?.map((props) => (
            <Button
              key={props.label}
              {...props}
              className="md:w-[fit-content]"
            />
          ))}
        </div>
      </div>
    </div>
  )
}
