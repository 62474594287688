import Button from '@/cmsComponents/ButtonV2'
import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import { IconsDescriptionsV2Props, ImagesProps } from '@/types/cms'

import { ButtonV2Props } from '../ButtonV2'
import cn from '@/utils/cn'

export interface BannerCardButtonIconProps {
  referId: string
  tag?: string
  title: string
  description?: string
  backgroundColor?: string
  images: ImagesProps
  iconsDescriptions?: IconsDescriptionsV2Props[]
  isInverter: boolean
  buttons?: ButtonV2Props[]
}

export default function BannerAboutUs({
  referId,
  tag,
  title,
  description,
  images,
  iconsDescriptions,
  backgroundColor,
  isInverter,
  buttons
}: BannerCardButtonIconProps) {
  return (
    <div
      className={cn(
        'flex md:py-40 lg:py-[56px] px-16 md:px-24 lg:px-40 pt-40 w-full',
        backgroundColor
      )}
    >
      <div
        id={referId}
        className={`flex flex-col md:flex-row gap-16 md:gap-24 lg:gap-40 lg:mx-auto w-full md:max-w-[1280px] ${
          isInverter ? 'md:flex-row-reverse' : 'md:flex-row'
        }`}
      >
        <div className="flex flex-col gap-16 p-16 md:p-24 lg:p-40 mx-auto md:mx-0 md:w-3/5 lg:min-w-[535px] max-w-[412px] md:max-w-none bg-display-0 rounded-[24px] lg:rounded-[40px]">
          <div className="p-8 mx-auto md:mx-0 w-[fit-content] bg-danger-450 rounded-[8px] rounded-bl-none">
            {tag && (
              <p
                className="text-display-0 paragraph-14"
                dangerouslySetInnerHTML={{ __html: tag }}
              />
            )}
          </div>
          <div className="flex flex-col gap-8 max-w-[650px] text-center md:text-left">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <p className="paragraph-16 md:paragraph-18">{description}</p>
          </div>
          <div className="flex flex-row flex-wrap gap-8 md:gap-16 max-w-[650px]">
            {iconsDescriptions?.map(({ iconName, description }) => (
              <div className="flex flex-row gap-8 md:items-center w-[calc(50%-4px)] md:w-[calc(50%-8px)] h-[fit-content]">
                <Icon
                  name={iconName}
                  className="self-center w-24 min-w-[24px] text-newTon-600 fill-current"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: description }}
                  className="font-medium paragraph-14 md:paragraph-16"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-16 items-center md:pt-8 ">
            {buttons?.map((props) => (
              <Button key={props.label} {...props} />
            ))}
          </div>
        </div>
        <div className="relative mx-auto md:mx-0 w-full md:w-2/5 max-w-[412px] md:max-w-[417px] lg:max-w-[510px] min-h-[160px] rounded-[24px] lg:rounded-[40px]">
          <div className="md:hidden rounded-[24px]">
            <CloudinaryImage
              src={images.mobile.path}
              alt={images.mobile.alternativeText}
              className="md:hidden object-cover rounded-[24px]"
              fill
              sizes="100vw"
            />
          </div>
          <div className="hidden md:block lg:hidden rounded-[24px]">
            <CloudinaryImage
              src={images.tablet.path}
              alt={images.tablet.alternativeText}
              className="hidden md:block lg:hidden object-cover rounded-[24px]"
              fill
              sizes="100vw"
            />
          </div>
          <div className="hidden lg:block rounded-[40px]">
            <CloudinaryImage
              src={images.web.path}
              alt={images.web.alternativeText}
              className="hidden lg:block object-cover rounded-[40px]"
              fill
              sizes="100vw"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
