import { ALL_MACHINES_COMMOM_INFO } from '@/constants/machines'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { AllMachines, AllMachinesRexCommissions } from '@/types/machines'
import { AllPlansForMachine } from '@/types/plans'

// Objeto com as imagens das maquininhas
export const ALL_MACHINES_IMAGES: Record<AllMachines, string> = {
  [AllMachines.T1]: '/maquininhas/new-t1-1',
  [AllMachines.T2]: '/maquininhas/new-t2-v2-1',
  [AllMachines.T3]: '/maquininhas/new-t3-1',
  [AllMachines.T3Smart]: '/maquininhas/new-t3-smart-1'
}

// Array com as comissões das maquininhas por plano
export const ALL_MACHINES_REX_COMMISIONS: AllMachinesRexCommissions = {
  [AllPlansForMachine.Pro]: {
    machines: [
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3Smart],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3Smart].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Pro].popularName,
        value: 'Até R$ 340'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Pro].popularName,
        value: 'Até R$ 320'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T2],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T2].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Pro].popularName,
        value: 'Até R$ 140'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T1],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T1].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Pro].popularName,
        value: 'Até R$ 100'
      }
    ]
  },
  [AllPlansForMachine.Ultra]: {
    machines: [
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3Smart],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3Smart].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Ultra].popularName,
        value: 'Até R$ 230'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Ultra].popularName,
        value: 'Até R$ 220'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T2],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T2].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Ultra].popularName,
        value: 'Até R$ 120'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T1],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T1].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Ultra].popularName,
        value: 'Até R$ 100'
      }
    ]
  },
  [AllPlansForMachine.Mega]: {
    machines: [
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3Smart],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3Smart].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Mega].popularName,
        value: 'R$ 230'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Mega].popularName,
        value: 'R$ 220'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T2],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T2].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Mega].popularName,
        value: 'R$ 120'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T1],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T1].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Mega].popularName,
        value: 'R$ 75'
      }
    ]
  },
  [AllPlansForMachine.Super]: {
    machines: [
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3Smart],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3Smart].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Super].popularName,
        value: 'Até R$ 130'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T3],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T3].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Super].popularName,
        value: 'Até R$ 110'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T2],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T2].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Super].popularName,
        value: 'Até R$ 60'
      },
      {
        image: ALL_MACHINES_IMAGES[AllMachines.T1],
        name: ALL_MACHINES_COMMOM_INFO[AllMachines.T1].popularName,
        plan: ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Super].popularName,
        value: 'Até R$ 5'
      }
    ]
  }
}
