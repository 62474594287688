import Icon from '@/components/Icon'
import { Slider } from '@/components/Slider'
import { ALL_PLANS_COMMOM_INFO, ALL_PLANS_FOR_MACHINE } from '@/constants/plans'
import { IconsDescriptionsV2Props } from '@/types/cms'

import Button, { ButtonV2Props } from '../ButtonV2'
import { SimplePlanCard } from './components/SimplePlanCard'
import { SIMPLE_PLAN_CARD_RESOURCE } from './resource/planCard'

export interface SimplePlanCardCarrouselProps {
  id: number
  title: string
  buttons: ButtonV2Props[]
  iconsAndDescriptions: IconsDescriptionsV2Props[]
}

export const SimplePlanCardCarrousel = ({
  title,
  buttons,
  iconsAndDescriptions
}: SimplePlanCardCarrouselProps) => {
  return (
    <div className="flex flex-col gap-24 py-40 bg-display-50">
      {title && (
        <div className="px-16">
          <h2
            className="text-center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      )}

      <div className="bg-display-50">
        <Slider.Root
          configs={{
            options: {
              slides: {
                spacing: 16
              }
            }
          }}
          navigations={{
            dots: {
              active: true
            }
          }}
        >
          {ALL_PLANS_FOR_MACHINE.map((plan) => (
            <Slider.Item className="min-w-[271px] md:min-w-[282px] max-w-[271px] md:max-w-[282px]">
              <SimplePlanCard
                planName={ALL_PLANS_COMMOM_INFO[plan].popularName}
                description={SIMPLE_PLAN_CARD_RESOURCE[plan].description}
                conditionalText={
                  SIMPLE_PLAN_CARD_RESOURCE[plan].conditionalText
                }
                highlight={SIMPLE_PLAN_CARD_RESOURCE[plan].highlight}
                image={{
                  src: SIMPLE_PLAN_CARD_RESOURCE[plan].image.src,
                  alt: SIMPLE_PLAN_CARD_RESOURCE[plan].image.alt
                }}
              />
            </Slider.Item>
          ))}
        </Slider.Root>
      </div>

      {buttons && (
        <div className="px-16 sm:mx-auto">
          {buttons.map((props) => (
            <Button
              key={props.label}
              {...props}
              className="md:order-4 md:w-[fit-content]"
            />
          ))}
        </div>
      )}

      {iconsAndDescriptions && (
        <div className="flex flex-wrap gap-16 sm:justify-center lg:p-0 px-16 sm:mx-auto max-w-[312px] sm:max-w-[768px] lg:max-w-none">
          {iconsAndDescriptions.map(({ iconName, description }) => {
            return (
              <div key={description} className="flex gap-8 items-center">
                <Icon
                  name={iconName}
                  className="text-newTon-600 fill-current"
                />
                <p className="font-medium text-display-900 ton-paragraph-14 lg:ton-paragraph-16">
                  {description}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
