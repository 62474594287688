import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import Icon from '@/components/Icon'
import { IconTitleAndDescriptionV2Props } from '@/types/cms'

import { FreeMode, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  NavigationOptions,
  PaginationOptions,
  SwiperOptions
} from 'swiper/types'

interface CardsCarouselProps {
  referId: string
  title: string
  cards: IconTitleAndDescriptionV2Props[]
}

const navigationOptions: NavigationOptions = {
  enabled: true,
  nextEl: '#swiper-next-rtb',
  prevEl: '#swiper-prev-rtb',
  disabledClass: 'text-display-400'
}

const paginationOptions: PaginationOptions = {
  enabled: true,
  clickable: true,
  el: '#unique-rtbs',
  bulletActiveClass: 'swiper-pagination-bullet-active !bg-newTon-700'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation, FreeMode, Pagination],
  freeMode: true,
  navigation: navigationOptions,
  pagination: paginationOptions,
  spaceBetween: 40,
  slidesPerView: 1.25,
  breakpoints: {
    768: {
      slidesPerView: 3
    },
    1025: {
      slidesPerView: 4
    }
  }
}

export function CardsCarousel({ referId, title, cards }: CardsCarouselProps) {
  return (
    <div
      id={referId}
      className="flex flex-col items-center py-40 lg:py-80 bg-display-0"
    >
      <div className="flex flex-col items-center px-0 mb-24 md:mb-40">
        <h2
          className="mx-24 md:mx-40 max-w-[250px] md:max-w-full xs:max-w-full text-[32px] lg:text-[40px] leading-[38px] lg:leading-[48px] text-center heading-2 lg:heading-3"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      <div className="md:!px-24 !pl-16 mx-auto max-w-full lg:max-w-full 2xl:max-w-[calc(1280px+24px+24px)]">
        <Swiper {...swiperOptions}>
          {cards.map(({ title, description, iconName }, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center mb-4 lg:max-w-[290px] !h-auto rounded-md rounded-br-none border border-display-300"
            >
              <div className="flex overflow-hidden flex-col items-start p-16 lg:p-24 w-full">
                <div className="flex items-start">
                  <div className="mb-16">
                    <Icon className="w-[56px] h-[56px]" name={iconName} />
                  </div>
                </div>
                <p className="pb-4 font-bold text-left paragraph-18">{title}</p>
                <p className="text-left paragraph-16">{description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div id="arrow-container" className="flex md:hidden gap-16 pt-24">
        <span
          id="swiper-prev-rtb"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="rotate-180 fill-current" />
        </span>
        <span
          id="swiper-next-rtb"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="fill-current" />
        </span>
      </div>
      <div
        id="unique-rtbs"
        className="hidden md:flex justify-center items-center mt-24"
      />
    </div>
  )
}
