import React from 'react'

import Button from '@/cmsComponents/ButtonV2'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString } from '@/helpers/fees'
import { PlanAndFeesInfo, ReceivingOptions } from '@/types/fees'
import { AllPlansForMachine, AllTiersForMachine } from '@/types/plans'

interface PlanCardModalInformationProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const PlanCardModalInformation = ({
  isOpen,
  setIsOpen
}: PlanCardModalInformationProps) => {
  const { allPlanAndFeesMachine, receivingOptions } = usePlans()

  const promotionalsPlan = allPlanAndFeesMachine.filter(
    (plan) => plan.planName === AllPlansForMachine.Pro
  ) as PlanAndFeesInfo[]

  // Taxas promocionais do plano Ton Pro Promo
  const tonProPromoFees = promotionalsPlan.find(
    (promo) => promo.tierName === AllTiersForMachine.TonProPromo
  )?.fees[receivingOptions].mainFlags as number[]

  // Taxas regulares do plano Ton Pro Base
  const tonProBaseFees = promotionalsPlan.find(
    (promo) => promo.tierName === AllTiersForMachine.TonProBase
  )?.fees[receivingOptions].mainFlags as number[]

  // Taxas regulares do plano Ton Pro Regular
  const tonProRegularFees = promotionalsPlan.find(
    (promo) => promo.tierName === AllTiersForMachine.TonProRegular
  )?.fees[receivingOptions].mainFlags as number[]

  // Taxas regulares do plano Ton Pro Plus
  const tonProPlusFees = promotionalsPlan.find(
    (promo) => promo.tierName === AllTiersForMachine.TonProPlus
  )?.fees[receivingOptions].mainFlags as number[]

  const receivingName =
    receivingOptions === ReceivingOptions.SameDay
      ? 'No mesmo dia'
      : '1 dia útil'

  return (
    <Modal
      open={isOpen}
      closeOnClickOut={true}
      onCloseClick={() => setIsOpen(false)}
      className="flex items-end md:items-center !p-0 w-full md:max-w-[618px] h-full bg-transparent rounded-b-none"
      classNameContainer="z-[101]"
    >
      <div className="flex overflow-y-auto relative flex-col gap-12 p-24 w-full max-h-[90%] md:max-h-full bg-white md:rounded-[16px] rounded-t-24 rounded-b-none">
        <div className="flex gap-24 items-start">
          <p className="font-bold leading-5 text-display-900 ton-paragraph-20">
            Como funciona o plano Ton Pro?
          </p>

          <button
            className="ml-auto cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <Icon name="close" className="ml-auto w-24 h-24 fill-current" />
          </button>
        </div>

        <div className="flex flex-col gap-12">
          <p className="text-center ton-paragraph-16">
            Aproveite o período promocional do Ton Pro para fazer as suas
            primeiras vendas.
          </p>

          <div className="flex flex-col gap-24">
            <div className="flex flex-col gap-8">
              <div className="p-8 font-bold text-center text-newTon-700 bg-newTon-50 rounded-8 ton-paragraph-14">
                Período promocional
              </div>

              <p className="text-center ton-paragraph-14">
                Taxas válidas para o recebimento da venda: {receivingName}
              </p>

              <div className="flex overflow-hidden flex-col md:flex-row items-center rounded-16 border">
                <div className="flex justify-center items-center py-8 md:px-24 w-full md:max-w-[258px] md:min-h-[90px] text-center bg-display-100 ton-paragraph-12">
                  Válidas durante 30 dias ou até vender 5 mil reais.
                </div>
                <div className="py-16 w-full">
                  <div className="flex">
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">DÉBITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPromoFees &&
                          formatFeeToString(tonProPromoFees[0])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">CRÉDITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPromoFees &&
                          formatFeeToString(tonProPromoFees[1])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">
                        CRÉDITO 12X
                      </b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPromoFees &&
                          formatFeeToString(tonProPromoFees[12])}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-16">
              <div className="p-8 font-bold text-center text-newTon-700 bg-newTon-50 rounded-8 ton-paragraph-14">
                Após o período promocional
              </div>

              <p className="text-center ton-paragraph-16">
                Você terá taxas de acordo com suas vendas mensais. No início de
                cada mês, são contabilizadas as suas vendas do mês anterior.
                Isso definirá a sua faixa de vendas e, consequentemente, as
                taxas do mês.
              </p>

              <p className="text-center ton-paragraph-14">
                Taxas válidas para o recebimento da venda: {receivingName}
              </p>

              <div className="flex overflow-hidden flex-col md:flex-row items-center rounded-16 border">
                <div className="flex justify-center items-center py-8 md:px-24 w-full md:max-w-[258px] md:min-h-[90px] text-center bg-display-100 ton-paragraph-14">
                  Vendas mensais acima de R$20 mil
                </div>
                <div className="py-16 w-full">
                  <div className="flex">
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">DÉBITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPlusFees && formatFeeToString(tonProPlusFees[0])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">CRÉDITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPlusFees && formatFeeToString(tonProPlusFees[1])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">
                        CRÉDITO 12X
                      </b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProPlusFees &&
                          formatFeeToString(tonProPlusFees[12])}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex overflow-hidden flex-col md:flex-row items-center rounded-16 border">
                <div className="flex justify-center items-center py-8 md:px-24 w-full md:max-w-[258px] md:min-h-[90px] text-center bg-display-100 ton-paragraph-14">
                  Vendas mensais de R$2 mil a R$20 mil
                </div>
                <div className="py-16 w-full">
                  <div className="flex">
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">DÉBITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProRegularFees &&
                          formatFeeToString(tonProRegularFees[0])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">CRÉDITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProRegularFees &&
                          formatFeeToString(tonProRegularFees[1])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">
                        CRÉDITO 12X
                      </b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProRegularFees &&
                          formatFeeToString(tonProRegularFees[12])}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex overflow-hidden flex-col md:flex-row items-center rounded-16 border">
                <div className="flex justify-center items-center py-8 md:px-24 w-full md:max-w-[258px] md:min-h-[90px] text-center bg-display-100 ton-paragraph-14">
                  Vendas mensais até R$2 mil
                </div>
                <div className="py-16 w-full">
                  <div className="flex">
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">DÉBITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProBaseFees && formatFeeToString(tonProBaseFees[0])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">CRÉDITO</b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProBaseFees && formatFeeToString(tonProBaseFees[1])}
                      </b>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <b className="text-center ton-paragraph-12">
                        CRÉDITO 12X
                      </b>
                      <b className="font-heading text-center text-newTon-700 ton-paragraph-28">
                        {tonProBaseFees &&
                          formatFeeToString(tonProBaseFees[12])}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <Button
                label="Confira todas as taxas e planos"
                linkPathname="/planos-e-taxas"
              />

              <button
                className="text-newTon-700 cursor-pointer ton-paragraph-16"
                onClick={() => setIsOpen(false)}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
