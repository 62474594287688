import { tv, VariantProps } from 'tailwind-variants'

import { KeenSliderInstanceProps } from '../../../Root'

const button = tv({
  base: 'w-8 h-8  rounded-16',

  variants: {
    color: {
      primary: 'bg-display-300',
      secondary: 'bg-newTon-700'
    },
    active: {
      true: ''
    }
  },
  compoundVariants: [
    {
      color: 'primary',
      active: true,
      className: 'bg-newTon-600'
    },
    {
      color: 'secondary',
      active: true,
      className: 'bg-display-0'
    }
  ],
  defaultVariants: {
    color: 'primary'
  }
})

export type DotOptionsProps = VariantProps<typeof button>

interface DotActionProps extends DotOptionsProps {
  instance: KeenSliderInstanceProps
  slideNumber: number
}

export const DotAction = ({
  instance,
  slideNumber,
  active,
  color
}: DotActionProps) => {
  const handleSliderIndex = (index: number) => {
    const indexWithoutGhostSliders = index === 0 ? 0 : index + 1
    instance.current?.moveToIdx(indexWithoutGhostSliders)
  }

  return (
    <button
      onClick={() => handleSliderIndex(slideNumber)}
      className={button({ color, active })}
    />
  )
}
