import {
  CMSTrackViewProvider,
  TrackViewProviderProps
} from '../CMSTrackViewProvider'
import { CardOverImage, CardOverImageProps } from '../CardOverImage'
import { CMSExperiment } from '../DynamicComponent'

export interface CardsOverImageProps extends TrackViewProviderProps {
  cards_over_images: CardOverImageProps[]
  experiment: CMSExperiment | null
  referId: string
}

export function CardsOverImage({
  cards_over_images,
  trackView,
  experiment,
  referId
}: CardsOverImageProps) {
  if (!cards_over_images) return null

  return (
    <div
      id={referId}
      className="flex lg:flex flex-col md:flex-row lg:flex-col md:flex-wrap gap-40 md:gap-24 justify-center py-40 lg:py-0 px-16 md:px-24 w-full"
    >
      {cards_over_images.map((cardOverImageProps, index) => (
        <CMSTrackViewProvider
          trackView={trackView}
          experiment={experiment}
          key={index}
        >
          <CardOverImage {...cardOverImageProps} />
        </CMSTrackViewProvider>
      ))}
    </div>
  )
}
