import dynamic from 'next/dynamic'
import { useContext, useEffect, useState } from 'react'

import Modal from '@/components/Modal'
import IS_SERVER from '@/constants/isServer'
import { AmplitudeContext } from '@/contexts/amplitude'
import { getAndSaveSearchParams } from '@/utils/searchParams'

const CouponModal = dynamic(() => import('./CouponModal'), { ssr: false })
const DiscountModal = dynamic(() => import('./DiscountModal'), { ssr: false })

export interface DiscountFormModalProps {
  referId: string
  couponCode: string
  tag?: string
  title?: string
  description?: string
  label?: string
  hasForm: boolean
}

export default function DiscountFormModal({
  referId,
  ...props
}: DiscountFormModalProps) {
  const { analytics } = useContext(AmplitudeContext)
  const [showModal, setShowModal] = useState(false)
  const [leadSent, setLeadSent] = useState(false)

  const handleOpenModal = () => {
    const searchParams = getAndSaveSearchParams()
    const hasRefererOrCoupon =
      !!searchParams?.referrer || !!searchParams?.coupon

    if (
      localStorage.getItem('IsModalShown') !== 'true' &&
      !hasRefererOrCoupon
    ) {
      setShowModal(true)
      localStorage.setItem('IsModalShown', 'true')
    }
  }

  useEffect(() => {
    if (IS_SERVER) return
    analytics?.trackPageViewed()
  }, [analytics])

  useEffect(() => {
    localStorage.setItem('IsModalShown', 'false')

    const timer = setTimeout(() => {
      handleOpenModal()
    }, 10000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Modal
      open={showModal}
      closeOnClickOut={true}
      onCloseClick={() => setShowModal(false)}
      className="flex items-end md:items-center !p-0 mt-auto md:mt-0 w-full max-w-[412px] md:max-w-[535px] h-auto bg-transparent rounded-b-none"
      classNameContainer="z-[101]"
    >
      <div
        id={referId}
        className="flex relative flex-col gap-8 justify-center md:items-center md:p-24 py-24 px-16 w-full max-w-[412px] md:max-w-[535px] h-auto bg-white md:rounded-[24px] rounded-t-[24px] rounded-b-none"
      >
        {leadSent ? (
          <CouponModal onCloseClick={setShowModal} />
        ) : (
          <DiscountModal
            setLeadSent={setLeadSent}
            onCloseClick={setShowModal}
            {...props}
          />
        )}
      </div>
    </Modal>
  )
}
