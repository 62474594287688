import React from 'react'

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1401_15493)">
        <path
          d="M47.3634 28.7344C48.7534 28.7344 49.8806 27.6072 49.8806 26.2173V19.3658C49.8806 13.8908 46.0116 9.7627 40.8803 9.7627C35.7491 9.7627 31.8801 13.8908 31.8801 19.3658V26.2173C31.8801 27.6072 33.0073 28.7344 34.3972 28.7344C35.7872 28.7344 36.9144 27.6072 36.9144 26.2173V19.3658C36.9144 16.4238 38.4344 14.5956 40.8803 14.5956C43.3262 14.5956 44.8463 16.4238 44.8463 19.3658V26.2173C44.8463 27.6072 45.9735 28.7344 47.3634 28.7344Z"
          fill="#00CC2C"
        />
        <path
          d="M20.2867 9.7627C14.9922 9.7627 10.6836 14.07 10.6836 19.3658C10.6836 24.6603 14.9909 28.9689 20.2867 28.9689C25.5812 28.9689 29.8898 24.6616 29.8898 19.3658C29.8898 14.0713 25.5825 9.7627 20.2867 9.7627ZM20.2867 24.136C17.6566 24.136 15.5165 21.9959 15.5165 19.3658C15.5165 16.7357 17.6566 14.5956 20.2867 14.5956C22.9168 14.5956 25.0569 16.7357 25.0569 19.3658C25.0569 21.9959 22.9168 24.136 20.2867 24.136Z"
          fill="#00CC2C"
        />
        <path
          d="M39.0927 6.46883H40.2088C40.2554 6.46883 40.2947 6.43813 40.2947 6.39025V3.30832C40.2947 2.43408 40.7245 1.97363 41.4649 1.97363C42.2065 1.97363 42.6277 2.43408 42.6277 3.30832V6.39025C42.6277 6.4369 42.667 6.46883 42.7136 6.46883H43.8297C43.8764 6.46883 43.9157 6.43813 43.9157 6.39025V3.31568C43.9157 1.99696 43.1827 0.842773 41.4661 0.842773C39.7496 0.842773 39.0079 1.99696 39.0079 3.31568V6.39025C39.0079 6.4369 39.0472 6.46883 39.0939 6.46883H39.0927Z"
          fill="#00CC2C"
        />
        <path
          d="M31.2969 6.53836C31.7021 6.53836 31.9686 6.4917 32.0852 6.44504C32.1245 6.42908 32.1478 6.39101 32.1478 6.35909V5.43819C32.1478 5.38417 32.1245 5.35224 32.0545 5.35224C32.0005 5.35224 31.8433 5.38294 31.7193 5.38294C31.2355 5.38294 31.0243 5.20367 31.0243 4.78252V2.18436H32.0545C32.1012 2.18436 32.1405 2.14507 32.1405 2.09841V1.09157C32.1405 1.04491 32.1012 1.00562 32.0545 1.00562H30.985V0.132609C30.985 0.0392915 30.9458 0 30.8758 0H29.8223C29.7363 0 29.697 0.0392915 29.697 0.132609V4.91512C29.697 6.07791 30.3441 6.53836 31.2969 6.53836Z"
          fill="#00CC2C"
        />
        <path
          d="M26.6986 6.60138C27.9547 6.60138 28.8523 5.82906 28.8523 4.82221C28.8523 3.69135 28.1033 3.36351 27.0805 3.14495L26.5734 3.03567C26.1915 2.94972 25.9803 2.77046 25.9803 2.46595C25.9803 2.07549 26.3708 1.86552 26.9086 1.86552C27.665 1.86552 28.1807 2.20932 28.258 2.20932C28.2973 2.20932 28.344 2.17863 28.3599 2.13934L28.5625 1.38297C28.6018 1.28106 28.5932 1.25773 28.5232 1.21967C28.0702 0.985148 27.5864 0.852539 26.9 0.852539C25.6279 0.852539 24.7463 1.58557 24.7463 2.56172C24.7463 3.48262 25.456 3.98972 26.4703 4.20828L26.9847 4.31756C27.3985 4.40351 27.6318 4.56682 27.6318 4.91798C27.6318 5.27652 27.3506 5.58962 26.6876 5.58962C25.7507 5.58962 25.111 5.21513 25.0103 5.21513C24.9477 5.21513 24.9244 5.24582 24.901 5.34773L24.7684 5.98008C24.7291 6.11269 24.7684 6.19864 24.8544 6.23793C25.1749 6.4172 26.0479 6.60506 26.6962 6.60506L26.6986 6.60138Z"
          fill="#00CC2C"
        />
        <path
          d="M35.4484 5.51724C34.6294 5.51724 33.8804 4.90085 33.8804 3.73807C33.8804 2.57528 34.6294 1.97486 35.4484 1.97486C36.276 1.97486 37.009 2.57528 37.009 3.73807C37.009 4.90822 36.276 5.51724 35.4484 5.51724ZM35.4484 6.64073C37.0864 6.64073 38.2811 5.41532 38.2811 3.73807C38.2811 2.06081 37.0876 0.842773 35.4484 0.842773C33.8178 0.842773 32.6157 2.05958 32.6157 3.73807C32.6157 5.41655 33.8178 6.64073 35.4484 6.64073Z"
          fill="#00CC2C"
        />
        <path
          d="M49.9887 3.67545C49.9887 1.95153 48.9978 0.842773 47.4213 0.842773C45.9392 0.842773 44.6586 1.98223 44.6586 3.71474C44.6586 5.5639 45.9699 6.64073 47.3734 6.64073C48.5521 6.64073 49.2311 6.1766 49.6523 5.61055C49.7333 5.50127 49.7505 5.45462 49.7505 5.42269C49.7505 5.39199 49.7309 5.36867 49.6596 5.30604L49.1685 4.87752C49.0519 4.77561 49.0126 4.74491 48.9733 4.74491C48.934 4.74491 48.9107 4.76824 48.88 4.80753C48.5447 5.26061 48.0757 5.51724 47.4446 5.51724C46.6477 5.51724 46.116 4.97575 45.981 4.0966H49.5811C49.8303 4.0966 49.9875 4.01802 49.9875 3.67545H49.9887ZM47.3746 1.9282C48.2255 1.9282 48.6761 2.50284 48.7007 3.13764H45.9982C46.1467 2.45618 46.6084 1.9282 47.3734 1.9282H47.3746Z"
          fill="#00CC2C"
        />
        <path
          d="M19.1534 32.26C20.0154 31.349 21.5109 31.2569 22.4637 32.0734C24.8212 34.0932 27.1357 35.186 30.5909 35.186C34.0449 35.186 36.3594 34.0944 38.7181 32.0734C39.6697 31.2581 41.1665 31.349 42.0285 32.26C42.9886 33.2755 42.9088 34.8545 41.8418 35.7557C38.5843 38.5086 35.1574 39.9992 30.5922 39.9992C26.027 39.9992 22.6 38.5074 19.3425 35.7557C18.2755 34.8533 18.1957 33.2755 19.1559 32.26H19.1534Z"
          fill="#00CC2C"
        />
        <path
          d="M0 20.8713C0 25.3272 3.15928 28.4263 7.38926 28.7161C7.38926 28.7161 7.96022 28.7321 8.07073 28.7296C9.3477 28.6989 10.3828 27.697 10.3828 26.4224C10.3828 25.1479 9.3477 24.1153 8.07073 24.1153C6.29892 24.1153 5.02932 22.6984 4.97406 20.9818V14.5945H7.85217C9.1009 14.5945 10.1127 13.5839 10.1127 12.3389C10.1127 11.0926 9.1009 10.0833 7.85217 10.0833H4.97406V8.2403C4.97406 6.87 3.86039 5.75879 2.48764 5.75879C1.11367 5.75756 0 6.86878 0 8.23907V13.3482V20.8713Z"
          fill="#00CC2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1401_15493">
          <rect width="49.9886" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
