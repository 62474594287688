import { AllPlansForMachine, AllPlansForMachineType } from '@/types/plans'

type SimplePlanCardResourceProps = {
  [key in AllPlansForMachineType]: {
    description: string
    highlight: boolean
    image: {
      src: string
      alt: string
    }
    conditionalText: string
  }
}

export const SIMPLE_PLAN_CARD_RESOURCE: SimplePlanCardResourceProps = {
  [AllPlansForMachine.Pro]: {
    description: 'Venda mais e pague menos taxas a cada mês',
    highlight: true,
    image: {
      src: '/site-ton/maquina-de-cartao/maquina-de-cartao-ton-pro.png',
      alt: 'Etiqueta verde com símbolo de porcentam em amarelo'
    },
    conditionalText: 'Taxas conforme vendas mensais'
  },
  [AllPlansForMachine.Ultra]: {
    description: 'Independentemente das vendas, taxa ultra baixa',
    highlight: false,
    image: {
      src: '/site-ton/maquina-de-cartao/maquina-de-cartao-ton-ultra.png',
      alt: 'Uma mão em cima e outra embaixo segurando moedas'
    },
    conditionalText: 'Sem mínimo de vendas'
  },
  [AllPlansForMachine.Mega]: {
    description: 'Pra quem vende muito à vista',
    highlight: false,
    image: {
      src: '/site-ton/maquina-de-cartao/maquina-de-cartao-ton-mega.png',
      alt: 'Máquina de Cartão com brilhos em volta e uma seta de retorno ao lado'
    },
    conditionalText: 'Sem mínimo de vendas'
  },
  [AllPlansForMachine.Super]: {
    description: 'Maquininhas com preços super baixos',
    highlight: false,
    image: {
      src: '/site-ton/maquina-de-cartao/maquina-de-cartao-ton-super.png',
      alt: 'Máquina de Cartão do Ton com carinha feliz no visor'
    },
    conditionalText: 'Sem mínimo de vendas'
  }
}
