import React from 'react'

export interface DefaultTagProps {
  direction?: 'right' | 'left'
  label?: string
  variant?: 'red' | 'green' | 'blue'
}
export default function DefaultTag({
  direction,
  variant = 'red',
  label
}: DefaultTagProps) {
  const variantClasses = {
    red: 'bg-red-400',
    green: 'bg-green-500',
    blue: 'bg-blue-500'
  }

  return (
    <span
      className={`w-max p-8 text-center rounded-t-8 ${
        direction === 'left' ? 'rounded-br-8' : 'rounded-bl-8'
      } ${variantClasses[variant]}`}
    >
      <p className="text-[14px] leading-2 text-white">{label}</p>
    </span>
  )
}
