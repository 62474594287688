import { useEffect, useState } from 'react'

import Button from '@/cmsComponents/ButtonV2'
import Icon from '@/components/Icon'
import NativeDropdown from '@/components/NativeDropdown'
import RetainQueryLink from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import {
  nativeDropdownCreditInstallments,
  nativeDropdownFlagGroupsMachine,
  nativeDropdownFlagGroupsTapton,
  RECEIVING_OPTIONS_COMMON_INFO
} from '@/constants/fees'
import { ALL_MACHINES_COMMOM_INFO } from '@/constants/machines'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { useCatalog } from '@/contexts/catalog'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString } from '@/helpers/fees'
import { FlagsGroupType } from '@/types/fees'
import { AllMachinesType, MachinesInfoDetails } from '@/types/machines'
import { AllPlansForMachine, AllPlansForSmartphone } from '@/types/plans'
import cn from '@/utils/cn'

interface FeesCardInformationProps {
  title: string
  taptonPlan?: boolean
  ctaCalculator?: boolean
  notColoredBg?: boolean
  machineName?: {
    value: AllMachinesType
  }
}

export const FeesCardInformation = ({
  title,
  ctaCalculator,
  notColoredBg,
  machineName
}: FeesCardInformationProps) => {
  const { plan, receivingOptions, plansAndFees } = usePlans()
  const { machineFromPlan } = useCatalog()

  const [planSelectedFees, setPlanSelectedFees] = useState(
    plansAndFees.fees.one_business_day.mainFlags
  )

  // Inicializa com a bandeira MasterCard e Visa
  const [flagGroupSelected, setFlagGroupSelected] = useState(0)
  const [installmentsSelected, setInstallmentsSelected] = useState(10)

  // Maquininha selecionada via CMS
  const machineSelected = machineFromPlan.machines.find(
    (machine) => machine.machineName === machineName?.value
  ) as MachinesInfoDetails

  useEffect(() => {
    const flagGroupNativeDropdown =
      plan === AllPlansForSmartphone.Tapton
        ? nativeDropdownFlagGroupsTapton
        : nativeDropdownFlagGroupsMachine

    const flagsGroup = flagGroupNativeDropdown[flagGroupSelected]
      .valueType as FlagsGroupType

    const fees = plansAndFees.fees[receivingOptions][flagsGroup]

    setPlanSelectedFees(fees)
  }, [flagGroupSelected, plan, plansAndFees, receivingOptions])

  return (
    <section
      className={cn(
        'flex justify-center py-40 px-16',
        notColoredBg ? 'bg-display-0' : 'bg-display-100'
      )}
    >
      <div className="flex flex-col gap-24 w-full max-w-[512px] md:max-w-[950px]">
        <div className="flex flex-col gap-8 md:gap-16 items-center">
          <h2 className="text-center heading-2">
            {title} {ALL_PLANS_COMMOM_INFO[plan].popularName}
          </h2>

          <div className="flex ton-paragraph-16">
            <span>Bandeiras</span>

            <NativeDropdown
              dropdownId="dropdown-flags"
              options={
                plan === AllPlansForSmartphone.Tapton
                  ? nativeDropdownFlagGroupsTapton
                  : nativeDropdownFlagGroupsMachine
              }
              selectedOption={flagGroupSelected}
              setStateValue={setFlagGroupSelected}
              dayValue
              labelClassName="pl-6"
            />
          </div>
        </div>

        <div className="flex flex-col gap-24 items-center w-full">
          <div className="flex flex-col md:flex-row justify-center items-center w-full">
            <div className="flex flex-col gap-8 justify-center p-16 w-full min-h-[138px] md:min-h-[154px] bg-display-0 rounded-t-16 md:rounded-l-16 md:rounded-tr-none md:border-r border-b md:border-b-0">
              <p className="font-bold ton-paragraph-18">DÉBITO</p>
              <div className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <div className="flex gap-8 items-center">
                    <div className="font-heading text-40 font-bold leading-48 text-newTon-700">
                      {formatFeeToString(planSelectedFees[0])}
                    </div>
                    {plan === AllPlansForMachine.Pro && (
                      <div className="py-4 px-8 font-bold text-display-0 bg-newTon-600 rounded-8 rounded-bl-none ton-paragraph-14">
                        PROMO
                      </div>
                    )}
                  </div>
                  <p className="ton-paragraph-16">
                    Recebimento em{' '}
                    <span className="font-bold text-newTon-700">
                      {RECEIVING_OPTIONS_COMMON_INFO[receivingOptions].label}
                    </span>
                  </p>
                </div>

                {plan === AllPlansForMachine.Pro && (
                  <div>
                    <p className="text-display-600 ton-paragraph-16">
                      Taxa válida durante 30 dias ou até atingir R$ 5.000,00 em
                      vendas
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-8 justify-center p-16 w-full min-h-[138px] md:min-h-[154px] bg-display-0 md:border-r border-b md:border-b-0">
              <p className="font-bold ton-paragraph-18">CRÉDITO À VISTA</p>
              <div className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <div className="flex gap-8 items-center">
                    <div className="font-heading text-40 font-bold leading-48 text-newTon-700">
                      {formatFeeToString(planSelectedFees[1])}
                    </div>
                    {plan === AllPlansForMachine.Pro && (
                      <div className="py-4 px-8 font-bold text-display-0 bg-newTon-600 rounded-8 rounded-bl-none ton-paragraph-14">
                        PROMO
                      </div>
                    )}
                  </div>
                  <p className="ton-paragraph-16">
                    Recebimento em{' '}
                    <span className="font-bold text-newTon-700">
                      {RECEIVING_OPTIONS_COMMON_INFO[receivingOptions].label}
                    </span>
                  </p>
                </div>

                {plan === AllPlansForMachine.Pro && (
                  <div>
                    <p className="text-display-600 ton-paragraph-16">
                      Taxa válida durante 30 dias ou até atingir R$ 5.000,00 em
                      vendas
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-8 justify-center p-16 w-full min-h-[138px] md:min-h-[154px] bg-display-0 md:rounded-r-16 rounded-b-16 md:rounded-bl-none">
              <NativeDropdown
                dropdownId="dropdown-installments-fees"
                options={nativeDropdownCreditInstallments}
                selectedOption={installmentsSelected}
                setStateValue={setInstallmentsSelected}
                dayValue
                labelClassName="!paragraph-18 !font-bold"
                menuClassName="!font-bold"
              />

              <div className="flex flex-col gap-8">
                <div className="flex flex-col">
                  <div className="flex gap-8 items-center">
                    <div className="font-heading text-40 font-bold leading-48 text-newTon-700">
                      {formatFeeToString(
                        planSelectedFees[installmentsSelected + 2] // +2 para pular a taxa de débito e crédito à vista
                      )}
                    </div>
                    {plan === AllPlansForMachine.Pro && (
                      <div className="py-4 px-8 font-bold text-display-0 bg-newTon-600 rounded-8 rounded-bl-none ton-paragraph-14">
                        PROMO
                      </div>
                    )}
                  </div>
                  <p className="ton-paragraph-16">
                    Recebimento em{' '}
                    <span className="font-bold text-newTon-700">
                      {RECEIVING_OPTIONS_COMMON_INFO[receivingOptions].label}
                    </span>
                  </p>
                </div>

                {plan === AllPlansForMachine.Pro && (
                  <div>
                    <p className="text-display-600 ton-paragraph-16">
                      Taxa válida durante 30 dias ou até atingir R$ 5.000,00 em
                      vendas
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {plan !== AllPlansForSmartphone.Tapton && (
            <Button linkPathname="/planos-e-taxas" uiStyleVariant="link">
              <span className="font-bold underline">
                Como funciona o Plano {ALL_PLANS_COMMOM_INFO[plan].popularName}
              </span>
            </Button>
          )}

          {ctaCalculator && (
            <Button color="secondary" linkHash="calculadora">
              <Icon name="calculator" className="mr-8 fill-current" />
              Simule suas taxas
            </Button>
          )}

          {!ctaCalculator && machineSelected && (
            <RetainQueryLink href={machineSelected.linkToCheckout}>
              <Button color="primary">
                {`Pedir ${
                  ALL_MACHINES_COMMOM_INFO[machineSelected.machineName]
                    .popularName
                } ${ALL_PLANS_COMMOM_INFO[plan].popularName}`}
              </Button>
            </RetainQueryLink>
          )}
        </div>
      </div>
    </section>
  )
}
