import { useState } from 'react'

import CloudinaryImage from '@/components/CloudinaryImage'
import { Slider } from '@/components/Slider'
import { useInfo } from '@/contexts/info'
import { useMachines } from '@/contexts/machines'
import { AllPlansForMachine } from '@/types/plans'

import { MachineCard } from './MachineCard'
import { usePromotions } from '@/utils/getActivesPromotions'

const MachinesAboutUs = ({ referId }: { referId: string }) => {
  const { fees } = useInfo()
  const { machines: allMachines } = useMachines()

  const { getActivePromotions } = usePromotions()

  const machinesForOrder = ['T3', 'T3 Smart', 'T2', 'T1']

  const machines = allMachines
    .filter((machine) => machine.plan === 'PromoTon')
    .sort((a, b) => {
      const indexA = machinesForOrder.indexOf(a.name)
      const indexB = machinesForOrder.indexOf(b.name)

      return indexA - indexB
    })

  const {
    isPromo20,
    isPromo25,
    isPromoZero,
    isPromoCombo,
    isPromoParcel,
    isPromoUntil35
  } = getActivePromotions()

  const [machineCardIsOpen, setMachineCardIsOpen] = useState(false)

  return (
    <div
      id={referId}
      className="flex flex-col gap-24 justify-center items-center py-40 lg:py-80 w-full bg-newTon-400"
    >
      <div className="flex flex-col md:flex-row gap-16 md:gap-24 lg:gap-40 md:justify-start items-center px-16 md:px-24 lg:px-40 2xl:px-0 w-full max-w-[412px] md:max-w-[1280px] text-center md:text-left">
        <h2>
          Já conhece as máquinas?
          <br />
          <span className="text-display-0">
            No <span className="underline">Plano PromoTon</span> a taxa é
            {isPromoZero || isPromoCombo ? ' zero:' : ' baixinha:'}
          </span>
        </h2>
        <div className="p-8 md:p-16 w-full md:w-max h-[max-content] bg-display-0 rounded-md md:rounded-[24px]">
          <div className="flex flex-row gap-8 justify-center items-center">
            <ul className="flex gap-16">
              <li className="w-min text-center" id="taxa-plano-promo-db">
                <p className="text-[12px] font-bold leading-[18px] text-display-900 whitespace-nowrap">
                  DÉBITO
                </p>
                <p
                  className={`mt-4 font-heading text-[28px] md:text-4xl font-bold leading-1 md:leading-7 ${
                    isPromoZero || isPromoCombo || isPromoParcel
                      ? 'text-[#8062FF]'
                      : 'text-newTon-700'
                  }`}
                >
                  {fees[AllPlansForMachine.Pro].mainFlags[0][1]
                    ?.replace('0,00', '0')
                    .replace('%', '')}
                  %
                </p>
              </li>
              <li className="w-min text-center" id="taxa-plano-promo-crav">
                <p className="text-[12px] font-bold leading-[18px] text-display-900 whitespace-nowrap">
                  CRÉDITO
                </p>
                <p
                  className={`mt-4 font-heading text-[28px] md:text-4xl font-bold leading-1 md:leading-7 ${
                    isPromoZero || isPromoCombo || isPromoParcel
                      ? 'text-[#8062FF]'
                      : 'text-newTon-700'
                  }`}
                >
                  {fees[AllPlansForMachine.Pro].mainFlags[1][1]
                    ?.replace('0,00', '0')
                    .replace('%', '')}
                  %
                </p>
              </li>
              <li className="w-min text-center" id="taxa-plano-promo-cr12x">
                <p className="text-[12px] font-bold leading-[18px] text-display-900 whitespace-nowrap">
                  CRÉDITO 12X
                </p>
                <p
                  className={`mt-4 font-heading text-[28px] md:text-4xl font-bold leading-1 md:leading-7 ${
                    isPromoZero || isPromoCombo || isPromoParcel
                      ? 'text-[#8062FF]'
                      : 'text-newTon-700'
                  }`}
                >
                  {fees[AllPlansForMachine.Pro].mainFlags[2].parcel
                    ? fees[
                        AllPlansForMachine.Pro
                      ].mainFlags[2].parcel?.[10][1].replace('%', '')
                    : null}
                  %
                </p>
              </li>
            </ul>
            <div className="w-[72px] h-[72px]">
              <CloudinaryImage
                src="v1/site-ton/taxapixqrcode"
                alt="Icone Taxa Pix Qr Code"
                width={72}
                height={72}
              />
            </div>
          </div>
        </div>
      </div>

      {machines.length > 0 && (
        <Slider.Root
          configs={{
            options: {
              slides: {
                spacing: 16
              }
            },
            breakpoints: {
              md: {
                slides: {
                  spacing: 24
                }
              }
            }
          }}
          navigations={{
            dots: {
              active: true,
              options: {
                color: 'secondary'
              }
            }
          }}
        >
          {machines.map((machine) => (
            <Slider.Item
              key={machine.description}
              className="min-w-[213px] md:min-w-[345px] max-w-[213px] md:max-w-[345px]"
            >
              <MachineCard.Root>
                <div className="flex flex-col md:flex-row gap-8 md:justify-between md:items-center">
                  <MachineCard.Image
                    machine={machine}
                    promotions={{
                      isPromo20,
                      isPromoCombo,
                      isPromo25,
                      isPromoUntil35
                    }}
                  />

                  <MachineCard.Info
                    machine={machine}
                    promotion={isPromo20 || isPromoCombo || isPromo25}
                  />
                </div>

                <div className="p-16 h-[112px] md:h-[92px] bg-newTon-50 rounded-[8px] rounded-tl-none border-[1px] border-newTon-500 border-dashed">
                  <p className="font-default paragraph-14">
                    Receba <b>seu dinheiro de volta</b> da sua 1ª maquininha ao
                    atingir R$ 15 mil em vendas
                  </p>
                </div>

                <MachineCard.Details
                  toggleDetails={() => setMachineCardIsOpen((state) => !state)}
                  open={machineCardIsOpen}
                  machine={machine}
                />
              </MachineCard.Root>
            </Slider.Item>
          ))}
        </Slider.Root>
      )}
    </div>
  )
}

export default MachinesAboutUs
