export interface IHasTargetOnHtmlOrChilds {
  clickOnTarget: boolean
  clickOnElement: boolean
  clickOnElementFirstChild: boolean
}

export const hasTargetOnHtmlOrChilds = (
  target: EventTarget,
  element: HTMLDivElement
): IHasTargetOnHtmlOrChilds => ({
  clickOnElement: target === element,
  clickOnElementFirstChild: Boolean(
    element.firstChild === element ||
      element.firstChild?.contains(target as HTMLElement)
  ),
  clickOnTarget: element.contains(target as HTMLElement)
})
