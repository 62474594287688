import { ButtonHTMLAttributes, ReactNode } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

const button = tv(
  {
    base: 'flex gap-8 items-center p-8 text-16 leading-24 rounded-16',

    variants: {
      condition: {
        active: 'text-display-0 bg-newTon-600 font-bold',
        default: 'text-display-900 bg-display-100'
      }
    },

    defaultVariants: {
      condition: 'default'
    }
  },
  {
    twMerge: false
  }
)

type ButtonVariantsProps = VariantProps<typeof button>
type NativeProps = ButtonHTMLAttributes<HTMLButtonElement>

export interface AntecipationFeesToggleButtonProps
  extends Omit<NativeProps, keyof ButtonVariantsProps>,
    ButtonVariantsProps {
  text: string
  children?: ReactNode
}

export const AntecipationFeesToggleButton = ({
  text,
  children,
  condition,
  ...rest
}: AntecipationFeesToggleButtonProps) => {
  return (
    <button {...rest} className={button({ condition })}>
      {children}
      <span>{text}</span>
    </button>
  )
}
