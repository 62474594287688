import { CMSEventParams } from '@/services/amplitude/event.shared.interfaces'
import { CMSExperiment } from '../DynamicComponent'
import {
  AmplitudeTestKey,
  CommonEventProperties,
  trackEvent
} from '@/services/amplitude/trackEvent'
import { useCMSEvent } from '@/services/amplitude/events/useCMSEvent'

type TrackEventType = {
  description?: string
  section_reference?: string
  cta_reference?: string
  experiment_id?: string
  variant_id?: string
} & CommonEventProperties

export interface CMSTrackOnClickProps {
  trackOnClick?: CMSEventParams
  experiment?: CMSExperiment | null
}

interface Props extends CMSTrackOnClickProps {
  children: React.ReactNode
}

export function CMSTrackOnClickProvider({
  trackOnClick,
  experiment,
  children
}: Props) {
  const { rules, customEventProperties } = useCMSEvent(trackOnClick)

  function handleTrackEvent() {
    const componentExperiment = experiment?.amplitude_experiment?.key
    const eventExperiment = trackOnClick?.experiment?.key

    const experimentIdToSendInEvent = (eventExperiment ||
      componentExperiment) as AmplitudeTestKey

    const variantIdToSendInEvent = eventExperiment
      ? undefined // if provide experiment in event, trackEvent function will find the current variant when taken undefined as variant_id
      : componentExperiment

    trackEvent<TrackEventType>(experimentIdToSendInEvent, trackOnClick!.name, {
      description: trackOnClick?.description,
      section_reference: trackOnClick?.sectionReference,
      cta_reference: trackOnClick?.ctaReference,
      experiment_id: experimentIdToSendInEvent,
      variant_id: variantIdToSendInEvent,
      ...customEventProperties
    })
  }

  if (!trackOnClick) {
    return <>{children}</>
  }

  const compliesWithCMSTrackRule =
    rules[trackOnClick.ruleToTrack as keyof typeof rules]

  if (!compliesWithCMSTrackRule()) {
    return <>{children}</>
  }

  return <div onClick={handleTrackEvent}>{children}</div>
}
