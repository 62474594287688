/* eslint-disable @typescript-eslint/no-unused-vars */
import 'swiper/css'
import 'swiper/css/pagination'

import { useState } from 'react'
import ReactPlayer from 'react-player'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  NavigationOptions,
  PaginationOptions,
  SwiperOptions
} from 'swiper/types'

import CloudinaryImage from '@/components/CloudinaryImage'
import { IconsNames } from '@/components/Icon'
import { ImageCMSProps, ImagesProps } from '@/types/cms'

import CasesVideoModal from './components/CasesVideoModal'

interface VideoTestimonialsCardProps {
  id: number
  namePersona: string
  description: string
  images: ImagesProps
  videoUrl: string
  level: {
    text: string
    iconName: IconsNames
  }
}
export interface VideoTestimonialsProps {
  referId: string
  title: string
  image: ImageCMSProps
  cards: VideoTestimonialsCardProps[]
}

const navigationOptions: NavigationOptions = {
  enabled: true,
  nextEl: '#swiper-next-rtb',
  prevEl: '#swiper-prev-rtb',
  disabledClass: 'text-display-400'
}

const paginationOptions: PaginationOptions = {
  enabled: true,
  clickable: true,
  el: '#dots-vt',
  bulletActiveClass: 'swiper-pagination-bullet-active !bg-newTon-600'
}

const swiperOptions: SwiperOptions = {
  modules: [Pagination, Navigation],
  navigation: navigationOptions,
  pagination: paginationOptions,
  slidesPerView: 'auto',
  slidesOffsetAfter: 16,
  slidesOffsetBefore: 16,
  spaceBetween: 24,
  breakpoints: {
    768: {
      slidesOffsetAfter: 24,
      slidesOffsetBefore: 24,
      spaceBetween: 40
    },
    1025: {
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      spaceBetween: 46
    }
  }
}

export function VideoTestimonials({
  referId,
  title,
  image,
  cards
}: VideoTestimonialsProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(false)

  const handleModalShow = async (url: string) => {
    const header = document.getElementById('Context_menu')

    if (header) {
      header.style.visibility = 'hidden'
    }

    setModalIsOpen(true)
    setModalChildren(
      <ReactPlayer
        url={url}
        playing={true}
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    )
  }

  const handleModalHidden = () => {
    const header = document.getElementById('Context_menu')

    if (header) {
      header.style.visibility = 'visible'
    }

    setModalIsOpen(false)
    setModalChildren(<div />)
  }

  return (
    <div className="flex flex-col gap-24 justify-center items-center py-40 lg:py-80 bg-display-50">
      <div className="flex flex-col gap-24 lg:gap-40 justify-center items-center w-full">
        <div className="flex gap-8 px-24 2xl:px-0 mx-auto w-full max-w-[1280px]">
          <div className="mt-auto">
            <CloudinaryImage
              src={image.path}
              width={54}
              height={54}
              alt={image.alternativeText}
              quality={100}
              className="object-cover max-w-full h-auto"
            />
          </div>
          <div className="flex flex-col justify-center items-center p-24 lg:py-[18px] lg:px-24 w-full max-w-[464px] md:text-[28px] md:leading-[36px] bg-newTon-800 rounded-[16px] rounded-bl-none">
            <p
              className="font-heading text-[24px] font-light leading-[32px] text-display-0 "
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </div>

        <div className="py-2 w-full max-w-[1280px]">
          <Swiper {...swiperOptions} className="!overflow-visible">
            {cards.map(
              (
                { images, namePersona, level, description, videoUrl },
                index
              ) => {
                return (
                  <SwiperSlide
                    id={`cardDepoiment-${index}`}
                    key={`cardVideo-${index}`}
                    className="!w-auto !h-auto"
                  >
                    <div className="flex flex-col md:flex-row gap-24 items-center p-24 max-w-[288px] md:max-w-[502px] lg:max-w-[624px] !h-full bg-display-0 rounded-t-[24px] rounded-bl-[24px] border-2 border-newTon-600">
                      <div
                        className="md:min-w-[215px] md:min-h-[382px] cursor-pointer"
                        onClick={() => handleModalShow(videoUrl)}
                      >
                        <div className="md:hidden">
                          <CloudinaryImage
                            src={images.mobile.path}
                            width={240}
                            height={215}
                            alt={images.mobile.alternativeText}
                            quality={100}
                            className="object-cover max-w-full h-auto"
                          />
                        </div>
                        <div className="hidden md:block">
                          <CloudinaryImage
                            src={images.web.path}
                            width={215}
                            height={379}
                            alt={images.web.alternativeText}
                            quality={100}
                            className="object-cover max-w-full h-auto"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col justify-center">
                        <p
                          className="font-bold paragraph-20 md:paragraph-24"
                          dangerouslySetInnerHTML={{ __html: namePersona }}
                        />

                        <div className="flex gap-4 self-start">
                          <CloudinaryImage
                            src={level.iconName}
                            width={20}
                            height={20}
                            alt="Icon"
                            quality={100}
                            className="flex object-cover justify-center items-center m-auto max-w-full h-auto"
                          />
                          <p
                            className={`paragraph-16 font-medium ${
                              level.iconName === ('gold-medal' as IconsNames)
                                ? 'text-[#C18E28]'
                                : 'text-[#0270A7]'
                            }`}
                            dangerouslySetInnerHTML={{ __html: level.text }}
                          />
                        </div>

                        <p
                          className="mt-16 lg:w-[319px] min-w-[240px] md:min-w-[215px] paragraph-16 md:paragraph-18"
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }
            )}
          </Swiper>
        </div>
        <div id="dots-vt" className="hidden lg:block w-full text-center" />
      </div>

      <CasesVideoModal
        showModal={modalIsOpen}
        onCloseModal={handleModalHidden}
        modalClassName={['relative']}
        modalInnerContainerClassName={['relative p-0 max-h-[700px]']}
        childrenContainerClassName={['h-[90%]']}
      >
        {modalChildren}
      </CasesVideoModal>
    </div>
  )
}
