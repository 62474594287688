import cn from '@/utils/cn'
import { pageModeProps } from '../CalculatorTabs'

type TButtonTab = {
  id: string
  option: pageModeProps
  selectMode?: pageModeProps
  setSelectMode: (id: pageModeProps) => void
  children?: React.ReactNode
}

const ButtonTab: React.FC<TButtonTab> = ({
  id,
  option,
  selectMode,
  setSelectMode,
  children
}) => (
  <button
    type="button"
    id={id}
    onClick={() => {
      setSelectMode(option)
    }}
    className={cn(
      'py-12 px-16 rounded-t-[8px] transition-all whitespace-nowrap w-auto',
      selectMode === option
        ? 'bg-display-0 border-transparent'
        : 'bg-transparent border-display-400 border border-b-0'
    )}
  >
    <b
      className={cn(
        'paragraph-18 transition-all font-medium',
        selectMode === option ? 'text-newTon-700' : 'text-display-700'
      )}
    >
      {children}
    </b>
  </button>
)
export default ButtonTab
