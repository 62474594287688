import cn from '@/utils/cn'
import { ReactNode } from 'react'

type HandleStyleHeightProps = {
  height: number
  ratio?: number
}

export interface HeroBannerContainerProps {
  height?: number
  ratio?: number
  children: ReactNode
  className?: string
}

export const HeroBannerContainer = ({
  height,
  ratio,
  children,
  className
}: HeroBannerContainerProps) => {
  const handleStyleHeight = ({
    height = 200,
    ratio = 2
  }: HandleStyleHeightProps) => {
    return {
      minHeight: Math.round(height / ratio) + 'px',
      maxHeight: Math.round(height / ratio) + 'px'
    }
  }

  const style = !height
    ? { maxHeight: '100%' }
    : handleStyleHeight({ height, ratio })

  return (
    <div style={style} className={cn('flex', className)}>
      {children}
    </div>
  )
}
