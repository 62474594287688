import { useMemo, useState } from 'react'

import cn from '@/utils/cn'

import Button from '../ButtonV2'
import { FaqItem } from './components/FaqItem'
import { FaqProps } from './Faq.types'

export function Faq({
  questions,
  identity,
  showAllItems = false,
  title = 'Ficou alguma dúvida?',
  buttons,
  backgroundColor
}: FaqProps) {
  const [showAllItemsState, setShowAllItems] = useState(showAllItems)

  const maxItemsToShow = 6

  const itemsSliced = useMemo(
    () => (showAllItemsState ? questions : questions.slice(0, maxItemsToShow)),
    [showAllItemsState, questions]
  )

  return (
    <div
      className={cn({
        [`bg-${backgroundColor?.classColor}`]: !!backgroundColor?.classColor
      })}
    >
      <div
        id={identity}
        className="flex flex-col gap-24 lg:gap-40 items-center py-40 md:py-40 lg:py-80 mx-auto w-full max-w-screen-xl"
      >
        <div className="flex flex-col items-center px-16 md:px-24 text-center">
          <h2 className="font-bold text-display-900 heading-2">{title}</h2>
        </div>

        <ul className="flex overflow-hidden flex-col gap-16 px-16 md:px-24">
          {itemsSliced.map((faqItem) => (
            <FaqItem
              key={faqItem.question}
              title={faqItem.question}
              text={faqItem.answer}
            />
          ))}
        </ul>

        {!showAllItemsState && questions.length > maxItemsToShow && (
          <div className="flex flex-col md:flex-row gap-16 justify-center px-16 md:px-24 mt-24">
            <button
              onClick={() => setShowAllItems(true)}
              type="button"
              className="w-full md:w-auto btn btn-secondary btn-large"
            >
              Todas as dúvidas
            </button>
          </div>
        )}

        <div
          className={cn(
            'flex flex-col md:flex-row gap-16 justify-center w-full px-16 md:px-24',
            !buttons ? 'hidden' : ''
          )}
        >
          {buttons?.map((props) => (
            <Button
              {...props}
              key={`button-${props.label}`}
              className="flex order-2 md:order-3 w-full md:w-max font-bold"
            />
          ))}
        </div>
      </div>
    </div>
  )
}
