import CloudinaryImage from '@/components/CloudinaryImage'
import { AllMachines, AllMachinesType } from '@/types/machines'
import { AllPlansForMachine } from '@/types/plans'
import cn from '@/utils/cn'
import { PromotionsTypes } from '@/utils/getActivesPromotions'

type TProps = {
  release?: boolean
  badges: string[]
  machineImageName: {
    left: string
    front: string
    right: string
  }
  activePromotion: PromotionsTypes
  plan: string
  machinePopularName: string
  machineName: AllMachinesType
}

const DefaultProducts = ({
  badges,
  machineImageName,
  plan,
  activePromotion = PromotionsTypes.NONE,
  machinePopularName,
  machineName
}: TProps) => {
  return (
    <>
      <div className="absolute top-[-60px] sm:top-[-50px] left-0 max-w-[141px] sm:max-w-[172px]">
        <CloudinaryImage
          src={`site-ton/maquininhas/${machineImageName.left}`}
          alt={`Imagem da maquininha ${machinePopularName}`}
          width={130}
          height={195}
          quality={90}
          className="object-cover max-w-full h-auto"
        />

        {activePromotion === PromotionsTypes.TWENTY && (
          <div className="absolute top-4 right-[-92px] z-[1]">
            <CloudinaryImage
              src={`v1/ton/cupom20/cupom-20-tag`}
              alt={`Tag de desconto de 20%`}
              width={102}
              height={80}
              quality={90}
              className="object-cover max-w-full h-auto"
            />
          </div>
        )}

        {activePromotion === PromotionsTypes.TWENTY_FIVE && (
          <div className="absolute top-4 right-[-120px] z-[1]">
            <CloudinaryImage
              src={`v1/ton/promo25OFF/tag_25OFF`}
              alt={`Tag de desconto de 25%`}
              width={132}
              height={88}
              quality={90}
              className="object-cover max-w-full h-auto"
            />
          </div>
        )}

        {activePromotion === PromotionsTypes.UNTIL_35 && (
          <>
            {plan !== AllPlansForMachine.Pro && (
              <div className="absolute top-4 right-[-120px] z-[1]">
                <CloudinaryImage
                  src="site-ton/promotions_tag/25OFF"
                  alt={`Tag de desconto de 25%`}
                  width={132}
                  height={88}
                  quality={90}
                  className="object-cover max-w-full h-auto"
                />
              </div>
            )}

            {plan === AllPlansForMachine.Pro &&
              machineName !== AllMachines.T3Smart && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/30OFF"
                    alt={`Tag de desconto de 30%`}
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}

            {plan === AllPlansForMachine.Pro &&
              machineName === AllMachines.T3Smart && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/35OFF"
                    alt={`Tag de desconto de 35%`}
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}
          </>
        )}

        {activePromotion === PromotionsTypes.BLACK_FRIDAY && (
          <>
            {(machineName === AllMachines.T1 ||
              machineName === AllMachines.T2) && (
              <div className="absolute top-4 right-[-120px] z-[1]">
                <CloudinaryImage
                  src="site-ton/promotions_tag/black/BLACK30OFF"
                  alt="Imagem de selo de desconto na cor preta com 30% de desconto. O 30% está na cor verde neon e a frase “de desconto” na cor branca."
                  width={132}
                  height={88}
                  quality={90}
                  className="object-cover max-w-full h-auto"
                />
              </div>
            )}

            {plan !== AllPlansForMachine.Pro &&
              machineName === AllMachines.T3Smart && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/black/BLACK70OFF"
                    alt="Imagem de selo de desconto na cor preta com 70% de desconto. O 70% está na cor verde neon e a frase “de desconto” na cor branca."
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}

            {plan !== AllPlansForMachine.Pro &&
              machineName === AllMachines.T3 && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/black/BLACK40OFF"
                    alt="Imagem de selo de desconto na cor preta com 40% de desconto. O 40% está na cor verde neon e a frase “de desconto” na cor branca."
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}
            {plan === AllPlansForMachine.Pro &&
              machineName === AllMachines.T3 && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/black/BLACK50OFF"
                    alt="Imagem de selo de desconto na cor preta com 50% de desconto. O 50% está na cor verde neon e a frase “de desconto” na cor branca."
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}

            {plan === AllPlansForMachine.Pro &&
              machineName === AllMachines.T3Smart && (
                <div className="absolute top-4 right-[-120px] z-[1]">
                  <CloudinaryImage
                    src="site-ton/promotions_tag/black/BLACK65OFF"
                    alt="Imagem de selo de desconto na cor preta com 65% de desconto. O 65% está na cor verde neon e a frase “de desconto” na cor branca."
                    width={132}
                    height={88}
                    quality={90}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}
          </>
        )}
      </div>

      <div className="flex justify-end ml-auto">
        {badges?.map((badge, index) => (
          <div
            className={cn(
              'ml-[-18px] first:z-40',
              badges?.length > 1 &&
                'max-w-[88px] max-h-[88px] md:max-w-[88px] md:max-h-[88px]'
            )}
            key={`img-name-${badge}-${index}`}
          >
            <CloudinaryImage
              src={`site-ton/${badge}1`}
              alt={`Icone representando a feature ${badge}`}
              width={96}
              height={96}
              className="object-cover max-w-full h-auto rounded-full"
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default DefaultProducts
