import { useCallback, useEffect, useMemo, useState } from 'react'

import Icon from '@/components/Icon'
import NativeDropdown from '@/components/NativeDropdown'
import RetainQueryLink from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import {
  nativeDropdownFlagGroupsMachine,
  nativeDropdownReceivingOption,
  nativeDropdownSaleType
} from '@/constants/fees'
import {
  ALL_PLANS_COMMOM_INFO,
  ALL_PLANS_FOR_MACHINE,
  nativeDropdownPromotionsTiersMachine
} from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { formatFeeToString, formatValueToString } from '@/helpers/fees'
import {
  FlagsGroupType,
  InformationFeesDetails,
  PlanAndFeesInfo,
  ReceivingOptionsType
} from '@/types/fees'
import {
  AllPlansForMachine,
  AllPlansForMachineType,
  AllPlansForSmartphone,
  AllTiersForMachineType
} from '@/types/plans'
import cn from '@/utils/cn'

import { feesCompetitorsPrices } from '../CalculatorTabs'
import Input from '../Input'
import ToolTip from '../ToolTip'

type CalculatorMachineProps = {
  className?: string
}

interface CalculatorMachineLinkToProps {
  planName: AllPlansForMachineType
  className?: string
}

const CalculatorMachineLinkTo: React.FC<CalculatorMachineLinkToProps> = ({
  planName,
  className
}) => {
  // Texto customizado para o plano promocional
  if (planName === AllPlansForMachine.Pro) {
    return (
      <div className={cn('font-medium ton-paragraph-16', className)}>
        Taxas de acordo com as vendas mensais.{' '}
        <RetainQueryLink href="/planos-e-taxas">
          <span className="text-newTon-700 underline">
            Veja como funciona o {ALL_PLANS_COMMOM_INFO[planName].popularName}
          </span>
        </RetainQueryLink>
      </div>
    )
  }

  // Texto para os demais planos
  return (
    <div className={className}>
      <RetainQueryLink href="/planos-e-taxas">
        <span className="font-medium text-newTon-700 underline ton-paragraph-16">
          Veja como funciona o {ALL_PLANS_COMMOM_INFO[planName].popularName}
        </span>
      </RetainQueryLink>
    </div>
  )
}

const CalculatorMachine: React.FC<CalculatorMachineProps> = () => {
  const { plan, allPlanAndFeesMachine } = usePlans()

  // Inicializa com o plano definido no contexto
  const [planSelected, setPlanSelected] = useState<AllPlansForMachineType>(
    plan as AllPlansForMachineType
  )

  // Inicializa com o primeiro tier promocional
  const [promotionalTierSelected, setPromotionalTierSelected] = useState(0)

  // Inicializa com a bandeira MasterCard e Visa
  const [flagGroupSelected, setFlagGroupSelected] = useState(0)

  // Inicializa com o tipo de venda em 12x
  const [saleTypeSelected, setSaleTypeSelected] = useState(12)

  // Inicializa com recebimento em 1 dia útil
  const [receivingSelected, setReceivingSelected] = useState(1)

  // Inicializa o valor da venda com R$ 1000,00
  const [saleValueSelected, setSaleValueSelected] = useState(1000)

  // Obtem o valor inserido no campo de valor da venda
  const handleInputSaleValue = useCallback((value: number) => {
    setSaleValueSelected(value)
  }, [])

  // Obtem a taxa de acordo com os valores selecionados nos dropdowns
  const feeMachineSelectedByDropdowns = useMemo(() => {
    const feeMachineSelected = allPlanAndFeesMachine.find(
      (planAndFeesMachine) => {
        if (planSelected === AllPlansForMachine.Pro) {
          const promoTierSelectedByDropdown =
            nativeDropdownPromotionsTiersMachine[promotionalTierSelected]
              .valueType as AllTiersForMachineType

          return (
            planAndFeesMachine.planName === planSelected &&
            planAndFeesMachine.tierName === promoTierSelectedByDropdown
          )
        }

        return planAndFeesMachine.planName === planSelected
      }
    ) as PlanAndFeesInfo

    const feeSelected = feeMachineSelected?.fees as InformationFeesDetails

    const receivingSelectedByDropdown = nativeDropdownReceivingOption[
      receivingSelected
    ].valueType as ReceivingOptionsType

    const flagsGroupSelectedByDropdown = nativeDropdownFlagGroupsMachine[
      flagGroupSelected
    ].valueType as FlagsGroupType

    return feeSelected?.[receivingSelectedByDropdown][
      flagsGroupSelectedByDropdown
    ][saleTypeSelected]
  }, [
    allPlanAndFeesMachine,
    planSelected,
    promotionalTierSelected,
    receivingSelected,
    flagGroupSelected,
    saleTypeSelected
  ])

  // Calcula o valor liquido da venda de acordo com os valores selecionados nos dropdowns
  const valueWithTonFeeCalculated = useMemo(() => {
    const valueInPercent =
      (saleValueSelected * feeMachineSelectedByDropdowns) / 100
    return saleValueSelected - valueInPercent
  }, [feeMachineSelectedByDropdowns, saleValueSelected])

  // Calcula o valor liquido da venda de acordo com os valores selecionados nos dropdowns
  const valueWithCompetitorFeeCalculated = useMemo(() => {
    const valueInPercentFromCompetitor =
      (saleValueSelected * feesCompetitorsPrices[saleTypeSelected]) / 100

    const valueFinalFromCompetitor =
      saleValueSelected - valueInPercentFromCompetitor

    return valueWithTonFeeCalculated - valueFinalFromCompetitor
  }, [saleTypeSelected, saleValueSelected, valueWithTonFeeCalculated])

  // Atualiza o plano de acordo com o contexto
  useEffect(() => {
    if (AllPlansForSmartphone.Tapton === plan) {
      setPlanSelected(AllPlansForMachine.Pro)
      return
    }

    setPlanSelected(plan as AllPlansForMachineType)
  }, [plan])

  return (
    <div className="flex flex-col md:flex-row gap-24 md:gap-0 md:items-center md:p-0 px-24 pt-24 pb-40 w-full bg-display-0 rounded-16 rounded-tl-none shadow-sm">
      <div className="flex flex-col gap-16 md:p-24 lg:p-40 w-full md:border-r">
        {/* Selecione o Plano */}
        <div className="flex flex-col gap-8">
          <div className="flex">
            <label className="mr-4 font-medium paragraph-16">
              Selecione seu Plano Ton:
            </label>

            <ToolTip
              id="plan-details"
              tooltip="O Plano Ton interfere no valor pago na taxa de adesão da(s) maquininha(s) escolhida(s) e também na taxa de cada transação realizada. Caso você queira migrar de plano, solicite mais detalhes com o nosso atendimento."
            >
              <button id="btn-plan-details" aria-label="dúvidas?">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                    fill="#919DAB"
                  />
                  <path
                    d="M12.2581 8.0244C11.7926 7.94455 11.3138 8.03203 10.9066 8.27135C10.4994 8.51066 10.1901 8.88637 10.0333 9.33193C9.85006 9.85292 9.27914 10.1267 8.75815 9.94342C8.23716 9.76015 7.96339 9.18923 8.14666 8.66824C8.46013 7.77713 9.07886 7.02572 9.89327 6.54708C10.7077 6.06845 11.6652 5.89349 12.5962 6.05319C13.5273 6.21289 14.3717 6.69694 14.9801 7.41961C15.5883 8.14213 15.9213 9.05656 15.92 10.001C15.9195 11.5314 14.7849 12.542 13.9747 13.0821C13.5391 13.3726 13.1105 13.5861 12.7949 13.7264C12.6356 13.7972 12.5015 13.8509 12.4049 13.8877C12.3565 13.9061 12.3173 13.9204 12.2887 13.9306L12.254 13.9427L12.2429 13.9465L12.239 13.9478L12.2375 13.9483C12.2372 13.9484 12.2362 13.9488 11.92 13.0001L12.2362 13.9488C11.7123 14.1234 11.146 13.8403 10.9713 13.3163C10.7968 12.7927 11.0794 12.2268 11.6027 12.0517L11.6014 12.0522C11.6015 12.0521 11.6016 12.0521 11.6027 12.0517L11.6187 12.0461C11.6341 12.0407 11.6593 12.0315 11.6929 12.0187C11.7603 11.993 11.8606 11.953 11.9826 11.8988C12.2294 11.7891 12.5509 11.6276 12.8653 11.418C13.5549 10.9583 13.92 10.4692 13.92 10.0001L13.92 9.99859C13.9207 9.52628 13.7542 9.06895 13.45 8.70761C13.1459 8.34628 12.7236 8.10425 12.2581 8.0244Z"
                    fill="#919DAB"
                  />
                  <path
                    d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
                    fill="#919DAB"
                  />
                </svg>
              </button>
            </ToolTip>
          </div>

          <ul className="flex">
            {ALL_PLANS_FOR_MACHINE.map((planName) => (
              <li
                key={`swith-item-${planName}`}
                onClick={() => setPlanSelected(planName)}
                className={cn(
                  'flex justify-center items-center py-12 px-8 w-full last:rounded-r-8 first:rounded-l-8 border cursor-pointer',
                  planName === planSelected
                    ? 'text-newTon-700 font-bold border-newTon-700'
                    : 'text-display-900 font-medium'
                )}
              >
                <span className="ton-paragraph-16">
                  {ALL_PLANS_COMMOM_INFO[planName]?.shortPopularName}
                </span>
              </li>
            ))}
          </ul>

          {planSelected === AllPlansForMachine.Pro && (
            <div className="flex flex-col gap-8">
              <NativeDropdown
                options={nativeDropdownPromotionsTiersMachine}
                selectedOption={promotionalTierSelected}
                dropdownId="dropdown-select-promotion-tier"
                setStateValue={setPromotionalTierSelected}
                bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
                labelClassName="font-medium whitespace-nowrap !w-full !justify-between text-14"
                menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row gap-16">
          {/* Bandeira */}
          <div className="flex flex-col flex-grow gap-8">
            <label
              htmlFor="dropdown-select-flags-group"
              className="font-medium paragraph-16"
            >
              Bandeiras
            </label>

            <div>
              <NativeDropdown
                options={nativeDropdownFlagGroupsMachine}
                selectedOption={flagGroupSelected}
                dropdownId="dropdown-select-flags-group"
                setStateValue={setFlagGroupSelected}
                bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
                labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
                menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
              />
            </div>
          </div>

          {/* Tipo da Venda */}
          <div className="flex flex-col gap-8">
            <label
              htmlFor="dropdown-select-sale-type"
              className="font-medium paragraph-16"
            >
              Tipo da Venda
            </label>

            <div>
              <NativeDropdown
                options={nativeDropdownSaleType}
                selectedOption={saleTypeSelected}
                dropdownId="dropdown-select-sale-type"
                setStateValue={setSaleTypeSelected}
                bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
                labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
                menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold whitespace-nowrap"
              />
            </div>
          </div>
        </div>

        {/* Recebimento da Venda */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="dropdown-select-receiving"
            className="font-medium paragraph-16"
          >
            Recebimento da Venda
          </label>

          <div>
            <NativeDropdown
              options={nativeDropdownReceivingOption}
              selectedOption={receivingSelected}
              dropdownId="dropdown-select-receiving"
              setStateValue={setReceivingSelected}
              bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
              labelClassName="font-medium whitespace-nowrap !w-full !justify-between ton-paragraph-16"
              menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
            />
          </div>
        </div>

        {/* Valor da Venda */}
        <div className="flex flex-col gap-8">
          <label
            htmlFor="input-sale-value"
            className="font-medium paragraph-16"
          >
            Valor da Venda
          </label>

          <div>
            <Input
              id="input-sale-value"
              name="value"
              defaultValue={1000.0}
              handleinputsalevalue={handleInputSaleValue}
            />
          </div>
        </div>

        <CalculatorMachineLinkTo
          planName={planSelected}
          className="hidden md:block"
        />
      </div>

      <div className="flex flex-col gap-16 md:gap-24 md:p-24 lg:p-40 w-full">
        <div className="flex gap-4 items-center pb-16 border-b border-display-400 ton-paragraph-16">
          <span className="flex-grow">Você recebe</span>
          <span className="font-bold text-newTon-700 whitespace-nowrap">
            {formatValueToString(valueWithTonFeeCalculated)}
          </span>
        </div>

        <div className="flex gap-4 items-center pb-16 border-b border-display-400 ton-paragraph-16">
          <div className="flex flex-col flex-grow">
            <div>
              <span>
                Taxa{' '}
                <span className="text-newTon-700">
                  {ALL_PLANS_COMMOM_INFO[planSelected].shortPopularName}
                </span>{' '}
                {nativeDropdownSaleType[saleTypeSelected].label}
              </span>
            </div>

            {planSelected === AllPlansForMachine.Pro && (
              <span className="text-display-600 ton-paragraph-14">
                {
                  nativeDropdownPromotionsTiersMachine[promotionalTierSelected]
                    .description
                }
              </span>
            )}
          </div>

          <span className="font-bold whitespace-nowrap">
            {formatFeeToString(feeMachineSelectedByDropdowns)}
          </span>
        </div>

        <div className="flex gap-4 items-center ton-paragraph-16">
          <span className="flex-grow">Recebimento da venda</span>
          <span className="font-bold whitespace-nowrap">
            {nativeDropdownReceivingOption[receivingSelected]?.label}
          </span>
        </div>

        <div className="flex gap-16 items-center p-16 bg-display-100 rounded-16">
          <div>
            <Icon
              name="cent-piggy-bank"
              className="text-newTon-700 fill-current"
            />
          </div>
          <div className="ton-paragraph-14 md:ton-paragraph-16">
            Você economiza até{' '}
            <span className="font-bold text-newTon-700">
              {formatValueToString(
                valueWithCompetitorFeeCalculated > 0
                  ? valueWithCompetitorFeeCalculated
                  : 0
              )}
            </span>{' '}
            em relação aos concorrentes
          </div>
        </div>

        <CalculatorMachineLinkTo
          planName={planSelected}
          className="md:hidden"
        />
      </div>
    </div>
  )
}

export default CalculatorMachine
