import { PlansSelectCard } from '@/components/PlansSelectCard'

export interface PlansProps {
  referId: string
  hasTapton: boolean
}

export function Plans(props: PlansProps) {
  return <PlansSelectCard {...props} />
}
