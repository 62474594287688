import React, { useEffect, useState } from 'react'

import { FlagNames, FlagSprite } from '@/components/IconSprite'
import NativeDropdown from '@/components/NativeDropdown'
import { FLAGS_GROUP_CARDS } from '@/constants/fees'
import { ALL_PLANS_FOR_MACHINE } from '@/constants/plans'
import { usePlans } from '@/contexts/plans'
import { FlagsGroup, PlanAndFeesInfo, ReceivingOptions } from '@/types/fees'
import { AllPlansForMachine, AllPlansForMachineType } from '@/types/plans'
import cn from '@/utils/cn'

import Button, { ButtonV2Props } from '../ButtonV2'
import { ModalPlansInformations } from './components/ModalPlansInformations'
import { PlanCard } from './components/PlanCard'

interface PlansAndFeesProps {
  referId: string
  title: string
  subtitleLinkLabel: string
  subtitle: string
  buttons: ButtonV2Props[]
}

enum ANTECIPATION_TERMS {
  SAME_DAY = 0,
  ONE_BUSINESS_DAY = 1
}

const ANTECIPATION_TERMS_OPTIONS = [
  ReceivingOptions.SameDay,
  ReceivingOptions.OneBusinessDay
]

export default function PlansAndFees({
  referId,
  title,
  subtitle,
  buttons
}: PlansAndFeesProps) {
  const {
    allPlanAndFeesMachine,
    receivingOptions,
    setReceivingOptions,
    promoTier,
    plan
  } = usePlans()

  // Estado para o controle do grupo de bandeiras
  const [flagGroup, setFlagGroup] = useState(FlagsGroup.main_flags)

  // Estado para exibir os modais de informações
  const [isOpen, setIsOpen] = useState(false)

  // Estado para gerenciar o conteudo dos modais de informações
  const [modalPlanActive, setModalPlanActive] =
    useState<AllPlansForMachineType>(plan as AllPlansForMachineType)

  // Estado para o dropdown de variações do Promoton
  const [antecipationDropdown, setAntecipationDropdown] = useState(
    ANTECIPATION_TERMS_OPTIONS.findIndex((term) => term === receivingOptions)
  )

  useEffect(() => {
    setReceivingOptions(ANTECIPATION_TERMS_OPTIONS[antecipationDropdown])
  }, [antecipationDropdown, setReceivingOptions])

  useEffect(() => {
    const antecipationDropdownIndex = ANTECIPATION_TERMS_OPTIONS.findIndex(
      (term) => term === receivingOptions
    )

    setAntecipationDropdown(antecipationDropdownIndex)
  }, [receivingOptions])

  return (
    <div
      id={referId}
      className={cn(
        'flex flex-col mx-auto mb-24 max-w-[950px]',
        ALL_PLANS_FOR_MACHINE.length > 3
          ? 'md:max-w-full'
          : 'md:max-w-screen-lg'
      )}
    >
      <div className="flex flex-col gap-24 lg:gap-40">
        <div className="flex flex-col gap-24 justify-center">
          <div className="flex flex-col gap-8 px-16 pt-24">
            {title && (
              <h1 className="font-extrabold text-center ton-heading-1">
                {title}
              </h1>
            )}

            {subtitle && (
              <p className="text-center ton-paragraph-16">{subtitle}</p>
            )}
          </div>

          <div className="mx-auto w-full md:max-w-[950px] bg-display-100 md:rounded-16">
            <div className="flex flex-col gap-32 py-32 px-24 mx-auto max-w-[412px] md:max-w-none">
              <div className="flex flex-col md:flex-row gap-16 justify-center items-center mx-auto max-w-max">
                <p className="font-bold text-center ton-paragraph-14">
                  Bandeiras:
                </p>

                <div className="flex flex-col md:flex-row gap-16 md:gap-32">
                  <label
                    htmlFor="flags-mainFlags"
                    className="flex gap-8 items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      id="flags-mainFlags"
                      name="flags"
                      value={FlagsGroup.main_flags}
                      defaultChecked
                      onChange={(e) =>
                        setFlagGroup(e.target.value as FlagsGroup)
                      }
                    />

                    {FLAGS_GROUP_CARDS.mainFlags.map((flag) => (
                      <div key={flag} className="w-[48px] h-[32px]">
                        <FlagSprite
                          name={flag as FlagNames}
                          className="w-full h-full"
                        />
                      </div>
                    ))}
                  </label>

                  <label
                    htmlFor="flags-otherFlags"
                    className="flex gap-8 items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      id="flags-otherFlags"
                      name="flags"
                      value={FlagsGroup.other_flags}
                      onChange={(e) =>
                        setFlagGroup(e.target.value as FlagsGroup)
                      }
                    />

                    {FLAGS_GROUP_CARDS.otherFlags.map((flag) => (
                      <div key={flag} className="w-[49px] h-[32px]">
                        <FlagSprite
                          name={flag as FlagNames}
                          className="w-full h-full"
                        />
                      </div>
                    ))}
                  </label>
                </div>
              </div>
              <div className="flex flex-wrap gap-16 justify-center items-center mx-auto w-full">
                <p className="font-bold text-center ton-paragraph-14">
                  Recebimento da venda:
                </p>

                <div className="w-full max-w-[328px]">
                  {/* TODO: Rever componente NativeDropdown, estão dependentes de enum com tipagem 'number' */}
                  <NativeDropdown
                    options={[
                      {
                        id: 'no-mesmo-dia',
                        label: 'No mesmo dia',
                        value: String(ANTECIPATION_TERMS.SAME_DAY),
                        className: '!text-left !py-[3px] !px-[12px] !mt-8'
                      },
                      {
                        id: '1-dia-util',
                        label: '1 dia útil',
                        value: String(ANTECIPATION_TERMS.ONE_BUSINESS_DAY),
                        className: '!text-left !py-[3px] !px-[12px] !mt-8'
                      }
                    ]}
                    selectedOption={antecipationDropdown}
                    dropdownId="dropdown-prazos"
                    setStateValue={setAntecipationDropdown}
                    bodyClassName="border border-[0.1rem] border-display-400 flex flex-1 rounded-8 p-12 w-full bg-display-0"
                    labelClassName="font-medium whitespace-nowrap !w-full !justify-between"
                    menuClassName="top-[44px] !w-[calc(100%+1px)] left-0 font-semibold"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex overflow-x-auto gap-16 md:gap-24 mx-auto w-full max-w-max no-scrollbar">
          <div className="flex gap-16 pl-16 md:pl-24 lg:pl-40 md:ml-auto max-w-[max-content]">
            <div className="flex flex-col gap-16">
              <span className="p-8 font-bold text-center text-newTon-700 bg-newTon-50 rounded-8 ton-paragraph-14">
                Taxas conforme vendas mensais
              </span>

              <div className="flex gap-16 md:gap-24 h-full">
                {ALL_PLANS_FOR_MACHINE.filter(
                  (plan) => plan === AllPlansForMachine.Pro
                ).map((plan) => {
                  let planFeesInfo = allPlanAndFeesMachine.filter(
                    (fee) => fee.planName === plan
                  ) as PlanAndFeesInfo[]

                  // Busca o índice do plano promocional padrão
                  const planFeesInfoIndex = planFeesInfo.findIndex(
                    (plan) => plan.tierName === promoTier
                  )

                  // Reordena o array de acordo com o plano promocional padrão
                  if (planFeesInfoIndex !== -1) {
                    planFeesInfo = [
                      planFeesInfo[planFeesInfoIndex],
                      ...planFeesInfo.slice(0, planFeesInfoIndex),
                      ...planFeesInfo.slice(planFeesInfoIndex + 1)
                    ]
                  }

                  return (
                    <PlanCard
                      key={`plan-${plan}-card`}
                      planCard={planFeesInfo}
                      flagGroup={flagGroup}
                      receivingOptions={receivingOptions}
                      modalFunctions={{
                        setIsOpen,
                        setModalPlanActive
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className="flex gap-16 pr-16 md:pr-24 lg:pr-40 md:mr-auto">
            <div className="flex flex-col gap-16">
              <span className="p-8 font-bold text-center text-newTon-700 bg-newTon-50 rounded-8 ton-paragraph-14">
                Sem mínimo de vendas
              </span>

              <div className="flex gap-16 md:gap-24 h-full">
                {ALL_PLANS_FOR_MACHINE.filter(
                  (plan) => plan !== AllPlansForMachine.Pro
                ).map((plan) => {
                  const planFeesInfo = allPlanAndFeesMachine.filter(
                    (fee) => fee.planName === plan
                  ) as PlanAndFeesInfo[]

                  return (
                    <PlanCard
                      key={`plan-${plan}-card`}
                      planCard={planFeesInfo}
                      flagGroup={flagGroup}
                      receivingOptions={receivingOptions}
                      modalFunctions={{
                        setIsOpen,
                        setModalPlanActive
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {buttons && (
          <div className="flex justify-center px-16 lg:px-0 w-full">
            {buttons.map((button) => (
              <Button key={button.label} {...button} />
            ))}
          </div>
        )}
      </div>

      <ModalPlansInformations
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        planName={modalPlanActive}
      />
    </div>
  )
}
