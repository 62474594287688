import 'swiper/css'
import 'swiper/css/navigation'

import { useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions, SwiperOptions } from 'swiper/types'

import Icon from '@/components/Icon'
import { useCatalog } from '@/contexts/catalog'
import {
  AllMachinesWithFamilhaoDoTon,
  MachinesInfoDetails
} from '@/types/machines'
import cn from '@/utils/cn'

import TonMachineCard from '../MachineCardV2'
import PlansSelectHeader from './components/PlansSelectHeader'

interface MachinesProps {
  title: string
  description?: string
  behavior?: 'default' | 'familhao-do-ton'
}

const navigationOptions: NavigationOptions = {
  nextEl: '#swiper-next-mv2',
  prevEl: '#swiper-prev-mv2',
  disabledClass: 'text-display-400'
}

const swiperOptions: SwiperOptions = {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 16,
  slidesOffsetBefore: 16,
  slidesOffsetAfter: 16,
  navigation: navigationOptions
}

const Machines = ({ title, description, behavior }: MachinesProps) => {
  const {
    machineFromPlan,
    familhaoDoTonPerMachine,
    setFamilhaoDoTonPerMachine
  } = useCatalog()

  const [machines, setMachines] = useState<MachinesInfoDetails[]>([])
  const [filteredMachinesLength, setFilteredMachinesLength] = useState(0)
  const machineOrder = ['t1', 't3', 't3-smart', 't2']

  useEffect(() => {
    const filteredMachines = machineFromPlan.machines

    setMachines(filteredMachines)
    setFilteredMachinesLength(filteredMachines.length)
  }, [machineFromPlan])

  useEffect(() => {
    if (behavior === 'familhao-do-ton') {
      const familhaoDoTonPerMachineModified = {} as AllMachinesWithFamilhaoDoTon

      Object.keys(familhaoDoTonPerMachine).forEach((familhaoDoTon) => {
        Object.assign(familhaoDoTonPerMachineModified, {
          [familhaoDoTon]: {
            hasFamilhaoDoTon: true
          }
        })
      })

      setFamilhaoDoTonPerMachine(familhaoDoTonPerMachineModified)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [behavior])

  return (
    <>
      <PlansSelectHeader />
      <div className="pt-40 w-full">
        <div id="maquininhas" className="anchor" />
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col gap-8 justify-center items-center max-w-screen-xl">
            <h2
              className="px-16 md:px-0 font-heading text-4xl lg:text-6xl font-bold text-center"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            {description && (
              <p
                className="px-16 text-center ton-paragraph-16"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>

          <div className="pt-24 lg:pt-40 w-full">
            <Swiper
              {...swiperOptions}
              className={cn(
                '!pb-40 max-w-[1300px] !overflow-visible',
                filteredMachinesLength === 2 && 'md:justify-center m-auto'
              )}
            >
              {machines
                .sort((a, b) => {
                  return (
                    machineOrder.indexOf(a.machineName) -
                    machineOrder.indexOf(b.machineName)
                  )
                })
                .map((machine) => (
                  <SwiperSlide
                    key={machine.name}
                    className="pt-[60px] sm:pt-[50px] max-w-[263px] md:max-w-[308px] !h-auto"
                  >
                    <TonMachineCard
                      className="!h-full"
                      {...machine}
                      behavior={behavior}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div id="arrow-container" className="flex gap-16 justify-center pb-40">
        <span
          id="swiper-prev-mv2"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="rotate-180 fill-current" />
        </span>
        <span
          id="swiper-next-mv2"
          className="w-40 h-40 text-newTon-700 cursor-pointer"
        >
          <Icon name="arrow-right-2x" className="fill-current" />
        </span>
      </div>
    </>
  )
}

export default Machines
