import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

import { ALL_PLANS_FOR_MACHINE } from '@/constants/plans'
import { splitArrayInPairsWithRemainder } from '@/helpers/divideArrayInCouplePositions'
import { AllPlansForMachineType } from '@/types/plans'
import useViewport from '@/utils/useViewport'

import { PlanCardDesktopWithCarousel } from '../PlanCardDesktopWithCarousel'

const PlanCardSwiperMobile = dynamic(() =>
  import('../PlanCardSwiperMobile').then((c) => c.PlanCardSwiperMobile)
)

interface PlanCardsProps {
  showTapTonCard?: boolean
  verticalOnly?: boolean
}
export const PlanCards = ({ showTapTonCard, verticalOnly }: PlanCardsProps) => {
  const { width, device } = useViewport()
  const isMobile = device === 'mobile'
  const [cardsPerRow, setCardsPerRow] = useState<number>(5)
  const [allPlansForMachineSplitted, setAllPlansForMachineSplitted] = useState<
    AllPlansForMachineType[][]
  >([])

  useEffect(() => {
    const ALL_PLANS_FOR_MACHINE_SPLITTED =
      splitArrayInPairsWithRemainder<AllPlansForMachineType>(
        ALL_PLANS_FOR_MACHINE,
        cardsPerRow
      )

    setAllPlansForMachineSplitted(ALL_PLANS_FOR_MACHINE_SPLITTED)
  }, [cardsPerRow])

  useEffect(() => {
    switch (device) {
      case 'tablet':
        if (verticalOnly) {
          setCardsPerRow(5)
          return
        }
        setCardsPerRow(5)
        break
      default:
        setCardsPerRow(5)
        break
    }
  }, [device, width, verticalOnly])

  if (!width) return null

  if (isMobile) {
    return <PlanCardSwiperMobile showTapTonCard={showTapTonCard} />
  }

  return (
    <PlanCardDesktopWithCarousel
      allPlansForMachineSplitted={allPlansForMachineSplitted}
      showTapTonCard={showTapTonCard}
      verticalOnly={verticalOnly}
    />
  )
}
