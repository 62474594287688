import CalculatorTabs, { pageModeProps } from './components/CalculatorTabs'

type TCalculators = {
  id?: string
  title?: string
  tabInitiallySelected?: pageModeProps
  children?: React.ReactNode
}

const Calculators: React.FC<TCalculators> = ({
  id = 'calculadora',
  title = 'Simule as taxas das suas vendas',
  tabInitiallySelected = 'machines',
  children
}) => {
  return (
    <div id={id} className="bg-display-100">
      <div className="flex flex-col gap-24 items-center py-40 lg:py-[80px] px-0 sm:px-24 lg:px-0 md:pb-80 mx-auto max-w-[950px]">
        <h2 className="px-16 md:px-24 text-center text-display-900 heading-2">
          {title}
        </h2>
        <div className="flex flex-col w-full">
          <CalculatorTabs defaultSelected={tabInitiallySelected} />
        </div>
        <div className="flex justify-center px-16 sm:px-0 w-full">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Calculators
