import { TrackDetails } from 'keen-slider'
import { ComponentProps } from 'react'
import { tv } from 'tailwind-variants'

import Icon, { IconsNames } from '@/components/Icon'

import { KeenSliderInstanceProps } from '../../../Root'

export interface ArrowActionProps extends ComponentProps<'button'> {
  icon: IconsNames
  instance: KeenSliderInstanceProps
  action: 'prev' | 'next'
}

const btnAction = tv({
  slots: {
    button: [
      'flex justify-center items-center w-40 h-40 text-newTon-700',
      'disabled:text-display-300 disabled:pointer-events-none'
    ],
    icon: 'fill-current'
  }
})

const { button, icon: iconStyle } = btnAction()

export const ArrowAction = ({
  icon,
  instance,
  action,
  disabled
}: ArrowActionProps) => {
  const handlePrevSlide = () => {
    if (!instance.current) {
      return () => {}
    }

    const { abs } = instance.current.track.details as TrackDetails

    abs > 2 ? instance.current.prev() : instance.current.moveToIdx(0)
  }

  const handleNextSlide = () => {
    if (!instance.current) {
      return () => {}
    }

    const { abs } = instance.current.track.details as TrackDetails

    abs === 0 ? instance.current.moveToIdx(2) : instance.current.next()
  }

  const onClickActions = {
    prev: () => handlePrevSlide(),
    next: () => handleNextSlide()
  }

  return (
    <button
      disabled={disabled}
      onClick={onClickActions[action]}
      className={button()}
    >
      <Icon name={icon} className={iconStyle()} />
    </button>
  )
}
