import RetainQueryLinkDefault from '@/components/RetainQueryLink/RetainQueryLinkDefault'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { AllPlansForMachine, AllPlansForMachineType } from '@/types/plans'

type ModalPlansInformationsProps = {
  [key in AllPlansForMachineType]: {
    title: string
    content: () => JSX.Element
  }
}

export const MODAL_PLANS_INFORMATIONS: Partial<ModalPlansInformationsProps> = {
  [AllPlansForMachine.Pro]: {
    title: 'Como funciona o plano Ton Pro?',
    content: () => {
      const planName = ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Pro].popularName
      return (
        <>
          <p className="ton-paragraph-16">
            No plano Ton Pro, você terá taxas promocionais durante 30 dias ou
            até atingir R$ 5 mil reais em vendas.
          </p>
          <p className="ton-paragraph-16">
            Após o período promocional, você terá taxas de acordo com suas
            vendas mensais. No início de cada mês, são contabilizadas as suas
            vendas do mês anterior. Isso definirá a sua faixa de vendas e,
            consequentemente, as taxas do mês.
          </p>
          <p className="ton-paragraph-16">
            O Ton tem as melhores taxas do mercado em todas as faixas de venda.
            Quanto mais você vender e usar o Ton, mais conseguirá abaixar suas
            taxas mensais.
          </p>
          <p className="ton-paragraph-16">
            As taxas se aplicam a vendas no link de pagamento e a todas as
            vendas na maquininha, exceto Pix QR Code.
          </p>
          <p className="ton-paragraph-16">
            Veja o{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/regulamento-tonpro-tier.pdf"
            >
              <span className="text-newTon-700 underline">
                regulamento do {planName}
              </span>
            </a>{' '}
            e os{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/termos-e-condicoes.pdf"
            >
              <span className="text-newTon-700 underline">
                termos e condições
              </span>
            </a>
            .
          </p>
        </>
      )
    }
  },
  [AllPlansForMachine.Ultra]: {
    title: 'Como funciona o plano Ton Ultra?',
    content: () => {
      const planName =
        ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Ultra].popularName

      return (
        <>
          <p className="ton-paragraph-16">
            No plano Ton Ultra, você tem as menores taxas independentemente das
            suas vendas mensais.
          </p>
          <p className="ton-paragraph-16">
            As taxas se aplicam a vendas no link de pagamento e a todas as
            vendas na maquininha, exceto Pix QR Code.
          </p>
          <p className="ton-paragraph-16">
            Veja o{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/regulamento-tonultra.pdf"
            >
              <span className="text-newTon-700 underline">
                regulamento do {planName}
              </span>
            </a>{' '}
            e os{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/termos-e-condicoes.pdf"
            >
              <span className="text-newTon-700 underline">
                termos e condições
              </span>
            </a>
            .
          </p>
        </>
      )
    }
  },
  [AllPlansForMachine.Mega]: {
    title: 'Como funciona o plano Ton Mega?',
    content: () => {
      const planName =
        ALL_PLANS_COMMOM_INFO[AllPlansForMachine.Mega].popularName

      return (
        <>
          <p className="ton-paragraph-16">
            No plano Ton Mega, as maquininhas têm preços mais baratos e as taxas
            não mudam de acordo com as suas vendas mensais.
          </p>
          <p className="ton-paragraph-16">
            As taxas se aplicam a vendas no link de pagamento e a todas as
            vendas na maquininha, exceto Pix QR Code.
          </p>
          <p className="ton-paragraph-16">
            Veja o{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/regulamento-megaton-tier.pdf"
            >
              <span className="text-newTon-700 underline">
                regulamento do {planName}
              </span>
            </a>{' '}
            e os{' '}
            <a
              target="_blank"
              href="https://documentos.ton.com.br/termos-e-condicoes.pdf"
            >
              <span className="text-newTon-700 underline">
                termos e condições
              </span>
            </a>
            .
          </p>
        </>
      )
    }
  },
  [AllPlansForMachine.Super]: {
    title: 'Como funciona o plano Ton Super?',
    content: () => {
      return (
        <>
          <p className="ton-paragraph-16">
            No plano Ton Super, as maquininhas têm preços mais baratos e as
            taxas não mudam de acordo com as suas vendas mensais.
          </p>
          <p className="ton-paragraph-16">
            As taxas se aplicam a vendas no link de pagamento e a todas as
            vendas na maquininha, exceto Pix QR Code.
          </p>
          <p className="ton-paragraph-16">
            Veja os{' '}
            <RetainQueryLinkDefault
              target="_blank"
              href="https://documentos.ton.com.br/termos-e-condicoes.pdf"
            >
              <span className="text-newTon-700 underline">
                termos e condições
              </span>
            </RetainQueryLinkDefault>
            .
          </p>
        </>
      )
    }
  }
}
