import { useEffect, useState } from 'react'

import useCROTon032 from '@/services/amplitude/experiments/useCROTon032'
import { FooterProps, MenuItem } from '@/types/cms'

import { Columns } from './components/Columns'
import Logos from './components/Logos'
import Products from './components/Products'
import Stoneco from './components/Stoneco'

interface Props {
  footer: FooterProps
}

export function Footer({ footer: footerFromCMS }: Props) {
  const { variantKey: variantCRO032Loaded, variants } = useCROTon032()

  // TODO: remover esse estado após CRO-032
  const [footer, setFooter] = useState<FooterProps>(footerFromCMS)

  // TODO: remover este useEffect após CRO-032
  useEffect(() => {
    if ([undefined, variants.CONTROL].includes(variantCRO032Loaded)) {
      return
    }

    const FOOTER_COLUMN_TO_INSERT = 'Ton'

    const columnItemTon = footerFromCMS.columns.find(
      (column) => column.title === FOOTER_COLUMN_TO_INSERT
    )

    if (!columnItemTon) {
      return
    }

    const columnItems = columnItemTon.menu_items_groups[0]

    if (!columnItems) {
      return
    }

    const secondColumn = columnItems.menu_items[1]
    const newColumn: MenuItem = {
      ...secondColumn,
      title: 'Familhão do Ton',
      link: {
        href: '/familhao-do-ton'
      }
    }

    const newColumnItemTon = [
      ...columnItems.menu_items.slice(0, 1), // insere o primeiro item
      newColumn, // insere o novo item
      ...columnItems.menu_items.slice(1) // insere os demais itens
    ]

    setFooter({
      ...footerFromCMS,
      columns: [
        ...footerFromCMS.columns.map((column) =>
          column.title === FOOTER_COLUMN_TO_INSERT
            ? {
                ...column,
                menu_items_groups: [
                  {
                    ...columnItems,
                    menu_items: newColumnItemTon
                  }
                ]
              }
            : column
        )
      ]
    })
  }, [footerFromCMS, variantCRO032Loaded, variants])

  return (
    <footer
      id="Context_footer"
      className="py-32 md:py-40 px-24 w-full bg-display-900"
    >
      <div className="mx-auto max-w-screen-xl">
        <Logos />

        <Columns
          columns={footer.columns}
          infoColumn={footer.infoColumn}
          awardsColumn={footer.awardsColumn}
        />

        <Stoneco />

        <Products />
      </div>
    </footer>
  )
}
