import { useEffect, useState } from 'react'

type ViewportProps = {
  width: number
  height: number
  device: 'mobile' | 'tablet' | 'desktop'
}

const useViewport = () => {
  const getDeviceName = (width: number) => {
    return width < 768 ? 'mobile' : width < 1280 ? 'tablet' : 'desktop'
  }

  const [viewport, setViewport] = useState<ViewportProps>({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
    device: getDeviceName(typeof window !== 'undefined' ? window.innerWidth : 0)
  })

  useEffect(() => {
    const handleWindowResize = () => {
      const currentWidth = typeof window !== 'undefined' ? window.innerWidth : 0
      const currentHeight =
        typeof window !== 'undefined' ? window.innerHeight : 0

      setViewport({
        width: currentWidth,
        height: currentHeight,
        device: getDeviceName(currentWidth)
      })
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  return viewport
}

export default useViewport
