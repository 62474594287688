import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from '@/components/Icon'
import { IconTitleAndDescriptionLinkV2Props } from '@/types/cms'
import { handleLinkWithParamsFromCMS } from '@/utils/handleLinkWithParamsFromCMS'

interface ReasonsProps {
  referId: string
  reasons: IconTitleAndDescriptionLinkV2Props[]
}

export function Reasons({ referId, reasons }: ReasonsProps) {
  const router = useRouter()

  return (
    <section
      id={referId}
      className="flex flex-col gap-40 justify-center items-center md:items-stretch lg:items-center bg-display-100 md:bg-display-0 rounded-b-md"
    >
      <div className="flex flex-wrap md:flex-nowrap gap-16 xl:gap-40 md:justify-evenly m-16 md:m-24 lg:mb-40 max-w-[512px] md:max-w-[1280px]">
        {reasons.map(({ title, description, iconName, linkHref }, index) => (
          <Link
            key={`card-${index}`}
            href={handleLinkWithParamsFromCMS({
              linkHref,
              routeQuery: router.query
            })}
            className="flex gap-12 lg:gap-16 even:ml-[-8px] md:!ml-0 w-[calc(50%-4px)] md:w-auto lg:max-w-[290px]"
          >
            <div className="w-40 md:w-[48px] lg:w-[72px] min-w-[40px] md:min-w-[48px] lg:min-w-[72px] h-40 md:h-[48px] lg:h-[72px] min-h-[40px] md:min-h-[48px] lg:min-h-[72px] bg-white md:bg-display-100 rounded-[8px] lg:rounded-[16px]">
              <Icon name={iconName} className="p-8 lg:p-16 w-full h-full" />
            </div>
            <div className="flex flex-col lg:self-center md:min-w-[108px]">
              <p className="font-bold text-newTon-700 lg:whitespace-nowrap paragraph-14 lg:paragraph-16">
                {title}
              </p>
              <p className=" text-display-700 paragraph-12 lg:paragraph-14">
                {description}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
