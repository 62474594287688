import Icon from '@/components/Icon'

type TLoading = {
  isLoading: boolean
  children: React.ReactNode
}

const Loading: React.FC<TLoading> = ({ isLoading, children }) => {
  return (
    <span
      data-testid="loading"
      className="flex gap-8 justify-center items-center w-full"
    >
      {isLoading ? (
        <Icon name="signal-loader" className="animate-spin fill-current" />
      ) : (
        children
      )}
    </span>
  )
}

export default Loading
