import React, { useState } from 'react'

import Icon, { IconsNames } from '@/components/Icon'
import cn from '@/utils/cn'

export type MenuWithSubMenuProps = {
  id?: string
  text: string
  iconName: IconsNames
  children: React.ReactNode
}

const MenuWithSubMenu: React.FC<MenuWithSubMenuProps> = ({
  id = '',
  text,
  iconName,
  children
}) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false)

  return (
    <div
      id={id}
      className={cn('w-full', subMenuIsOpen ? 'bg-display-50' : 'bg-display-0')}
    >
      <button
        type="button"
        aria-label={text}
        onClick={() => setSubMenuIsOpen((value) => !value)}
        className="flex p-16 my-auto w-full font-medium text-display-900 paragraph-16"
      >
        {iconName && <Icon name={iconName} className="mr-12 fill-current" />}
        {text}
        <Icon
          name="chevron-down"
          className={cn(
            'fill-current ml-auto transform transition-all',
            subMenuIsOpen && 'rotate-180'
          )}
        />
      </button>
      <div
        className={cn(
          'flex overflow-hidden flex-col pl-16 bg-display-0',
          subMenuIsOpen
            ? 'opacity-100 max-h-[1000px] duration-[600ms] ease-in'
            : 'opacity-0 max-h-0 duration-500 ease-out'
        )}
      >
        {children}
        <hr className="ml-[-16px] bg-display-200" />
      </div>
    </div>
  )
}

export default MenuWithSubMenu
