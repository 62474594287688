import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

import Icon, { IconsNames } from '@/components/Icon'
import { useQRCodeDrawer } from '@/contexts/qrcodeDrawer'
import cn from '@/utils/cn'
import isMobileDevice from '@/utils/isMobileDevice'

import {
  CMSTrackOnClickProps,
  CMSTrackOnClickProvider
} from '../CMSTrackOnClickProvider'
import Loading from './components/Loading'

const button = tv({
  base: 'btn w-full md:w-auto font-semibold whitespace-nowrap',
  variants: {
    color: {
      primary: 'btn-primary',
      secondary: 'btn-secondary'
    },
    size: {
      small: 'btn-small',
      regular: 'btn-regular',
      large: 'btn-large'
    }
  },
  defaultVariants: {
    size: 'large',
    color: 'primary'
  }
})

const link = tv({
  base: 'flex gap-4 md:p-0 px-16 pb-8 w-max max-w-[620px] md:max-w-none text-[16px] font-medium leading-[24px] paragraph-16',
  variants: {
    color: {
      primary: 'text-newTon-700',
      secondary: 'text-newTon-300'
    }
  },
  defaultVariants: {
    color: 'primary'
  }
})

export type ButtonV2Props = {
  /* CMS props */
  label?: string
  color?: string
  size?: string
  linkPathname?: string
  linkTarget?: string
  linkQuery?: string
  linkHash?: string
  linkHref?: string
  iconName?: IconsNames
  iconPosition?: 'left' | 'right'
  handleAppDownload?: boolean
  uiStyleVariant?: 'link' | 'button'

  /* Internal props */
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  preFetch?: boolean
  disabled?: boolean
  className?: string
  onClick?: () => void
  children?: React.ReactNode
} & CMSTrackOnClickProps &
  VariantProps<typeof button>

function ButtonContent({
  isLoading,
  iconName,
  iconPosition,
  label,
  children
}: ButtonV2Props) {
  return (
    <Loading isLoading={!!isLoading}>
      {iconName && iconPosition === 'left' && (
        <Icon name={iconName} className="fill-current" />
      )}
      {label}
      {children}
      {iconName && iconPosition === 'right' && (
        <Icon name={iconName} className="fill-current" />
      )}
    </Loading>
  )
}

export function Button(props: ButtonV2Props) {
  const {
    color,
    size,
    type = 'button',
    preFetch = false,
    className = '',
    linkPathname,
    linkQuery,
    linkHash,
    linkHref,
    linkTarget,
    isLoading,
    disabled,
    handleAppDownload = false,
    uiStyleVariant = 'button',
    trackOnClick,
    experiment,
    onClick
  } = props

  const router = useRouter()

  const [isDevice, setIsDevice] = useState(false)
  const [queryParamsFromCms, setQueryParamsFromCms] = useState<
    Record<string, string>
  >({})

  const { toggleDrawer, linkToDownloadApp } = useQRCodeDrawer()

  useEffect(() => {
    const params = new URLSearchParams(linkQuery)
    const paramsObject = {} as Record<string, string>
    for (const [key, value] of params.entries()) {
      if (key !== 'null') {
        paramsObject[key] = value
      }
    }

    setQueryParamsFromCms(paramsObject)
  }, [linkQuery])

  useEffect(() => {
    setIsDevice(isMobileDevice())
  }, [])

  return (handleAppDownload && isDevice) ||
    linkPathname ||
    linkHash ||
    linkQuery ||
    linkHref ? (
    <CMSTrackOnClickProvider
      trackOnClick={trackOnClick}
      experiment={experiment}
    >
      <Link
        href={
          (handleAppDownload && linkToDownloadApp) ||
          linkHref || {
            pathname: linkPathname,
            query: { ...router.query, ...queryParamsFromCms },
            hash: linkHash
          }
        }
        target={linkTarget}
        prefetch={preFetch}
        className={cn(
          {
            [button({ color, size })]: uiStyleVariant === 'button',
            [link({ color })]: uiStyleVariant === 'link'
          },
          className
        )}
      >
        <ButtonContent {...props} />
      </Link>
    </CMSTrackOnClickProvider>
  ) : (
    <CMSTrackOnClickProvider
      trackOnClick={trackOnClick}
      experiment={experiment}
    >
      <button
        type={type}
        disabled={disabled || isLoading}
        className={cn({
          [button({ color, size, className })]: uiStyleVariant === 'button',
          [link({ color, className })]: uiStyleVariant === 'link'
        })}
        onClick={handleAppDownload ? toggleDrawer : onClick}
      >
        <ButtonContent {...props} />
      </button>
    </CMSTrackOnClickProvider>
  )
}

export default Button
