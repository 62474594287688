import { ReactNode } from 'react'
import { tv } from 'tailwind-variants'

export interface HeroRootProps {
  children: ReactNode
  className?: string
  background?: 'default' | 'gradient' | 'custom'
}

const root = tv({
  base: 'px-16 py-24 md:px-24 md:py-40 rounded-b-16 lg:rounded-b-24',
  variants: {
    background: {
      default: 'bg-newTon-400  text-white',
      gradient: 'gradient-ton-1',
      custom: 'bg-display-900 text-white'
    }
  }
})

export const HeroRoot = ({
  children,
  className,
  background
}: HeroRootProps) => {
  return (
    <section className={root({ className, background })}>{children}</section>
  )
}
