import { Context, useCountdownsContext } from '@/contexts/countdowns'

export enum PromotionsTypes {
  NONE = '',
  PARCEL = 'promo-parcel',
  ZERO = 'promo-zero',
  COMBO = 'promo-combo',
  TWENTY = 'promo-20',
  TWENTY_FIVE = 'promo-25',
  UNTIL_35 = 'promo-until-35',
  BLACK_FRIDAY = 'promo-black-friday'
}

export function getActivePromotions(
  activeCountdowns: Context['activeCountdowns']
) {
  return function inner_getActivePromotions() {
    const isPromo20 = activeCountdowns.has(PromotionsTypes.TWENTY)
    const isPromo25 = activeCountdowns.has(PromotionsTypes.TWENTY_FIVE)
    const isPromoZero = activeCountdowns.has(PromotionsTypes.ZERO)
    const isPromoCombo = activeCountdowns.has(PromotionsTypes.COMBO)
    const isPromoParcel = activeCountdowns.has(PromotionsTypes.PARCEL)
    const isPromoUntil35 = activeCountdowns.has(PromotionsTypes.UNTIL_35)
    const isPromoBlackFriday = activeCountdowns.has(
      PromotionsTypes.BLACK_FRIDAY
    )

    return {
      isPromo20,
      isPromo25,
      isPromoZero,
      isPromoCombo,
      isPromoParcel,
      isPromoUntil35,
      isPromoBlackFriday
    }
  }
}

export function getFirstActivePromotion(
  activeCountdowns: Context['activeCountdowns']
) {
  return function inner_getFirstActivePromotion() {
    const {
      isPromo20,
      isPromo25,
      isPromoZero,
      isPromoCombo,
      isPromoParcel,
      isPromoUntil35,
      isPromoBlackFriday
    } = getActivePromotions(activeCountdowns)()

    switch (true) {
      case isPromo20: {
        return PromotionsTypes.TWENTY
      }

      case isPromo25: {
        return PromotionsTypes.TWENTY_FIVE
      }

      case isPromoUntil35: {
        return PromotionsTypes.UNTIL_35
      }

      case isPromoCombo: {
        return PromotionsTypes.COMBO
      }

      case isPromoZero: {
        return PromotionsTypes.ZERO
      }

      case isPromoParcel: {
        return PromotionsTypes.PARCEL
      }

      case isPromoBlackFriday: {
        return PromotionsTypes.BLACK_FRIDAY
      }

      default: {
        return PromotionsTypes.NONE
      }
    }
  }
}

export function usePromotions() {
  const { activeCountdowns } = useCountdownsContext()

  return {
    getActivePromotions: getActivePromotions(activeCountdowns),
    getFirstActivePromotion: getFirstActivePromotion(activeCountdowns)
  }
}
