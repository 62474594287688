/* eslint-disable @next/next/no-html-link-for-pages */
import { FooterSprite } from '@/components/IconSprite'

const Logos = () => (
  <div className="flex flex-col md:flex-row md:items-center mx-auto mt-8 mb-28 w-full h-auto">
    <a aria-label="ton" href="/" className="w-[63px] h-48">
      <FooterSprite name="logoTon_green" className="w-full h-full" />
    </a>
    <div className="flex flex-1 md:justify-end items-center pt-32">
      <a
        aria-label="facebook"
        className="mr-32"
        href="https://www.facebook.com/seliganoton/"
        rel="noreferrer"
        target="_blank"
      >
        <FooterSprite name="logo-facebook" className="w-24 h-24" />
      </a>
      <a
        aria-label="twitter"
        className="mr-32"
        href="https://twitter.com/seliganoton"
        rel="noreferrer"
        target="_blank"
      >
        <FooterSprite name="logo-twitter" className="w-24 h-24" />
      </a>
      <a
        aria-label="instagram"
        className="mr-32"
        href="https://www.instagram.com/seliganoton/"
        rel="noreferrer"
        target="_blank"
      >
        <FooterSprite name="logo-instagram" className="w-24 h-24" />
      </a>
      <a
        aria-label="youtube"
        href="https://www.youtube.com/channel/UCo2OofOde1YZrDGRTqNdBVA/featured?view_as=subscriber"
        rel="noreferrer"
        target="_blank"
      >
        <FooterSprite name="logo-youtube" className="w-24 h-24" />
      </a>
    </div>
  </div>
)

export default Logos
