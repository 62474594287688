import React from 'react'

import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import { AllPlansForMachineType } from '@/types/plans'

import { MODAL_PLANS_INFORMATIONS } from './resources/modalInformations'

interface ModalPlansInformationsProps {
  planName: AllPlansForMachineType
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ModalPlansInformations = ({
  planName,
  isOpen,
  setIsOpen
}: ModalPlansInformationsProps) => {
  return (
    <Modal
      open={isOpen}
      closeOnClickOut={true}
      onCloseClick={() => setIsOpen(false)}
      className="flex items-end md:items-center !p-0 w-full md:max-w-[618px] h-full bg-transparent rounded-b-none"
      classNameContainer="z-[101]"
    >
      <div className="flex overflow-y-auto relative flex-col gap-8 p-24 md:p-40 pb-40 w-full h-auto max-h-[400px] md:max-h-full bg-white md:rounded-[16px] rounded-t-24 rounded-b-none">
        {MODAL_PLANS_INFORMATIONS[planName] && (
          <>
            <div className="flex gap-24 items-start">
              <p className="font-bold leading-5 text-display-900 ton-paragraph-20">
                {MODAL_PLANS_INFORMATIONS[planName]?.title}
              </p>

              <button
                className="ml-auto cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                <Icon name="close" className="ml-auto w-24 h-24 fill-current" />
              </button>
            </div>

            <div className="flex flex-col gap-12">
              {MODAL_PLANS_INFORMATIONS[planName]?.content()}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
