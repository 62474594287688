export const HalfStart: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 1.44507L8.045 4.57507L11.5 5.08007L9 7.51507L9.59 10.9551L6.5 9.33007L3.41 10.9551L4 7.51507L1.5 5.08007L4.955 4.57507L6.5 1.44507Z"
        stroke="#00CC2C"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.625 5.32007L5 4.57007L6.5 1.94507H6.875V9.07007L3.5 10.9451L3.875 7.57007L1.625 5.32007Z"
        fill="#00CC2C"
      />
    </svg>
  )
}
