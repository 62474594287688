import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import { CLOUDINARY_BASE_URL } from '@/constants/links'
import { ImageCMSProps } from '@/types/cms'
import cn from '@/utils/cn'
import { datadogRum } from '@datadog/browser-rum'

import Button, { ButtonV2Props } from '../ButtonV2'
import styles from './VideoSteps.module.css'

interface VideoStepsProps {
  referId: string
  title: string
  labelTime: {
    label: string
    frameStart: number
    frameStop: number
  }[]
  machineCardImage: ImageCMSProps
  mobileEdgeImage: ImageCMSProps
  videoUrl: string
  button: ButtonV2Props
}

export function VideoSteps({
  referId,
  button,
  labelTime,
  machineCardImage,
  mobileEdgeImage,
  title,
  videoUrl
}: VideoStepsProps) {
  const [stepNumber, setStepNumber] = useState(0)
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true
  })

  const videoRef = useRef<HTMLVideoElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const step = useMemo(() => labelTime[stepNumber], [labelTime, stepNumber])

  useEffect(() => {
    if (videoRef.current && inView) {
      const { currentTime } = videoRef.current

      const time = currentTime - step.frameStart
      const isNextFrame = time > 0 && time < 1

      if (!isNextFrame) {
        videoRef.current.currentTime = step.frameStart
      }

      videoRef.current.play().catch((err) => {
        datadogRum.addError(err, { component: 'VideoSteps' })
      })
    }
  }, [inView, step])

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeStep = document.getElementsByClassName(
        styles.active
      )[0] as HTMLDivElement

      scrollContainerRef.current.scrollTo({
        left: activeStep.offsetLeft - 24,
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [step, scrollContainerRef])

  return (
    <div
      id={referId}
      className="flex overflow-hidden flex-col gap-24 md:gap-40 py-40 md:py-80 px-24 md:px-40 text-display-0 bg-display-900"
    >
      <h2 className="text-center" dangerouslySetInnerHTML={{ __html: title }} />

      <div className="flex flex-col-reverse md:flex-row gap-40 md:justify-center items-center">
        <div className="flex flex-col gap-40 w-full md:max-w-[400px]">
          <div ref={scrollContainerRef} className={styles.listContainer}>
            {labelTime.map(({ label }, index) => (
              <div
                key={`step-${index}`}
                onClick={() => setStepNumber(index)}
                className={cn(
                  'cursor-pointer',
                  stepNumber === index && styles.active
                )}
              >
                <MarkdownContent content={label} className="paragraph-20" />
              </div>
            ))}
          </div>
          <div>
            <Button {...button} />
          </div>
        </div>
        <div className="relative max-w-[161px] md:max-w-none" ref={ref}>
          <div
            className={cn(
              'absolute top-[110px] left-[40px] w-[280px] md:w-[352px]',
              stepNumber === 2
                ? 'opacity-100 translate-x-0 translate-y-0 duration-[9000ms] block ease-out'
                : 'opacity-0 translate-x-full -translate-y-1/2 duration-[1800ms] delay-1000 hidden ease-in-out'
            )}
          >
            <div className="flex !w-[215px] md:!w-[352px]">
              <picture>
                <source
                  media="(min-width: 768px)"
                  srcSet={`${CLOUDINARY_BASE_URL}/w_352/${machineCardImage.path}.webp`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 360px)"
                  srcSet={`${CLOUDINARY_BASE_URL}/w_215/${machineCardImage.path}.webp`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 0px)"
                  srcSet={`${CLOUDINARY_BASE_URL}/w_1,q_1/${machineCardImage.path}.webp`}
                  type="image/webp"
                />
                <img
                  src={`${CLOUDINARY_BASE_URL}/${machineCardImage.path}.png`}
                  alt={machineCardImage.alternativeText}
                  className="object-fill"
                />
              </picture>
            </div>
          </div>

          <picture className="relative z-10">
            <source
              media="(min-width: 768px)"
              srcSet={`${CLOUDINARY_BASE_URL}/h_560,w_264/${mobileEdgeImage.path}.webp`}
              type="image/webp"
            />
            <source
              media="(min-width: 768px)"
              srcSet={`${CLOUDINARY_BASE_URL}/h_560,w_264/${mobileEdgeImage.path}.png`}
              type="image/png"
            />
            <source
              media="(min-width: 360px)"
              srcSet={`${CLOUDINARY_BASE_URL}/w_180/${mobileEdgeImage.path}.webp`}
              type="image/webp"
            />
            <source
              media="(min-width: 0px)"
              srcSet={`${CLOUDINARY_BASE_URL}/w_1,q_1/${mobileEdgeImage.path}.webp`}
              type="image/webp"
            />
            <img
              src={`${CLOUDINARY_BASE_URL}/h_560,w_264/${mobileEdgeImage.path}.webp`}
              alt={mobileEdgeImage.alternativeText}
            />
          </picture>
          <div className="overflow-hidden absolute top-[29px] md:top-[48px] right-[8px] md:right-[16px] left-[8px] md:left-[14px] z-10 rounded-large md:rounded-xlarge border border-display-400">
            <video
              preload="auto"
              muted
              playsInline
              onEnded={(e) => {
                e.currentTarget.currentTime = 0
                e.currentTarget.play()
                setStepNumber(0)
              }}
              onTimeUpdate={(e) => {
                const { currentTime } = e.currentTarget

                if (currentTime >= step.frameStop && currentTime !== 0) {
                  setStepNumber((step) =>
                    stepNumber < labelTime.length - 1 ? step + 1 : step
                  )
                }
              }}
              id={`${referId}-video`}
              ref={videoRef}
              src={videoUrl}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoSteps
