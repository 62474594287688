/* eslint-disable @typescript-eslint/no-explicit-any */
import AMPLITUDE from '@/constants/amplitude'
import { getCrossLeadId } from '@/utils/getCrossLeadId'
import { getUserCurrentPath } from '@/utils/getCurrentPath'
import { getGaClientId } from '@/utils/getGaClientId'
import { getUserAgent } from '@/utils/getUserAgent'
import { getUserDevice } from '@/utils/getUserDevice'
import { getUserPlatform } from '@/utils/getUserPlatform'
import { getUserSourcePath } from '@/utils/getUserSourcePath'
import { ValueOf } from '@/utils/ValueOf'

import { checkDuplicated } from './checkDuplicated'
import experimentFetcher from './experimentFetcher'
import { getDeviceId } from './getDeviceId'
import { getEventId } from './getEventId'
import { getInsertId } from './getInsertId'
import { getSessionId } from './getSessionId'
import getUserId from './getUserId'

export interface CommonEventProperties {
  variant_id?: unknown
  cross_lead_id?: string
  ga_client_id?: string
  client_id?: string
  category?: string
  user_device?: string
  user_agent?: string
  source?: string
}

interface EventType<T extends CommonEventProperties> {
  user_id: string
  session_id: string | number
  event_id: string | number
  device_id: string
  platform: string
  event_type: string
  insert_id: string
  event_properties: T
}

export type AmplitudeTestKey = ValueOf<typeof AMPLITUDE.TESTS>

export async function sendEvent<T extends CommonEventProperties>(
  experimentId: AmplitudeTestKey | undefined,
  eventType: string,
  eventProperties: T & { variant_id?: unknown }
) {
  const clientId = getGaClientId()
  const commonProperties: CommonEventProperties = {
    cross_lead_id: getCrossLeadId(),
    ga_client_id: clientId,
    client_id: clientId,
    source: getUserSourcePath(),
    category: getUserCurrentPath(),
    user_device: getUserDevice(),
    user_agent: getUserAgent()
  }
  const eventDeduplicationParam = getInsertId(eventType)
  if (checkDuplicated(eventDeduplicationParam)) return

  if (experimentId && !eventProperties?.variant_id) {
    const resExp: any = await experimentFetcher(experimentId).then(
      (res) => res.data
    )

    eventProperties.variant_id = resExp[experimentId]?.key
  }

  const events: EventType<T>[] = [
    {
      user_id: getUserId(),
      platform: getUserPlatform(),
      session_id: getSessionId(),
      device_id: getDeviceId(),
      event_id: getEventId(),
      event_type: eventType,
      insert_id: eventDeduplicationParam,
      event_properties: { ...commonProperties, ...eventProperties }
    }
  ]

  return await fetch('https://api.amplitude.com/2/httpapi', {
    method: 'POST',
    signal: AbortSignal.timeout(5000),
    body: {
      // @ts-ignore
      api_key: AMPLITUDE.KEYS.AMPLITUDE_ANALYTICS_API_KEY,
      client_upload_time: new Date(),
      events
    }
  })
}

export async function trackEvent<T extends CommonEventProperties>(
  experimentId: AmplitudeTestKey | undefined,
  eventType: string,
  eventProperties: T
) {
  return await sendEvent(experimentId, eventType, eventProperties)
}
