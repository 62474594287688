import { useContext } from 'react'

import CloudinaryImage from '@/components/CloudinaryImage'
import Features from '@/components/MachineCardV2/components/Features'
import PlansSelectHeader from '@/components/MachinesV2/components/PlansSelectHeader'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import { AmplitudeContext } from '@/contexts/amplitude'
import { useQRCodeDrawer } from '@/contexts/qrcodeDrawer'
import { MachineResourceFeature } from '@/types/machines'
import { AllPlansForSmartphone } from '@/types/plans'

const TapTonMachine = () => {
  const { analytics } = useContext(AmplitudeContext)
  const { toggleTaptonDrawer } = useQRCodeDrawer()

  const tapTonFeatures = [
    {
      className: 'text-newTon-700',
      icon: 'logos-new',
      text: 'Venda na hora pelo App Ton'
    },
    {
      icon: 'card-credit',
      text: 'Parcele em até 12x'
    },
    {
      icon: 'signal-contactless',
      text: 'Aceite débito e crédito por aproximação (NFC)'
    },
    {
      icon: 'message-r-chat',
      text: 'Comprovante por SMS'
    },
    {
      icon: 'dollar-phone',
      text: 'Venda pelo App com Link, Pix e Boleto'
    }
  ] as MachineResourceFeature[]

  return (
    <>
      <PlansSelectHeader />
      <div className="p-24 md:py-40 mx-auto w-full max-w-[512px] md:max-w-[1280px]">
        <div className="mx-auto text-center">
          <h3 className="pb-8 font-heading text-3xl leading-7">
            Aceite cartão no seu celular
          </h3>
          <span>
            <p className="text-sm leading-3">
              Você pode vender com TapTon e lucrar ainda mais usando também a
              maquininha.
              <br />
              Seu celular precisa ter conexão NFC. Disponível para Android (10.0
              ou superior) e iOS (iPhone XS ou superior / iOS 17 ou superior)
            </p>
          </span>
        </div>

        <div
          className="flex relative flex-col gap-24 p-24 mx-auto mt-[72px] w-[308px] rounded-md"
          style={{ boxShadow: '0px 4px 16px rgba(103, 116, 129, 0.15)' }}
        >
          <div className="flex relative items-end rounded rounded-b-none">
            <div className="absolute top-[-60px] sm:top-[-50px] left-0 max-w-[141px] sm:max-w-[172px] max-h-[176px] sm:max-h-[220px]">
              <div className="w-[130px] h-[150px]">
                <CloudinaryImage
                  src="v1/site-ton/taptonplanos"
                  alt="Icone Taxa Pix Qr Code"
                  width={130}
                  height={150}
                />
              </div>
            </div>

            <div className="flex justify-end ml-auto">
              <CloudinaryImage
                src="v1/site-ton/badge-venda-pelo-celular-new"
                alt="Icone representando a feature Venda Pelo Celular"
                className="max-w-full h-auto rounded-full"
                width={96}
                height={96}
              />
            </div>
          </div>
          <div className="flex flex-col gap-8 w-full">
            <h2 className="text-6xl leading-9 text-left">TapTon</h2>
            <div className="flex flex-row gap-16 h-full">
              <h2 className="text-6xl leading-9 text-left text-newTon-700">
                Grátis
              </h2>
              <p className="pt-4 text-sm leading-3">
                Baixe o App e comece a vender{' '}
              </p>
            </div>
            <div
              className="mt-8"
              onClick={async () => {
                if (!window) return

                analytics?.track({
                  event_type: `choose prod ton`,
                  event_properties: {
                    name: `choose prod ton`,
                    description: `Evento disparado quando usuario clicar no cta de tapton`,
                    section_reference: `Aceite cartão no seu celular`,
                    cta_reference: `Venda pelo celular agora`
                  }
                })
              }}
            >
              <>
                <button
                  type="button"
                  className="hidden lg:block w-full font-bold btn btn-primary btn-large"
                  onClick={toggleTaptonDrawer}
                >
                  Venda pelo celular agora
                </button>
                <RetainQueryLink
                  href={{
                    pathname: 'https://app.adjust.com/15faft9j',
                    query: {
                      campaing: 'ton',
                      adgroup: 'teste_carrossel_planos_tapton_end'
                    }
                  }}
                  className="flex lg:hidden items-center pt-[14] w-full font-bold btn btn-primary btn-large"
                >
                  Venda pelo celular agora
                </RetainQueryLink>
              </>
            </div>
          </div>

          <Features
            machine={AllPlansForSmartphone.Tapton}
            features={tapTonFeatures}
          />
        </div>
      </div>
    </>
  )
}

export default TapTonMachine
