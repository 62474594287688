import React from 'react'

import Icon, { IconsNames } from '@/components/Icon'
import { MachineResourceFeature } from '@/types/machines'

export type FeaturesProps = {
  machine: string
  features?: MachineResourceFeature[]
}

const Features: React.FC<FeaturesProps> = ({ machine, features }) => {
  return (
    <ul className="flex flex-col">
      {features?.map(
        (feature, index) =>
          feature.text && (
            <li
              className="flex items-center mt-16 first:mt-0 list-none"
              key={`${machine}${feature.text}`}
            >
              <div className="min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px]">
                <Icon
                  name={feature.icon as IconsNames}
                  className={`fill-current ${feature.className}`}
                />
              </div>
              <p
                className={`ml-8 font-medium paragraph-14 ${
                  feature.className || ''
                } ${index === 0 ? '!font-bold' : ''}`}
              >
                {feature.text}
              </p>
            </li>
          )
      )}
    </ul>
  )
}

export default Features
