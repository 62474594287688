import React, { useState } from 'react'

import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import Icon from '@/components/Icon'
import cn from '@/utils/cn'

import styles from './FaqItem.module.css'
import { FaqItemProps } from './FaqItem.types'

export function FaqItem({ title, text }: FaqItemProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <li
      className="flex flex-col p-16 lg:p-24 first:mt-0 max-w-[840px] list-none bg-display-0 rounded-md border border-display-300 cursor-pointer"
      onClick={toggleOpen}
    >
      <div className="flex justify-between">
        <h3 className="font-default text-[16px] lg:text-[20px] font-bold leading-[26px] lg:leading-[28px]">
          {title}
        </h3>

        <div
          className={cn(
            'ml-12 h-24',
            isOpen
              ? 'rotate-0 duration-[600ms] ease-in-out'
              : 'rotate-180 duration-[400ms] ease-in-out'
          )}
        >
          <Icon name="chevron-up" />
        </div>
      </div>

      <div
        className={cn(
          styles.FaqItemText,
          isOpen
            ? 'opacity-100 max-h-[1000px] duration-[600ms] ease-in'
            : 'opacity-0 max-h-0 duration-500 ease-out'
        )}
      >
        <MarkdownContent content={text} className="flex flex-col gap-8 mt-8" />
      </div>
    </li>
  )
}
