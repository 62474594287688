export function getCookiesAsObject() {
  const cookiesAsObject = {} as { [key: string]: string }

  try {
    const cookiesStringFromDocument = document.cookie

    if (!cookiesStringFromDocument) {
      throw new Error('No cookies found')
    }

    cookiesStringFromDocument.split(';').forEach(function (el) {
      const splitCookie = el.split('=')
      const key = splitCookie[0].trim()
      const value = splitCookie[1]

      cookiesAsObject[key] = value
    })
  } catch (err) {}

  return cookiesAsObject
}
