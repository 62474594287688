import Icon from '@/components/Icon'
import { IconAndDescriptionV2Props, ImageCMSProps } from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'

interface OverviewPanelProps {
  id: number
  referId?: string
  title?: string
  iconsAndDescriptions?: IconAndDescriptionV2Props[]
  label?: string
  buttons?: ButtonV2Props[]
  image?: ImageCMSProps
}

export const OverviewPanel = ({
  referId,
  title,
  iconsAndDescriptions,
  label,
  buttons,
  image
}: OverviewPanelProps) => {
  return (
    <div
      id={referId}
      className="flex flex-col gap-16 py-40 px-16 md:px-0 mx-auto max-w-[455px] md:max-w-[578px] xl:max-w-[1170px]"
    >
      <div className="flex flex-col xl:flex-row gap-24 xl:gap-40 xl:items-center p-24 xl:py-40 xl:px-[56px] bg-display-0 rounded-24 xl:rounded-40 border border-newTon-300">
        <div className="flex flex-col gap-16 xl:gap-24 xl:max-w-[563px]">
          {title && (
            <h2
              className="text-center xl:text-left"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          {iconsAndDescriptions && (
            <div className="flex flex-col xl:flex-row xl:flex-wrap gap-16 justify-between">
              {iconsAndDescriptions?.map(({ iconName, description }) => (
                <div
                  key={iconName}
                  className="flex gap-8 items-center xl:max-w-[270px]"
                >
                  <div className="w-24 h-24">
                    <Icon
                      name={iconName}
                      className="text-newTon-600 fill-current"
                    />
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: description }}
                    className="font-medium ton-paragraph-16"
                  />
                </div>
              ))}
            </div>
          )}

          <hr />

          <div className="flex flex-col md:flex-row gap-16 md:justify-center xl:justify-start md:items-center">
            {label && (
              <p
                className="font-bold text-center ton-paragraph-20"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}

            {buttons?.map((props) => (
              <Button key={props.label} {...props} />
            ))}
          </div>
        </div>

        {image && (
          <div className="w-full xl:max-w-[455px]">
            <CloudinaryImage
              width={image.width}
              height={image.height}
              src={image.path}
              alt={image.alternativeText}
              className="object-cover max-w-full h-auto rounded-16 md:rounded-24"
              sizes="100vw"
            />
          </div>
        )}
      </div>
    </div>
  )
}
