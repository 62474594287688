import { DefaultTagProps } from '@/cmsComponents/DefaultTag'
import { BoardItensBlockProps } from '@/types/cms'
import { Hero } from '../Hero'

interface FeeStatementProps {
  referId?: string
  defaultTag?: DefaultTagProps
  title: string
  description: string
  boardTitle: string
  boardItens?: BoardItensBlockProps[]
  boardCaption: string
  regulationDescription: string
}

export function FeeStatement({
  referId,
  defaultTag,
  title,
  description,
  boardTitle,
  boardItens,
  boardCaption,
  regulationDescription
}: FeeStatementProps) {
  return (
    <div
      id={referId}
      className="flex flex-col justify-center items-center px-24 my-24 md:mx-auto lg:mt-0 md:max-w-[720px]"
    >
      {defaultTag && (
        <div className="flex flex-row justify-center w-full">
          <Hero.Tag
            variant={defaultTag.variant}
            direction={defaultTag.direction}
            label={defaultTag.label}
          />
        </div>
      )}

      <div className="pt-16 max-w-[400px] md:max-w-2xl md:text-center">
        <h2
          className="max-w-full font-heading font-bold text-center break-words ton-paragraph-32"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      {description && (
        <p className="py-16 max-w-[400px] md:max-w-3xl text-base text-center ton-paragraph-16">
          {description}
        </p>
      )}

      <div className="w-full md:max-w-[768px] h-[146px] rounded-16 border-2">
        <div className="w-full h-[36px] bg-display-100 rounded-t-16">
          <p className="flex flex-row justify-center items-center h-full text-sm">
            {boardTitle}
          </p>
        </div>
        <div className="flex flex-col justify-center mx-auto w-4/6 md:w-3/6">
          <div className="flex gap-x-2 justify-around">
            {boardItens?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col justify-center items-center mt-16 h-full max-h-[58px] text-sm"
              >
                <div className="font-bold">{item.itemTitle}</div>
                <div
                  className="font-heading font-bold text-newTon-700 ton-paragraph-28"
                  dangerouslySetInnerHTML={{ __html: item.itemInfo }}
                />
              </div>
            ))}
          </div>
        </div>

        <p className="text-12 font-normal text-center text-display-500">
          {boardCaption}
        </p>
      </div>

      <div>
        <div
          className="mt-16 text-base text-center"
          dangerouslySetInnerHTML={{ __html: regulationDescription }}
        />
      </div>
    </div>
  )
}
