import { ColorProps, ImageCMSProps } from '@/types/cms'

import CloudinaryImage from '../../components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'
import cn from '@/utils/cn'

interface PlainBannerProps {
  id: number
  referId?: string
  text?: string
  backgroundColor?: ColorProps
  buttons?: ButtonV2Props[]
  image?: ImageCMSProps
}

export const PlainBanner = ({
  referId,
  image,
  buttons,
  text,
  backgroundColor
}: PlainBannerProps) => {
  const bgColor = backgroundColor?.classColor
    ? `bg-${backgroundColor.classColor}`
    : ''

  return (
    <div id={referId} className={cn(bgColor)}>
      <div className="py-40 md:py-80 px-16 md:px-24 lg:px-0 mx-auto max-w-[412px] md:max-w-[1280px]">
        <div className="flex flex-col md:flex-row gap-24 justify-center">
          <div className="flex flex-col md:flex-row md:gap-8 justify-center md:items-center w-full md:max-w-[455px]">
            {image && (
              <div className="mx-auto w-full max-w-[72px] md:max-w-[80px] h-full max-h-[72px] md:max-h-[80px]">
                <CloudinaryImage
                  width={80}
                  height={80}
                  src={image.path}
                  alt={image.alternativeText}
                  className="object-contain max-w-full h-full"
                />
              </div>
            )}

            {text && (
              <h2
                dangerouslySetInnerHTML={{ __html: text }}
                className="md:max-w-[357px] text-center md:text-left"
              />
            )}
          </div>

          {buttons && (
            <div className="flex flex-col gap-16">
              {buttons.map((props) => (
                <Button key={props.label} {...props} className="md:w-max" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
