import { tv } from 'tailwind-variants'

import Icon from '@/components/Icon'
import { IconTitleAndDescriptionV2Props } from '@/types/cms'

import Button, { ButtonV2Props } from '../ButtonV2'

const styles = tv({
  slots: {
    container: 'relative px-24 w-full',
    cardsAndButtonsWrapper:
      'mx-auto w-full max-w-[455px] flex flex-col gap-24 lg:gap-40 justify-center items-center',
    description: 'pt-8',
    cardsWrapper: '',
    card: 'flex w-full gap-12',
    button: 'font-bold md:btn-large'
  },
  variants: {
    variant: {
      expanded: {
        container: 'py-24 md:py-40 lg:py-80',
        cardsAndButtonsWrapper: 'md:max-w-[1280px]',
        description: 'paragraph-14 lg:paragraph-18',
        cardsWrapper:
          'flex flex-col lg:flex-row gap-24 lg:gap-40 justify-center w-full',
        card: 'lg:gap-16 lg:max-w-[400px]',
        button: '!btn-large'
      },
      compact: {
        container: 'py-40',
        cardsAndButtonsWrapper: 'md:max-w-[1060px]',
        cardsWrapper:
          'flex flex-col gap-24 lg:gap-40 justify-center md:flex-row',
        card: 'flex-col'
      },
      grid: {
        container: 'py-40 lg:py-80',
        cardsAndButtonsWrapper: 'md:max-w-[1280px]',
        cardsWrapper:
          'grid md:flex md:flex-wrap gap-16 lg:gap-24 xl:gap-40 justify-center w-full max-w-[455px] md:max-w-[1280px]',
        card: 'flex lg:gap-16 w-full md:max-w-[360px] lg:max-w-[315px] xl:max-w-[390px]'
      },
      'grid-col': {
        container: 'py-40 lg:py-80',
        cardsAndButtonsWrapper: 'md:max-w-[1280px]',
        cardsWrapper:
          'grid md:flex md:flex-wrap gap-16 lg:gap-24 xl:gap-40 justify-center w-full max-w-[455px] md:max-w-[1280px]',
        card: 'flex flex-col lg:gap-16 w-full md:max-w-[360px] lg:max-w-[315px] xl:max-w-[390px]'
      },
      'grid-cols-2': {
        container: 'py-40 lg:py-80',
        cardsAndButtonsWrapper: 'md:max-w-[1280px]',
        cardsWrapper:
          'grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16 justify-center items-center w-full lg:w-2/3  max-w-[455px] md:max-w-[1280px]',
        card: 'flex flex-row md:flex-col lg:gap-12 w-full md:max-w-[360px] lg:max-w-[315px] xl:max-w-[390px]'
      },
      'grid-cols-2-lg-3': {
        container: 'py-40 lg:py-80',
        cardsAndButtonsWrapper: 'md:max-w-[1060px]',
        cardsWrapper:
          'grid grid-cols-1 md:grid-cols-3 gap-16 md:gap-24 xl:gap-40 justify-center w-full max-w-[455px] md:max-w-[1280px]',
        card: 'flex flex-row md:flex-col lg:gap-16 w-full md:max-w-[360px] lg:max-w-[315px] xl:max-w-[390px]'
      }
    }
  }
})

export interface InfoEnsembleProps {
  referId: string
  title?: string
  description?: string
  buttons: ButtonV2Props[]
  cards: IconTitleAndDescriptionV2Props[]
  variant?: 'compact' | 'expanded' | 'grid' | 'grid-col' | 'grid-cols-2-lg-3'
}

export function InfoEnsemble({
  referId,
  title,
  description,
  cards,
  buttons,
  variant = 'compact'
}: InfoEnsembleProps) {
  const classes = styles({ variant: variant || 'compact' })

  return (
    <div id={referId} className={classes.container()}>
      <div className={classes.cardsAndButtonsWrapper()}>
        <div className="md:max-w-none md:text-center">
          {title && (
            <h2
              className="max-w-full font-heading text-center break-words"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <p
              className={classes.description()}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>

        <div className={classes.cardsWrapper()}>
          {cards.map(({ title, description, iconName }) => (
            <div key={title} className={classes.card()}>
              <div className="p-12 w-[fit-content] h-[min-content] text-newTon-700 bg-newTon-100 rounded-md border-4 border-newTon-50">
                <Icon name={iconName} className="w-24 h-24 fill-current" />
              </div>
              <div className="flex flex-col gap-4">
                <p className="font-bold paragraph-18 lg:paragraph-20">
                  {title}
                </p>
                <p
                  className="paragraph-16"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          ))}
        </div>
        {buttons.map((button, index) => (
          <Button
            key={button.label || index}
            className={classes.button()}
            {...button}
          />
        ))}
      </div>
    </div>
  )
}
