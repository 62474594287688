import Button from '@/cmsComponents/ButtonV2'
import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import { IconsDescriptionsV2Props } from '@/types/cms'

import { ButtonV2Props } from '../ButtonV2'

export interface BannerBGDividedProps {
  referId: string
  title: string
  infos: IconsDescriptionsV2Props[]
  buttonTitle: string
  buttons: ButtonV2Props[]
  image: {
    width: number
    height: number
    path: string
    alternativeText: string
  }
}

export default function BannerBGDivided({
  title,
  infos,
  buttonTitle,
  buttons,
  image
}: BannerBGDividedProps) {
  return (
    <div className="flex relative justify-center py-40 lg:py-80 w-full">
      <div className="flex flex-col lg:flex-row gap-16 md:gap-24 lg:gap-x-40 p-24 lg:py-40 lg:px-[56px] mx-16 md:mx-24 lg:mx-40 xl:mx-0 mt-40 lg:mt-80 max-w-[412px] md:max-w-[1280px] bg-display-0 rounded-[24px] lg:rounded-[40px] border border-newTon-300">
        <div className="flex flex-col gap-16 md:gap-24 w-full lg:max-w-[563px] rounded-[24px] lg:rounded-[40px]">
          <h2
            dangerouslySetInnerHTML={{ __html: title }}
            className="font-heading text-center lg:text-left"
          />
          <div className="flex flex-col md:flex-row md:flex-wrap gap-16 md:gap-x-40 md:gap-y-24">
            {infos.map(({ iconName, description }) => (
              <div className="flex flex-row gap-8 md:w-[calc(50%-20px)]">
                <Icon
                  name={iconName}
                  className="my-auto w-24 text-newTon-600 fill-current"
                />
                <p
                  dangerouslySetInnerHTML={{ __html: description }}
                  className="md:w-full max-w-[calc(100%-32px)] font-medium paragraph-16"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-row lg:flex-wrap gap-16 md:justify-center lg:justify-start items-center pt-16 md:pt-24 border-t border-display-300">
            <p
              dangerouslySetInnerHTML={{ __html: buttonTitle }}
              className="font-bold text-center lg:text-left paragraph-20"
            />
            {buttons?.map((props) => (
              <Button key={props.label} {...props} />
            ))}
          </div>
        </div>
        <div className="lg:flex lg:items-center pt-8 lg:pt-0 lg:m-0 mx-auto w-[280px] md:w-[350px] lg:w-auto lg:min-w-[396px] lg:max-w-[455px] h-full">
          <CloudinaryImage
            src={image.path}
            alt={image.alternativeText}
            width={image.width}
            height={image.height}
            className="max-w-full h-auto"
          />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 z-[-10] w-full h-[120px] bg-newTon-400" />
    </div>
  )
}
