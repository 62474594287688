import React, { useCallback, useEffect, useRef, useState } from 'react'

import { ALL_PLANS_COMMOM_INFO, ALL_PLANS_FOR_MACHINE } from '@/constants/plans'
import { usePlans } from '@/contexts/plans'

const PlansSelectHeader: React.FC = () => {
  const { plan, setPlan } = usePlans()
  const ref = useRef<HTMLDivElement>(null)
  const [isOnTop, setIsOnTop] = useState(false)

  const scrollEvent = useCallback(() => {
    const tabsEl = ref.current
    if (tabsEl) {
      const { y } = tabsEl?.getBoundingClientRect()

      if ((isOnTop && y < 5) || (!isOnTop && y === 0)) {
        return setIsOnTop(true)
      }

      if (isOnTop) {
        setIsOnTop(false)
      }
    }
  }, [ref, isOnTop])

  useEffect(() => {
    if (ref) {
      addEventListener('scroll', scrollEvent)
    }

    return () => {
      if (ref) {
        removeEventListener('scroll', scrollEvent)
      }
    }
  }, [scrollEvent, ref])

  return (
    <div className="sticky top-0 z-[53] w-full" ref={ref}>
      <div
        className={`absolute justify-between xs:justify-around overflow-auto top-0 z-50 flex items-center bg-display-0 h-[65px] px-16 md:hidden shadow-menu w-screen ${
          isOnTop ? '' : 'opacity-0 max-h-0'
        }`}
      >
        {ALL_PLANS_FOR_MACHINE.map((planName) => {
          const planPopularName = ALL_PLANS_COMMOM_INFO[planName].popularName
          return (
            <label
              className="flex mr-16 last:mr-0"
              key={`mobile-${planName}`}
              htmlFor={`mobile-${planName}`}
              onClick={() => setPlan(planName)}
            >
              <input
                type="radio"
                name="plan-mobile"
                id={`mobile-${planName}`}
                checked={plan === planName}
                onChange={() => {}}
              />
              <span className="ml-8 font-bold whitespace-nowrap">
                {planPopularName}
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default PlansSelectHeader
