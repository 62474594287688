import 'keen-slider/keen-slider.min.css'

import {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  TrackDetails,
  useKeenSlider
} from 'keen-slider/react'
import {
  ComponentProps,
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from 'react'

import cn from '@/utils/cn'
import useViewport from '@/utils/useViewport'

import { SliderGhost } from '../components/Ghost'
import {
  NavigationArrowStateProps,
  SliderNavigationArrow
} from '../components/Navigation/Arrow'
import { SliderNavigationDots } from '../components/Navigation/Dots'
import { DotOptionsProps } from '../components/Navigation/Dots/DotAction'
import { initialSliderConfig } from './sliderConfigs'

export type KeenSliderInstanceProps = MutableRefObject<KeenSliderInstance<
  {},
  {},
  KeenSliderHooks
> | null>

enum SliderBreakpointsItemProps {
  xs = '(min-width: 476px)',
  md = '(min-width: 768px)',
  lg = '(min-width: 1025px)',
  xl = '(min-width: 1280px)'
}

interface HandleSliderBreakpointsResponse {
  [key: string]: Omit<KeenSliderOptions<{}, {}, KeenSliderHooks>, 'breakpoints'>
}

type SliderBreakpointItemProps = Omit<
  KeenSliderOptions<{}, {}, KeenSliderHooks>,
  'breakpoints'
>

export interface SliderBreakpointsProps {
  xs?: SliderBreakpointItemProps
  md?: SliderBreakpointItemProps
  lg?: SliderBreakpointItemProps
  xl?: SliderBreakpointItemProps
}

export type KeenSliderOption = Pick<KeenSliderOptions, 'slides' | 'mode'>

export interface SliderRootProps extends ComponentProps<'div'> {
  configs?: {
    options?: KeenSliderOption
    breakpoints?: SliderBreakpointsProps
  }
  navigations?: {
    arrows?: boolean
    dots?: {
      active: boolean
      options?: Omit<DotOptionsProps, 'active'>
    }
  }
}

export const SliderRoot = ({
  configs,
  navigations,
  children
}: SliderRootProps) => {
  const { width } = useViewport()

  const [isActive, setIsActive] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [currentOptionsProps, setCurrentOptionsProps] = useState(
    {} as KeenSliderOptions
  )
  const [arrowState, setArrowState] = useState<NavigationArrowStateProps>({
    prev: true,
    next: false
  })

  // Início da Lib
  const [keenSliderRef, keenSlider] = useKeenSlider<HTMLDivElement>({
    ...initialSliderConfig,
    created() {
      setIsMounted(true)
    },
    slideChanged() {
      const { abs, maxIdx } = keenSlider?.current?.track.details as TrackDetails

      setArrowState({
        prev: abs === 0,
        next: abs === maxIdx
      })
    }
  })

  // Slider está ativo?
  useEffect(() => {
    const isSliderActive = !!keenSlider.current?.track.details.maxIdx
    setIsActive(isSliderActive)
  }, [width, keenSlider])

  const handleOptionsForSlider = useCallback(async () => {
    if (isActive) {
      let breakpoints = {}
      const defaultOptions = keenSlider.current?.options

      // Configurações iniciais (sliderConfig.ts)
      const slidesOptionDefault = keenSlider.current?.options.slides ?? {}
      const modeOptionDefault = keenSlider.current?.options.mode ?? {}

      // Configurações vindas das propriedades do componente SliderRoot
      const slidesOptionConfig = configs?.options?.slides ?? {}
      const modeOptionConfig = configs?.options?.mode ?? undefined

      // Configurações concatenadas
      const slides = Object.assign({}, slidesOptionDefault, slidesOptionConfig)
      const mode = modeOptionConfig ?? modeOptionDefault

      const defaultOptionsWithConfigs = await Object.assign(
        { ...currentOptionsProps },
        { ...defaultOptions },
        { slides },
        { mode }
      )

      // Lidando com Breakpoints
      const breakpointsOptionConfig = configs?.breakpoints ?? {}

      if (Object.keys(breakpointsOptionConfig).length) {
        breakpoints = Object.entries(breakpointsOptionConfig).reduce(
          (acc, curr) => {
            const breakpoint =
              curr[0] as keyof typeof SliderBreakpointsItemProps
            const props = curr[1] as SliderBreakpointItemProps

            if (!Object.keys(props).length) return acc

            const slides = Object.assign(
              {},
              defaultOptionsWithConfigs.slides,
              props.slides
            )

            const mode = props.mode ?? defaultOptionsWithConfigs.mode

            Object.assign(props, { slides }, { mode })

            const newBreakpointObject: HandleSliderBreakpointsResponse = {}
            newBreakpointObject[`${SliderBreakpointsItemProps[breakpoint]}`] =
              props

            Object.assign(acc, newBreakpointObject)

            return acc
          },
          {}
        )
      }

      // Monta objeto com os breakpoints, se definidos via props.
      const optionsWithBreakpoints = Object.assign(
        { ...defaultOptionsWithConfigs },
        { breakpoints }
      )

      keenSlider.current?.update(optionsWithBreakpoints)
    }
  }, [currentOptionsProps, keenSlider, configs, isActive])

  useEffect(() => {
    handleOptionsForSlider()
  }, [handleOptionsForSlider, keenSlider, isActive])

  useEffect(() => {
    !isActive && setCurrentOptionsProps(keenSlider.current?.options ?? {})
  }, [isActive, keenSlider])

  return children ? (
    <div
      className={cn(
        'flex justify-center mx-auto w-full invisible',
        isMounted ? 'visible' : ''
      )}
    >
      <div className="flex flex-col gap-24 justify-center items-center w-full">
        <div
          ref={keenSliderRef}
          className={cn(
            'keen-slider',
            isActive ? 'absolute' : 'flex justify-center gap:16 lg:gap-24'
          )}
        >
          <SliderGhost />
          {children}
          <SliderGhost />
        </div>

        {navigations?.arrows && isActive && (
          <SliderNavigationArrow instance={keenSlider} states={arrowState} />
        )}

        {navigations?.dots?.active && isActive && (
          <SliderNavigationDots
            instance={keenSlider}
            options={navigations.dots.options}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
