import FlagsCarouselComponent, {
  FlagsCarouselProps as FlagsCarouselComponentProps
} from '@/components/FlagsCarousel'
import { ColorProps } from '@/types/cms'

interface FlagsCarouselProps {
  flag_payment: FlagsCarouselComponentProps
  backgroundColor?: ColorProps
}

export function FlagsCarousel({
  flag_payment,
  backgroundColor
}: FlagsCarouselProps) {
  return (
    <FlagsCarouselComponent
      {...flag_payment}
      theme={
        backgroundColor?.classColor ? `bg-${backgroundColor?.classColor}` : ''
      }
    />
  )
}
