import { ColorProps, ReviewsInTextContentProps } from '@/types/cms'

import { Score } from '../Score'
import { ReviewTextCarrousel } from './components/ReviewTextCarrousel'

interface ReviewsInTextProps {
  review_text?: ReviewsInTextContentProps
  backgroundColor?: ColorProps
}

export const ReviewsInText = ({
  review_text,
  backgroundColor
}: ReviewsInTextProps) => {
  return (
    <div
      className={`${
        backgroundColor?.classColor ? `bg-${backgroundColor.classColor}` : ''
      } pt-40 md:pt-80 flex flex-col gap-24`}
    >
      <div className="flex flex-col xl:flex-row gap-24 xl:gap-0 xl:justify-between xl:items-center mx-auto w-full max-w-[1280px]">
        {review_text?.title && (
          <div className="px-16 md:px-24 xl:px-0 xl:mx-0 w-full">
            <h2
              dangerouslySetInnerHTML={{ __html: review_text?.title }}
              className={`text-center ${
                review_text?.scores && review_text.scores.length > 0
                  ? 'xl:text-left'
                  : ''
              }`}
            />
          </div>
        )}

        {review_text?.scores && review_text.scores.length > 0 && (
          <div className="px-16 md:px-24 xl:px-0 mx-auto xl:mx-0">
            <Score scores={review_text.scores} />
          </div>
        )}
      </div>

      {review_text?.reviews && (
        <ReviewTextCarrousel reviews={review_text.reviews} />
      )}
    </div>
  )
}
