/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react'

import { LabelTimeProps } from '@/types/cms'
import cn from '@/utils/cn'

import { Alert, AlertProps } from '../Alert'
import Button, { ButtonV2Props } from '../ButtonV2'

export interface StepwiseGuideProps {
  referId: string
  title: string
  subtitle: string
  steps: LabelTimeProps[]
  buttons: ButtonV2Props[]
  alert?: AlertProps
}

export function StepwiseGuide({
  title,
  subtitle,
  referId,
  steps,
  buttons,
  alert
}: StepwiseGuideProps) {
  const [stepNumber, setStepNumber] = useState(0)

  return (
    <section id={referId} className="py-40 lg:py-80 px-16 md:px-24 w-full">
      <div className="flex overflow-hidden flex-col mx-auto max-w-[455px] md:max-w-[1280px]">
        <div className="flex flex-col gap-8 lg:pb-40">
          <h2
            className="font-heading text-[32px] lg:text-[40px] font-bold lg:leading-9 text-center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className="lg:text-xl font-normal lg:leading-5 text-center font-inter paragraph-18">
            {subtitle}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row-reverse lg:gap-40 justify-center items-center w-full md:max-w-[1280px]">
          {steps.map(
            ({ image }, index) =>
              image && (
                <div
                  key={`imagem-${index}`}
                  className={`w-full max-w-[214px] md:max-w-[224px] lg:max-w-[290px] ${
                    stepNumber === index ? 'flex' : 'hidden'
                  }`}
                >
                  <picture className="my-24 lg:my-0 mx-auto">
                    <source
                      srcSet={`https://res.cloudinary.com/dunz5zfpt/fl_progressive/f_auto,c_limit,w_${image.width},q_auto/${image.path}`}
                      media="(min-width:1025px)"
                    />
                    <source
                      srcSet={`https://res.cloudinary.com/dunz5zfpt/fl_progressive/f_auto,c_limit,w_${image.width},q_auto/${image.path}`}
                      media="(min-width:768px)"
                    />
                    <source
                      srcSet={`https://res.cloudinary.com/dunz5zfpt/fl_progressive/f_auto,c_limit,w_${image.width},q_auto/${image.path}`}
                      media="(min-width:200px)"
                    />
                    <source
                      srcSet={`https://res.cloudinary.com/dunz5zfpt/fl_progressive/f_auto,c_limit,w_${image.width},q_1/${image.path}`}
                      media="(min-width:0px)"
                    />
                    <img
                      src={`https://res.cloudinary.com/dunz5zfpt/fl_progressive/f_auto,c_limit,w_${image.width},q_1/${image.path}`}
                      alt={image.alternativeText || ''}
                      loading="lazy"
                    />
                  </picture>
                </div>
              )
          )}

          <div className="flex md:hidden flex-row justify-center w-full text-center cursor-pointer">
            {[0, 1, 2].map((number) => (
              <div
                key={`passo-${number}`}
                className={`py-10 w-1/3 border-b-4 ${
                  stepNumber === number
                    ? 'border-newTon-600 text-newTon-600'
                    : 'border-display-400 text-display-400'
                }`}
              >
                <p
                  className="font-heading font-bold paragraph-28"
                  onClick={() => {
                    setStepNumber(number)
                  }}
                >
                  {number + 1}
                </p>
              </div>
            ))}
          </div>

          <div className="flex flex-col my-auto">
            <div className="flex overflow-auto lg:overflow-y-visible flex-row md:flex-col gap-40 md:gap-24 md:self-center pl-16 ml-[-16px] w-full md:max-w-[fit-content] lg:max-w-[636px]">
              {steps.map(({ title, text }, index) => (
                <div
                  key={title}
                  className={cn({
                    relative: stepNumber === index,
                    'hidden md:block': stepNumber !== index
                  })}
                >
                  <div
                    key={`step-${index}`}
                    onClick={() => {
                      setStepNumber(index)
                    }}
                    className={`flex flex-row gap-8 md:gap-0 p-16 md:py-8 md:pr-0 md:cursor-pointer md:!flex w-full ${
                      stepNumber === index
                        ? 'before:top-0 before:-left-2 before:absolute before:h-full md:before:border-l-4 md:before:border-newTon-600 lg:box-border'
                        : 'hidden'
                    }`}
                  >
                    <div
                      className={`hidden md:block my-auto mr-16 min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px] text-center ${
                        stepNumber === index
                          ? 'text-newTon-600'
                          : 'text-display-400'
                      }`}
                    >
                      <p
                        className="font-heading text-3xl font-bold leading-6"
                        onClick={() => {
                          setStepNumber(index)
                        }}
                      >
                        {index + 1}
                      </p>
                    </div>
                    <div className="flex flex-col gap-8">
                      <p className="w-full text-xl lg:text-2xl font-bold leading-5 lg:leading-6">
                        {title}
                      </p>
                      <p className="w-full lg:text-xl lg:leading-5">{text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {buttons.map((button, index) => (
              <Button
                key={button.label || index}
                className="md:mx-auto lg:mx-0 mt-24 w-full md:w-[fit-content] font-bold md:btn-large"
                {...button}
              />
            ))}
          </div>
        </div>

        {alert && (
          <div className="mt-24 lg:mt-40">
            <Alert {...alert} />
          </div>
        )}
      </div>
    </section>
  )
}
