import { datadogRum } from '@datadog/browser-rum'

import getUserId from './getUserId'

/**
 *  @param {string} flag_key - Feature flag name that can be found in the Amplitude dashboard
 *  @see {@link https://app.amplitude.com/experiment/stone-data/dashboard}
 */
const experimentFetcher = async (flag_key: string) => {
  const config: RequestInit = {
    headers: {
      Authorization: `Api-Key ${process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENTS_API_KEY}`,
      Accept: 'application/json;charset=utf-8'
    }
  }

  const data = await fetch(
    `https://api.lab.amplitude.com/v1/vardata?user_id=${getUserId()}&flag_key=${flag_key}`,
    config
  )
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(
          `Error fetching experiment w/ user id equals ${getUserId()} flag key equals ${flag_key}`
        )
      }
      return { data: await response.json(), error: undefined }
    })
    .catch((err) => {
      datadogRum.addError(err)
      return { data: undefined, error: err }
    })

  return data
}

export default experimentFetcher
