type UserDevices = 'Desktop' | 'Mobile' | 'Tablet'

export function getUserDevice(): UserDevices {
  const width = window?.innerWidth
  if (width < 768) {
    return 'Mobile'
  } else if (width >= 768 && width <= 1024) {
    return 'Tablet'
  } else {
    return 'Desktop'
  }
}
