import { IS_SERVER } from 'swr/_internal'

import { usePlans } from '@/contexts/plans'
import { useExperiment } from '@/services/amplitude/experiments'
import { getUserDevice } from '@/utils/getUserDevice'
import { getAndSaveSearchParams } from '@/utils/searchParams'

import { CMSEventParams } from '../event.shared.interfaces'

export function useCMSEvent(cmsEventParams: CMSEventParams | undefined) {
  const { data } = useExperiment(cmsEventParams?.experiment?.key)
  const { plan } = usePlans()

  const variantKey = cmsEventParams?.experiment?.key
    ? data?.data[cmsEventParams?.experiment?.key]?.key
    : null

  const customEventPropertiesByEvent: { [key: string]: unknown } = {
    page_view_section_know_fees: {
      plan
    }
  }

  const customEventProperties =
    customEventPropertiesByEvent[cmsEventParams?.name || ''] || {}

  function isNotDesktopRule() {
    if (IS_SERVER) return false

    return getUserDevice() !== 'Desktop'
  }

  function isPlansCarouselExperimentRule() {
    if (IS_SERVER) return false

    const ALLOWED_VARIANTS = [
      'control',
      'plans-carousel-tapton-end',
      'plans-carousel-tapton-below'
    ]

    return ALLOWED_VARIANTS.includes(variantKey) && isNotDesktopRule()
  }

  function isD0ExperimentWithoutCampaignRefererRule() {
    if (IS_SERVER) {
      return false
    }

    const hasCampaignReferer = !!getAndSaveSearchParams()?.referrer
    const hasVariant = !!variantKey

    if (hasVariant) {
      if (hasCampaignReferer) {
        localStorage.removeItem(
          `amplitude-experiment-variant-${cmsEventParams?.experiment?.key}`
        )
        return false
      }

      return true
    }

    return false
  }

  return {
    rules: {
      none: () => true,
      isNotDesktopRule,
      isPlansCarouselExperimentRule,
      isD0ExperimentWithoutCampaignRefererRule
    },
    customEventProperties
  }
}
