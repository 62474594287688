/* eslint-disable tailwindcss/no-custom-classname */
import { clsx } from 'clsx'
import CloudinaryImage from '@/components/CloudinaryImage'
import { ImageCMSProps } from '@/types/cms'

export interface AlertProps {
  title?: string
  paragraphs: string
  backgroundColor?: string
  icon?: Pick<ImageCMSProps, 'path' | 'alternativeText'>
}

export function Alert({
  title,
  icon,
  paragraphs,
  backgroundColor = 'newTon-50'
}: AlertProps) {
  const paragraphsArray = paragraphs.split('\n').filter(Boolean)

  return (
    <section
      role="alert"
      aria-labelledby="alert-title"
      className={clsx(
        'flex flex-col md:flex-row md:items-start lg:items-center p-16 mx-auto space-y-16 md:space-y-0 md:space-x-16 lg:max-w-[1060px] text-display-900 rounded-10',
        `bg-${backgroundColor}`
      )}
    >
      {icon && (
        <div className="flex flex-shrink-0">
          <CloudinaryImage
            width={40}
            height={50}
            src={icon.path}
            data-testid={'alert-icon'}
            alt={icon.alternativeText}
            className="object-contain max-w-full h-auto"
          />
        </div>
      )}

      <div className="space-y-8">
        {paragraphsArray.map((text, index) => (
          <p
            key={index}
            className="text-14 xl:text-16 xl:font-medium leading-3 xl:leading-24"
          >
            {text}
          </p>
        ))}
      </div>

      {title && (
        <h4 id="alert-title" className="m-0 sr-only">
          {title}
        </h4>
      )}
    </section>
  )
}
