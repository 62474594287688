import React from 'react'

const Tag25PercentOFF: React.FC<React.SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width={56}
      height={39}
      viewBox="0 0 93 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2761 37.6118H4.09461C1.83322 37.6118 0 39.4463 0 41.7093V57.9024C0 60.1654 1.83322 61.9999 4.09461 61.9999H20.2761C22.5375 61.9999 24.3708 60.1654 24.3708 57.9024V41.7093C24.3708 39.4463 22.5375 37.6118 20.2761 37.6118Z"
        fill="#8062FF"
      />
      <path
        d="M20.9557 37.2393H4.24816C1.98826 37.2393 0.15625 39.0726 0.15625 41.3341V57.9023C0.15625 60.1638 1.98826 61.9971 4.24816 61.9971H20.9557C23.2156 61.9971 25.0476 60.1638 25.0476 57.9023V41.3341C25.0476 39.0726 23.2156 37.2393 20.9557 37.2393Z"
        fill="#8062FF"
      />
      <path
        d="M21.6391 36.8667H4.40832C2.14842 36.8667 0.316406 38.7 0.316406 40.9615V57.8969C0.316406 60.1584 2.14842 61.9917 4.40832 61.9917H21.6391C23.899 61.9917 25.7311 60.1584 25.7311 57.8969V40.9615C25.7311 38.7 23.899 36.8667 21.6391 36.8667Z"
        fill="#8062FF"
      />
      <path
        d="M22.3187 36.4971H4.56457C2.30467 36.4971 0.472656 38.3304 0.472656 40.5919V57.897C0.472656 60.1585 2.30467 61.9919 4.56457 61.9919H22.3187C24.5786 61.9919 26.4106 60.1585 26.4106 57.897V40.5919C26.4106 38.3304 24.5786 36.4971 22.3187 36.4971Z"
        fill="#8062FF"
      />
      <path
        d="M23.0009 36.1245H4.71812C2.45971 36.1245 0.628906 37.9566 0.628906 40.2166V57.897C0.628906 60.157 2.45971 61.9891 4.71812 61.9891H23.0009C25.2593 61.9891 27.0901 60.157 27.0901 57.897V40.2166C27.0901 37.9566 25.2593 36.1245 23.0009 36.1245Z"
        fill="#8062FF"
      />
      <path
        d="M23.6805 35.752H4.87437C2.61596 35.752 0.785156 37.5841 0.785156 39.8441V57.8942C0.785156 60.1542 2.61596 61.9864 4.87437 61.9864H23.6805C25.9389 61.9864 27.7697 60.1542 27.7697 57.8942V39.8441C27.7697 37.5841 25.9389 35.752 23.6805 35.752Z"
        fill="#8062FF"
      />
      <path
        d="M24.3573 35.3794H5.03062C2.77221 35.3794 0.941406 37.2115 0.941406 39.4715V57.8915C0.941406 60.1515 2.77221 61.9836 5.03062 61.9836H24.3573C26.6157 61.9836 28.4465 60.1515 28.4465 57.8915V39.4715C28.4465 37.2115 26.6157 35.3794 24.3573 35.3794Z"
        fill="#8062FF"
      />
      <path
        d="M25.0395 35.0073H5.18418C2.92725 35.0073 1.09766 36.8382 1.09766 39.0967V57.8919C1.09766 60.1504 2.92725 61.9813 5.18418 61.9813H25.0395C27.2965 61.9813 29.1261 60.1504 29.1261 57.8919V39.0967C29.1261 36.8382 27.2965 35.0073 25.0395 35.0073Z"
        fill="#8062FF"
      />
      <path
        d="M25.723 34.6348H5.34433C3.08741 34.6348 1.25781 36.4657 1.25781 38.7242V57.8892C1.25781 60.1477 3.08741 61.9786 5.34433 61.9786H25.723C27.9799 61.9786 29.8095 60.1477 29.8095 57.8892V38.7242C29.8095 36.4657 27.9799 34.6348 25.723 34.6348Z"
        fill="#8062FF"
      />
      <path
        d="M26.4052 34.2622H5.49788C3.24245 34.2622 1.41406 36.0919 1.41406 38.3489V57.8891C1.41406 60.1461 3.24245 61.9758 5.49788 61.9758H26.4052C28.6607 61.9758 30.489 60.1461 30.489 57.8891V38.3489C30.489 36.0919 28.6607 34.2622 26.4052 34.2622Z"
        fill="#8062FF"
      />
      <path
        d="M27.0821 33.8896H5.65413C3.3987 33.8896 1.57031 35.7193 1.57031 37.9764V57.8863C1.57031 60.1434 3.3987 61.9731 5.65413 61.9731H27.0821C29.3375 61.9731 31.1659 60.1434 31.1659 57.8863V37.9764C31.1659 35.7193 29.3375 33.8896 27.0821 33.8896Z"
        fill="#8062FF"
      />
      <path
        d="M27.7616 33.5171H5.81038C3.55495 33.5171 1.72656 35.3468 1.72656 37.6038V57.8809C1.72656 60.1379 3.55495 61.9676 5.81038 61.9676H27.7616C30.017 61.9676 31.8454 60.1379 31.8454 57.8809V37.6038C31.8454 35.3468 30.017 33.5171 27.7616 33.5171Z"
        fill="#8062FF"
      />
      <path
        d="M28.4438 33.1445H5.96394C3.70999 33.1445 1.88281 34.973 1.88281 37.2286V57.8808C1.88281 60.1364 3.70999 61.9649 5.96394 61.9649H28.4438C30.6978 61.9649 32.525 60.1364 32.525 57.8808V37.2286C32.525 34.973 30.6978 33.1445 28.4438 33.1445Z"
        fill="#8062FF"
      />
      <path
        d="M29.1234 32.772H6.12019C3.86624 32.772 2.03906 34.6005 2.03906 36.856V57.8781C2.03906 60.1336 3.86624 61.9621 6.12019 61.9621H29.1234C31.3773 61.9621 33.2045 60.1336 33.2045 57.8781V36.856C33.2045 34.6005 31.3773 32.772 29.1234 32.772Z"
        fill="#8062FF"
      />
      <path
        d="M29.8029 32.3994H6.27374C4.02129 32.3994 2.19531 34.2267 2.19531 36.4807V57.878C2.19531 60.1321 4.02129 61.9593 6.27374 61.9593H29.8029C32.0554 61.9593 33.8813 60.1321 33.8813 57.878V36.4807C33.8813 34.2267 32.0554 32.3994 29.8029 32.3994Z"
        fill="#8062FF"
      />
      <path
        d="M30.4864 32.0269H6.4339C4.18144 32.0269 2.35547 33.8541 2.35547 36.1082V57.8753C2.35547 60.1293 4.18144 61.9566 6.4339 61.9566H30.4864C32.7388 61.9566 34.5648 60.1293 34.5648 57.8753V36.1082C34.5648 33.8541 32.7388 32.0269 30.4864 32.0269Z"
        fill="#8062FF"
      />
      <path
        d="M31.1659 31.6548H6.59015C4.33769 31.6548 2.51172 33.4821 2.51172 35.7361V57.873C2.51172 60.127 4.33769 61.9543 6.59015 61.9543H31.1659C33.4184 61.9543 35.2443 60.127 35.2443 57.873V35.7361C35.2443 33.4821 33.4184 31.6548 31.1659 31.6548Z"
        fill="#8062FF"
      />
      <path
        d="M31.8481 31.2822H6.7437C4.49274 31.2822 2.66797 33.1083 2.66797 35.3609V57.8729C2.66797 60.1255 4.49274 61.9516 6.7437 61.9516H31.8481C34.0991 61.9516 35.9239 60.1255 35.9239 57.8729V35.3609C35.9239 33.1083 34.0991 31.2822 31.8481 31.2822Z"
        fill="#8062FF"
      />
      <path
        d="M32.525 30.9097H6.89995C4.64898 30.9097 2.82422 32.7357 2.82422 34.9883V57.8702C2.82422 60.1227 4.64898 61.9488 6.89995 61.9488H32.525C34.776 61.9488 36.6007 60.1227 36.6007 57.8702V34.9883C36.6007 32.7357 34.776 30.9097 32.525 30.9097Z"
        fill="#8062FF"
      />
      <path
        d="M33.2072 30.5371H7.0535C4.80403 30.5371 2.98047 32.362 2.98047 34.613V57.8701C2.98047 60.1212 4.80403 61.946 7.0535 61.946H33.2072C35.4567 61.946 37.2803 60.1212 37.2803 57.8701V34.613C37.2803 32.362 35.4567 30.5371 33.2072 30.5371Z"
        fill="#8062FF"
      />
      <path
        d="M33.8868 30.1646H7.20975C4.96028 30.1646 3.13672 31.9894 3.13672 34.2405V57.8647C3.13672 60.1157 4.96028 61.9406 7.20975 61.9406H33.8868C36.1362 61.9406 37.9598 60.1157 37.9598 57.8647V34.2405C37.9598 31.9894 36.1362 30.1646 33.8868 30.1646Z"
        fill="#8062FF"
      />
      <path
        d="M34.5663 29.792H7.366C5.11653 29.792 3.29297 31.6168 3.29297 33.8679V57.8619C3.29297 60.113 5.11653 61.9378 7.366 61.9378H34.5663C36.8158 61.9378 38.6393 60.113 38.6393 57.8619V33.8679C38.6393 31.6168 36.8158 29.792 34.5663 29.792Z"
        fill="#8062FF"
      />
      <path
        d="M35.2498 29.4194H7.52346C5.27548 29.4194 3.45312 31.2431 3.45312 33.4927V57.8619C3.45312 60.1114 5.27548 61.9351 7.52346 61.9351H35.2498C37.4977 61.9351 39.3201 60.1114 39.3201 57.8619V33.4927C39.3201 31.2431 37.4977 29.4194 35.2498 29.4194Z"
        fill="#8062FF"
      />
      <path
        d="M35.9293 29.0469H7.67971C5.43173 29.0469 3.60938 30.8705 3.60938 33.1201V57.8591C3.60938 60.1087 5.43173 61.9323 7.67971 61.9323H35.9293C38.1773 61.9323 39.9996 60.1087 39.9996 57.8591V33.1201C39.9996 30.8705 38.1773 29.0469 35.9293 29.0469Z"
        fill="#8062FF"
      />
      <path
        d="M36.6115 28.6743H7.83326C5.58677 28.6743 3.76562 30.4968 3.76562 32.7448V57.859C3.76562 60.1071 5.58677 61.9296 7.83326 61.9296H36.6115C38.858 61.9296 40.6792 60.1071 40.6792 57.859V32.7448C40.6792 30.4968 38.858 28.6743 36.6115 28.6743Z"
        fill="#8062FF"
      />
      <path
        d="M37.2911 28.3022H7.98951C5.74302 28.3022 3.92188 30.1247 3.92188 32.3728V57.8568C3.92188 60.1049 5.74302 61.9273 7.98951 61.9273H37.2911C39.5376 61.9273 41.3587 60.1049 41.3587 57.8568V32.3728C41.3587 30.1247 39.5376 28.3022 37.2911 28.3022Z"
        fill="#8062FF"
      />
      <path
        d="M37.9679 27.9297H8.14576C5.89927 27.9297 4.07812 29.7521 4.07812 32.0002V57.854C4.07812 60.1021 5.89927 61.9245 8.14576 61.9245H37.9679C40.2144 61.9245 42.0356 60.1021 42.0356 57.854V32.0002C42.0356 29.7521 40.2144 27.9297 37.9679 27.9297Z"
        fill="#8062FF"
      />
      <path
        d="M38.6502 27.5571H8.29931C6.05431 27.5571 4.23438 29.3784 4.23438 31.625V57.854C4.23438 60.1006 6.05431 61.9218 8.29931 61.9218H38.6502C40.8952 61.9218 42.7151 60.1006 42.7151 57.854V31.625C42.7151 29.3784 40.8952 27.5571 38.6502 27.5571Z"
        fill="#8062FF"
      />
      <path
        d="M39.3336 27.1846H8.45947C6.21447 27.1846 4.39453 29.0058 4.39453 31.2524V57.8485C4.39453 60.0951 6.21447 61.9163 8.45947 61.9163H39.3336C41.5786 61.9163 43.3985 60.0951 43.3985 57.8485V31.2524C43.3985 29.0058 41.5786 27.1846 39.3336 27.1846Z"
        fill="#8062FF"
      />
      <path
        d="M40.0158 26.8149H8.61302C6.36951 26.8149 4.55078 28.635 4.55078 30.8801V57.8514C4.55078 60.0965 6.36951 61.9165 8.61302 61.9165H40.0158C42.2594 61.9165 44.0781 60.0965 44.0781 57.8514V30.8801C44.0781 28.635 42.2594 26.8149 40.0158 26.8149Z"
        fill="#8062FF"
      />
      <path
        d="M40.6927 26.4424H8.76927C6.52576 26.4424 4.70703 28.2624 4.70703 30.5075V57.8486C4.70703 60.0937 6.52576 61.9138 8.76927 61.9138H40.6927C42.9362 61.9138 44.7549 60.0937 44.7549 57.8486V30.5075C44.7549 28.2624 42.9362 26.4424 40.6927 26.4424Z"
        fill="#8062FF"
      />
      <path
        d="M41.3722 26.0698H8.92552C6.68201 26.0698 4.86328 27.8898 4.86328 30.135V57.8459C4.86328 60.091 6.68201 61.911 8.92552 61.911H41.3722C43.6157 61.911 45.4345 60.091 45.4345 57.8459V30.135C45.4345 27.8898 43.6157 26.0698 41.3722 26.0698Z"
        fill="#8062FF"
      />
      <path
        d="M42.0545 25.6973H9.07908C6.83705 25.6973 5.01953 27.5161 5.01953 29.7597V57.8458C5.01953 60.0894 6.83705 61.9082 9.07908 61.9082H42.0545C44.2965 61.9082 46.114 60.0894 46.114 57.8458V29.7597C46.114 27.5161 44.2965 25.6973 42.0545 25.6973Z"
        fill="#8062FF"
      />
      <path
        d="M42.734 25.3247H9.23533C6.9933 25.3247 5.17578 27.1435 5.17578 29.3871V57.8431C5.17578 60.0867 6.9933 61.9055 9.23533 61.9055H42.734C44.976 61.9055 46.7935 60.0867 46.7935 57.8431V29.3871C46.7935 27.1435 44.976 25.3247 42.734 25.3247Z"
        fill="#8062FF"
      />
      <path
        d="M43.4162 24.9521H9.38888C7.14834 24.9521 5.33203 26.7698 5.33203 29.0119V57.843C5.33203 60.0851 7.14834 61.9027 9.38888 61.9027H43.4162C45.6568 61.9027 47.4731 60.0851 47.4731 57.843V29.0119C47.4731 26.7698 45.6568 24.9521 43.4162 24.9521Z"
        fill="#8062FF"
      />
      <path
        d="M44.097 24.5796H9.54904C7.3085 24.5796 5.49219 26.3972 5.49219 28.6393V57.8402C5.49219 60.0824 7.3085 61.9 9.54904 61.9H44.097C46.3375 61.9 48.1538 60.0824 48.1538 57.8402V28.6393C48.1538 26.3972 46.3375 24.5796 44.097 24.5796Z"
        fill="#8062FF"
      />
      <path
        d="M44.7765 24.207H9.70529C7.46475 24.207 5.64844 26.0246 5.64844 28.2668V57.8375C5.64844 60.0796 7.46475 61.8972 9.70529 61.8972H44.7765C47.0171 61.8972 48.8334 60.0796 48.8334 57.8375V28.2668C48.8334 26.0246 47.0171 24.207 44.7765 24.207Z"
        fill="#8062FF"
      />
      <path
        d="M45.4588 23.835H9.85884C7.61979 23.835 5.80469 25.6514 5.80469 27.892V57.8352C5.80469 60.0759 7.61979 61.8923 9.85884 61.8923H45.4588C47.6978 61.8923 49.5129 60.0759 49.5129 57.8352V27.892C49.5129 25.6514 47.6978 23.835 45.4588 23.835Z"
        fill="#8062FF"
      />
      <path
        d="M46.1383 23.4624H10.0151C7.77604 23.4624 5.96094 25.2788 5.96094 27.5194V57.8325C5.96094 60.0731 7.77604 61.8895 10.0151 61.8895H46.1383C48.3773 61.8895 50.1924 60.0731 50.1924 57.8325V27.5194C50.1924 25.2788 48.3773 23.4624 46.1383 23.4624Z"
        fill="#8062FF"
      />
      <path
        d="M46.8178 23.0898H10.1686C7.93109 23.0898 6.11719 24.905 6.11719 27.1442V57.8324C6.11719 60.0715 7.93109 61.8867 10.1686 61.8867H46.8178C49.0554 61.8867 50.8693 60.0715 50.8693 57.8324V27.1442C50.8693 24.905 49.0554 23.0898 46.8178 23.0898Z"
        fill="#8062FF"
      />
      <path
        d="M47.4974 22.7173H10.3249C8.08734 22.7173 6.27344 24.5325 6.27344 26.7716V57.8297C6.27344 60.0688 8.08734 61.884 10.3249 61.884H47.4974C49.7349 61.884 51.5488 60.0688 51.5488 57.8297V26.7716C51.5488 24.5325 49.7349 22.7173 47.4974 22.7173Z"
        fill="#8062FF"
      />
      <path
        d="M48.1769 22.3447H10.4811C8.24358 22.3447 6.42969 24.1599 6.42969 26.3991V57.8269C6.42969 60.066 8.24358 61.8812 10.4811 61.8812H48.1769C50.4145 61.8812 52.2284 60.066 52.2284 57.8269V26.3991C52.2284 24.1599 50.4145 22.3447 48.1769 22.3447Z"
        fill="#8062FF"
      />
      <path
        d="M48.8631 21.9722H10.6386C8.40253 21.9722 6.58984 23.7861 6.58984 26.0238V57.8268C6.58984 60.0645 8.40253 61.8785 10.6386 61.8785H48.8631C51.0991 61.8785 52.9118 60.0645 52.9118 57.8268V26.0238C52.9118 23.7861 51.0991 21.9722 48.8631 21.9722Z"
        fill="#8062FF"
      />
      <path
        d="M49.5399 21.5996H10.7949C8.55878 21.5996 6.74609 23.4136 6.74609 25.6512V57.8241C6.74609 60.0617 8.55878 61.8757 10.7949 61.8757H49.5399C51.776 61.8757 53.5887 60.0617 53.5887 57.8241V25.6512C53.5887 23.4136 51.776 21.5996 49.5399 21.5996Z"
        fill="#8062FF"
      />
      <path
        d="M50.2221 21.2271H10.9484C8.71383 21.2271 6.90234 23.0398 6.90234 25.276V57.824C6.90234 60.0602 8.71383 61.873 10.9484 61.873H50.2221C52.4567 61.873 54.2682 60.0602 54.2682 57.824V25.276C54.2682 23.0398 52.4567 21.2271 50.2221 21.2271Z"
        fill="#8062FF"
      />
      <path
        d="M50.9017 20.8545H11.1047C8.87008 20.8545 7.05859 22.6673 7.05859 24.9034V57.8213C7.05859 60.0574 8.87008 61.8702 11.1047 61.8702H50.9017C53.1363 61.8702 54.9478 60.0574 54.9478 57.8213V24.9034C54.9478 22.6673 53.1363 20.8545 50.9017 20.8545Z"
        fill="#8062FF"
      />
      <path
        d="M51.5812 20.4824H11.2609C9.02632 20.4824 7.21484 22.2952 7.21484 24.5314V57.8163C7.21484 60.0525 9.02632 61.8652 11.2609 61.8652H51.5812C53.8158 61.8652 55.6273 60.0525 55.6273 57.8163V24.5314C55.6273 22.2952 53.8158 20.4824 51.5812 20.4824Z"
        fill="#8062FF"
      />
      <path
        d="M52.2608 20.1099H11.4145C9.18137 20.1099 7.37109 21.9214 7.37109 24.1561V57.8162C7.37109 60.0509 9.18137 61.8625 11.4145 61.8625H52.2608C54.4939 61.8625 56.3041 60.0509 56.3041 57.8162V24.1561C56.3041 21.9214 54.4939 20.1099 52.2608 20.1099Z"
        fill="#8062FF"
      />
      <path
        d="M52.9442 19.7373H11.5746C9.34152 19.7373 7.53125 21.5489 7.53125 23.7835V57.8135C7.53125 60.0482 9.34152 61.8597 11.5746 61.8597H52.9442C55.1773 61.8597 56.9876 60.0482 56.9876 57.8135V23.7835C56.9876 21.5489 55.1773 19.7373 52.9442 19.7373Z"
        fill="#8062FF"
      />
      <path
        d="M53.6264 19.3647H11.7282C9.49657 19.3647 7.6875 21.1751 7.6875 23.4083V57.8134C7.6875 60.0466 9.49657 61.857 11.7282 61.857H53.6264C55.858 61.857 57.6671 60.0466 57.6671 57.8134V23.4083C57.6671 21.1751 55.858 19.3647 53.6264 19.3647Z"
        fill="#8062FF"
      />
      <path
        d="M54.306 18.9922H11.8844C9.65282 18.9922 7.84375 20.8025 7.84375 23.0357V57.8107C7.84375 60.0439 9.65282 61.8542 11.8844 61.8542H54.306C56.5376 61.8542 58.3467 60.0439 58.3467 57.8107V23.0357C58.3467 20.8025 56.5376 18.9922 54.306 18.9922Z"
        fill="#8062FF"
      />
      <path
        d="M54.9828 18.6196H12.0407C9.80907 18.6196 8 20.43 8 22.6632V57.8079C8 60.0411 9.80907 61.8515 12.0407 61.8515H54.9828C57.2144 61.8515 59.0235 60.0411 59.0235 57.8079V22.6632C59.0235 20.43 57.2144 18.6196 54.9828 18.6196Z"
        fill="#8062FF"
      />
      <path
        d="M55.6651 18.2471H12.1942C9.96411 18.2471 8.15625 20.0562 8.15625 22.2879V57.8079C8.15625 60.0396 9.96411 61.8487 12.1942 61.8487H55.6651C57.8952 61.8487 59.703 60.0396 59.703 57.8079V22.2879C59.703 20.0562 57.8952 18.2471 55.6651 18.2471Z"
        fill="#8062FF"
      />
      <path
        d="M56.3446 17.8745H12.3505C10.1204 17.8745 8.3125 19.6837 8.3125 21.9153V57.8051C8.3125 60.0368 10.1204 61.8459 12.3505 61.8459H56.3446C58.5747 61.8459 60.3826 60.0368 60.3826 57.8051V21.9153C60.3826 19.6837 58.5747 17.8745 56.3446 17.8745Z"
        fill="#8062FF"
      />
      <path
        d="M57.0288 17.502H12.506C10.2774 17.502 8.4707 19.3099 8.4707 21.5401V57.8024C8.4707 60.0326 10.2774 61.8405 12.506 61.8405H57.0288C59.2574 61.8405 61.0641 60.0326 61.0641 57.8024V21.5401C61.0641 19.3099 59.2574 17.502 57.0288 17.502Z"
        fill="#8062FF"
      />
      <path
        d="M57.7076 17.1299H12.6642C10.4356 17.1299 8.62891 18.9378 8.62891 21.168V57.8001C8.62891 60.0303 10.4356 61.8382 12.6642 61.8382H57.7076C59.9362 61.8382 61.7429 60.0303 61.7429 57.8001V21.168C61.7429 18.9378 59.9362 17.1299 57.7076 17.1299Z"
        fill="#8062FF"
      />
      <path
        d="M58.3871 16.7598H12.8204C10.5918 16.7598 8.78516 18.5677 8.78516 20.7979V57.7998C8.78516 60.03 10.5918 61.8379 12.8204 61.8379H58.3871C60.6157 61.8379 62.4224 60.03 62.4224 57.7998V20.7979C62.4224 18.5677 60.6157 16.7598 58.3871 16.7598Z"
        fill="#8062FF"
      />
      <path
        d="M59.0694 16.3872H12.974C10.7469 16.3872 8.94141 18.1939 8.94141 20.4226V57.7997C8.94141 60.0284 10.7469 61.8352 12.974 61.8352H59.0694C61.2965 61.8352 63.1019 60.0284 63.1019 57.7997V20.4226C63.1019 18.1939 61.2965 16.3872 59.0694 16.3872Z"
        fill="#8062FF"
      />
      <path
        d="M59.7489 16.0146H13.1302C10.9031 16.0146 9.09766 17.8214 9.09766 20.0501V57.797C9.09766 60.0257 10.9031 61.8324 13.1302 61.8324H59.7489C61.976 61.8324 63.7815 60.0257 63.7815 57.797V20.0501C63.7815 17.8214 61.976 16.0146 59.7489 16.0146Z"
        fill="#8062FF"
      />
      <path
        d="M60.4285 15.6426H13.2838C11.0581 15.6426 9.25391 17.4481 9.25391 19.6753V57.7974C9.25391 60.0246 11.0581 61.8301 13.2838 61.8301H60.4285C62.6541 61.8301 64.4583 60.0246 64.4583 57.7974V19.6753C64.4583 17.4481 62.6541 15.6426 60.4285 15.6426Z"
        fill="#8062FF"
      />
      <path
        d="M61.11 15.27H13.442C11.2163 15.27 9.41211 17.0755 9.41211 19.3028V57.7946C9.41211 60.0219 11.2163 61.8274 13.442 61.8274H61.11C63.3356 61.8274 65.1398 60.0219 65.1398 57.7946V19.3028C65.1398 17.0755 63.3356 15.27 61.11 15.27Z"
        fill="#8062FF"
      />
      <path
        d="M61.7914 14.8975H13.6002C11.3745 14.8975 9.57031 16.703 9.57031 18.9302V57.7919C9.57031 60.0191 11.3745 61.8246 13.6002 61.8246H61.7914C64.0171 61.8246 65.8213 60.0191 65.8213 57.7919V18.9302C65.8213 16.703 64.0171 14.8975 61.7914 14.8975Z"
        fill="#8062FF"
      />
      <path
        d="M62.4737 14.5249H13.7537C11.5296 14.5249 9.72656 16.3292 9.72656 18.5549V57.7918C9.72656 60.0176 11.5296 61.8219 13.7537 61.8219H62.4737C64.6978 61.8219 66.5009 60.0176 66.5009 57.7918V18.5549C66.5009 16.3292 64.6978 14.5249 62.4737 14.5249Z"
        fill="#8062FF"
      />
      <path
        d="M63.1532 14.1523H13.91C11.6858 14.1523 9.88281 15.9567 9.88281 18.1824V57.7864C9.88281 60.0121 11.6858 61.8164 13.91 61.8164H63.1532C65.3774 61.8164 67.1804 60.0121 67.1804 57.7864V18.1824C67.1804 15.9567 65.3774 14.1523 63.1532 14.1523Z"
        fill="#8062FF"
      />
      <path
        d="M63.8328 13.7798H14.0635C11.8409 13.7798 10.0391 15.5829 10.0391 17.8071V57.7863C10.0391 60.0106 11.8409 61.8137 14.0635 61.8137H63.8328C66.0554 61.8137 67.8573 60.0106 67.8573 57.7863V17.8071C67.8573 15.5829 66.0554 13.7798 63.8328 13.7798Z"
        fill="#8062FF"
      />
      <path
        d="M64.5123 13.4072H14.2198C11.9971 13.4072 10.1953 15.2103 10.1953 17.4346V57.7836C10.1953 60.0078 11.9971 61.8109 14.2198 61.8109H64.5123C66.735 61.8109 68.5368 60.0078 68.5368 57.7836V17.4346C68.5368 15.2103 66.735 13.4072 64.5123 13.4072Z"
        fill="#8062FF"
      />
      <path
        d="M65.1918 13.0347H14.376C12.1534 13.0347 10.3516 14.8378 10.3516 17.062V57.7808C10.3516 60.005 12.1534 61.8081 14.376 61.8081H65.1918C67.4145 61.8081 69.2163 60.005 69.2163 57.7808V17.062C69.2163 14.8378 67.4145 13.0347 65.1918 13.0347Z"
        fill="#8062FF"
      />
      <path
        d="M65.876 12.6626H14.5315C12.3104 12.6626 10.5098 14.4645 10.5098 16.6872V57.7812C10.5098 60.004 12.3104 61.8059 14.5315 61.8059H65.876C68.0972 61.8059 69.8978 60.004 69.8978 57.7812V16.6872C69.8978 14.4645 68.0972 12.6626 65.876 12.6626Z"
        fill="#8062FF"
      />
      <path
        d="M66.5548 12.29H14.6898C12.4686 12.29 10.668 14.0919 10.668 16.3147V57.7785C10.668 60.0012 12.4686 61.8031 14.6898 61.8031H66.5548C68.776 61.8031 70.5766 60.0012 70.5766 57.7785V16.3147C70.5766 14.0919 68.776 12.29 66.5548 12.29Z"
        fill="#8062FF"
      />
      <path
        d="M67.2371 11.9175H14.8433C12.6236 11.9175 10.8242 13.7182 10.8242 15.9394V57.7784C10.8242 59.9997 12.6236 61.8004 14.8433 61.8004H67.2371C69.4567 61.8004 71.2562 59.9997 71.2562 57.7784V15.9394C71.2562 13.7182 69.4567 11.9175 67.2371 11.9175Z"
        fill="#8062FF"
      />
      <path
        d="M67.9166 11.5449H14.9996C12.7799 11.5449 10.9805 13.3456 10.9805 15.5669V57.7757C10.9805 59.9969 12.7799 61.7976 14.9996 61.7976H67.9166C70.1363 61.7976 71.9357 59.9969 71.9357 57.7757V15.5669C71.9357 13.3456 70.1363 11.5449 67.9166 11.5449Z"
        fill="#8062FF"
      />
      <path
        d="M68.5961 11.1724H15.1558C12.9361 11.1724 11.1367 12.973 11.1367 15.1943V57.7729C11.1367 59.9942 12.9361 61.7949 15.1558 61.7949H68.5961C70.8158 61.7949 72.6152 59.9942 72.6152 57.7729V15.1943C72.6152 12.973 70.8158 11.1724 68.5961 11.1724Z"
        fill="#8062FF"
      />
      <path
        d="M69.2757 10.7998H15.3094C13.0912 10.7998 11.293 12.5993 11.293 14.819V57.7702C11.293 59.9899 13.0912 61.7894 15.3094 61.7894H69.2757C71.4939 61.7894 73.2921 59.9899 73.2921 57.7702V14.819C73.2921 12.5993 71.4939 10.7998 69.2757 10.7998Z"
        fill="#8062FF"
      />
      <path
        d="M69.9572 10.4272H15.4676C13.2494 10.4272 11.4512 12.2267 11.4512 14.4465V57.7674C11.4512 59.9872 13.2494 61.7866 15.4676 61.7866H69.9572C72.1754 61.7866 73.9736 59.9872 73.9736 57.7674V14.4465C73.9736 12.2267 72.1754 10.4272 69.9572 10.4272Z"
        fill="#8062FF"
      />
      <path
        d="M70.6394 10.0547H15.6211C13.4044 10.0547 11.6074 11.853 11.6074 14.0712V57.7673C11.6074 59.9856 13.4044 61.7839 15.6211 61.7839H70.6394C72.8561 61.7839 74.6531 59.9856 74.6531 57.7673V14.0712C74.6531 11.853 72.8561 10.0547 70.6394 10.0547Z"
        fill="#8062FF"
      />
      <path
        d="M71.3209 9.68213H15.7793C13.5626 9.68213 11.7656 11.4804 11.7656 13.6987V57.7646C11.7656 59.9829 13.5626 61.7811 15.7793 61.7811H71.3209C73.5376 61.7811 75.3346 59.9829 75.3346 57.7646V13.6987C75.3346 11.4804 73.5376 9.68213 71.3209 9.68213Z"
        fill="#8062FF"
      />
      <path
        d="M71.9978 9.31006H15.9356C13.7189 9.31006 11.9219 11.1083 11.9219 13.3266V57.7623C11.9219 59.9806 13.7189 61.7789 15.9356 61.7789H71.9978C74.2145 61.7789 76.0114 59.9806 76.0114 57.7623V13.3266C76.0114 11.1083 74.2145 9.31006 71.9978 9.31006Z"
        fill="#8062FF"
      />
      <path
        d="M72.68 8.9375H16.0891C13.8739 8.9375 12.0781 10.7346 12.0781 12.9513V57.7623C12.0781 59.979 13.8739 61.7761 16.0891 61.7761H72.68C74.8952 61.7761 76.691 59.979 76.691 57.7623V12.9513C76.691 10.7346 74.8952 8.9375 72.68 8.9375Z"
        fill="#8062FF"
      />
      <path
        d="M73.3595 8.56494H16.2454C14.0302 8.56494 12.2344 10.362 12.2344 12.5788V57.7595C12.2344 59.9763 14.0302 61.7733 16.2454 61.7733H73.3595C75.5747 61.7733 77.3705 59.9763 77.3705 57.7595V12.5788C77.3705 10.362 75.5747 8.56494 73.3595 8.56494Z"
        fill="#8062FF"
      />
      <path
        d="M74.0437 8.19238H16.4009C14.1872 8.19238 12.3926 9.98824 12.3926 12.2035V57.7595C12.3926 59.9747 14.1872 61.7706 16.4009 61.7706H74.0437C76.2574 61.7706 78.052 59.9747 78.052 57.7595V12.2035C78.052 9.98824 76.2574 8.19238 74.0437 8.19238Z"
        fill="#8062FF"
      />
      <path
        d="M74.7206 7.81982H16.5571C14.3434 7.81982 12.5488 9.61568 12.5488 11.831V57.754C12.5488 59.9693 14.3434 61.7651 16.5571 61.7651H74.7206C76.9343 61.7651 78.7289 59.9693 78.7289 57.754V11.831C78.7289 9.61568 76.9343 7.81982 74.7206 7.81982Z"
        fill="#8062FF"
      />
      <path
        d="M75.4021 7.44727H16.7153C14.5016 7.44727 12.707 9.24312 12.707 11.4584V57.7512C12.707 59.9665 14.5016 61.7624 16.7153 61.7624H75.4021C77.6158 61.7624 79.4103 59.9665 79.4103 57.7512V11.4584C79.4103 9.24312 77.6158 7.44727 75.4021 7.44727Z"
        fill="#8062FF"
      />
      <path
        d="M76.0843 7.07764H16.8689C14.6566 7.07764 12.8633 8.87228 12.8633 11.0861V57.7541C12.8633 59.9679 14.6566 61.7626 16.8689 61.7626H76.0843C78.2965 61.7626 80.0899 59.9679 80.0899 57.7541V11.0861C80.0899 8.87228 78.2965 7.07764 76.0843 7.07764Z"
        fill="#8062FF"
      />
      <path
        d="M76.7638 6.70508H17.0251C14.8129 6.70508 13.0195 8.49972 13.0195 10.7135V57.7514C13.0195 59.9652 14.8129 61.7598 17.0251 61.7598H76.7638C78.9761 61.7598 80.7694 59.9652 80.7694 57.7514V10.7135C80.7694 8.49972 78.9761 6.70508 76.7638 6.70508Z"
        fill="#8062FF"
      />
      <path
        d="M77.4434 6.33252H17.1787C14.9679 6.33252 13.1758 8.12595 13.1758 10.3383V57.7513C13.1758 59.9636 14.9679 61.757 17.1787 61.757H77.4434C79.6541 61.757 81.4463 59.9636 81.4463 57.7513V10.3383C81.4463 8.12595 79.6541 6.33252 77.4434 6.33252Z"
        fill="#8062FF"
      />
      <path
        d="M78.1249 5.95996H17.3369C15.1261 5.95996 13.334 7.7534 13.334 9.96571V57.7485C13.334 59.9609 15.1261 61.7543 17.3369 61.7543H78.1249C80.3356 61.7543 82.1278 59.9609 82.1278 57.7485V9.96571C82.1278 7.7534 80.3356 5.95996 78.1249 5.95996Z"
        fill="#8062FF"
      />
      <path
        d="M78.8044 5.5874H17.4931C15.2824 5.5874 13.4902 7.38084 13.4902 9.59315V57.7458C13.4902 59.9581 15.2824 61.7515 17.4931 61.7515H78.8044C81.0152 61.7515 82.8073 59.9581 82.8073 57.7458V9.59315C82.8073 7.38084 81.0152 5.5874 78.8044 5.5874Z"
        fill="#8062FF"
      />
      <path
        d="M79.4867 5.21484H17.6467C15.4374 5.21484 13.6465 7.00707 13.6465 9.21789V57.7457C13.6465 59.9566 15.4374 61.7488 17.6467 61.7488H79.4867C81.6959 61.7488 83.4869 59.9566 83.4869 57.7457V9.21789C83.4869 7.00707 81.6959 5.21484 79.4867 5.21484Z"
        fill="#8062FF"
      />
      <path
        d="M80.1655 4.84229H17.8049C15.5956 4.84229 13.8047 6.63451 13.8047 8.84533V57.743C13.8047 59.9538 15.5956 61.746 17.8049 61.746H80.1655C82.3747 61.746 84.1656 59.9538 84.1656 57.743V8.84533C84.1656 6.63451 82.3747 4.84229 80.1655 4.84229Z"
        fill="#8062FF"
      />
      <path
        d="M80.8477 4.47021H17.9584C15.7507 4.47021 13.9609 6.26123 13.9609 8.47056V57.7407C13.9609 59.95 15.7507 61.7411 17.9584 61.7411H80.8477C83.0554 61.7411 84.8452 59.95 84.8452 57.7407V8.47056C84.8452 6.26123 83.0554 4.47021 80.8477 4.47021Z"
        fill="#8062FF"
      />
      <path
        d="M81.5272 4.09766H18.1147C15.9069 4.09766 14.1172 5.88867 14.1172 8.098V57.738C14.1172 59.9473 15.9069 61.7383 18.1147 61.7383H81.5272C83.735 61.7383 85.5247 59.9473 85.5247 57.738V8.098C85.5247 5.88867 83.735 4.09766 81.5272 4.09766Z"
        fill="#8062FF"
      />
      <path
        d="M82.2087 3.7251H18.2729C16.0651 3.7251 14.2754 5.51611 14.2754 7.72545V57.7352C14.2754 59.9445 16.0651 61.7355 18.2729 61.7355H82.2087C84.4165 61.7355 86.2062 59.9445 86.2062 57.7352V7.72545C86.2062 5.51611 84.4165 3.7251 82.2087 3.7251Z"
        fill="#8062FF"
      />
      <path
        d="M82.891 3.35254H18.4265C16.2202 3.35254 14.4316 5.14235 14.4316 7.35019V57.7351C14.4316 59.943 16.2202 61.7328 18.4265 61.7328H82.891C85.0972 61.7328 86.8858 59.943 86.8858 57.7351V7.35019C86.8858 5.14235 85.0972 3.35254 82.891 3.35254Z"
        fill="#8062FF"
      />
      <path
        d="M83.5678 2.97998H18.5827C16.3764 2.97998 14.5879 4.76979 14.5879 6.97763V57.7324C14.5879 59.9402 16.3764 61.73 18.5827 61.73H83.5678C85.7741 61.73 87.5626 59.9402 87.5626 57.7324V6.97763C87.5626 4.76979 85.7741 2.97998 83.5678 2.97998Z"
        fill="#8062FF"
      />
      <path
        d="M84.25 2.60742H18.7363C16.5315 2.60742 14.7441 4.39602 14.7441 6.60237V57.7323C14.7441 59.9387 16.5315 61.7273 18.7363 61.7273H84.25C86.4548 61.7273 88.2422 59.9387 88.2422 57.7323V6.60237C88.2422 4.39602 86.4548 2.60742 84.25 2.60742Z"
        fill="#8062FF"
      />
      <path
        d="M84.9315 2.23486H18.8945C16.6897 2.23486 14.9023 4.02346 14.9023 6.22981V57.7296C14.9023 59.9359 16.6897 61.7245 18.8945 61.7245H84.9315C87.1363 61.7245 88.9236 59.9359 88.9236 57.7296V6.22981C88.9236 4.02346 87.1363 2.23486 84.9315 2.23486Z"
        fill="#8062FF"
      />
      <path
        d="M85.6111 1.8623H19.0507C16.8459 1.8623 15.0586 3.65091 15.0586 5.85725V57.7268C15.0586 59.9332 16.8459 61.7218 19.0507 61.7218H85.6111C87.8158 61.7218 89.6032 59.9332 89.6032 57.7268V5.85725C89.6032 3.65091 87.8158 1.8623 85.6111 1.8623Z"
        fill="#8062FF"
      />
      <path
        d="M86.2926 1.49023H19.2062C17.0029 1.49023 15.2168 3.27762 15.2168 5.48248V57.7272C15.2168 59.9321 17.0029 61.7195 19.2062 61.7195H86.2926C88.4959 61.7195 90.282 59.9321 90.282 57.7272V5.48248C90.282 3.27762 88.4959 1.49023 86.2926 1.49023Z"
        fill="#8062FF"
      />
      <path
        d="M86.9721 1.11768H19.3625C17.1592 1.11768 15.373 2.90507 15.373 5.10993V57.7218C15.373 59.9266 17.1592 61.714 19.3625 61.714H86.9721C89.1754 61.714 90.9615 59.9266 90.9615 57.7218V5.10993C90.9615 2.90507 89.1754 1.11768 86.9721 1.11768Z"
        fill="#8062FF"
      />
      <path
        d="M87.6543 0.745117H19.516C17.3142 0.745117 15.5293 2.5313 15.5293 4.73467V57.7217C15.5293 59.9251 17.3142 61.7113 19.516 61.7113H87.6543C89.8561 61.7113 91.6411 59.9251 91.6411 57.7217V4.73467C91.6411 2.5313 89.8561 0.745117 87.6543 0.745117Z"
        fill="#8062FF"
      />
      <path
        d="M88.3339 0.372559H19.6723C17.4705 0.372559 15.6855 2.15874 15.6855 4.36211V57.719C15.6855 59.9223 17.4705 61.7085 19.6723 61.7085H88.3339C90.5357 61.7085 92.3206 59.9223 92.3206 57.719V4.36211C92.3206 2.15874 90.5357 0.372559 88.3339 0.372559Z"
        fill="#8062FF"
      />
      <path
        d="M89.0127 0H19.8305C17.6287 0 15.8438 1.78618 15.8438 3.98955V57.7162C15.8438 59.9196 17.6287 61.7058 19.8305 61.7058H89.0127C91.2145 61.7058 92.9994 59.9196 92.9994 57.7162V3.98955C92.9994 1.78618 91.2145 0 89.0127 0Z"
        fill="#8062FF"
      />
      <path
        d="M24.3811 39.0158H36.6164C37.3339 39.0158 37.8868 38.7431 38.2753 38.1952C38.6637 37.6499 38.8579 36.8887 38.8579 35.9089C38.8579 34.929 38.6637 34.2137 38.2753 33.6658C37.8868 33.1205 37.3339 32.8479 36.6164 32.8479H28.1277V31.2958C28.1277 30.9233 28.1709 30.621 28.2572 30.3915C28.3435 30.1621 28.473 29.9543 28.6456 29.7653C28.8183 29.579 29.0907 29.3982 29.4656 29.2254L33.9891 26.8123C35.7397 25.9215 36.9535 24.8148 37.6306 23.4921C38.3049 22.1695 38.6421 20.2746 38.6421 17.802V14.4819C38.6421 12.0391 37.8868 10.1415 36.379 8.79182C34.8712 7.44217 32.7375 6.76465 29.9808 6.76465C27.2241 6.76465 25.1903 7.43947 23.7121 8.79182C22.2313 10.1442 21.4922 12.0418 21.4922 14.4819V16.7682C21.4922 17.5456 21.7781 18.1422 22.3527 18.5578C22.9272 18.9735 23.7445 19.1841 24.8073 19.1841C25.87 19.1841 26.6442 18.9762 27.2214 18.5578C27.796 18.1422 28.0846 17.5456 28.0846 16.7682V14.4819C28.0846 13.8503 28.241 13.3752 28.5593 13.0594C28.8749 12.7436 29.3496 12.5843 29.9808 12.5843C30.612 12.5843 31.0921 12.7436 31.4239 13.0594C31.753 13.3752 31.9202 13.8503 31.9202 14.4819V17.802C31.9202 18.5497 31.8204 19.203 31.6181 19.7644C31.4158 20.3259 31.0868 20.836 30.6282 21.2949C30.1696 21.7538 29.5223 22.2154 28.6888 22.6743L25.7163 24.2264C24.079 25.1171 22.938 26.0889 22.2906 27.1362C21.6433 28.1862 21.3223 29.687 21.3223 31.6413V35.9521C21.3223 36.9292 21.5866 37.685 22.1207 38.2168C22.6521 38.7485 23.4046 39.0158 24.3838 39.0158H24.3811Z"
        fill="white"
      />
      <path
        d="M44.1801 37.3772C45.6583 38.7296 47.7488 39.4044 50.4488 39.4044C53.1489 39.4044 55.3392 38.7296 56.847 37.3772C58.3548 36.0276 59.1101 34.13 59.1101 31.6871V25.4788C59.1101 23.6675 58.5868 22.2396 57.5375 21.1896C56.4882 20.1396 55.0748 19.6159 53.2945 19.6159H48.9868C48.8439 19.6159 48.7711 19.5457 48.7711 19.4V13.4075C48.7711 13.1484 48.9005 13.0188 49.1595 13.0188H52.1751C52.4341 13.0188 52.5636 13.1484 52.5636 13.4075V14.9165C52.5636 15.6938 52.8441 16.2904 53.4051 16.7061C53.9662 17.1218 54.7754 17.3323 55.8382 17.3323C56.9009 17.3323 57.7182 17.1245 58.2955 16.7061C58.87 16.2904 59.1559 15.6938 59.1559 14.9165V10.6921C59.1559 9.59885 58.8403 8.73778 58.2092 8.10614C57.578 7.47451 56.7148 7.15869 55.6251 7.15869H45.7149C44.6225 7.15869 43.762 7.47451 43.1309 8.10614C42.4997 8.73778 42.1841 9.60155 42.1841 10.6921V21.9454C42.1841 23.0386 42.4997 23.8943 43.1309 24.5097C43.762 25.1279 44.6252 25.4356 45.7149 25.4356H50.7995C51.3174 25.4356 51.7031 25.5732 51.962 25.8459C52.221 26.1185 52.3505 26.5153 52.3505 27.0309V31.6871C52.3505 32.3188 52.1913 32.7938 51.8757 33.1097C51.5601 33.4255 51.0854 33.5847 50.4542 33.5847C49.823 33.5847 49.3402 33.4282 49.0111 33.1097C48.6793 32.7938 48.5148 32.3188 48.5148 31.6871V30.7829C48.5148 29.9785 48.2262 29.3738 47.6516 28.9717C47.0771 28.5695 46.2868 28.367 45.2807 28.367C44.2179 28.367 43.3979 28.5695 42.826 28.9717C42.2515 29.3738 41.9629 29.9785 41.9629 30.7829V31.6871C41.9629 34.13 42.702 36.0276 44.1828 37.3772H44.1801Z"
        fill="white"
      />
      <path
        d="M83.3665 27.5466C82.4764 26.7126 81.241 26.2969 79.6603 26.2969C78.0797 26.2969 76.8524 26.7126 75.9757 27.5466C75.0991 28.3807 74.6621 29.5441 74.6621 31.0395V34.662C74.6621 36.1871 75.0937 37.3559 75.9541 38.1765C76.8146 38.9943 78.05 39.4046 79.6603 39.4046C81.2707 39.4046 82.4737 38.9943 83.3665 38.1765C84.2567 37.3559 84.7017 36.1871 84.7017 34.662V31.0395C84.7017 29.5441 84.2567 28.3807 83.3665 27.5466ZM80.6071 34.6188C80.6071 34.9346 80.5289 35.1802 80.3697 35.353C80.2106 35.5257 79.9894 35.6121 79.7008 35.6121C79.3852 35.6121 79.1397 35.5257 78.9698 35.353C78.7972 35.1802 78.7109 34.9373 78.7109 34.6188V31.0827C78.7109 30.7669 78.7972 30.5213 78.9698 30.3485C79.1424 30.1758 79.3717 30.0894 79.6603 30.0894C79.9759 30.0894 80.2133 30.1758 80.3724 30.3485C80.5289 30.5213 80.6098 30.7642 80.6098 31.0827V34.6188H80.6071Z"
        fill="white"
      />
      <path
        d="M66.9945 19.9156C68.5752 19.9156 69.8079 19.4999 70.7007 18.6658C71.5908 17.8317 72.0359 16.6683 72.0359 15.1729V11.5505C72.0359 10.0281 71.5908 8.8485 70.7007 8.01442C69.8106 7.18034 68.5752 6.76465 66.9945 6.76465C65.4139 6.76465 64.1434 7.18034 63.2668 8.01442C62.3901 8.8485 61.9531 10.0281 61.9531 11.5505V15.1729C61.9531 16.6683 62.3901 17.8317 63.2668 18.6658C64.1434 19.4999 65.3842 19.9156 66.9945 19.9156ZM66.045 11.5937C66.045 11.2482 66.1233 10.9836 66.2824 10.7947C66.4389 10.6084 66.6627 10.514 66.9514 10.514C67.2669 10.514 67.5043 10.6084 67.6635 10.7947C67.8199 10.9809 67.9008 11.2482 67.9008 11.5937V15.1297C67.9008 15.4456 67.8226 15.6912 67.6635 15.864C67.5043 16.0367 67.2831 16.1231 66.9945 16.1231C66.7059 16.1231 66.4766 16.0367 66.304 15.864C66.1314 15.6912 66.045 15.4483 66.045 15.1297V11.5937Z"
        fill="white"
      />
      <path
        d="M64.7735 38.2196C65.3912 38.8674 66.2598 39.1887 67.3792 39.1887C67.9241 39.1887 68.3556 39.1023 68.6712 38.9295C68.9868 38.7568 69.2458 38.5138 69.4481 38.1953C69.6477 37.8795 69.8797 37.4179 70.1386 36.816L70.3976 36.1682L82.1176 10.4277C82.4036 9.79609 82.4629 9.20764 82.2903 8.65969C82.1176 8.11443 81.7508 7.69604 81.1924 7.40991C80.6314 7.12379 79.9921 6.97803 79.2746 6.97803C78.5571 6.97803 77.9826 7.15888 77.6373 7.51789C77.292 7.87689 76.9333 8.50313 76.5611 9.39389L76.3021 9.99853L64.5389 35.739C64.0776 36.7458 64.1585 37.5718 64.7762 38.2169L64.7735 38.2196Z"
        fill="white"
      />
      <path
        d="M26.0043 44.1202C25.6348 43.9231 25.2167 43.8232 24.75 43.8232H22.9293C22.6137 43.8232 22.3521 43.9258 22.1498 44.1283C21.9475 44.3307 21.8477 44.5898 21.8477 44.9084V52.515C21.8477 52.8227 21.9502 53.0791 22.1498 53.2816C22.3521 53.484 22.611 53.5866 22.9293 53.5866H24.75C25.2167 53.5866 25.6348 53.4867 26.0043 53.2897C26.3738 53.0926 26.6598 52.8119 26.8621 52.4502C27.0644 52.0885 27.1642 51.6809 27.1642 51.222V46.204C27.1642 45.737 27.0617 45.324 26.8621 44.9623C26.6598 44.6006 26.3738 44.3226 26.0043 44.1229V44.1202ZM25.0926 51.1518C25.0926 51.3894 25.036 51.5648 24.92 51.6809C24.8067 51.7942 24.634 51.8536 24.4048 51.8536H23.9165V45.5535H24.4048C24.634 45.5535 24.804 45.6129 24.92 45.7316C25.036 45.8504 25.0926 46.0232 25.0926 46.2526V51.1518Z"
        fill="white"
      />
      <path
        d="M32.2339 45.7098C32.4524 45.7098 32.6224 45.6288 32.741 45.4642C32.8597 45.3022 32.9191 45.0701 32.9191 44.7705C32.9191 44.4708 32.8597 44.2387 32.741 44.0713C32.6224 43.904 32.4524 43.8203 32.2339 43.8203H29.2129C28.8865 43.8203 28.6302 43.9094 28.4414 44.0848C28.2526 44.2603 28.1582 44.4951 28.1582 44.784V52.6146C28.1582 52.9142 28.2526 53.1491 28.4414 53.3218C28.6302 53.4946 28.8892 53.5782 29.2129 53.5782H32.2339C32.4524 53.5782 32.6224 53.4946 32.741 53.3272C32.8597 53.1599 32.9191 52.9277 32.9191 52.6281C32.9191 52.3285 32.8597 52.1098 32.741 51.9425C32.6224 51.7751 32.4524 51.6914 32.2339 51.6914H30.2271V50.0017H32.153C32.3742 50.0017 32.5414 49.9207 32.6601 49.7561C32.7788 49.5941 32.8381 49.362 32.8381 49.0623C32.8381 48.7627 32.7788 48.5306 32.6601 48.3632C32.5414 48.1959 32.3715 48.1122 32.153 48.1122H30.2271V45.7098H32.2339Z"
        fill="white"
      />
      <path
        d="M40.4145 44.1202C40.0449 43.9231 39.6268 43.8232 39.1602 43.8232H37.3395C37.0239 43.8232 36.7622 43.9258 36.5599 44.1283C36.3576 44.3307 36.2578 44.5898 36.2578 44.9084V52.515C36.2578 52.8227 36.3603 53.0791 36.5599 53.2816C36.7622 53.484 37.0212 53.5866 37.3395 53.5866H39.1602C39.6268 53.5866 40.0449 53.4867 40.4145 53.2897C40.784 53.0926 41.0699 52.8119 41.2722 52.4502C41.4745 52.0885 41.5743 51.6809 41.5743 51.222V46.204C41.5743 45.737 41.4718 45.324 41.2722 44.9623C41.0699 44.6006 40.784 44.3226 40.4145 44.1229V44.1202ZM39.5055 51.1518C39.5055 51.3894 39.4488 51.5648 39.3328 51.6809C39.2195 51.7942 39.0469 51.8536 38.8176 51.8536H38.3294V45.5535H38.8176C39.0469 45.5535 39.2168 45.6129 39.3328 45.7316C39.4488 45.8504 39.5055 46.0232 39.5055 46.2526V51.1518Z"
        fill="white"
      />
      <path
        d="M46.646 45.7098C46.8645 45.7098 47.0345 45.6288 47.1531 45.4642C47.2718 45.3022 47.3312 45.0701 47.3312 44.7705C47.3312 44.4708 47.2718 44.2387 47.1531 44.0713C47.0345 43.904 46.8645 43.8203 46.646 43.8203H43.625C43.2986 43.8203 43.0424 43.9094 42.8535 44.0848C42.6647 44.2603 42.5703 44.4951 42.5703 44.784V52.6146C42.5703 52.9142 42.6647 53.1491 42.8535 53.3218C43.0424 53.4946 43.3013 53.5782 43.625 53.5782H46.646C46.8645 53.5782 47.0345 53.4946 47.1531 53.3272C47.2718 53.1599 47.3312 52.9277 47.3312 52.6281C47.3312 52.3285 47.2718 52.1098 47.1531 51.9425C47.0345 51.7751 46.8645 51.6914 46.646 51.6914H44.6392V50.0017H46.5651C46.7863 50.0017 46.9535 49.9207 47.0722 49.7561C47.1909 49.5941 47.2503 49.362 47.2503 49.0623C47.2503 48.7627 47.1909 48.5306 47.0722 48.3632C46.9535 48.1959 46.7836 48.1122 46.5651 48.1122H44.6392V45.7098H46.646Z"
        fill="white"
      />
      <path
        d="M52.0688 49.0921L51.265 48.6683C50.9926 48.528 50.7822 48.3822 50.6311 48.2337C50.4828 48.0853 50.3749 47.9206 50.3075 47.7452C50.24 47.5697 50.2077 47.37 50.2077 47.1513V46.0689C50.2077 45.8746 50.2589 45.7315 50.3587 45.6343C50.4612 45.5372 50.6042 45.4886 50.7876 45.4886C50.971 45.4886 51.1275 45.5372 51.23 45.6343C51.3298 45.7315 51.381 45.8773 51.381 46.0689V46.822C51.381 47.0596 51.47 47.2431 51.6454 47.37C51.8207 47.4968 52.0688 47.5616 52.3844 47.5616C52.7 47.5616 52.9482 47.4968 53.1235 47.37C53.2988 47.2431 53.3879 47.0596 53.3879 46.822V46.1364C53.3879 45.3617 53.1613 44.7625 52.7081 44.3414C52.255 43.9176 51.6157 43.707 50.7876 43.707C49.9595 43.707 49.331 43.9176 48.8751 44.3414C48.4166 44.7652 48.19 45.3617 48.19 46.1364V47.154C48.19 47.648 48.2575 48.0745 48.3869 48.4362C48.5191 48.7979 48.7268 49.1218 49.0127 49.4079C49.2986 49.6941 49.679 49.9613 50.1537 50.2069L50.9845 50.6415C51.1436 50.7198 51.2569 50.8062 51.3271 50.8979C51.3972 50.9897 51.4323 51.1031 51.4323 51.2354C51.4323 51.4297 51.3702 51.5836 51.2488 51.6969C51.1248 51.8103 50.9548 51.8697 50.7336 51.8697C50.5745 51.8697 50.4234 51.8508 50.2778 51.8103C50.1321 51.7698 49.9595 51.7077 49.7572 51.6187L49.7032 51.5917L49.5441 51.5269C49.3229 51.4216 49.153 51.3676 49.0289 51.3676C48.7376 51.3676 48.5137 51.4729 48.3546 51.6834C48.1954 51.894 48.1172 52.1288 48.1172 52.3826C48.1172 52.5418 48.1576 52.6849 48.2413 52.8117C48.3249 52.9386 48.4463 53.0574 48.6054 53.1626C48.8698 53.3219 49.18 53.4515 49.536 53.5513C49.8921 53.6512 50.2724 53.7025 50.677 53.7025C51.5402 53.7025 52.2172 53.4839 52.7108 53.0412C53.2044 52.6012 53.4499 52.002 53.4499 51.2462C53.4499 50.7711 53.3366 50.3554 53.1127 49.9991C52.8888 49.6428 52.5382 49.3405 52.0634 49.0948L52.0688 49.0921Z"
        fill="white"
      />
      <path
        d="M58.9284 44.3254C58.4618 43.9124 57.8063 43.7046 56.9621 43.7046C56.1178 43.7046 55.4758 43.9124 55.0092 44.3254C54.5425 44.7384 54.3105 45.3215 54.3105 46.0692V50.8496C54.3105 51.7566 54.5695 52.4584 55.0901 52.9551C55.6107 53.4517 56.339 53.7001 57.2804 53.7001C57.9844 53.7001 58.5508 53.5894 58.9824 53.3707C59.1928 53.2736 59.3519 53.1521 59.4571 53.0063C59.5623 52.8606 59.6163 52.6959 59.6163 52.5124C59.6163 52.2829 59.5461 52.0643 59.4059 51.8591C59.2656 51.6513 59.066 51.5487 58.8125 51.5487C58.7585 51.5487 58.6884 51.5568 58.6021 51.5757C58.5589 51.5838 58.5131 51.5973 58.4699 51.6162C58.1354 51.7053 57.8279 51.7485 57.5474 51.7485C57.1509 51.7485 56.8596 51.6594 56.6707 51.4839C56.4819 51.3085 56.3875 51.0305 56.3875 50.6526V46.0692C56.3875 45.8748 56.4388 45.7318 56.5386 45.6346C56.6411 45.5374 56.784 45.4888 56.9675 45.4888C57.1509 45.4888 57.3073 45.5374 57.4098 45.6346C57.5123 45.7318 57.5609 45.8775 57.5609 46.0692V47.7076C57.5609 47.9452 57.6499 48.1287 57.8306 48.2556C58.0113 48.3825 58.2649 48.4472 58.5886 48.4472C58.9123 48.4472 59.1793 48.3825 59.36 48.2556C59.5408 48.1287 59.6298 47.9452 59.6298 47.7076V46.0692C59.6298 45.3215 59.3951 44.7411 58.9311 44.3254H58.9284Z"
        fill="white"
      />
      <path
        d="M63.2062 43.7046C62.37 43.7046 61.72 43.9151 61.2533 44.3308C60.7867 44.7492 60.5547 45.3269 60.5547 46.0665V51.3355C60.5547 52.0832 60.7867 52.6662 61.2533 53.0792C61.72 53.4922 62.37 53.7001 63.2062 53.7001C64.0424 53.7001 64.7059 53.4922 65.1726 53.0792C65.6392 52.6662 65.8712 52.0832 65.8712 51.3355V46.0665C65.8712 45.3269 65.6365 44.7492 65.1726 44.3308C64.7059 43.9124 64.0505 43.7046 63.2062 43.7046ZM63.7996 51.3382C63.7996 51.5325 63.7484 51.6783 63.6486 51.7728C63.5488 51.8672 63.4004 51.9185 63.2062 51.9185C63.012 51.9185 62.8771 51.8699 62.7773 51.7728C62.6775 51.6756 62.6263 51.5298 62.6263 51.3382V46.0692C62.6263 45.8748 62.6775 45.7318 62.7773 45.6346C62.8798 45.5374 63.0228 45.4888 63.2062 45.4888C63.3896 45.4888 63.5461 45.5374 63.6486 45.6346C63.7511 45.7318 63.7996 45.8775 63.7996 46.0692V51.3382Z"
        fill="white"
      />
      <path
        d="M72.1143 43.961C71.939 43.8341 71.6962 43.7693 71.3887 43.7693C71.0812 43.7693 70.8223 43.8341 70.6442 43.961C70.4635 44.0878 70.3745 44.2714 70.3745 44.5089V48.8791L68.9881 44.4954C68.9179 44.2579 68.7912 44.077 68.6131 43.9529C68.4324 43.8287 68.1842 43.7666 67.8687 43.7666C67.5531 43.7666 67.3049 43.8314 67.1296 43.9583C66.9542 44.0851 66.8652 44.2687 66.8652 44.5062V52.9064C66.8652 53.1358 66.9542 53.314 67.1296 53.4409C67.3049 53.5677 67.5477 53.6325 67.8552 53.6325C68.1627 53.6325 68.4216 53.5677 68.5996 53.4409C68.7804 53.314 68.8694 53.1358 68.8694 52.9064V48.4175H68.8829L70.2963 52.9064C70.3664 53.152 70.4878 53.3356 70.6604 53.4544C70.8331 53.5731 71.0758 53.6325 71.3914 53.6325C71.707 53.6325 71.9417 53.5677 72.117 53.4409C72.2923 53.314 72.3814 53.1358 72.3814 52.9064V44.5062C72.3814 44.2687 72.2923 44.0851 72.117 43.9583L72.1143 43.961Z"
        fill="white"
      />
      <path
        d="M77.6847 43.8232H73.7924C73.5631 43.8232 73.3905 43.9069 73.2718 44.0743C73.1531 44.2416 73.0938 44.4738 73.0938 44.7734C73.0938 45.073 73.1531 45.3052 73.2718 45.4671C73.3905 45.6291 73.5631 45.7127 73.7924 45.7127H74.7041V52.8983C74.7041 53.1358 74.7931 53.3193 74.9738 53.4462C75.1545 53.5731 75.4081 53.6379 75.7318 53.6379C76.0555 53.6379 76.3225 53.5731 76.5032 53.4462C76.6839 53.3193 76.773 53.1358 76.773 52.8983V45.7127H77.6847C77.9059 45.7127 78.0731 45.6318 78.1918 45.4671C78.3105 45.3052 78.3698 45.073 78.3698 44.7734C78.3698 44.4738 78.3105 44.2416 78.1918 44.0743C78.0731 43.9069 77.9032 43.8232 77.6847 43.8232Z"
        fill="white"
      />
      <path
        d="M81.6808 43.7046C80.8446 43.7046 80.1946 43.9151 79.7279 44.3308C79.2613 44.7492 79.0293 45.3269 79.0293 46.0665V51.3355C79.0293 52.0832 79.2613 52.6662 79.7279 53.0792C80.1946 53.4922 80.8446 53.7001 81.6808 53.7001C82.517 53.7001 83.1806 53.4922 83.6472 53.0792C84.1139 52.6662 84.3458 52.0832 84.3458 51.3355V46.0665C84.3458 45.3269 84.1112 44.7492 83.6472 44.3308C83.1806 43.9124 82.5251 43.7046 81.6808 43.7046ZM82.2742 51.3382C82.2742 51.5325 82.223 51.6783 82.1232 51.7728C82.0234 51.8672 81.875 51.9185 81.6808 51.9185C81.4866 51.9185 81.3517 51.8699 81.2519 51.7728C81.1521 51.6756 81.1009 51.5298 81.1009 51.3382V46.0692C81.1009 45.8748 81.1521 45.7318 81.2519 45.6346C81.3544 45.5374 81.4974 45.4888 81.6808 45.4888C81.8642 45.4888 82.0207 45.5374 82.1232 45.6346C82.2257 45.7318 82.2742 45.8775 82.2742 46.0692V51.3382Z"
        fill="white"
      />
    </svg>
  )
}

export default Tag25PercentOFF
