import { tv } from 'tailwind-variants'

import Button, { ButtonV2Props } from '@/cmsComponents/ButtonV2'
import HeroImage from '@/cmsComponents/DefaultHero/components/HeroImage'
import { DefaultTagProps } from '@/cmsComponents/DefaultTag'
import { Hero } from '@/cmsComponents/Hero'
import UltraRates from '@/cmsComponents/UltraRates'
import BannerMarquee from '@/components/BannerMarquee'
import CloudinaryImage from '@/components/CloudinaryImage'
import { IconsDescriptionsV2Props, ImagesProps } from '@/types/cms'
import cn from '@/utils/cn'

export enum feesModelTypes {
  Tapton = 'tapton',
  Machine = 'maquininha',
  Link = 'link-de-pagamento'
}
export interface DefaultHeroProps {
  DefaultTag?: DefaultTagProps
  title: string
  iconsDescriptions?: IconsDescriptionsV2Props[]
  description?: string
  images: ImagesProps
  bannerImageOverlay?: ImagesProps
  bannerButtons?: ButtonV2Props[]
  referId: string
  feesTitle?: string
  feesSubtitle?: string
  mobileDescription?: boolean
  feesButtons?: ButtonV2Props[]
  feesAntecipation?: 'daily' | 'business-day'
  feesBand?: string
  variant: 'default' | 'overlay-image-overboard'
  background: 'default' | 'gradient' | 'custom'
  feesModel:
    | feesModelTypes.Tapton
    | feesModelTypes.Machine
    | feesModelTypes.Link
  imgHash?: string
  hasMarquee?: boolean
}
const styles = tv({
  slots: {
    bannerImageOverlay: 'absolute z-10 ml-16'
  },
  variants: {
    variant: {
      default: {
        bannerImageOverlay: 'mt-16'
      },
      'overlay-image-overboard': {
        bannerImageOverlay: '-translate-y-24 lg:translate-y-0 lg:mt-16'
      }
    }
  }
})

export function DefaultHero({
  DefaultTag,
  bannerButtons,
  images,
  title,
  iconsDescriptions,
  description,
  referId,
  feesTitle,
  feesSubtitle,
  mobileDescription = true,
  feesBand,
  feesButtons,
  imgHash,
  background = 'default',
  bannerImageOverlay,
  variant = 'default',
  feesModel = feesModelTypes.Tapton,
  feesAntecipation = 'business-day',
  hasMarquee = false
}: DefaultHeroProps) {
  const classes = styles({ variant })

  const descriptionTextColor = background === 'custom' ? 'white' : 'default'

  return (
    <div className={feesTitle ? 'bg-display-900' : ''}>
      <Hero.Root background={background}>
        <Hero.Wrapper>
          <div className="flex flex-col gap-16 md:gap-0 justify-center items-center md:items-start mx-auto w-full lg:max-w-[370px] xl:max-w-full">
            {DefaultTag && (
              <Hero.Tag
                variant={DefaultTag.variant}
                direction={DefaultTag.direction}
                label={DefaultTag.label}
              />
            )}

            <Hero.Title
              title={title}
              className="md:order-2 md:mt-16 md:text-left text-newTon-900"
            />

            <Hero.Banner.Container className="md:hidden">
              {bannerImageOverlay && (
                <div className={cn('flex', classes.bannerImageOverlay())}>
                  <CloudinaryImage
                    width={bannerImageOverlay.mobile.width / 2}
                    height={bannerImageOverlay.mobile.height / 2}
                    src={bannerImageOverlay.mobile.path}
                    alt={bannerImageOverlay.mobile.alternativeText}
                    quality={100}
                    priority
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              )}
              <HeroImage
                src={images.mobile.path}
                alt={images.mobile.alternativeText}
                height={images.mobile.height}
                width={images.mobile.width}
                hash={imgHash}
              />
            </Hero.Banner.Container>

            {description && (
              <Hero.Description
                text={description}
                className="hidden md:block md:order-3 md:mt-8 md:text-left"
                textColor={descriptionTextColor}
              />
            )}
            <div className="flex flex-row flex-wrap lg:flex-nowrap md:order-4 gap-16 justify-center lg:justify-start md:mt-16 lg:mt-24 w-full md:w-min max-w-[464px]">
              {bannerButtons?.map((props) => (
                <Button key={props.label} {...props} />
              ))}
            </div>
            {!!iconsDescriptions?.length && (
              <Hero.IconDescriptions
                descriptions={iconsDescriptions}
                className="md:order-3 md:mt-8 md:text-left"
                textColor={descriptionTextColor}
              />
            )}
            {description && mobileDescription && (
              <Hero.Description
                text={description}
                className={cn(
                  'md:order-3 md:mt-8 text-center',
                  mobileDescription ? 'block md:hidden' : 'hidden'
                )}
                textColor={descriptionTextColor}
              />
            )}
          </div>
          <Hero.Banner.Container className="hidden md:flex w-full max-w-max">
            {bannerImageOverlay && (
              <>
                <div
                  className={cn('flex lg:hidden', classes.bannerImageOverlay())}
                >
                  <CloudinaryImage
                    width={bannerImageOverlay.tablet.width / 2}
                    height={bannerImageOverlay.tablet.height / 2}
                    src={bannerImageOverlay.tablet.path}
                    alt={bannerImageOverlay.tablet.alternativeText}
                    quality={100}
                    className="object-cover max-w-full h-auto"
                  />
                </div>
                <div
                  className={cn('hidden lg:flex', classes.bannerImageOverlay())}
                >
                  <CloudinaryImage
                    width={bannerImageOverlay.web.width / 2}
                    height={bannerImageOverlay.web.height / 2}
                    src={bannerImageOverlay.web.path}
                    alt={bannerImageOverlay.web.alternativeText}
                    quality={100}
                    priority
                    className="object-cover max-w-full h-auto"
                  />
                </div>
              </>
            )}
            <HeroImage
              src={images.web.path}
              alt={images.web.alternativeText}
              height={images.web.height}
              width={images.web.width}
              hash={feesModel}
            />
          </Hero.Banner.Container>
        </Hero.Wrapper>
      </Hero.Root>

      {feesTitle && (
        <UltraRates
          id={`fees-${referId}`}
          title={feesTitle}
          subtitle={feesSubtitle}
          band={feesBand}
          buttons={feesButtons}
          feesModel={feesModel}
          feesAntecipation={feesAntecipation}
        />
      )}

      {hasMarquee && <BannerMarquee />}
    </div>
  )
}
