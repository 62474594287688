export function splitArrayInPairsWithRemainder<T>(
  array: T[],
  groupSize: number
): T[][] {
  const matriz = []

  for (let i = 0; i < array.length; i += groupSize) {
    matriz.push(array.slice(i, i + groupSize))
  }

  return matriz
}
