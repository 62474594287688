import CloudinaryImage from '@/components/CloudinaryImage'
import MachinePrice from '@/components/MachinePrice'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import {
  ALL_MACHINES_COMMOM_INFO,
  ALL_USER_TAGS_PLANS_MAPPER
} from '@/constants/machines'
import { ALL_PLANS_COMMOM_INFO } from '@/constants/plans'
import { useMachines } from '@/contexts/machines'
import { MachinesInfoDetails } from '@/types/machines'
import { AllPlansType } from '@/types/plans'

type AnotherMachineCardProps = MachinesInfoDetails

const AnotherMachineCard = ({
  machineName,
  catalog,
  original_price: from,
  amount: to,
  installmentValue: or,
  linkToCheckout,
  linkToProduct,
  installmentParcel
}: AnotherMachineCardProps) => {
  const { machinesInfo } = useMachines()

  const machineInfo = machinesInfo[machineName] || {}

  const machineImage = machineInfo.imageName.front
  const machinePopularName = ALL_MACHINES_COMMOM_INFO[machineName].popularName
  const planName = ALL_USER_TAGS_PLANS_MAPPER[catalog] as AllPlansType
  const planPopularName = ALL_PLANS_COMMOM_INFO[planName].popularName

  return (
    <div className="flex flex-col p-24 min-w-[312px] h-full bg-display-0 rounded-md shadow">
      <div className="flex justify-center items-center mb-16">
        <CloudinaryImage
          src={`site-ton/maquininhas/${machineImage}`}
          alt={`Imagem da máquininha ${machineImage}`}
          width={120}
          height={180}
          className="object-contain max-w-full h-auto"
        />
      </div>
      <h3 className="flex gap-6 justify-center items-center text-[40px] leading-[48px]">
        <span className="font-bold">{machinePopularName}</span>
        <span className="font-light">{planPopularName}</span>
      </h3>
      <div className="flex justify-center items-center my-16">
        <MachinePrice
          className="justify-between"
          from={from}
          to={to}
          or={or}
          installment={installmentParcel}
        />
      </div>
      <div className="flex gap-8">
        <RetainQueryLink
          href={linkToProduct}
          className="p-0 w-1/2 btn btn-secondary btn-regular"
        >
          Saiba mais
        </RetainQueryLink>
        <RetainQueryLink
          href={linkToCheckout}
          className="p-0 w-1/2 btn btn-primary btn-regular"
        >
          Pedir agora
        </RetainQueryLink>
      </div>
    </div>
  )
}
export default AnotherMachineCard
