import Head from 'next/head'

import { CLOUDINARY_BASE_URL } from '@/constants/links'
import { ImageCMSProps, ImagesProps } from '@/types/cms'

import CloudinaryImage from '@/components/CloudinaryImage'
import Button, { ButtonV2Props } from '../ButtonV2'

interface BasicBannerProps {
  referId?: string
  tag?: string
  title: string
  description?: string
  images: ImagesProps
  iconImage?: ImageCMSProps
  buttons: ButtonV2Props[]
}

export function BasicBanner({
  referId,
  tag,
  title,
  description,
  images,
  iconImage,
  buttons
}: BasicBannerProps) {
  return (
    <>
      <Head>
        <link
          rel="preload"
          media="(min-width: 768px)"
          href={`${CLOUDINARY_BASE_URL}/w_${images.tablet?.width},q_auto/${images.tablet?.path}`}
          as="image"
        />
        <link
          rel="preload"
          media="(max-width: 767px)"
          href={`${CLOUDINARY_BASE_URL}/w_${images.mobile?.width},q_auto/${images.mobile?.path}`}
          as="image"
        />
      </Head>
      <section
        id={referId}
        className="relative md:px-24 md:mt-8 lg:mt-16 md:mb-40 md:h-[425px] lg:h-[423px]"
      >
        <div className="flex flex-col md:flex-row md:gap-24 lg:gap-40 justify-between items-center md:items-center py-40 md:py-0 px-16 md:px-0 m-auto w-full max-w-screen-xl h-full bg-display-100 md:rounded-[24px] xl:rounded-[40px] md:rounded-bl-none xl:rounded-bl-none">
          <div className="flex relative flex-col gap-16 lg:gap-8 justify-center items-center md:items-start md:py-24 lg:py-16 md:px-24 md:pr-0 md:pl-24 lg:pl-32 w-full max-w-[455px] md:max-w-[620px]">
            <div className="flex md:order-1 justify-center items-center lg:mb-8 min-w-[87px] min-h-[33px] bg-danger-450 rounded-t-[8px] rounded-br-[8px] md:rounded-br-none xl:rounded-br-[8px] md:rounded-bl-[8px] xl:rounded-bl-none">
              <p className="p-8 leading-normal text-display-0 paragraph-14">
                {tag}
              </p>
            </div>
            <h1 className="md:order-2 font-heading text-[36px] font-extrabold leading-[44px] text-center md:text-left text-display-900 lg:heading-1">
              {title}
            </h1>
            <div className="md:hidden overflow-hidden relative w-full h-[200px] rounded-t-[24px] rounded-br-[24px]">
              <div className="absolute w-full h-[200px]">
                <picture>
                  <source
                    srcSet={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_1,q_1/${images.mobile?.path}`}
                    media="(min-width:768px)"
                  />
                  <source
                    srcSet={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_${images.mobile?.width},q_auto/${images.mobile?.path}`}
                    media="(min-width:200px)"
                  />
                  <source
                    srcSet={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_1,q_1/${images.mobile?.path}`}
                    media="(min-width:0)"
                  />
                  <img
                    src={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_1,q_1/${images.mobile?.path}`}
                    alt={images.mobile?.alternativeText}
                    style={{
                      objectFit: 'cover',
                      height: '200px'
                    }}
                  />
                </picture>
                {iconImage && (
                  <div className="absolute top-16 left-16 z-10 w-64 h-64">
                    <CloudinaryImage
                      width={64}
                      height={64}
                      src={iconImage.path}
                      alt={iconImage.alternativeText}
                      quality={100}
                      className="object-contain max-w-full h-auto"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="md:order-4 lg:mt-16 w-full md:w-auto">
              {buttons.map((props) => (
                <Button
                  key={props.label}
                  className="font-bold lg:btn-large"
                  {...props}
                />
              ))}
            </div>
            <p className="md:order-3 text-center md:text-left text-display-900 paragraph-16 lg:paragraph-18">
              {description}
            </p>
          </div>
          <div className="hidden md:flex overflow-hidden relative md:mr-24 lg:mr-32 w-auto md:min-w-[324px] md:max-w-[324px] lg:max-w-[620px] h-full md:h-[377px] lg:h-[352px] max-h-[377px] md:rounded-t-[16px] lg:rounded-t-[24px] md:rounded-br-[16px] lg:rounded-br-[24px]">
            <picture>
              <source
                srcSet={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_${images.tablet?.width},q_auto/${images.tablet?.path}`}
                media="(min-width:768px)"
              />
              <source
                srcSet={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_1,q_1/${images.mobile?.path}`}
                media="(min-width:0)"
              />
              <img
                src={`${CLOUDINARY_BASE_URL}/fl_progressive/f_auto,c_limit,w_1,q_1/${images.tablet?.path}`}
                alt={images.tablet?.alternativeText}
                style={{
                  objectFit: 'cover',
                  height: '100%'
                }}
              />
            </picture>
            {iconImage && (
              <div className="absolute top-16 left-16 z-10">
                <CloudinaryImage
                  width={88}
                  height={88}
                  src={iconImage.path}
                  alt={iconImage.alternativeText}
                  quality={100}
                  className="object-contain w-[72px] lg:w-[88px] max-w-full h-[72px] lg:h-[88px]"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}
