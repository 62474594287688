import { useState } from 'react'

import Button from '@/cmsComponents/ButtonV2'
import CloudinaryImage from '@/components/CloudinaryImage'
import Icon from '@/components/Icon'
import Modal from '@/components/Modal'
import { useCatalog } from '@/contexts/catalog'
import { AllMachinesType } from '@/types/machines'

interface FamilhaoTonWidgetProps {
  machineName: AllMachinesType
}

export const FamilhaoTonWidget = ({ machineName }: FamilhaoTonWidgetProps) => {
  const { familhaoDoTonPerMachine, setFamilhaoDoTonPerMachine } = useCatalog()
  const [isModalOpen, setIsModalOpen] = useState(false)

  function handleFamilhaoDoTonPerMachine() {
    const familhaoDoTonPerMachineModified = {
      ...familhaoDoTonPerMachine,
      [machineName]: {
        hasFamilhaoDoTon: !familhaoDoTonPerMachine[machineName].hasFamilhaoDoTon
      }
    }

    setFamilhaoDoTonPerMachine(familhaoDoTonPerMachineModified)
  }

  return (
    <>
      <div className="flex flex-col gap-8 p-8 mt-16 bg-display-200 rounded-16">
        <div className="flex flex-col gap-2 justify-center items-center ">
          <CloudinaryImage
            src="/site-ton/logo-familhao-do-ton"
            alt="Logo Familhão do Ton"
            width={83}
            height={50}
          />

          <div className="flex py-2 px-10 bg-newTon-800 rounded-12">
            <span className="flex font-heading text-[11px] font-semibold text-display-0">
              CONCORRA A:
            </span>
          </div>
        </div>

        <div className="flex gap-16 justify-center items-center font-heading text-newTon-800">
          <div className="flex flex-col gap-4">
            <span className="text-16 leading-1">1 SORTEIO DE</span>
            <span className="text-20 font-extrabold leading-1">1 MILHÃO*</span>
          </div>

          <Icon name="plus-circle" />

          <div className="flex flex-col gap-1">
            <span className="text-16 leading-1">1 SORTEIO DE</span>
            <span className="text-20 font-extrabold leading-1">2CARROS</span>
            <span className="text-[13px] leading-1">
              E <span className="font-extrabold">6 MOTOS</span> OKM**
            </span>
          </div>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name={`familhao-ton-${machineName}`}
            id={`familhao-ton-${machineName}`}
            checked={familhaoDoTonPerMachine[machineName].hasFamilhaoDoTon}
            onChange={() => handleFamilhaoDoTonPerMachine()}
          />
          <label
            htmlFor={`familhao-ton-${machineName}`}
            className="ton-paragraph-12"
          >
            Adicione por R$ 1 a mais na sua parcela
          </label>
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            className="text-display-900 underline ton-paragraph-12"
            onClick={() => setIsModalOpen(true)}
          >
            Saiba Mais
          </button>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        closeOnClickOut={true}
        onCloseClick={() => setIsModalOpen(false)}
        className="flex items-end md:items-center !p-0 w-full md:max-w-[618px] h-full bg-transparent rounded-b-none"
        classNameContainer="z-[101]"
      >
        <div className="flex overflow-y-auto relative flex-col gap-12 py-24 px-16 w-full max-h-[90%] md:max-h-full bg-white md:rounded-[16px] rounded-t-24 rounded-b-none">
          <div className="flex flex-col gap-8 justify-center items-center w-full">
            <CloudinaryImage
              src="site-ton/logo-familhao-do-ton"
              alt="Logo Familhão do Ton"
              width={137}
              height={83}
            />

            <div className="flex gap-8 w-full">
              <div className="flex justify-center items-center w-48 h-48 bg-newTon-600 rounded-10 rounded-br-none">
                <Icon
                  name="emotiton-money"
                  className="w-48 h-48 text-display-0"
                />
              </div>

              <div className="flex justify-center items-center p-8 w-full font-bold text-display-0 bg-newTon-800 rounded-8 rounded-bl-none">
                <p className="ton-paragraph-16">O que é o Familhão do Ton?</p>
              </div>
            </div>
          </div>

          <p className="text-center ton-paragraph-12 md:ton-paragraph-14">
            É o clube de benefícios por assinatura que te dá a chance de
            concorrer a prêmios milionários! Se você é cliente Ton, garante
            desconto na assinatura e vantagens exclusivas com ainda mais
            prêmios.
          </p>

          <p className="text-center ton-paragraph-12 md:ton-paragraph-14">
            Concorra a sorteios de <b>1 milhão*, 2 carros e 6 motos 0km**.</b>
          </p>

          <p className="text-center ton-paragraph-12 md:ton-paragraph-14">
            Tenha um mês de acesso ao Familhão do Ton. Se você adicionar mais
            pacotes, seus benefícios são multiplicados. Exemplo: 2 pacotes = 2
            números da sorte para os sorteios.
          </p>

          <p className="text-[8px] md:text-[10px] leading-[12px]">
            *Promoção válida de 26/09/2024 a 10/02/2025. Consulte regulamento,
            condições, períodos e demais informações em
            <span className="underline">https://www.familhao.com</span>.
            Certificado de Autorização SPA n. 04.036926/2024. Conheça o clube de
            benefícios Familhão em
            <span className="underline">https://www.familhao.com</span>. *Prêmio
            entregue por meio de certificado de ouro. **Promoção válida de
            05/11/2024 a 26/03/2025. Consulte regulamento, condições, períodos e
            demais informações em
            <span className="underline">www.ton.com.br/familhao-do-ton</span>.
            Certificado de Autorização SPA n. 04.037944/2024. * Prêmio entregue
            por meio de certificado de ouro, com sugestão de uso para compra de
            carro ou moto.
          </p>

          <div className="flex flex-col gap-16 justify-center items-center">
            <Button
              label="Fechar"
              className="!w-full"
              onClick={() => setIsModalOpen(false)}
              type="button"
            />

            <Button
              linkPathname="/familhao-do-ton"
              linkTarget="_blank"
              label="Confira todas as vantagens"
              uiStyleVariant="link"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
