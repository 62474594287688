import Icon from '@/components/Icon'
import { useCatalog } from '@/contexts/catalog'

export function DiscountCoupon({ PercentApplied }: { PercentApplied: number }) {
  return (
    <a
      href="#maquininhas"
      className="block top-0 z-50 w-full bg-yellow-400"
      id="coupon"
    >
      <p className="flex gap-8 justify-center items-center py-8 px-16 text-[12px] md:text-[14px] font-bold leading-[18px] md:leading-[20px] text-display-900">
        <span className="w-16 h-16">
          <Icon name="e-tag-coupon" className="w-full h-full fill-current" />
        </span>
        Cupom de {PercentApplied}% de desconto aplicado ao carrinho
      </p>
    </a>
  )
}

export default function ActiveDiscountCoupon() {
  const { couponPercentApplied } = useCatalog()

  return couponPercentApplied && couponPercentApplied > 0 ? (
    <DiscountCoupon PercentApplied={couponPercentApplied} />
  ) : (
    <></>
  )
}
