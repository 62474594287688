import { nanoid } from 'nanoid'

export const LOCAL_STORAGE_USER_ID_KEY = 'amplitude-user-id' as const

export default function getUserId(): string {
  const existingUserId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY)

  if (existingUserId) {
    return existingUserId
  }

  const newUserId = nanoid(16)
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, newUserId)

  return newUserId
}
