import { tv, VariantProps } from 'tailwind-variants'

import Icon, { IconsNames } from '@/components/Icon'
import { Machine } from '@/types'

const cardDetails = tv({
  slots: {
    container: 'flex flex-col h-full justify-between',
    details: 'flex flex-col gap-16'
  },

  variants: {
    open: {
      true: {
        container: 'gap-16',
        details: 'max-h-[500px] visible opacity-100'
      },
      false: {
        details: 'max-h-[0px] invisible opacity-0'
      }
    }
  },

  defaultVariants: {
    open: false
  }
})

const { container, details } = cardDetails()

interface MachineCardDetailsProps extends VariantProps<typeof cardDetails> {
  machine: Machine
  toggleDetails: () => void
}

export const MachineCardDetails = ({
  machine,
  open,
  toggleDetails
}: MachineCardDetailsProps) => {
  const { features } = machine
  const iconAction: IconsNames = open ? 'chevron-up' : 'chevron-down'
  const labelAction = open ? 'Menos detalhes' : 'Mais detalhes'

  return (
    <div className={container({ open })}>
      <div
        style={{
          transition: 'max-height 1s, visibility 0.7s, opacity 0.3s'
        }}
        className={details({ open })}
      >
        {features.map((feature) => (
          <div
            key={`feature-machine-${feature.icon}`}
            className="flex gap-8 items-center font-medium first:!font-bold first:text-newTon-700"
          >
            <div className="w-24 h-24">
              <Icon
                name={feature.icon as IconsNames}
                className="fill-current"
              />
            </div>
            <p className=" ton-paragraph-14">{feature.name}</p>
          </div>
        ))}
      </div>

      <button
        onClick={() => toggleDetails()}
        className="flex gap-4 justify-center items-center"
      >
        <Icon name={iconAction} className="text-newTon-700 fill-current" />
        <span className="font-medium text-newTon-700 ton-label-14">
          {labelAction}
        </span>
      </button>
    </div>
  )
}
