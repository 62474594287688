/* eslint-disable tailwindcss/no-custom-classname */
import 'swiper/css'
import 'swiper/css/navigation'

import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from '@/components/Icon'

import { SwiperOptions } from 'swiper/types'
import { Slide } from './Slide'
import { SlideProps } from './Slide/types'
import { useCountdownsContext } from '@/contexts/countdowns'

type Props = {
  slides: SlideProps[]
}

const swiperOptions: SwiperOptions = {
  slidesPerView: 1,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
  modules: [Navigation, Autoplay],
  navigation: {
    nextEl: '.nextEl',
    prevEl: '.prevEl',
    disabledClass: '!text-newTon-800'
  }
}

export function CarouselBar({ slides }: Props) {
  const { activeCountdowns } = useCountdownsContext()

  return (
    <div className="relative">
      <Swiper
        {...swiperOptions}
        className="!p-8 text-center text-white bg-display-900 cashback_swiperC"
      >
        <div className="absolute top-1/2 left-8 md:left-[calc(50%-287.5px)] lg:left-1/2 xl:left-[calc(50%-78.5px)] z-50 text-newTon-500 lg:translate-x-[-264px] -translate-y-1/2 prevEl">
          <div className="w-16 md:w-24 h-16 md:h-24 text-current cursor-pointer">
            <Icon
              name="chevron-down"
              className="z-50 w-full h-auto rotate-90 fill-current"
            />
          </div>
        </div>

        <div className="absolute top-1/2 right-8 md:right-[calc(50%-287.5px)] xl:right-[calc(50%-60.5px)] lg:left-1/2 xl:left-auto z-50 text-newTon-500 lg:translate-x-[264px] -translate-y-1/2 nextEl">
          <div
            className={'w-16 md:w-24 h-16 md:h-24 text-current cursor-pointer'}
          >
            <Icon
              name="chevron-down"
              className="z-50 w-full h-auto -rotate-90 fill-current"
            />
          </div>
        </div>

        {slides
          .filter((slideProps) => {
            if (!slideProps.countdownID) return true

            return activeCountdowns.has(slideProps.countdownID)
          })
          .map((slideProps, index) => {
            return (
              <SwiperSlide key={index} className="m-auto">
                <Slide {...slideProps} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </div>
  )
}
