import CloudinaryImage from '@/components/CloudinaryImage'
import { useVideoPlayer } from '@/contexts/videoPlayer'
import { ImageCMSProps, VideoReviewBlockProps } from '@/types/cms'

type ReviewVideoCardProps = VideoReviewBlockProps

export const ReviewVideoCard = ({ thumb, video }: ReviewVideoCardProps) => {
  const { mountVideoPlayer } = useVideoPlayer()

  const handleVideoClick = (video: ImageCMSProps | undefined) => {
    if (video && video.url) {
      mountVideoPlayer(video.url)
    }
  }

  return (
    <div
      className="rounded-16 border-4 border-newTon-600"
      onClick={() => handleVideoClick(video)}
    >
      {thumb && (
        <CloudinaryImage
          width={thumb.width}
          height={thumb.height}
          src={thumb.path}
          alt={thumb.alternativeText}
          className="object-contain w-full h-auto rounded-12 cursor-pointer"
          sizes="100vw"
        />
      )}
    </div>
  )
}
