import { FooterSprite } from '@/components/IconSprite'

const Stoneco: React.FC = () => {
  return (
    <div className="flex justify-end mb-24 h-24">
      <a
        aria-label="Stone.co"
        href="https://www.stone.co/"
        rel="noreferrer"
        target="_blank"
      >
        <FooterSprite
          name="logo_stoneco"
          className="w-full max-w-[88px] h-full"
        />
      </a>
    </div>
  )
}

export default Stoneco
