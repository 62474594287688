import clsx from 'clsx'
import Head from 'next/head'

import Button from '@/cmsComponents/ButtonV2'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import { CLOUDINARY_BASE_URL } from '@/constants/links'

import styles from './HeroPromoUntil35OFF.module.css'

const HeroPromoUntil35OFF = () => {
  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          href={`${CLOUDINARY_BASE_URL}/w_600/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-mobile.webp`}
          media="(max-width: 767.9px)"
        />
        <link
          rel="preload"
          as="image"
          href={`${CLOUDINARY_BASE_URL}/w_1400/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-desktop.webp`}
          media="(min-width: 1260px)"
        />
        <link
          rel="preload"
          as="image"
          href={`${CLOUDINARY_BASE_URL}/w_1200/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-desktop.webp`}
          media="(min-width: 1024px) and (max-width: 1259.9px)"
        />
        <link
          rel="preload"
          as="image"
          href={`${CLOUDINARY_BASE_URL}/w_900/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-tablet.webp`}
          media="(min-width: 768px) and (max-width: 1023.9px)"
        />
      </Head>

      <div className="flex p-16 md:p-24 lg:py-40 2xl:px-0 bg-ton-400 rounded-b-16 lg:rounded-b-24">
        <div className="mx-auto max-w-[412px] md:max-w-[1280px]">
          <div className={styles.wrapper}>
            <div className={styles.grid_title} id="hero_home_title">
              <div className="flex flex-col gap-8">
                <h1 className="font-extrabold text-center md:text-left text-newTon-900">
                  <span className="text-display-0">
                    Tá no corre pra vender?
                  </span>{' '}
                  <br />O Ton tá nessa com você
                </h1>

                <p className="hidden md:block lg:paragraph-18">
                  A melhor máquina de cartão pra você vender rápido e receber
                  todo o dinheiro no mesmo dia na Super Conta Ton
                </p>
              </div>
            </div>

            <div className={styles.grid_image} id="hero_home_image">
              <RetainQueryLink href={{ hash: 'maquininhas' }}>
                <picture>
                  <source
                    media="(min-width: 1260px)"
                    srcSet={`${CLOUDINARY_BASE_URL}/w_1400/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-desktop.webp`}
                  />
                  <source
                    media="(min-width: 1024px)"
                    srcSet={`${CLOUDINARY_BASE_URL}/w_1200/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-desktop.webp`}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${CLOUDINARY_BASE_URL}/w_900/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-tablet.webp`}
                  />
                  <source
                    media="(min-width: 320px)"
                    srcSet={`${CLOUDINARY_BASE_URL}/w_600/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-mobile.webp`}
                  />
                  <img
                    alt={`3 empreendedores e cada um está segurando uma Máquina de Cartão do Ton na sua frente. Ao lado esquerdo contém um balão de 35% off na cor roxa e mais abaixo um outro balãozinho na cor verde com a frase "vendeu hoje? recebeu hoje!" e um emoji de foguinho`}
                    src={`${CLOUDINARY_BASE_URL}/f_auto,q_auto/site-ton/promo-ta-no-corre/maquininha-de-cartao-ton-empreendedores-banner-home-header-mobile`}
                  />
                </picture>
              </RetainQueryLink>
            </div>

            <div
              className={clsx(
                styles.grid_cta,
                'flex flex-col lg:flex-row items-start space-y-16 lg:space-y-0 lg:space-x-24'
              )}
              id="hero_home_cta"
            >
              <Button
                color="secondary"
                label="Peça já sua maquininha"
                linkPathname="/"
                linkHash="maquininhas"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroPromoUntil35OFF
