import dynamic from 'next/dynamic'
import React, { useCallback, useEffect, useRef } from 'react'

import cn from '@/utils/cn'
import { listenClickOnElementBasedOnRef } from '@/utils/listenClickOnElementBasedOnRef'

const ClientOnlyPortal = dynamic(() => import('../ClientOnlyPortal'), {
  ssr: false
})

interface IModal {
  open: boolean
  onCloseClick(): void
  children: React.ReactNode
  closeOnClickOut?: boolean
  renderOnOpen?: boolean
  className?: string
  classNameContainer?: string
}

const Modal: React.FC<IModal> = ({
  open,
  children,
  onCloseClick,
  closeOnClickOut = true,
  renderOnOpen,
  className,
  classNameContainer
}) => {
  const modalContainerRef = useRef(null)

  const modalEventListener = useCallback(
    (event: MouseEvent) =>
      listenClickOnElementBasedOnRef(
        modalContainerRef,
        ({ clickOnElement }) => {
          if (open && clickOnElement) {
            onCloseClick()
          }
        }
      )(event),
    [open, onCloseClick, modalContainerRef]
  )

  useEffect(() => {
    if (closeOnClickOut && open) {
      window.addEventListener('click', modalEventListener)

      return () => {
        window.removeEventListener('click', modalEventListener)
      }
    }
  }, [open, closeOnClickOut, modalEventListener])

  if (renderOnOpen && !open) {
    return null
  }

  return (
    <ClientOnlyPortal selector="#modal">
      <div
        ref={modalContainerRef}
        className={cn(
          'fixed bg-display-900/40 bg-opacity-75 top-0 left-0 bottom-0 right-0 z-50 flex items-center justify-center',
          !open && 'hidden',
          classNameContainer
        )}
      >
        <div
          className={cn(
            'flex overflow-auto md:overflow-visible md:p-24 py-16 px-24 bg-display-0 h-full md:h-[90%] rounded',
            className
          )}
        >
          {children}
        </div>
      </div>
    </ClientOnlyPortal>
  )
}

export default Modal
