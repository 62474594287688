import { MarkdownContent } from '@/cmsComponents/MarkdownContent'
import CloudinaryImage from '@/components/CloudinaryImage'
import { RetainQueryLink } from '@/components/RetainQueryLink'
import cn from '@/utils/cn'

import { Countdown } from '../Countdown'

import { SlideProps } from './types'

import styles from './Slide.module.css'
import { useCountdownsContext } from '@/contexts/countdowns'

export function Slide({
  image,
  link,
  text,
  textColor,
  countdownID,
  textHighlighColor
}: SlideProps) {
  const { countdownsByID } = useCountdownsContext()
  const countdown = countdownsByID.get(countdownID)

  return (
    <RetainQueryLink
      href={{ pathname: link.href, hash: link.hash }}
      className="flex justify-center items-center mx-auto md:max-w-full md:h-full font-bold text-center cursor-pointer"
    >
      <div className="flex md:flex-row gap-8 mx-auto">
        {image && (
          <span className="flex items-center !w-40 min-w-[40px] !h-48">
            <CloudinaryImage
              src={image.path}
              alt={image.alternativeText}
              width={image.width}
              height={image.height}
              className="object-cover max-w-full h-auto"
              quality={70}
              priority
            />
          </span>
        )}

        <div className="flex justify-center pr-6">
          <MarkdownContent
            className={cn(
              styles.Content,
              `text-${textColor.classColor}`,
              styles[textHighlighColor.classColor]
            )}
            content={text}
          />

          {countdown && <Countdown finalDate={countdown.period.endAt} />}
        </div>
      </div>
    </RetainQueryLink>
  )
}
