import { KeenSliderOptions } from 'keen-slider'

// Configuração inicial
export const initialSliderConfig: Pick<
  KeenSliderOptions,
  'slides' | 'mode' | 'renderMode'
> = {
  slides: {
    spacing: 4,
    perView: 'auto'
  },
  mode: 'free-snap',
  renderMode: 'performance'
}
