import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { UrlObject } from 'url'

import { handleAppUtmUrl } from '@/utils/handleAppUtmUrl'

interface RetainQueryProps {
  isDownload?: boolean
  isTapton?: boolean
  className?: string
}

export type RetainQueryLinkHref = UrlObject
export type RetainQueryLinkProps = LinkProps &
  PropsWithChildren<unknown> &
  RetainQueryProps & {
    query?: string
  }

export const RetainQueryLink = ({
  href,
  query: passQuery,
  prefetch = false,
  isDownload = false,
  isTapton = false,
  ...props
}: RetainQueryLinkProps) => {
  const router = useRouter()
  const [linkToDownloadApp, setLinkToDownloadApp] = useState('')

  const pathname = typeof href === 'object' ? href.pathname : href

  const query =
    typeof href === 'object' && typeof href.query === 'object' ? href.query : {}

  const hash =
    typeof href === 'object' && typeof href.hash === 'string' ? href.hash : ''

  const hrefDefault = {
    pathname: pathname,
    query: passQuery || {
      ...router?.query,
      ...query
    },
    hash: hash
  }

  useEffect(() => {
    const handleURLUtm = isDownload ? handleAppUtmUrl(isTapton) : ''
    setLinkToDownloadApp(handleURLUtm)
  }, [isDownload, isTapton])

  return (
    <Link
      {...props}
      href={isDownload ? linkToDownloadApp : hrefDefault}
      prefetch={prefetch}
      passHref
    />
  )
}
